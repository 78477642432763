// @file Surface remake panel store

import { trackEvent } from '@@/bits/analytics'
import { captureException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { getVuexStore } from '@@/bits/pinia'
import { TITLE_CHAR_LIMIT } from '@@/bits/surface_settings_helper'
import { isPartOfUnlimitedSandboxesEvent } from '@@/bits/unlimited_sandboxes_event'
import { displayNameForUser } from '@@/bits/user_model'
import { LibraryType } from '@@/enums'
import anxiousFace from '@@/images/anxious_face.svg'
import stopHandIcon from '@@/images/stop_hand.svg'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfaceSharePanelStore } from '@@/pinia/surface_share_panel'
import PadletApi from '@@/surface/padlet_api'
import type { Library, LibraryAccess, LibraryId, User, UserLibrarySetting } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

export const useSurfaceRemakePanelStore = defineStore('surfaceRemakePanelStore', () => {
  const surfaceCurrentUserStore = useSurfaceCurrentUserStore()
  const surfaceSharePanelStore = useSurfaceSharePanelStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfaceStore = useSurfaceStore()
  const globalAlertDialogStore = useGlobalAlertDialogStore()
  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */
  const surfaceVuexStore = getVuexStore<RootState>()

  const user = surfaceCurrentUserStore.currentUser as User
  const isLibraryWall: boolean = surfaceStore.isLibraryWall
  const isBuilderCurrentUser = computed((): boolean => surfaceVuexStore?.getters.isBuilderCurrentUser)
  const wall = computed(() => surfaceVuexStore?.getters.wall)
  const isOrgWall = computed((): boolean => surfaceVuexStore?.getters.isOrgWall)
  const canIAdminister = computed((): boolean => surfacePermissionsStore.canIAdminister)
  const copyWallpaper = ref<boolean>(true)
  const copyPosts = ref<boolean>(true)
  const copyOnlyFrozenObjects = ref<boolean>(false)
  const copyComments = ref<boolean>(false)
  const copyReactions = ref<boolean>(false)
  const copyAuthorship = ref<boolean>(false)
  const copyPeopleAndPrivacy = ref<boolean>(false)
  const privacyType = computed((): string => surfaceSharePanelStore.privacyType)
  const hasCollaborators = computed((): boolean => surfaceSharePanelStore.collaborators.length > 0)

  const xRemakePanel = ref<boolean>(false)
  const xLibrarySelectionMenu = ref<boolean>(false)

  const librariesById = ref<Record<LibraryId, Library>>({})
  const creatableLibraryIds = ref<LibraryId[]>([])
  const librariesArray = computed((): Library[] => Object.values(librariesById.value))
  const wallViewableAndVisibleLibrariesArray = computed((): Library[] =>
    librariesArray.value.filter((library) => library.visible),
  )

  const librarySelected = ref<Library | null>(null)
  const defaultLibrarySetting = computed((): UserLibrarySetting => surfaceVuexStore?.getters.defaultLibrary)
  const currentLibrary = computed((): Partial<Library> => {
    return {
      name: librarySelected.value != null ? librarySelected.value.name : displayNameForUser(user),
      avatar: librarySelected.value != null ? librarySelected.value?.avatar : user.avatar,
      libraryType: librarySelected.value != null ? librarySelected.value.libraryType : LibraryType.Solo,
      libraryAccess: librarySelected.value != null ? librarySelected.value.libraryAccess : emptyLibraryAccess,
    }
  })

  const titleInputString = ref<string>('')
  const isTitleValid = computed(
    () => titleInputString.value?.length > 0 && titleInputString.value?.length < TITLE_CHAR_LIMIT,
  )
  const isRemakeButtonDisabled = computed(() => !isTitleValid.value)

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */
  const showRemakePanel = (): void => {
    xRemakePanel.value = true
  }

  const closeRemakePanel = (): void => {
    xRemakePanel.value = false
  }

  function toggleLibrarySelectionMenu(): void {
    xLibrarySelectionMenu.value = !xLibrarySelectionMenu.value
  }

  function closeLibrarySelectionMenu(): void {
    xLibrarySelectionMenu.value = false
  }

  function updateLibrarySelected(payload: { librarySelected: Library; user: User }): void {
    librarySelected.value = payload.librarySelected
    xLibrarySelectionMenu.value = !xLibrarySelectionMenu.value
  }

  async function initializeStore(payload?: { librariesById; wallCreatableLibraryIds }): Promise<void> {
    const promisesArray: Array<Promise<void>> = []

    if (!isOrgWall.value) {
      promisesArray.push(getVuexStore()?.dispatch('fetchUserLibrarySettings') as Promise<void>)
    }

    if (canIAdminister.value) {
      promisesArray.push(surfaceSharePanelStore.fetchWallAccessSettings())
    }

    await Promise.all(promisesArray)

    if (payload !== undefined) {
      librariesById.value = payload.librariesById
      creatableLibraryIds.value = payload.wallCreatableLibraryIds
    }

    // Re-initialize the selected library when the wallViewableAndVisibleLibrariesArray is fetched fully
    // Context: when you first open the remake panel, the wallViewableAndVisibleLibrariesArray is not fetched yet. So the initial library is not set correctly
    watch(
      [wallViewableAndVisibleLibrariesArray],
      () => {
        initializeSelectedLibrary()
      },
      { immediate: true },
    )
  }

  function initializeSelectedLibrary(): void {
    if (wallViewableAndVisibleLibrariesArray.value == null || defaultLibrarySetting.value == null) {
      // cannot initialize librarySelected if wallViewableAndVisibleLibrariesArray or defaultLibrarySetting is not fetched yet
      return
    }
    const wallInPersonalLibrary: boolean = !isLibraryWall && isBuilderCurrentUser.value
    const wallLibrary: Library | undefined = findLibraryById(wall.value?.library_id)

    if (wallLibrary !== undefined && !wallInPersonalLibrary && canUseLibrary(wallLibrary)) {
      librarySelected.value = wallLibrary
    } else {
      const defaultLibrary: Library | null = findLibraryById(Number(defaultLibrarySetting.value.libraryId)) ?? null // null represents personal library
      if (canUseLibrary(defaultLibrary)) {
        librarySelected.value = defaultLibrary
      } else {
        librarySelected.value = findUsableLibrary() ?? defaultLibrary
      }
    }
  }

  function findLibraryById(id: number): Library | undefined {
    return wallViewableAndVisibleLibrariesArray.value.find((library) => library.id === id)
  }

  function canUseLibrary(library: Library | null): boolean {
    // If library is a personal library, user can use it if they have quota
    if (library == null) {
      if (wall.value.viz === 'whiteboard' && isPartOfUnlimitedSandboxesEvent()) {
        return true
      }
      return user.quota.can_make
    }
    return (
      creatableLibraryIds.value.includes(library.id) &&
      (library.isTrialExpired == null || !library.isTrialExpired) &&
      !library.isPlanCancelled
    )
  }

  function findUsableLibrary(): Library | undefined {
    return wallViewableAndVisibleLibrariesArray.value.find(canUseLibrary)
  }

  async function remakeWall(): Promise<void> {
    // If remaking into personal library
    if (librarySelected.value == null) {
      if (wall.value.viz === 'whiteboard' && isPartOfUnlimitedSandboxesEvent()) {
        // NOOP. We do not check quotas for sandboxes during the Unlimited Sandboxes event.
      } else if (!user.quota.can_make) {
        usePersonalPlansStore().quotaTriggeredUpgrade({
          user,
        })
        return
      }
    }

    // If remaking into non-personal library
    if (librarySelected.value != null) {
      // User selected a non-creatable library
      if (!creatableLibraryIds.value.includes(librarySelected.value.id)) {
        if (librarySelected.value.libraryType === LibraryType.Classroom) {
          globalAlertDialogStore.openAlertDialog({
            iconSrc: stopHandIcon,
            title: __('Unable to make new padlets'),
            body: __('A teacher has disabled permissions for making new padlets.'),
            xShadow: true,
          })
          return
        }

        if (
          librarySelected.value.libraryType === LibraryType.School ||
          librarySelected.value.libraryType === LibraryType.Team
        ) {
          globalAlertDialogStore.openAlertDialog({
            iconSrc: stopHandIcon,
            title: __('Unable to make new padlets'),
            body: __('An admin has disabled permissions for making new padlets.'),
            xShadow: true,
          })
          return
        }
      }

      // library has hit the padlet quota.
      if (librarySelected.value.quota.quotaHit) {
        globalAlertDialogStore.openAlertDialog({
          iconSrc: anxiousFace,
          iconAlt: __('Anxious face'),
          title: __('Padlet quota reached!'),
          body: __('This team has reached its padlet quota. Ask the team owner to upgrade to continue making padlets.'),
          xShadow: true,
        })
        return
      }
    }

    try {
      const data = await PadletApi.RemakePanel.fetch(wall.value.hashid, {
        title: titleInputString.value,
        with_posts: copyPosts.value,
        with_only_frozen_objects: copyOnlyFrozenObjects.value,
        with_comments: copyComments.value,
        with_reactions: copyReactions.value,
        with_same_privacy: copyPeopleAndPrivacy.value,
        with_original_authors: copyAuthorship.value,
        library_id: librarySelected.value?.id,
        copy_wallpaper: copyWallpaper.value,
      })
      trackEvent('Surface', 'Remade padlet', null, null, { sourceWallId: wall.value.id })
      window.location.href = data.next
    } catch (error) {
      captureException(error)
    }
  }

  function toggleCopyWallpaper(): void {
    copyWallpaper.value = !copyWallpaper.value
  }

  function toggleCopyPosts(): void {
    const newValue = !copyPosts.value
    // If turning off copyPosts, also turn off related settings
    if (!newValue) {
      copyAuthorship.value = false
      copyReactions.value = false
      copyComments.value = false
    }
    // When turning on copy posts, leave other settings as they are
    copyPosts.value = newValue
  }

  const copyObjects = ref<'none' | 'only_frozen' | 'all'>('all')

  function toggleObjectsCopyMode(value: 'none' | 'only_frozen' | 'all') {
    copyObjects.value = value
    switch (value) {
      case 'none':
        copyPosts.value = false
        copyOnlyFrozenObjects.value = false
        break
      case 'only_frozen':
        copyPosts.value = true
        copyOnlyFrozenObjects.value = true
        break
      case 'all':
        copyPosts.value = true
        copyOnlyFrozenObjects.value = false
        break
    }
  }

  function toggleCopyComments(): void {
    copyComments.value = !copyComments.value
  }

  function toggleCopyReactions(): void {
    copyReactions.value = !copyReactions.value
  }

  function toggleCopyAuthorship(): void {
    copyAuthorship.value = !copyAuthorship.value
  }

  function toggleCopyPeopleAndPrivacy(): void {
    copyPeopleAndPrivacy.value = !copyPeopleAndPrivacy.value
  }

  /* ---------------------- */
  /* CONSTANTS                */
  /* ---------------------- */
  const emptyLibraryAccess: LibraryAccess = {
    canViewLibraryInfo: false,
    canUpdateLibraryInfo: false,
    canSetupLti: false,
    canUpdatePermissions: false,
    canViewLibraryMemberships: false,
    canViewLibraryAnalytics: false,
    canManageMembers: false,
    canManageBilling: false,
    canTrashLibrary: false,
    canUseLibraryAsOwner: false,
    canUseLibraryAsAdmin: false,
    canUseLibraryAsTeacher: false,
    canViewLibrarySecurity: false,
    canInviteSchoolAdmins: false,
    canInviteSchoolTeachers: false,
    canInviteSchoolStudents: false,
    canInviteMembers: false,
    canViewGallery: false,
    canManageContentSafetySettings: false,
  }

  return {
    user,
    wall,
    xRemakePanel,
    xLibrarySelectionMenu,
    creatableLibraryIds,
    librarySelected,
    currentLibrary,
    titleInputString,
    isTitleValid,
    isRemakeButtonDisabled,
    copyWallpaper,
    copyPosts,
    copyObjects,
    copyOnlyFrozenObjects,
    copyComments,
    copyReactions,
    copyAuthorship,
    copyPeopleAndPrivacy,
    privacyType,
    hasCollaborators,
    canIAdminister,
    wallViewableAndVisibleLibrariesArray,
    showRemakePanel,
    closeRemakePanel,
    toggleLibrarySelectionMenu,
    updateLibrarySelected,
    closeLibrarySelectionMenu,
    initializeStore,
    initializeSelectedLibrary,
    remakeWall,

    toggleCopyWallpaper,
    toggleCopyPosts,
    toggleObjectsCopyMode,
    toggleCopyComments,
    toggleCopyReactions,
    toggleCopyAuthorship,
    toggleCopyPeopleAndPrivacy,
  }
})
