// @file Surface demo padlet window size store
import { BreakPoints, useWindowSizeStore } from '@@/pinia/window_size'
import { defineStore } from 'pinia'
import { computed } from 'vue'

const DEMO_PADLET_ONBOARDING_PANEL_WIDTH = 480 // in px

export const useSurfaceDemoPadletWindowSizeStore = defineStore('surfaceDemoPadletWindowSizeStore', () => {
  const windowSizeStore = useWindowSizeStore()

  const isDemoPadletPanelScreensize = computed(() => {
    return windowSizeStore.windowWidth - DEMO_PADLET_ONBOARDING_PANEL_WIDTH >= BreakPoints.Phone
  })
  return {
    isDemoPadletPanelScreensize,
  }
})
