// @file Utils for post state
import type { DraftPost, Post } from '@@/types'

function isPostScheduled(post: Post | DraftPost): boolean {
  return post.scheduled_at != null
}

function isPostPublished(post: Post | DraftPost): boolean {
  return post.published === true
}

function isPostSubmitted(post: Pick<Post, 'published' | 'scheduled_at'>): boolean {
  return post.published === false && post.scheduled_at == null
}

function isPostPinned(post?: Post): boolean {
  if (post == null) return false
  return post.pinned_at != null
}

export { isPostPinned, isPostPublished, isPostScheduled, isPostSubmitted }
