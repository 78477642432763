// @file Vuex Store module for Settings panels and subpanels
import { __ } from '@@/bits/intl'
import { vSet } from '@@/bits/vue'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import PadletApi from '@@/surface/padlet_api'
import type { Wall as WallApiType } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type {
  ContentModeration,
  ContentProtection,
  JsonAPIResource,
  JsonAPIResponse,
  Wall,
  WallBackgroundCollection,
  WallBackgroundType,
  WallTheme,
} from '@padlet/arvo'
import { fetchMapThemes as arvoFetchMapThemes, fetchThemes, GroupByTypes, validateWall } from '@padlet/arvo'
import { cloneDeep, isEqual, merge } from 'lodash-es'
import { nextTick } from 'vue'
import type { ActionContext, Module } from 'vuex'

enum SettingsSubpanel {
  Main = 'main',
  MapStyle = 'mapstyle',
  Wallpaper = 'wallpaper',
  Icon = 'icon',
  Address = 'address',
  GroupBy = 'groupby',
  Reactions = 'reactions',
  PostProperties = 'postProperties',
}

interface WallpaperPicker {
  title: string
  type: WallBackgroundType
  backgrounds: WallBackgroundCollection
  xWallpaperNames: boolean
}

interface SettingsPanelState {
  activePanel: SettingsSubpanel | null
  isSaving: boolean
  previewAttributes: Partial<Wall>
  saveErrorMessage: string | null
  wallpaperPicker: WallpaperPicker | null
  isWallNameAvailable: boolean
  isWallNameValid: boolean
  isReactionDataValid: boolean
  arePreviewAttributesValid: boolean
  lastAvailableWallName: string | null
  wallThemes: WallTheme[]
  isFetchingWallThemes: boolean
  allMapThemes: WallTheme[]
  isFetchingMapThemes: boolean
}

type SettingsActionContext = ActionContext<SettingsPanelState, RootState>

const defaultState = (): SettingsPanelState => ({
  activePanel: null,
  isSaving: false,
  previewAttributes: {},
  saveErrorMessage: null,
  wallpaperPicker: null,
  isWallNameAvailable: true,
  isWallNameValid: true,
  isReactionDataValid: true,
  arePreviewAttributesValid: true,
  lastAvailableWallName: null,
  wallThemes: [],
  isFetchingWallThemes: false,
  allMapThemes: [],
  isFetchingMapThemes: false,
})

const SettingsModule: Module<SettingsPanelState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters: {
    arePreviewAttributesDirty(state, _, rootState): boolean {
      return !isEqual(state.previewAttributes, rootState.wall)
    },
    isOpen(state): boolean {
      return state.activePanel !== null
    },
    activePanel(state): SettingsSubpanel | null {
      return state.activePanel
    },
    previewAttributes(state): { [key: string]: any } {
      return state.previewAttributes
    },
    wishGroupBy(state): GroupByTypes {
      return state.previewAttributes.wish_arrangement?.group_by ?? GroupByTypes.None
    },
    wishSortBy(state, getters, rootState, rootGetters) {
      return state.previewAttributes.wish_arrangement?.sort_by ?? rootGetters.defaultWishSortBy
    },
    isSaving(state): boolean {
      return state.isSaving
    },
    isPreviewing(state): boolean {
      return Object.keys(state.previewAttributes).length > 0
    },
    saveErrorMessage(state): string | null {
      return state.saveErrorMessage
    },
    wallpaperPicker(state): WallpaperPicker | null {
      return state.wallpaperPicker
    },
    xWallpaperPicker(state): boolean {
      return state.wallpaperPicker !== null
    },
    wallThemes(state): WallTheme[] {
      return state.wallThemes
    },
    mapThemesDictionary(state): Record<number, WallTheme> {
      return state.allMapThemes.reduce((obj, item) => {
        obj[item.id] = item
        return obj
      }, {})
    },
    contentModeration(state): ContentModeration {
      return state.previewAttributes.content_moderation ?? 'none'
    },
    contentProtection(state): ContentProtection {
      return state.previewAttributes.content_protection ?? 'public'
    },
  },
  mutations: {
    RESET_SETTINGS_STATE(state): void {
      // keep the fetched themes
      Object.assign(state, { ...defaultState(), wallThemes: state.wallThemes, allMapThemes: state.allMapThemes })
    },
    START_PREVIEWING_ATTRIBUTES(state, previewAttributes: Wall): void {
      state.previewAttributes = previewAttributes
    },
    SET_ACTIVE_PANEL(state, panel: SettingsSubpanel): void {
      state.activePanel = panel
    },
    SHOW_WALLPAPER_PICKER(state, options: WallpaperPicker): void {
      state.wallpaperPicker = options
    },
    HIDE_WALLPAPER_PICKER(state): void {
      state.wallpaperPicker = null
    },
    SETTINGS_SAVE(state): void {
      state.isSaving = true
      state.saveErrorMessage = null
    },
    SETTINGS_SAVE_SUCCESS(state): void {
      state.isSaving = false
    },
    SETTINGS_SAVE_ERROR(state, message): void {
      state.isSaving = false
      state.saveErrorMessage = message
    },
    UPDATE_PREVIEW_ATTRIBUTES(state, attributes): void {
      state.previewAttributes = merge(cloneDeep(state.previewAttributes), attributes)
    },
    UPDATE_WALL_AVAILABILITY(state, { isAvailable }: { isAvailable: boolean }): void {
      state.isWallNameAvailable = isAvailable
    },
    UPDATE_WALL_NAME_VALIDITY(state, { isValid }: { isValid: boolean }): void {
      state.isWallNameValid = isValid
    },
    UPDATE_WALL_REACTION_DATA_VALIDITY(state, { isValid }): void {
      state.isReactionDataValid = isValid
    },
    UPDATE_PREVIEW_ATTRIBUTES_VALIDITY(state, { arePreviewAttributesValid }): void {
      state.arePreviewAttributesValid = arePreviewAttributesValid
    },
    UPDATE_LAST_CHECKED_NAME(state: SettingsPanelState, name: string): void {
      state.lastAvailableWallName = name
    },
    START_FETCH_WALL_THEMES(state): void {
      state.isFetchingWallThemes = true
    },
    FETCHED_WALL_THEMES(state, wallThemes): void {
      vSet(state, 'wallThemes', [...wallThemes])
      state.isFetchingWallThemes = false
    },
    START_FETCH_MAP_THEMES(state): void {
      state.isFetchingMapThemes = true
    },
    FETCHED_MAP_THEMES(state, mapThemes): void {
      vSet(state, 'allMapThemes', [...mapThemes])
      state.isFetchingMapThemes = false
    },
  },
  actions: {
    startWallAttributesPreview({ commit, rootState }): void {
      commit('START_PREVIEWING_ATTRIBUTES', { ...rootState.wall })
    },
    stopWallAttributesPreview({ commit }): void {
      commit('RESET_SETTINGS_STATE')
    },
    hideSettingsPanel({ commit }): void {
      commit('RESET_SETTINGS_STATE')
    },
    showSettingsPanel({ dispatch }, activePanel: SettingsSubpanel = SettingsSubpanel.Main): void {
      dispatch('startWallAttributesPreview')
      dispatch('changeActivePanel', activePanel)
    },
    changeActivePanel({ commit }, panel: SettingsSubpanel): void {
      commit('SET_ACTIVE_PANEL', panel)
    },
    async saveSettings({ commit, dispatch, state, getters, rootGetters }): Promise<any> {
      try {
        commit('SETTINGS_SAVE')
        const updatedWall = await PadletApi.Wall.update(state.previewAttributes as Partial<WallApiType>)
        dispatch('updateWall', updatedWall, { root: true })
        dispatch('updateAndValidatePreviewAttributes', updatedWall)
        commit('SETTINGS_SAVE_SUCCESS')
        void useNativeAppStore().postSurfaceState()

        // clear manually so we don't have to check for links again after saving settings
        if (updatedWall.wish_arrangement.group_by !== GroupByTypes.Section) {
          useSurfaceShareLinksStore().setSectionBreakoutLinksState(null)
        }
      } catch {
        dispatch('setSaveSettingError', { message: __('Sorry! Something went wrong. Please try saving again.') })
      }
    },
    setSaveSettingError({ commit }, { message }): void {
      commit('SETTINGS_SAVE_ERROR', message)
    },
    toggleSettingsPanel({ dispatch, getters }, panel: SettingsSubpanel): void {
      if (getters.isOpen) {
        dispatch('hideSettingsPanel')
      } else {
        // there is a weird issue where the panel is set as a pointer event due to SurfaceActionBar / SurfaceActionMoreMenu
        // so in those cases we just set the activePanel as Main.
        dispatch('showSettingsPanel', typeof panel !== 'string' ? SettingsSubpanel.Main : panel)
      }
    },
    updatePreviewAttributes({ commit, rootState }: SettingsActionContext, attributes): void {
      if (attributes?.background?.url && attributes.background.url !== rootState.wall.background.url) {
        // if the background url changes and there is no is_custom specified (usually from native bridge) => it's a custom background
        attributes.background.is_custom = attributes.background.is_custom ?? true
      }

      // Native bridge only knows about the singular "effect", not the array "effects"
      if (attributes?.background?.effect !== null && attributes?.background?.effect !== undefined) {
        attributes.background.effects = [attributes.background.effect]
      }
      commit('UPDATE_PREVIEW_ATTRIBUTES', attributes)
    },
    updateAndValidatePreviewAttributes({ getters, dispatch }: SettingsActionContext, attributes): void {
      void dispatch('updatePreviewAttributes', attributes)
      if (getters.arePreviewAttributesDirty as boolean) {
        nextTick(() => {
          void dispatch('updateWallValidity')
        })
      }
    },
    showWallpaperPicker({ commit }, options: WallpaperPicker): void {
      commit('SHOW_WALLPAPER_PICKER', options)
    },
    hideWallpaperPicker({ commit }): void {
      commit('HIDE_WALLPAPER_PICKER')
    },

    updateBackgroundMetadata({ dispatch }, { dominantColourAsRgb, imageLuminance }): void {
      const attributes = {
        background: {
          dominant_color_as_rgb: dominantColourAsRgb,
          luminance_name: imageLuminance,
          primary_color_as_rgb: dominantColourAsRgb,
        },
      }
      dispatch('updatePreviewAttributes', attributes)
    },
    async resetWallNameToKey({ dispatch, rootGetters }): Promise<void> {
      dispatch('updatePreviewAttributes', { name: rootGetters.publicKey })
      await dispatch('updateWallValidity')
    },
    async updateWallValidity({ commit, state, rootState, rootGetters }: SettingsActionContext): Promise<void> {
      try {
        // reset validity
        commit('UPDATE_WALL_NAME_VALIDITY', { isValid: true })
        commit('UPDATE_WALL_REACTION_DATA_VALIDITY', { isValid: true })
        let arePreviewAttributesValid = true
        const previewAttributes = state.previewAttributes as Wall
        await validateWall(previewAttributes)

        // check if name is different and update wall name availability
        const { name } = state.previewAttributes as Wall
        if (name && name !== rootState.wall.name && name !== state.lastAvailableWallName) {
          // Check against last queried name so we don't make wasted queries against
          // a name that has already been validated in this round of settings updates.
          const isAvailable = (await PadletApi.Wall.checkAvailability(name, rootGetters.wallHashid)).available
          commit('UPDATE_WALL_AVAILABILITY', { isAvailable })
          commit('UPDATE_LAST_CHECKED_NAME', name)
          arePreviewAttributesValid = arePreviewAttributesValid && isAvailable
        }
        commit('UPDATE_PREVIEW_ATTRIBUTES_VALIDITY', { arePreviewAttributesValid })
      } catch (e) {
        const reasons = e.reasons || []
        const isWallNameInvalid = reasons.find((reason: { field: string }): boolean => reason.field === 'name')
        if (isWallNameInvalid) {
          commit('UPDATE_WALL_NAME_VALIDITY', { isValid: false })
        }
        const isReactionDataInvalid = reasons.find(
          (reason: { field: string }): boolean => reason.field === 'reaction_data',
        )
        if (isReactionDataInvalid) {
          commit('UPDATE_WALL_REACTION_DATA_VALIDITY', { isValid: false })
        }
        commit('UPDATE_PREVIEW_ATTRIBUTES_VALIDITY', { arePreviewAttributesValid: false })
      }
    },
    async fetchWallThemes({ commit, state }): Promise<void> {
      if (state.wallThemes.length > 0 || state.isFetchingWallThemes) {
        return
      }

      commit('START_FETCH_WALL_THEMES')
      const themesResponse: JsonAPIResponse<WallTheme> = await fetchThemes()
      const allThemes = (themesResponse.data as Array<JsonAPIResource<WallTheme>>).map(
        (wallTheme: JsonAPIResource<WallTheme>) => wallTheme.attributes,
      )
      commit('FETCHED_WALL_THEMES', allThemes)
    },
    async fetchMapThemes({ commit, state, rootGetters }): Promise<void> {
      if (!rootGetters.isMap || state.allMapThemes.length > 0 || state.isFetchingMapThemes) {
        return
      }

      commit('START_FETCH_MAP_THEMES')
      const themesResponse: JsonAPIResponse<WallTheme> = await arvoFetchMapThemes()
      const allThemes = (themesResponse.data as Array<JsonAPIResource<WallTheme>>).map(
        (mapTheme: JsonAPIResource<WallTheme>) => mapTheme.attributes,
      )
      commit('FETCHED_MAP_THEMES', allThemes)
    },
  },
}
export default SettingsModule
export { SettingsSubpanel }
export type { SettingsPanelState, WallpaperPicker }
