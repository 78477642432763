// @file state and actions for the "Freeze padlet" panel
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSurfaceFreezePadletPanel = defineStore('surfaceFreezePadletPanel', () => {
  const xSurfaceFreezePanel = ref(false)

  function showSurfaceFreezePadletPanel(): void {
    xSurfaceFreezePanel.value = true
  }

  function hideSurfaceFreezePadletPanel(): void {
    xSurfaceFreezePanel.value = false
  }

  return {
    xSurfaceFreezePanel,
    showSurfaceFreezePadletPanel,
    hideSurfaceFreezePadletPanel,
  }
})
