// @file Surface contributing status store
import { getVuexStore } from '@@/bits/pinia'
import { vDel, vSet } from '@@/bits/vue'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import type { User, UserId } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Store as VuexStore } from 'vuex'

interface StatusObject {
  user: User
  status: ContributingStatus
}
enum ContributingStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

const SHOWN_ACTIVE_CONTRIBUTORS_COUNT_FALLBACK = 3

export const useSurfaceContributingStatusStore = defineStore('surfaceContributingStatus', () => {
  const getSurfaceVuexStore = (): VuexStore<RootState> | undefined => getVuexStore<RootState>()

  const surfaceUserContributors = useSurfaceUserContributorsStore()

  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */

  const sentStatus = ref<ContributingStatus>(ContributingStatus.INACTIVE)
  const statusEntities = ref<Record<UserId, StatusObject>>({})
  const statusExpireTimeout = ref<Record<UserId, number>>({})
  const statusRefreshTimeout = ref<number | undefined>()
  const orderedActiveUsers = ref<number[]>([])

  /* ---------------------- */
  /* GETTERS                */
  /* ---------------------- */

  const numberOfActiveContributors = computed(() => orderedActiveUsers.value.length)
  const numberOfAdditionalContributors = computed(
    () => numberOfActiveContributors.value - SHOWN_ACTIVE_CONTRIBUTORS_COUNT_FALLBACK,
  )
  const xActivityIndicator = computed<boolean>(() => numberOfActiveContributors.value > 0)
  const xAdditionalContributors = computed<boolean>(() => numberOfAdditionalContributors.value > 1)
  const activeContributorsList = computed<User[]>(() => {
    // Until 4 contributors we always show avatars.
    // If there are more than 4, we only show SHOWN_ACTIVE_CONTRIBUTORS_COUNT_FALLBACK.
    const numberOfShownActiveContributors: number = xAdditionalContributors.value
      ? SHOWN_ACTIVE_CONTRIBUTORS_COUNT_FALLBACK
      : numberOfActiveContributors.value
    return orderedActiveUsers.value
      .map((x) => statusEntities.value[x].user)
      .filter((x) => !!x)
      .slice(0, numberOfShownActiveContributors)
  })

  /* ---------------------- */
  /* HELPERS                */
  /* ---------------------- */

  const setActiveStatus = ({ user }: { user: User }): void => {
    const userId = user.id
    vSet(statusEntities.value, userId, { status: ContributingStatus.ACTIVE, user })
    if (!orderedActiveUsers.value.includes(userId)) {
      orderedActiveUsers.value = orderedActiveUsers.value.concat([userId])
    }
  }

  const clearActiveStatus = ({ user }: { user: User }): void => {
    const userId = user.id
    vSet(statusEntities.value, userId, { status: ContributingStatus.INACTIVE, user })
    orderedActiveUsers.value = orderedActiveUsers.value.filter((x) => x !== userId)
  }

  const setExpireTimeout = ({ user, timeout }: { user: User; timeout }): void => {
    const userId = user.id
    vSet(statusExpireTimeout.value, userId, timeout)
  }

  const clearExpireTimeout = ({ user }: { user: User }): void => {
    const userId = user.id
    vDel(statusExpireTimeout.value, userId)
  }

  const setRefreshTimeout = (timeout): void => {
    statusRefreshTimeout.value = timeout
  }

  const clearRefreshTimeout = (): void => {
    statusRefreshTimeout.value = undefined
  }

  const setSentStatus = (status: ContributingStatus): void => {
    sentStatus.value = status
  }

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */

  /**
   * Handle remote (realtime) contribution status.
   *
   */
  const ping = (): void => {
    // TODO: [to-be-migrated][realtime]
    void getSurfaceVuexStore()?.dispatch('realtime/pingContributingStatus', {}, { root: true })
  }

  const updateContributingStatusRemote = async (payload): Promise<void> => {
    const status = payload.status
    const userId = payload.user.id
    const userHashId = payload.user.hashid

    if (userId === getSurfaceVuexStore()?.state.user.id) {
      return
    }

    let user
    if (payload.user.id == null && payload.user.avatar != null) {
      // anonymous user message from the mobile app doesn't have id but it has an avatar, which is enough to render the contributing status UI
      // => use it because surfaceUserContributors/fetchUser won't work with userId == null
      user = payload.user
    } else {
      await surfaceUserContributors.fetchUser(userHashId ?? userId)
      user = surfaceUserContributors.getUserById(userHashId ?? userId)
    }

    if (status === ContributingStatus.INACTIVE) {
      const oldTimeout: number | undefined = statusExpireTimeout.value[user.id]
      if (oldTimeout != null) {
        clearTimeout(oldTimeout)
      }
      // set contributing status to inactive after 1 second. This is so that if it is a publish post,
      // the post can appear first before the contributing activity disappears.
      setTimeout(() => {
        clearActiveStatus({ user })
        clearExpireTimeout({ user })
      }, 1 * 1000)
      return
    }
    if (status === ContributingStatus.ACTIVE) {
      const oldTimeout: number | undefined = statusExpireTimeout.value[user.id]
      if (oldTimeout != null) {
        clearTimeout(oldTimeout)
      }
      // set contributing status to inactive after 30 seconds.
      const newTimeout = setTimeout(() => {
        clearActiveStatus({ user })
        clearExpireTimeout({ user })
      }, 30 * 1000)
      setExpireTimeout({ user, timeout: newTimeout })
      setActiveStatus({ user })
    }
  }

  const updateContributingStatus = (): void => {
    // Do not push update if the page is blurred
    if (document.visibilityState === 'hidden') return
    // Send a push update with the current status
    const status = useSurfaceDraftsStore().isAnyDraftActive ? ContributingStatus.ACTIVE : ContributingStatus.INACTIVE
    sendStatus(status)
    if (statusRefreshTimeout.value != null) {
      clearTimeout(statusRefreshTimeout.value)
    }
    if (status === ContributingStatus.ACTIVE) {
      // refresh after 25 seconds
      const refreshTimeout = setTimeout(() => {
        setSentStatus(ContributingStatus.INACTIVE) // set status back to inactive to resend the status
        updateContributingStatus()
      }, 25 * 1000)
      setRefreshTimeout(refreshTimeout)
    } else {
      clearActiveStatus({ user: getSurfaceVuexStore()?.state.user })
      clearRefreshTimeout()
    }
  }

  const clearContributingStatus = (): void => {
    // We allow clearing anytime the frontend wishes too.
    sendStatus(ContributingStatus.INACTIVE)
    if (statusRefreshTimeout.value != null) {
      clearTimeout(statusRefreshTimeout.value)
      clearRefreshTimeout()
    }
  }

  const setContributingStatusIfActive = (): void => {
    // We only allow setting if there is an active draft.
    if (useSurfaceDraftsStore().isAnyDraftActive) {
      sendStatus(ContributingStatus.ACTIVE)
    }
  }

  const sendStatus = (status): void => {
    if (sentStatus.value !== status) {
      // TODO: [to-be-migrated][realtime]
      void getSurfaceVuexStore()?.dispatch('realtime/updateContributingStatus', { status }, { root: true })
      setSentStatus(status)
    }
  }

  return {
    // Getters
    activeContributorsList,
    numberOfAdditionalContributors,
    xAdditionalContributors,
    xActivityIndicator,

    // Actions
    clearContributingStatus,
    updateContributingStatus,
    updateContributingStatusRemote,
    setContributingStatusIfActive,
    ping,
  }
})
