// @file Surface wish arrangement store
import window from '@@/bits/global'
import type { Group } from '@@/bits/surface_posts_grouping_helper'
import { BLANK_GROUP, ORDERED_COLOR_GROUPS } from '@@/bits/surface_posts_grouping_helper'
import type { ManualSortBy } from '@@/bits/surface_settings_helper'
import { ManualSortByArray } from '@@/bits/surface_settings_helper'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import type { SortByTypes, WishArrangement } from '@padlet/arvo'
import { GroupByTypes } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useSurfaceWishArrangementStore = defineStore('surfaceWishArrangement', () => {
  const surfaceVuexStore = window.app?.$store // For gradual conversion to pinia

  const surfaceStore = useSurfaceStore()
  const surfaceSectionsStore = useSurfaceSectionsStore()
  const surfaceSettingsStore = useSurfaceSettingsStore()

  const originalWishArrangement = computed<WishArrangement | undefined>(
    () => surfaceVuexStore?.state.wall.wish_arrangement,
  )
  const previewWishArrangement = computed<WishArrangement | undefined>(
    () => surfaceSettingsStore.previewAttributes.wish_arrangement,
  )

  const wishGroupBy = computed<GroupByTypes>(() => {
    if (!surfaceStore.canUseSections) return GroupByTypes.None
    return surfaceSettingsStore.isPreviewing ? surfaceSettingsStore.wishGroupBy : surfaceVuexStore?.getters.wishGroupBy
  })
  const isSectionedAndNonmanualSorted = computed<boolean>(
    () => !areWishesManuallySorted.value && surfaceStore.isSectioned,
  )
  const isNotSectionedAndNonmanualSorted = computed<boolean>(
    () => !areWishesManuallySorted.value && !surfaceStore.isSectioned,
  )
  const isSectionable = computed<boolean>(() => surfaceStore.wallAttributes.wish_arrangement?.is_sectionable ?? false)
  const originalWishSortBy = computed<SortByTypes>(() => surfaceVuexStore?.getters.wishSortBy)
  const previewWishSortBy = computed<SortByTypes>(() => surfaceSettingsStore.wishSortBy)
  const hasWishSortByChanged = computed(() => previewWishSortBy.value !== originalWishSortBy.value)
  const defaultWishSortBy = computed<SortByTypes>(() => surfaceVuexStore?.getters.defaultWishSortBy)
  const areWishesManuallySorted = computed<boolean>(() =>
    ManualSortByArray.includes(originalWishSortBy.value as ManualSortBy),
  )

  const sortedGroups = computed<Group[]>(() => {
    switch (wishGroupBy.value) {
      case GroupByTypes.Section:
        return surfaceSectionsStore.sortedSections
      case GroupByTypes.Color:
        return ORDERED_COLOR_GROUPS
      case GroupByTypes.None:
      default:
        return [BLANK_GROUP]
    }
  })

  return {
    // getters
    isNotSectionedAndNonmanualSorted,
    isSectionedAndNonmanualSorted,
    wishGroupBy,
    defaultWishSortBy,
    sortedGroups,
    originalWishSortBy,
    previewWishSortBy,
    originalWishArrangement,
    previewWishArrangement,
    hasWishSortByChanged,
    areWishesManuallySorted,
    isSectionable,
  }
})
