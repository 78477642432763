/*
 * @file handle display value attachment caption
 */
import { isTweet } from '@@/bits/post_attachment'
import type { BeethovenDisplayAttributes } from '@padlet/beethoven-client'

/**
 * Generate a caption to be displayed for an attachment.
 * Attachment caption can have a default value based on its display attributes.
 * If there is no custom value for attachment caption set in the post, we will display that default value
 */
export function attachmentCaptionForDisplay({
  attachmentCaption,
  attachmentDisplayAttributes,
}: {
  attachmentCaption: string | null | undefined
  attachmentDisplayAttributes: BeethovenDisplayAttributes | null
}): string {
  if (attachmentCaption != null) {
    return attachmentCaption
  }
  return defaultLinkCaption(attachmentDisplayAttributes)
}

export const defaultLinkCaption = (linkData: BeethovenDisplayAttributes | null): string => {
  if (linkData == null) {
    return ''
  }

  // If the link is an audio/video whose title is also "audio"/"video", we don't want to show it since it doesn't look good
  if (
    (linkData.content_category === 'audio' && linkData.title === 'audio') ||
    (linkData.content_category === 'video' && linkData.title === 'video')
  ) {
    return ''
  }

  // If the link is a tweet, there's no default caption
  if (isTweet(linkData.metadata?.attachment_props)) {
    return ''
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return linkData.title || linkData.description || ''
}
