// @file The store for handling guided templates on surface
import { GuidedTemplateType, WallCreatedFromType } from '@@/enums'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceGuidedTemplateStore = defineStore('surfaceGuidedTemplateStore', () => {
  const surfaceStartingStateStore = useSurfaceStartingStateStore()

  const isDiscussionBoard = computed(
    () =>
      surfaceStartingStateStore.startingState.onboardingFlow?.wallCreatedFromType ===
        WallCreatedFromType.guided_template &&
      surfaceStartingStateStore.startingState.onboardingFlow?.guidedTemplateData?.sourceId ===
        GuidedTemplateType.DiscussionBoard,
  )

  const isMinimized = ref(false)

  function togglePanelMinimizationState(): void {
    isMinimized.value = !isMinimized.value
  }

  return {
    isDiscussionBoard,
    isMinimized,
    togglePanelMinimizationState,
  }
})
