// @file a pinia store that handles data sync between post/comment/user data in vuex and a search engine and exposes actions to the search engine.
import { trackEvent } from '@@/bits/analytics'
import { getMetadataText, shouldDisplayMetadataTag } from '@@/bits/attachments'
import { getAuthorDisplayName } from '@@/bits/author'
import { getDisplayAttributes } from '@@/bits/beethoven'
import { stripHtml } from '@@/bits/html_parser'
import { getVuexStore } from '@@/bits/pinia'
import { getTweet, getTweetDisplayAttributes, isTweet } from '@@/bits/post_attachment'
import { escapeStringRegexp } from '@@/bits/regex'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { attachmentCaptionForDisplay } from '@@/surface/attachment_caption_for_display'
import type { Comment, Poll, Post } from '@@/types'
import type { TweetDisplayAttributes } from '@@/types/surface'
import type { RootState } from '@@/vuexstore/surface/types'
import type { User } from '@padlet/arvo'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface PostRecord {
  id: number
  subject: string
  body: string
  customProperties?: Record<string, any>
  locationName?: string
  attachmentCaption?: string
  metadataTag?: string
  comments?: CommentRecord[]
  author?: UserRecord
  tweet?: TweetDisplayAttributes
  poll?: Poll
}

export interface CommentRecord {
  id: number
  body: string
  author?: UserRecord
}

export interface UserRecord {
  id: number
  displayName: string // Not the display_name attribute on the model; see SurfacePostAuthor.vue:authorName
}

export const SEARCH_QUERY_PARAM_KEY = 'q'

function shouldPostBeSearchable(post: Post): boolean {
  return post.id != null
}

export const useSurfacePostSearchStore = defineStore('surfacePostSearch', () => {
  const surfaceVuexStore = getVuexStore<RootState>()
  const surfaceStore = useSurfaceStore()
  const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
  const surfaceUserContributorsStore = useSurfaceUserContributorsStore()
  const windowSizeStore = useWindowSizeStore()

  const searchTerm = ref('')

  function setSearchTerm(term: string): void {
    searchTerm.value = term
    if (searchTerm.value.length > 0) {
      trackEvent('Surface search', 'Searched posts', searchTerm.value)
    }
  }

  function setSearchTermFromQueryParam(params: URLSearchParams): void {
    const term = params.get(SEARCH_QUERY_PARAM_KEY) ?? ''
    searchTerm.value = term
    if (searchTerm.value.length > 0) {
      trackEvent('Surface search', 'Searched posts', searchTerm.value)
    }
  }

  async function filterPostsBySearchTerm(posts: Post[], searchTerm: string): Promise<Post[]> {
    if (searchTerm === '') return posts
    const searchTermRegex = new RegExp(escapeStringRegexp(searchTerm), 'i')
    const postSearchResults = await Promise.all(
      posts.map(async (post) => {
        const postSearchData = await buildPostDataForSearch(post)
        if (postSearchData == null) return undefined

        if (searchPostData(postSearchData, searchTermRegex)) {
          return post
        }

        return undefined
      }),
    )
    return postSearchResults.filter((result) => result != null) as Post[] // TS can't tell we filtered out undefined values
  }

  /**
   * When carrying out post search, we follow exactly what would be displayed on screen for the user.
   * Here we extract all such relevant data give a post.
   */
  async function buildPostDataForSearch(post: Post): Promise<PostRecord | undefined> {
    if (!shouldPostBeSearchable(post)) return
    const postId = post.id as number

    const subject = post.subject ?? ''
    const body = post.body != null ? stripHtml(post.body) : ''
    const customProperties = post.custom_properties ?? undefined
    const locationName = surfaceStore.isMap ? post.location_name ?? '' : ''
    const commentsWithAuthors = getComments(post)
    const author = getAuthor(post)
    const attachmentCaption = await getAttachmentCaption(post)
    const metadataTag = await getMetadataTag(post)
    const tweetDisplayAttributes = isTweet(post.wish_content?.attachment_props)
      ? getTweetDisplayAttributes(getTweet(post.wish_content?.attachment_props), {
          monthFormat: windowSizeStore.isSmallerThanTabletPortrait ? 'short' : 'long',
        })
      : undefined
    const poll =
      post.wish_content?.attachment_props?.type === 'poll' ? (post.wish_content?.attachment_props as Poll) : undefined

    return {
      id: postId,
      subject,
      body,
      customProperties,
      locationName,
      attachmentCaption,
      metadataTag,
      comments: commentsWithAuthors,
      author,
      tweet: tweetDisplayAttributes,
      poll,
    }
  }

  function searchPostData(post: PostRecord, searchTermRegex: RegExp): boolean {
    return (
      searchTermRegex.test(post.subject) ||
      searchTermRegex.test(post.body) ||
      searchCustomProperties(post.customProperties, searchTermRegex) ||
      searchTermRegex.test(post.locationName ?? '') ||
      searchTermRegex.test(post.attachmentCaption ?? '') ||
      searchTermRegex.test(post.metadataTag ?? '') ||
      searchComments(post.comments, searchTermRegex) ||
      searchAuthor(post.author, searchTermRegex) ||
      searchTweet(post.tweet, searchTermRegex) ||
      searchPoll(post.poll, searchTermRegex)
    )
  }

  function searchCustomProperties(customProperties: Record<string, any> | undefined, searchTermRegex: RegExp): boolean {
    if (customProperties == null) return false
    return (
      // Search custom prop name
      surfacePostPropertiesStore.wallCustomPostProperties.some((customProp) => searchTermRegex.test(customProp.name)) ||
      // Search custom prop value
      Object.values(customProperties).some((value) => searchTermRegex.test(value))
    )
  }

  function searchPoll(poll: Poll | undefined, searchTermRegex: RegExp): boolean {
    if (poll == null) return false
    return (
      searchTermRegex.test(poll.question) ||
      poll.poll_choices.some((choice) => searchTermRegex.test(choice.choice_text))
    )
  }
  function searchTweet(tweet: TweetDisplayAttributes | undefined, searchTermRegex: RegExp): boolean {
    if (tweet == null) return false
    return (
      searchTermRegex.test(tweet.author) ||
      searchTermRegex.test(tweet.text) ||
      searchTermRegex.test(tweet.createdAtDateString ?? '')
    )
  }

  function searchComments(comments: CommentRecord[] | undefined, searchTermRegex: RegExp): boolean {
    // Only search comments if they're displayed on the surface itself
    if (!surfaceStore.xCommentsUnderPosts) return false

    if (comments == null) return false

    return comments.some(
      (comment) => searchTermRegex.test(comment.body) || searchTermRegex.test(comment.author?.displayName ?? ''),
    )
  }

  function searchAuthor(author: UserRecord | undefined, searchTermRegex: RegExp): boolean {
    if (!surfaceStore.xAuthor) return false
    return searchTermRegex.test(author?.displayName ?? '')
  }

  async function getAttachmentCaption(post: Post): Promise<string | undefined> {
    if (post.wish_content?.attachment_props?.type === 'poll') return post.attachment_caption ?? undefined
    if (post.attachment == null || post.attachment === '') return

    const displayAttributes = await getDisplayAttributes(post.attachment)
    return attachmentCaptionForDisplay({
      attachmentCaption: post.attachment_caption,
      attachmentDisplayAttributes: displayAttributes,
    })
  }

  async function getMetadataTag(post: Post): Promise<string | undefined> {
    if (post.attachment == null || post.attachment === '') return

    // the fetchLink request in bits/beethoven.ts is memoized so this shouldn't fire too many requests
    const displayAttributes = await getDisplayAttributes(post.attachment)

    if (displayAttributes == null) return

    if (!shouldDisplayMetadataTag(displayAttributes)) return

    return getMetadataText(displayAttributes)
  }

  function getAuthor(post: Post): UserRecord | undefined {
    const postAuthor = surfaceUserContributorsStore.getUserById(post.author_id ?? -1)
    if (postAuthor == null) return undefined

    return {
      id: postAuthor.id,
      displayName: getAuthorDisplayName(postAuthor),
    }
  }

  function getComments(post: Post): CommentRecord[] {
    if (post.id == null) return []

    const commentsByPost: Record<number, Comment[]> = surfaceVuexStore?.getters['comment/commentsByPost']

    if (isEmpty(commentsByPost)) return []

    const commentsForPost = commentsByPost[post.id]
    if (commentsForPost != null) {
      const commentRecords = commentsForPost.map((comment) => {
        const commentAuthor = getAuthorForComment(comment)
        return {
          id: Number(comment.id),
          body: comment.body,
          author:
            commentAuthor != null
              ? {
                  id: commentAuthor.id,
                  displayName: getAuthorDisplayName(commentAuthor),
                }
              : undefined,
        }
      })
      return commentRecords
    }
    return []
  }

  function getAuthorForComment(comment: Comment): User | undefined {
    return surfaceUserContributorsStore.getUserById(comment.user_id ?? -1) as any
  }

  return { filterPostsBySearchTerm, searchTerm, setSearchTerm, setSearchTermFromQueryParam }
})
