// @file automations store
import getCsrfToken from '@@/bits/csrf_token'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { Automation } from '@@/bits/surface_automations'
import { isAutomationValid } from '@@/bits/surface_automations'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { fetchJson } from '@@/surface/api_fetch'
import type { JsonAPIResource } from '@padlet/arvo'
import { fetchResponse, HTTPMethod } from '@padlet/fetch'
import { sortBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceAutomationsStore = defineStore('surfaceAutomations', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceStore = useSurfaceStore()

  const xSurfaceAutomationsPanel = ref(false)
  const automations = ref<Automation[]>([])
  const sortedAutomations = computed(() => sortBy(automations.value, (x) => x.trigger_props.scheduled_at))
  const hasWallAutomations = computed(() => automations.value.length > 0)

  const apiUrl = computed(() => `/api/1/walls/${surfaceStore.wallId}/automations`)

  const closeSurfaceAutomationsPanel = (): void => {
    xSurfaceAutomationsPanel.value = false
  }

  const openSurfaceAutomationsPanel = (): void => {
    xSurfaceAutomationsPanel.value = true
  }

  const fetchAutomations = async (): Promise<void> => {
    try {
      const { data } = await fetchJson(apiUrl.value)
      if (data != null && Array.isArray(data)) {
        automations.value = data.map((jsonResource: JsonAPIResource<Automation>) => jsonResource.attributes)
      }
    } catch (error) {
      captureFetchException(error, { source: 'fetchAutomations' })
      globalSnackbarStore.genericFetchError()
    }
  }

  const createAutomation = async (automation: Automation): Promise<void> => {
    const { isValid, validationMessage } = isAutomationValid(automation, automations.value)
    if (!isValid) {
      throw new Error(validationMessage)
    }

    try {
      const { data } = await fetchJson(apiUrl.value, {
        method: HTTPMethod.post,
        body: asciiSafeStringify({ attributes: automation }),
      })
      if (data != null) {
        automations.value = [...automations.value, (data as JsonAPIResource<Automation>).attributes]
      }
    } catch (err) {
      captureFetchException(err, { source: 'createAutomation' })
      throw new Error(__('Failed to create automation'))
    }
  }

  const updateAutomation = async (automation: Automation): Promise<void> => {
    if (automation.id == null) throw new Error(__('Failed to update automation'))

    const automationsExcludingCurrentAutomation = automations.value.filter((a) => a.id !== automation.id)
    const { isValid, validationMessage } = isAutomationValid(automation, automationsExcludingCurrentAutomation)
    if (!isValid) {
      throw new Error(validationMessage)
    }

    const url = `${apiUrl.value}/${automation.id}`
    try {
      const { data } = await fetchJson(url, {
        method: HTTPMethod.put,
        body: asciiSafeStringify({ attributes: automation }),
      })

      if (data != null) {
        const updatedAutomation = (data as JsonAPIResource<Automation>).attributes
        automations.value = automations.value.map((a) => (a.id === updatedAutomation.id ? updatedAutomation : a))
      }
    } catch (err) {
      captureFetchException(err, { source: 'updateAutomation' })
      throw new Error(__('Failed to update automation'))
    }
  }

  const deleteAutomation = async (automationId: string): Promise<void> => {
    const url = `${apiUrl.value}/${automationId}`
    try {
      await fetchResponse(url, {
        method: HTTPMethod.delete,
        headers: {
          'X-CSRF-Token': getCsrfToken(),
        },
      })

      automations.value = automations.value.filter((a) => a.id !== automationId)
    } catch (err) {
      captureFetchException(err, { source: 'deleteAutomation' })
      throw new Error(__('Failed to delete automation'))
    }
  }

  const createAutomationRemote = (payload: Automation): void => {
    const automation = payload
    automations.value = [...automations.value, automation]
  }

  const updateAutomationRemote = (payload: Automation): void => {
    const automation = payload
    automations.value = automations.value.map((a) => (a.id === automation.id ? automation : a))
  }

  const deleteAutomationRemote = (payload: { id: string }): void => {
    const { id: automationId } = payload
    automations.value = automations.value.filter((a) => a.id !== automationId)
  }

  return {
    // Panel
    xSurfaceAutomationsPanel,
    closeSurfaceAutomationsPanel,
    openSurfaceAutomationsPanel,

    // Automations
    automations: sortedAutomations,
    hasWallAutomations,

    fetchAutomations,
    createAutomation,
    updateAutomation,
    deleteAutomation,
    createAutomationRemote,
    updateAutomationRemote,
    deleteAutomationRemote,
  }
})
