// @file Surface user contributors store
// TODO: [to-be-migrated][post]
import { captureFetchException } from '@@/bits/error_tracker'
import { getVuexStore } from '@@/bits/pinia'
import { vSet } from '@@/bits/vue'
import PadletApi from '@@/surface/padlet_api'
import type { HashId, Id, User, WallId } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { sortBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceUserContributorsStore = defineStore('surfaceUserContributors', () => {
  const surfaceVuexStore = getVuexStore<RootState>()

  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */

  const userById = ref<Record<Id, User>>({})
  const userByHashId = ref<Record<HashId, User>>({})
  const userIdsBeingFetched = ref<Array<Id | HashId>>([])
  const xContributorsList = ref(false)

  /* ---------------------- */
  /* GETTERS                */
  /* ---------------------- */

  const postAuthorIds = computed<Id[]>(() => surfaceVuexStore?.getters['post/postAuthorIds'])
  const postAuthors = computed<User[]>(() =>
    Object.values(userById.value).filter((u) => postAuthorIds.value.includes(u.id)),
  )
  const postAuthorsById = computed<Record<Id, User>>(() => {
    const authors = postAuthors.value
    return authors.reduce<Record<Id, User>>((acc, author) => {
      acc[author.id] = author
      return acc
    }, {})
  })
  const registeredPostAuthors = computed<User[]>(() =>
    sortBy(
      postAuthors.value.filter((u) => u.registered),
      (u: User): string | undefined => {
        return surfaceVuexStore?.getters.builder.id === u.id ? '' : u.short_name
      },
    ),
  )
  const anonymousPostAuthors = computed<User[]>(() => postAuthors.value.filter((u) => u.registered === false))
  const totalPostAuthors = computed<number>(() => postAuthorIds.value.length)

  /* ---------------------- */
  /* GETTER FUNCTIONS       */
  /* ---------------------- */

  const getUserById = (id: Id | HashId): User | null => {
    return typeof id === 'number' ? userById.value[id] : userByHashId.value[id]
  }

  const isUserIdBeingFetched = (id: Id | HashId): boolean => {
    return userIdsBeingFetched.value.includes(id)
  }

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */

  const fetchUser = async (userId: Id | HashId, wallId?: WallId): Promise<void> => {
    if (getUserById(userId) != null || isUserIdBeingFetched(userId)) {
      return
    }

    userIdsBeingFetched.value.push(userId)
    try {
      const fetchedUserData =
        wallId != null
          ? await PadletApi.WallUserContributor.read(userId, wallId)
          : await PadletApi.UserContributor.read(userId)
      // TODO: consolidate User type
      const newUser = (fetchedUserData.data as JsonAPIResource<any>).attributes

      updateUser(newUser)

      userIdsBeingFetched.value = userIdsBeingFetched.value.filter((id) => id !== userId)
    } catch (e) {
      captureFetchException(e, { source: 'fetchUserContributorInfo' })
    }
  }

  // For the contributors panel (Currently it only shows post authors)
  const fetchPostAuthors = async (): Promise<void> => {
    try {
      postAuthorIds.value.forEach((userId) => {
        void fetchUser(userId)
      })
    } catch (e) {
      captureFetchException(e, { source: 'fetchPostAuthors' })
    }
  }

  const fetchAndWaitForAuthors = async (): Promise<void> => {
    try {
      await Promise.all(postAuthorIds.value.map(async (userId: Id): Promise<void> => await fetchUser(userId)))
    } catch (e) {
      captureFetchException(e, { source: 'fetchAndWaitForAuthors' })
    }
  }

  const showContributorsList = (): void => {
    xContributorsList.value = true
  }
  const hideContributorsList = (): void => {
    xContributorsList.value = false
  }

  const updateUser = (newUser: User): void => {
    vSet(userById.value, newUser.id, newUser)
    if (newUser.hashid != null) {
      vSet(userByHashId.value, newUser.hashid, newUser)
    }
  }

  return {
    // Getters
    postAuthors,
    postAuthorsById,
    registeredPostAuthors,
    anonymousPostAuthors,
    totalPostAuthors,
    xContributorsList,

    // Getter functions
    getUserById,
    isUserIdBeingFetched,

    // Actions
    fetchUser,
    fetchPostAuthors,
    fetchAndWaitForAuthors,
    hideContributorsList,
    showContributorsList,
    updateUser,
  }
})
