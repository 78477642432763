/**
 * @file This allows us to search images/audio/videos/gif/web/stock
 *  based on user input
 * */
import { trackEvent } from '@@/bits/analytics'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { transformUrl } from '@@/bits/location'
import ping from '@@/bits/ping'
import giphyAttributionImage from '@@/images/giphy_attribution.png'
import giphyAttributionBlackImage from '@@/images/giphy_attribution_black.png'
import giphyAttributionWhiteImage from '@@/images/giphy_attribution_white.png'
import spotifyAttributionImage from '@@/images/spotify_attribution.png'
import tenorAttributionImage from '@@/images/tenor_attribution.png'
import unsplashAttributionImage from '@@/images/unsplash_attribution.png'
import youtubeAttributionImage from '@@/images/youtube_attribution.png'
import { fetchJson } from '@padlet/fetch'

enum SearchFilters {
  None = 'none',
  Web = 'web',
  Audio = 'audio',
  Videos = 'videos',
  Images = 'images',
  Gif = 'gif',
  Stock = 'stock',
  AiImages = 'aiImages',
  UnsplashImages = 'unsplashImages',
}

// @see web_search.rb
interface WebSearchResult {
  url: string
  description: string
  title: string
}

// @see web_search.rb
interface ImageSearchResult {
  height: number
  thumbnail_height: number
  thumbnail_url: string
  thumbnail_width: number
  title: string
  url: string
  width: number
  attribution_link: string
  provider_name?: string
}

// @see web_search.rb
interface AiImageSearchResult {
  height: number
  width: number
  url: string
  thumbnail_height: number
  thumbnail_width: number
  thumbnail_url: string
}

// @see image_search.rb web_search.rb unsplash_search.rb
interface VideoSearchResult {
  description: string
  duration?: string
  preview_url?: string
  provider_name: string
  thumbnail_url: string
  thumbnail_width: number
  thumbnail_height: number
  title: string
  url: string
  beacon_link?: string // for Unsplash, @ see unsplash_search.rb
  view_count: number
}

// @see audio_search.rb
interface AudioSearchResult {
  album: string
  artist: string
  description: string
  duration: string
  preview: string
  thumbnail: string
  title: string
  show_name?: string
  url: string
  type: string
  release_date?: Date
  preview_url?: string
  thumbnail_url?: string
}

interface SearchResultDataCollectionMap {
  webpages?: WebSearchResult[]
  audio?: AudioSearchResult[]
  videos?: VideoSearchResult[]
  images?: ImageSearchResult[]
  text?: string[]
}

interface SearchResult {
  data: SearchResultDataCollectionMap
  links: { self: string; next?: string }
  meta: { provider?: string; total?: number; query?: string }
  error?: string
}

const searchTypeMap = {
  [SearchFilters.Web]: {
    path: 'web',
  },
  [SearchFilters.Audio]: {
    path: 'audio',
  },
  [SearchFilters.Videos]: {
    path: 'video',
  },
  [SearchFilters.Images]: {
    path: 'images',
  },
  [SearchFilters.Gif]: {
    path: 'gif',
  },
  [SearchFilters.Stock]: {
    path: 'stock',
  },
  [SearchFilters.UnsplashImages]: {
    path: 'unsplash',
  },
}

// Can be used when next page URL is provided for paginated searches
const searchByUrl = async (url: string): Promise<SearchResult> => {
  return await fetchJson<SearchResult>(url, { cors: true })
}

const search = async (query: string, userId: string, filter: SearchFilters): Promise<SearchResult> => {
  const params: { path: string } = searchTypeMap[filter]
  const search: Record<string, string> = { q: query, user_id: userId }

  const url = transformUrl('https://disco.padlet.com/', {
    path: `api/1/${params.path}`,
    search,
  })
  const searchResult = await fetchJson<SearchResult>(url, {
    cors: true,
  })
  ping(`websearch-${filter}`)
  return searchResult
}

async function aiImageSearch(query: string, userId: string): Promise<SearchResult> {
  const url = transformUrl('https://disco.padlet.com/', {
    path: 'api/1/aiart',
    search: { q: query, count: '4', user_id: userId },
  })
  trackEvent('AI Image Search', 'Searched image with AI', query)
  const fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const searchResult = await fetch(url, fetchOptions)
  const searchResultData = await searchResult?.json()
  return searchResultData
}

async function aiAnswersSearch(query: string): Promise<SearchResult> {
  const url = transformUrl('https://disco.padlet.com/', {
    path: 'api/aianswers',
  })
  trackEvent('Magic Padlet', 'Created wall with magic padlet', query)
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: asciiSafeStringify({ q: query }),
  }
  const searchResult = await fetch(url, fetchOptions)
  const searchResultData = await searchResult?.json()
  return searchResultData
}

const attributionMap = {
  giphy: {
    height: 11,
    href: 'https://giphy.com',
    src: giphyAttributionImage,
    width: 100,
  },
  giphyBlack: {
    height: 16,
    href: 'https://giphy.com',
    src: giphyAttributionBlackImage,
    width: 45,
  },
  giphyWhite: {
    height: 16,
    href: 'https://giphy.com',
    src: giphyAttributionWhiteImage,
    width: 45,
  },
  spotify: {
    height: 18,
    href: 'https://spotify.com',
    src: spotifyAttributionImage,
    width: 60,
  },
  tenor: {
    height: 10,
    href: 'https://tenor.com',
    src: tenorAttributionImage,
    width: 70,
  },
  unsplash: {
    height: 13,
    href: 'https://unsplash.com',
    src: unsplashAttributionImage,
    width: 57,
  },
  youtube: {
    height: 13,
    href: 'https://youtube.com',
    src: youtubeAttributionImage,
    width: 58,
  },
}

export { aiAnswersSearch, aiImageSearch, attributionMap, search, searchByUrl, SearchFilters }
export type {
  AiImageSearchResult,
  AudioSearchResult,
  ImageSearchResult,
  SearchResult,
  SearchResultDataCollectionMap,
  VideoSearchResult,
  WebSearchResult,
}
