// @file Dash wall single actions pinia store
import { trackEvent } from '@@/bits/analytics'
import backendState from '@@/bits/backend_state'
import { copyToClipboard } from '@@/bits/clipboard'
import { assertPresent } from '@@/bits/collections_helper'
import getCsrfToken from '@@/bits/csrf_token'
import { WAVING_HAND_EMOJI } from '@@/bits/emoji'
import { captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { buildUrlFromPath, currentHostWithProtocol, navigateTo, transformCurrentUrl } from '@@/bits/location'
import PromiseQueue from '@@/bits/promise_queue'
import { buildSlideshowLink } from '@@/bits/slideshow'
import { isPartOfUnlimitedSandboxesEvent } from '@@/bits/unlimited_sandboxes_event'
import { Wall as WallApi } from '@@/dashboard/padlet_api'
import { ApiErrorCode, LibraryType, SnackbarNotificationType } from '@@/enums'
import anxiousFace from '@@/images/anxious_face.svg'
import { OzConfirmationDialogBoxButtonScheme } from '@@/library/v4/components/OzConfirmationDialogBox.vue'
import type { PopoverAnchor } from '@@/library/v4/components/OzPopoverModal.vue'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashWallBulkActionsStore } from '@@/pinia/dash_wall_bulk_actions_store'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { LibraryUpgradeSource, LibraryUpgradeStep, useLibraryPlansStore } from '@@/pinia/library_plans'
import { UpgradeSource, usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { fetchJson } from '@@/surface/api_fetch'
import PadletApi from '@@/surface/padlet_api'
import type { AccountKey, FolderId, Library, LibraryId, UserId, WallCamelCase as Wall, WallId } from '@@/types'
import type { CollectionKey } from '@@/types/collections'
import { CollectionKeyTypes } from '@@/types/collections'
import { PageType } from '@@/types/slideshow'
import { fetchResponse, HTTPMethod } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

// Avoid race conditions that cause walls and folders to dis/appear etc unexpectedly.
const q = new PromiseQueue()

export const useDashWallSingleActionsStore = defineStore('dashWallSingleActionsStore', () => {
  // External stores
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalAlertDialogStore = useGlobalAlertDialogStore()
  const dashAccountsStore = useDashAccountsStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const libraryPlansStore = useLibraryPlansStore()
  const personalPlansStore = usePersonalPlansStore()
  const dashWallBulkActionsStore = useDashWallBulkActionsStore()

  function genericFetchError(payload: { error: any; source: string }): void {
    captureNonNetworkFetchError(payload.error, { source: payload.source })
    globalSnackbarStore.genericFetchError()
  }

  const xWallActions = ref<boolean>(false)
  const wallActionsAnchor = ref<PopoverAnchor | null>(null)

  const canCreateWall = computed((): boolean => dashAccountsStore.currentUser.quota.can_make)

  function showWallActions(payload: { wallId: WallId; popoverAnchor?: PopoverAnchor }): void {
    setActiveWall(payload.wallId)
    xWallActions.value = true
    wallActionsAnchor.value = payload.popoverAnchor ?? null
  }

  function hideWallActions(): void {
    xWallActions.value = false
    wallActionsAnchor.value = null
  }

  function closeWallActions(): void {
    unsetActiveWall()
    hideWallActions()
  }

  function unsetActiveWall(): void {
    dashCollectionsStore.activeWallIdCursor = null
  }

  function setActiveWall(wallId: WallId): void {
    dashCollectionsStore.activeWallIdCursor = wallId
  }

  /**
   * ==================== BOOKMARKS ====================
   */

  const xBookmarksDialog = ref<boolean>(false)

  function openBookmarksDialog(): void {
    hideWallActions()
    xBookmarksDialog.value = true
  }

  function closeBookmarksDialog(): void {
    unsetActiveWall()
    xBookmarksDialog.value = false
  }

  async function removeBookmark(params: {
    folderId: FolderId | null
    wallId: WallId
    throwException?: boolean
  }): Promise<void> {
    try {
      await WallApi.removeBookmark(params)
      const folderId = params.folderId
      const accountKey: AccountKey = dashCollectionsStore.currentAccountKey
      const collectionKey: CollectionKey =
        folderId !== null
          ? { typeKey: CollectionKeyTypes.FolderId, indexKey: folderId }
          : { typeKey: CollectionKeyTypes.Filter, indexKey: 'favorites' }
      const wallId = params.wallId
      dashCollectionsStore.removeWallIdFromCollection({ accountKey, collectionKey, wallId })
    } catch (error) {
      if (params.throwException === true) {
        throw error
      }
      genericFetchError({ error, source: 'DashWallSingleActionsRemoveBookmark' })
    }
  }

  async function removeBookmarkVuex(payload: { folderId: FolderId | null; wallId: WallId }): Promise<void> {
    // folderId is allowed to be blank
    const folderId = payload.folderId
    const wallId = payload.wallId
    if (!assertPresent({ wallId })) return

    await fetchJson(`/api/1/bookmarks`, {
      method: HTTPMethod.delete,
      jsonData: { wallId, folderId },
    })

    const accountKey: AccountKey = { type: 'user', id: dashCollectionsStore.activeUserIdCursor as UserId }
    const collectionKey: CollectionKey =
      folderId != null ? { typeKey: 'folderId', indexKey: folderId } : { typeKey: 'filter', indexKey: 'favorites' }
    dashCollectionsStore.removeWallIdFromCollection({ accountKey, collectionKey, wallId })
  }

  async function undoRemoveBookmark(params: {
    folderId: FolderId | null
    wallId: WallId
    throwException?: boolean
  }): Promise<void> {
    try {
      await WallApi.addBookmark(params)
      const folderId = params.folderId
      const accountKey: AccountKey = dashCollectionsStore.currentAccountKey
      const collectionKey: CollectionKey =
        folderId !== null
          ? { typeKey: CollectionKeyTypes.FolderId, indexKey: folderId }
          : { typeKey: CollectionKeyTypes.Filter, indexKey: 'favorites' }
      const wallId = params.wallId
      dashCollectionsStore.addWallIdsToCollection({ accountKey, collectionKey, wallIds: [wallId] })
    } catch (error) {
      if (params.throwException === true) {
        throw error
      }
      genericFetchError({ error, source: 'DashWallSingleActionsUndoRemoveBookmark' })
    }
  }

  async function addBookmark(params: {
    folderId: FolderId | null
    wallId: WallId
    throwException?: boolean
  }): Promise<void> {
    try {
      await WallApi.addBookmark(params)
      const folderId = params.folderId
      const accountKey: AccountKey = dashCollectionsStore.currentAccountKey
      const collectionKey: CollectionKey =
        folderId !== null
          ? { typeKey: CollectionKeyTypes.FolderId, indexKey: folderId }
          : { typeKey: CollectionKeyTypes.Filter, indexKey: 'favorites' }
      const wallId = params.wallId
      dashCollectionsStore.addWallIdsToCollection({ accountKey, collectionKey, wallIds: [wallId] })
    } catch (error) {
      if (params.throwException === true) {
        throw error
      }
      genericFetchError({ error, source: 'DashWallSingleActionsAddBookmark' })
    }
  }

  async function addBookmarkVuex(payload: { folderId: FolderId | null; wallId: WallId }): Promise<void> {
    // folderId is allowed to be blank
    const folderId = payload.folderId
    const wallId = payload.wallId
    if (!assertPresent({ wallId })) return

    await fetchJson(`/api/1/bookmarks`, {
      method: HTTPMethod.post,
      jsonData: { wallId, folderId },
    })

    const accountKey: AccountKey = { type: 'user', id: dashCollectionsStore.activeUserIdCursor as UserId }
    const collectionKey: CollectionKey =
      folderId != null ? { typeKey: 'folderId', indexKey: folderId } : { typeKey: 'filter', indexKey: 'favorites' }
    dashCollectionsStore.addWallIdsToCollection({ accountKey, collectionKey, wallIds: [wallId] })
  }

  async function undoAddBookmark(params: {
    folderId: FolderId | null
    wallId: WallId
    throwException?: boolean
  }): Promise<void> {
    try {
      await WallApi.removeBookmark(params)
      const folderId = params.folderId
      const accountKey: AccountKey = dashCollectionsStore.currentAccountKey
      const collectionKey: CollectionKey =
        folderId !== null
          ? { typeKey: CollectionKeyTypes.FolderId, indexKey: folderId }
          : { typeKey: CollectionKeyTypes.Filter, indexKey: 'favorites' }
      const wallId = params.wallId
      dashCollectionsStore.removeWallIdFromCollection({ accountKey, collectionKey, wallId })
    } catch (error) {
      if (params.throwException === true) {
        throw error
      }
      genericFetchError({ error, source: 'DashWallSingleActionsUndoAddBookmark' })
    }
  }

  /**
   * ==================== TRANSFER WALL ====================
   */
  const xTransferWallModal = ref<boolean>(false)

  function openTransferWallModal(): void {
    xTransferWallModal.value = true
    hideWallActions()
  }

  function closeTransferWallModal(): void {
    xTransferWallModal.value = false
    closeWallActions()
  }

  /**
   * ==================== TRASH / UNSTRASH ====================
   */

  async function trashActiveWall(): Promise<void> {
    const wallId = dashCollectionsStore.activeWallId as WallId
    try {
      hideWallActions()
      await trashWall(dashCollectionsStore.activeWallIdCursor as WallId, false)
      unsetActiveWall()
      dashAccountsStore.refreshWallQuotas()
      const movedToTrashSnackbarUid = globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Moved to trash'),
        timeout: 5000,
        actionText: __('Undo'),
        actionTextActions: [
          () => {
            globalSnackbarStore.removeSnackbar(movedToTrashSnackbarUid)
            void undoTrashActiveWall(wallId)
          },
        ],
      })
    } catch {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Padlet not trashed'),
      })
    }
  }

  async function undoTrashActiveWall(wallId: WallId): Promise<Promise<void>> {
    try {
      await WallApi.untrash(wallId)
      dashAccountsStore.refreshWallQuotas()
      void dashCollectionsStore.fetchActiveCollectionWallsNow()

      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Padlet restored'),
      })
    } catch (error) {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Padlet not restored'),
      })
    }
  }

  async function trashWall(wallId, catchException: boolean = true): Promise<void> {
    const promise = async (): Promise<void> => {
      try {
        await WallApi.trash(wallId)
        dashCollectionsStore.removeWallIdFromAllCollections(wallId)
      } catch (error) {
        if (catchException) {
          genericFetchError({ error, source: 'DashWallSingleActionsTrashWall' })
          return
        }

        throw error
      }
    }

    return await q.enqueue('trashWall', promise)
  }

  async function untrashActiveWallOrPromptUpgrade(): Promise<void> {
    hideWallActions()

    const activeWallLibrary = dashCollectionsStore.isActiveWallInsideLibrary
      ? dashAccountsStore.librariesById[dashCollectionsStore.activeWall?.libraryId as LibraryId]
      : null

    if (
      (activeWallLibrary?.quota?.quotaHit as boolean) ||
      (activeWallLibrary?.isTrialExpired as boolean) ||
      (activeWallLibrary?.isPlanCancelled as boolean)
    ) {
      if (dashAccountsStore.currentUser != null && dashAccountsStore.currentUser.id === activeWallLibrary?.ownerId) {
        libraryPlansStore.fetchPlansAndShowQuotaUpsellModal({
          upgradeStep: LibraryUpgradeStep.ChooseTierPadletQuota,
          upgradeSource: LibraryUpgradeSource.UntrashWallQuota,
          library: activeWallLibrary,
        })
      } else {
        // show an alert to non owners
        const alertBody =
          (activeWallLibrary as Library).libraryType === LibraryType.Classroom
            ? __('Please contact a teacher to upgrade your plan.')
            : __('Please contact the owner of the team to upgrade your plan.')
        globalAlertDialogStore.openAlertDialog({
          iconSrc: anxiousFace,
          iconAlt: __('Anxious face'),
          title: __('Unable to restore padlet'),
          body: alertBody,
          xShadow: true,
        })
      }
    } else if (
      !dashCollectionsStore.isActiveWallInsideLibrary &&
      dashAccountsStore.currentUser != null &&
      (isPartOfUnlimitedSandboxesEvent()
        ? dashCollectionsStore.activeWall?.viz !== 'whiteboard' && !canCreateWall.value
        : !canCreateWall.value)
    ) {
      void personalPlansStore.quotaTriggeredUpgrade({
        user: dashAccountsStore.currentUser,
        upgradeSource: UpgradeSource.UntrashWallQuota,
      })
    } else {
      await untrashActiveWall()
    }
  }

  async function untrashActiveWall(): Promise<void> {
    const wall = dashCollectionsStore.activeWall
    try {
      hideWallActions()
      await untrashWall(wall?.id as WallId, false)
      dashAccountsStore.refreshWallQuotas()
      unsetActiveWall()
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Padlet restored'),
        timeout: 5000,
        actionText: __('Open'),
        actionTextActions: [
          () => {
            navigateTo(buildUrlFromPath(wall?.address as string), { target: '_blank' })
          },
        ],
      })
    } catch {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Padlet not restored'),
      })
    }
  }

  async function untrashWall(wallId, catchException: boolean = true): Promise<void> {
    const promise = async (): Promise<void> => {
      try {
        await WallApi.untrash(wallId)
        dashCollectionsStore.removeWallIdFromAllCollections(wallId)
      } catch (error) {
        if (catchException) {
          genericFetchError({ error, source: 'DashWallSingleActionsUntrashWall' })
          return
        }

        throw error
      }
    }
    return await q.enqueue('untrashWall', promise)
  }

  async function undoTrashWall(wallId, catchException: boolean = true): Promise<void> {
    try {
      await WallApi.untrash(wallId)
      // Current collection is trash
      dashCollectionsStore.addWallIdsToCurrentCollection([wallId])
    } catch (error) {
      if (catchException) {
        genericFetchError({ error, source: 'DashWallSingleActionsUndoTrashWall' })
        return
      }

      throw error
    }
  }

  async function undoUntrashWall(wallId, catchException: boolean = true): Promise<void> {
    try {
      await WallApi.trash(wallId)
      // Current collection is trash
      dashCollectionsStore.addWallIdsToCurrentCollection([wallId])
    } catch (error) {
      if (catchException) {
        genericFetchError({ error, source: 'DashWallSingleActionsUndoUntrashWall' })
        return
      }

      throw error
    }
  }

  function confirmPermanentlyDeleteActiveWall(): void {
    if (backendState.is_recently_verified === true) {
      globalConfirmationDialogStore.openConfirmationDialog({
        subject: { wall: dashCollectionsStore.activeWall as Wall },
        shouldFadeIn: false,
        isCodeProtected: false,
        title: __('Permanently delete?'),
        body: __('Your padlet will be permanently deleted. You will not be able to undo this action.'),
        confirmButtonText: __('Delete'),
        cancelButtonText: __('Cancel'),
        buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
        afterConfirmActions: [permanentlyDeleteActiveWall],
      })
    } else {
      globalConfirmationDialogStore.openConfirmationDialog({
        shouldFadeIn: false,
        isCodeProtected: false,
        title: __('Log in to verify your account'),
        body: __('For security purposes please log in to your account to verify it’s you.'),
        confirmButtonText: __('Log in'),
        cancelButtonText: __('Cancel'),
        buttonScheme: OzConfirmationDialogBoxButtonScheme.Default,
        afterConfirmActions: [navigateToVerifyIdentity],
      })
    }
  }

  function navigateToVerifyIdentity(): void {
    const wallIds =
      dashCollectionsStore.activeWallIdCursor?.toString() ||
      dashWallBulkActionsStore.selectedWalls.map((wall) => wall.id).join(',')
    navigateTo(
      transformCurrentUrl(
        {},
        {
          path: '/auth/verify_identity',
          search: {
            email: dashAccountsStore.currentUser.email ?? dashAccountsStore.currentUser.username ?? '',
            referrer: transformCurrentUrl(
              { withQueryString: true },
              {
                searchParams: {
                  wallIds,
                },
              },
            ),
          },
        },
      ),
    )
  }

  async function permanentlyDeleteActiveWall(): Promise<void> {
    try {
      hideWallActions()
      await permanentlyDeleteWall(dashCollectionsStore.activeWallIdCursor as WallId, false)
      dashAccountsStore.refreshWallQuotas()
      unsetActiveWall()
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Padlet permanently deleted'),
      })
    } catch (error) {
      const parsedErrorMessage = JSON.parse(error.message)
      if (parsedErrorMessage.errors[0].code === ApiErrorCode.NEED_TO_VERIFY_IDENTITY) {
        globalConfirmationDialogStore.openConfirmationDialog({
          shouldFadeIn: false,
          isCodeProtected: false,
          title: __('Log in to verify your account'),
          body: __('For security purposes please log in to your account to verify it’s you.'),
          confirmButtonText: __('Log in'),
          cancelButtonText: __('Cancel'),
          buttonScheme: OzConfirmationDialogBoxButtonScheme.Default,
          afterConfirmActions: [navigateToVerifyIdentity],
        })
      } else {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Padlet not deleted'),
        })
        genericFetchError({ error, source: 'DashWallSingleActionsPermanentlyDeleteActiveWall' })
      }
    }
  }

  async function permanentlyDeleteWall(wallId, catchException: boolean = true): Promise<void> {
    const promise = async (): Promise<void> => {
      try {
        await WallApi.permanentlyDelete(wallId)
        dashCollectionsStore.removeWallIdFromAllCollections(wallId)
      } catch (error) {
        if (catchException) {
          genericFetchError({ error, source: 'DashWallSingleActionsPermanentlyDeleteWall' })
          return
        }

        throw error
      }
    }
    return await q.enqueue('permanentlyDeleteWall', promise)
  }

  /**
   * ==================== LEAVE ====================
   */

  function confirmLeaveActiveWall(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      subject: { wall: dashCollectionsStore.activeWall as Wall },
      shouldFadeIn: false,
      ...WAVING_HAND_EMOJI,
      isCodeProtected: false,
      title: __('Leave this padlet?'),
      body: __('You will have to be invited back by an administrator.'),
      confirmButtonText: __('Leave'),
      cancelButtonText: __('Nevermind'),
      afterConfirmActions: [leaveActiveWall, hideWallActions],
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
    })
  }

  async function leaveActiveWall(): Promise<void> {
    try {
      await leaveWall(dashCollectionsStore.activeWallIdCursor as WallId, false)
      unsetActiveWall()
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Padlet left'),
      })
    } catch {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Padlet not left'),
      })
    }
  }

  async function leaveWall(wallId, catchException: boolean = true): Promise<void> {
    const promise = async (): Promise<void> => {
      try {
        await PadletApi.collaboratorLeavePadlet(wallId)
        if (dashCollectionsStore.xSharedCollections) {
          const accountKey: AccountKey = dashCollectionsStore.currentAccountKey
          dashCollectionsStore.removeWallIdFromCollection({
            accountKey,
            collectionKey: { typeKey: 'filter', indexKey: 'combined_shared' },
            wallId,
          })
          if (dashCollectionsStore.activeSharedCollectionAccountKey?.type === 'library') {
            dashCollectionsStore.removeWallIdFromCollection({
              accountKey,
              collectionKey: { typeKey: 'filter', indexKey: 'combined_shared_library' },
              wallId,
            })
            return
          }

          if (dashCollectionsStore.activeSharedCollectionAccountKey?.type === 'user') {
            dashCollectionsStore.removeWallIdFromCollection({
              accountKey,
              collectionKey: { typeKey: 'filter', indexKey: 'combined_shared_user' },
              wallId,
            })
            return
          }

          return
        }

        dashCollectionsStore.updateWallAttribute({
          wallId,
          targetAttribute: 'collaboratorRight',
          newAttributeValue: 0,
        })
      } catch (error) {
        if (catchException) {
          genericFetchError({ error, source: 'DashWallSingleActionsLeaveWall' })
          return
        }

        throw error
      }
    }
    return await q.enqueue('leaveWall', promise)
  }

  /**
   * ==================== ARCHIVE / UNARCHIVE ====================
   */

  async function archiveActiveWall(): Promise<void> {
    try {
      hideWallActions()
      await archiveWall(dashCollectionsStore.activeWallIdCursor as WallId, false)
      dashAccountsStore.refreshWallQuotas()
      unsetActiveWall()
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Padlet archived'),
      })
    } catch {
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.error,
        message: __('Padlet not archived'),
      })
    }
  }

  async function archiveWall(wallId, catchException: boolean = true): Promise<void> {
    const promise = async (): Promise<void> => {
      try {
        await WallApi.archive(wallId)
        dashCollectionsStore.removeWallIdFromAllCollections(wallId)
        dashCollectionsStore.addWallIdsToCollection({
          accountKey: dashCollectionsStore.currentAccountKey,
          collectionKey: { typeKey: 'filter', indexKey: 'archived' },
          wallIds: [wallId],
        })
      } catch (error) {
        if (catchException) {
          genericFetchError({ error, source: 'DashWallSingleActionsArchiveWall' })
          return
        }

        throw error
      }
    }
    return await q.enqueue('archiveWall', promise)
  }

  async function unarchiveActiveWallOrPromptUpgrade(): Promise<void> {
    hideWallActions()

    const activeWallLibrary = dashCollectionsStore.isActiveWallInsideLibrary
      ? dashAccountsStore.librariesById[dashCollectionsStore.activeWall?.libraryId as LibraryId]
      : null

    if (
      dashCollectionsStore.isActiveWallInsideLibrary &&
      activeWallLibrary != null &&
      activeWallLibrary?.quota?.quotaHit
    ) {
      if (dashAccountsStore.currentUser != null && dashAccountsStore.currentUser.id === activeWallLibrary?.ownerId) {
        // owner => show upsell modal
        void libraryPlansStore.fetchPlansAndShowUpgradeModal({
          upgradeStep: LibraryUpgradeStep.ChooseTierPadletQuota,
          library: activeWallLibrary,
        })
      } else {
        // show an alert
        globalAlertDialogStore.openAlertDialog({
          iconSrc: anxiousFace,
          iconAlt: __('Anxious face'),
          title: __('Padlet quota reached!'),
          body: __('This team has reached its padlet quota. Ask the team owner to upgrade to continue making padlets.'),
          xShadow: true,
        })
      }
    } else if (
      !dashCollectionsStore.isActiveWallInsideLibrary &&
      dashAccountsStore.currentUser != null &&
      (isPartOfUnlimitedSandboxesEvent()
        ? dashCollectionsStore.activeWall?.viz !== 'whiteboard' && !canCreateWall.value
        : !canCreateWall.value)
    ) {
      void personalPlansStore.quotaTriggeredUpgrade({
        user: dashAccountsStore.currentUser,
        upgradeSource: UpgradeSource.UnarchiveWallQuota,
      })
    } else {
      try {
        await unarchiveActiveWall()
        dashAccountsStore.refreshWallQuotas()
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.success,
          message: __('Padlet unarchived'),
        })
      } catch {
        globalSnackbarStore.setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Padlet not unarchived'),
        })
      }
    }
  }

  async function unarchiveActiveWall(): Promise<void> {
    await unarchiveWall(dashCollectionsStore.activeWallIdCursor as WallId, false)
    unsetActiveWall()
  }

  async function unarchiveWall(wallId, catchException: boolean = true): Promise<void> {
    const promise = async (): Promise<void> => {
      try {
        await WallApi.unarchive(wallId)
        dashCollectionsStore.removeWallIdFromAllCollections(wallId)
      } catch (error) {
        if (catchException) {
          genericFetchError({ error, source: 'DashWallSingleActionsUnarchiveWall' })
          return
        }

        throw error
      }
    }
    return await q.enqueue('unarchiveWall', promise)
  }

  async function undoArchiveWall(wallId, catchException: boolean = true): Promise<void> {
    try {
      await WallApi.unarchive(wallId)
      // Current collection is archive
      dashCollectionsStore.addWallIdsToCurrentCollection([wallId])
    } catch (error) {
      if (catchException) {
        genericFetchError({ error, source: 'DashWallSingleActionsUndoArchiveWall' })
        return
      }

      throw error
    }
  }

  async function undoUnarchiveWall(wallId, catchException: boolean = true): Promise<void> {
    try {
      await WallApi.archive(wallId)
      // Current collection is archive
      dashCollectionsStore.addWallIdsToCurrentCollection([wallId])
    } catch (error) {
      if (catchException) {
        genericFetchError({ error, source: 'DashWallSingleActionsUndoUnarchiveWall' })
        return
      }

      throw error
    }
  }

  /**
   * ==================== REMOVE FROM RECENTS ====================
   */

  async function removeWallFromRecents(wallId: WallId, catchException: boolean = true): Promise<void> {
    try {
      await WallApi.removeFromRecents(wallId)
      dashCollectionsStore.updateWallAttribute({
        wallId,
        targetAttribute: 'isInRecents',
        newAttributeValue: false,
      })
      dashCollectionsStore.removeWallIdFromCollection({
        accountKey: dashCollectionsStore.currentAccountKey,
        collectionKey: { typeKey: 'filter', indexKey: 'combined_recents' },
        wallId,
      })
    } catch (error) {
      if (catchException) {
        genericFetchError({ error, source: 'DashWallSingleActionsRemoveWallFromRecents' })
        return
      }

      throw error
    }
  }

  async function removeActiveWallRecents(): Promise<void> {
    hideWallActions()
    await removeWallRecents({
      wallId: dashCollectionsStore.activeWallIdCursor as WallId,
      accountKey: dashCollectionsStore.currentAccountKey,
    })
    unsetActiveWall()
    globalSnackbarStore.setSnackbar({
      notificationType: SnackbarNotificationType.success,
      message: __('Padlet removed from recents'),
    })
  }

  async function removeWallRecents(payload: { wallId: WallId; accountKey: AccountKey }): Promise<void> {
    const promise = async (): Promise<void> => {
      try {
        await fetchResponse(`/api/1/walls/${payload.wallId}/remove_from_recents`, {
          method: HTTPMethod.delete,
          headers: {
            'X-CSRF-Token': getCsrfToken(),
          },
        })
        dashCollectionsStore.updateWallAttribute({
          wallId: payload.wallId,
          targetAttribute: 'isInRecents',
          newAttributeValue: false,
        })
        dashCollectionsStore.removeWallIdFromCollection({
          accountKey: payload.accountKey,
          collectionKey: { typeKey: 'filter', indexKey: 'combined_recents' },
          wallId: payload.wallId,
        })
      } catch (error) {
        genericFetchError({ error, source: 'DashWallSingleActionsRemoveWallRecents' })
      }
    }
    return await q.enqueue('removeWallRecents', promise)
  }

  /**
   * ==================== OPEN SLIDESHOW ====================
   */

  function openActiveWallSlideshow(): void {
    trackEvent('Dashboard', 'Opened slideshow')
    hideWallActions()

    if (dashCollectionsStore.activeWall?.links.show == null) {
      return
    }

    const coverPageUrl = buildSlideshowLink(dashCollectionsStore.activeWall?.links.show, PageType.Cover)

    navigateTo(coverPageUrl, { target: '_blank' })
    unsetActiveWall()
  }

  /**
   * ==================== COPY TO CLIPBOARD ====================
   */

  function copyActiveWallLinkToClipboard(): void {
    hideWallActions()
    void copyToClipboard(`${currentHostWithProtocol()}/${dashCollectionsStore.activeWall?.address as string}`)
    unsetActiveWall()
    globalSnackbarStore.setSnackbar({
      notificationType: SnackbarNotificationType.success,
      message: __('Link copied to clipboard'),
    })
  }

  /**
   * ==================== WHITEBOARD ====================
   */

  function openActiveWallPlayMode(): void {
    trackEvent('Dashboard', 'Opened Sandbox play mode')
    hideWallActions()

    if (dashCollectionsStore.activeWall?.links.show == null) {
      return
    }

    const playModeUrl = `${dashCollectionsStore.activeWall?.links.show as string}?play=1`

    navigateTo(playModeUrl, { target: '_blank' })
    unsetActiveWall()
  }

  return {
    xWallActions,
    wallActionsAnchor,
    canCreateWall,
    showWallActions,
    hideWallActions,
    closeWallActions,
    unsetActiveWall,
    setActiveWall,

    /**
     * ==================== BOOKMARKS ====================
     */

    xBookmarksDialog,
    openBookmarksDialog,
    closeBookmarksDialog,
    removeBookmark,
    removeBookmarkVuex,
    undoRemoveBookmark,
    addBookmark,
    addBookmarkVuex,
    undoAddBookmark,

    /**
     * ==================== TRANSFER WALL ====================
     */
    xTransferWallModal,
    openTransferWallModal,
    closeTransferWallModal,

    /**
     * ==================== TRASH / UNSTRASH ====================
     */

    trashActiveWall,
    trashWall,
    untrashActiveWallOrPromptUpgrade,
    untrashActiveWall,
    untrashWall,
    undoTrashWall,
    undoUntrashWall,
    confirmPermanentlyDeleteActiveWall,
    navigateToVerifyIdentity,
    permanentlyDeleteActiveWall,
    permanentlyDeleteWall,

    /**
     * ==================== LEAVE ====================
     */

    confirmLeaveActiveWall,
    leaveActiveWall,
    leaveWall,

    /**
     * ==================== ARCHIVE / UNARCHIVE ====================
     */

    archiveActiveWall,
    archiveWall,
    unarchiveActiveWallOrPromptUpgrade,
    unarchiveActiveWall,
    unarchiveWall,
    undoArchiveWall,
    undoUnarchiveWall,

    /**
     * ==================== REMOVE FROM RECENTS ====================
     */

    removeWallFromRecents,
    removeActiveWallRecents,
    removeWallRecents,

    /**
     * ==================== OPEN SLIDESHOW ====================
     */

    openActiveWallSlideshow,
    copyActiveWallLinkToClipboard,

    /**
     * ==================== OPEN WHITEBOARD ====================
     */

    openActiveWallPlayMode,
  }
})
