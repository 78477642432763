// @file pinia store for native app state
import appCan from '@@/bits/app_can'
import device from '@@/bits/device'
import { circularSafeStringify } from '@@/bits/json_stringify'
import { getVuexStore } from '@@/bits/pinia'
import type { SyncableSurfaceState } from '@@/native_bridge/actions'
import { postSurfaceState as nativeBridgePostSurfaceStateMessage } from '@@/native_bridge/actions'
import postMessage from '@@/native_bridge/post_message'
import type { LinkType } from '@@/native_bridge/types'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import type { AccumulatedReactionData, UserId } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type { Reaction } from '@padlet/arvo'
import type { BeethovenContentCategory, BeethovenContentSubcategory } from '@padlet/beethoven-client/src/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type LinkTarget = '_blank' | '_top' | '_self'
export interface NativeAppOpenLink {
  url: string | null
  linkType: LinkType | null
  target?: LinkTarget | null
  contentCategory?: BeethovenContentCategory
  contentSubcategory?: BeethovenContentSubcategory | 'profile'
  username?: string | null
}

export const useNativeAppStore = defineStore('nativeApp', () => {
  const surfaceVuexStore = getVuexStore<RootState>() // For gradual conversion to pinia
  const surfaceSettingsStore = useSurfaceSettingsStore()
  const expandedPostStore = useExpandedPostStore()
  const commentsStore = useCommentsStore()
  const reactionsStore = useReactionsStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const surfacePostActionStore = useSurfacePostActionStore()
  const surfaceSectionsStore = useSurfaceSectionsStore()
  const surfaceUserContributorsStore = useSurfaceUserContributorsStore()
  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */
  const linkToOpen = ref<NativeAppOpenLink | null>(null)
  const syncableSurfaceState = ref<SyncableSurfaceState | null>(null)

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */
  const openLink = (link: NativeAppOpenLink): void => {
    linkToOpen.value = link
  }

  const clearOpenLink = (): void => {
    linkToOpen.value = null
  }

  const postSurfaceState = async (transientPayload?: { collaborator_just_left_user_id?: UserId }): Promise<void> => {
    if (!device.app) {
      return
    }

    const expandedPostAuthorId = expandedPostStore.expandedPost?.author_id
    if (expandedPostAuthorId != null) {
      await surfaceUserContributorsStore.fetchUser(expandedPostAuthorId)
      await Promise.all(
        expandedPostStore.expandedPostCommenterIds.map(
          async (id): Promise<void> => await surfaceUserContributorsStore.fetchUser(id as number),
        ),
      )
    }

    // the current user's reactions
    const reactions = Object.values(reactionsStore.accumulatedReactionsByWishId).reduce<Reaction[]>((acc, val) => {
      if (val?.userReaction != null) {
        acc.push(val.userReaction)
      }
      return acc
    }, [])

    // aggregated reaction numbers
    const accumulatedReactions = Object.entries(reactionsStore.accumulatedReactionsByWishId).reduce<
      Record<string, Pick<AccumulatedReactionData, 'reactionType' | 'sum' | 'totalReactionsCount'>>
    >((acc, [wishId, reactionsData]) => {
      if (reactionsData == null) {
        return acc
      }
      acc[wishId] = {
        reactionType: reactionsData.reactionType,
        sum: reactionsData.sum,
        totalReactionsCount: reactionsData.totalReactionsCount,
      }
      return acc
    }, {})

    const appState: SyncableSurfaceState = {
      wall_sections: surfaceSectionsStore.sortedSections,
      oauth_token: surfaceVuexStore?.getters.oauthToken,
      reactions,
      has_comments: commentsStore.commentIds.length > 0,
      accumulated_reactions: accumulatedReactions,
      // TODO: [to-be-migrated][reaction]
      wall_reaction_data: surfaceVuexStore?.getters.reactionData,
      expanded_post_data: expandedPostStore.expandedPostData,
      is_remake_toggleable: true,
      // amICollaborator is to show/hide the "Leave padlet" button in the mobile app
      am_i_collaborator: surfacePermissionsStore.amICollaborator,
      collaborator_just_left_user_id: transientPayload?.collaborator_just_left_user_id,
      // Permissions
      can_i_write: surfacePermissionsStore.canIWrite,
      can_i_edit: surfacePermissionsStore.canIEdit,
      can_i_administer: surfacePermissionsStore.canIAdminister,
      // Surface V2 is launched in Jan 2023 Winter release. This flag should be true for all users after public release.
      is_surface_v2_launched: appCan('useSurfaceV2'),
      // App needs knowledge of cid to id mapping that we have assigned to drafts
      post_drafts_by_cid: surfaceDraftsStore.draftByCid,
    }

    syncableSurfaceState.value = appState

    const plainObjectAppState: SyncableSurfaceState = JSON.parse(circularSafeStringify(appState))

    postMessage(nativeBridgePostSurfaceStateMessage(plainObjectAppState))
  }

  // TODO: [potential-migration][panels] - most of the states below actually makes more sense to be within a new pinia store for panels state than their current modules.
  const hideAllPanelsAndMenus = (): void => {
    surfaceSettingsStore.hideSettingsPanelWithoutConfirmation()

    // TODO: [to-be-migrated][share]
    // surfaceSharePanelStore.xSurfaceSharePanel = false

    // TODO: [to-be-migrated][surface]
    void surfaceVuexStore?.dispatch('hideRemakePanel', null, { root: true })

    surfacePostActionStore.endPostTransfer()
    surfacePostActionStore.endPostCopy()

    surfacePostActionStore.hidePostActionMenu()

    surfaceSectionsStore.sectionUnderActionId = null

    // TODO: [to-be-migrated][comment]
    if (surfaceVuexStore?.state.comment?.actionMenuCommentId != null) {
      void surfaceVuexStore?.dispatch('comment/hideCommentActionMenu', null, { root: true })
    }
  }

  return {
    // getters
    linkToOpen,
    syncableSurfaceState, // not used directly, but good for debugging via pinia devtools

    // actions
    openLink,
    clearOpenLink,
    postSurfaceState,
    hideAllPanelsAndMenus,
  }
})
