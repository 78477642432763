// @file Surface ai chat store

import { trackEvent } from '@@/bits/analytics'
import environment from '@@/bits/environment'
import { EventSourceWithHeaders } from '@@/bits/event_source_with_headers'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { magicTemplateEnum } from '@@/pinia/magic_padlet_panel_store'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { useSurfaceOnboardingPanelStore } from '@@/pinia/surface_onboarding_panel'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import PadletApi from '@@/surface/padlet_api'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum Role {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}

export interface Message {
  role: string
  content: string
}

export interface Suggestion {
  long_message: string
  short_message: string
}

export interface SerializedWall {
  title: string
  subtitle: string
  sections: Array<{
    section_id: string
    section_title: string
  }>
  posts: Array<{
    post_id: string
    section_id: string
    subject: string
    body: string
  }>
}

export enum SuggestionsStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const useSurfaceAIChatStore = defineStore('surfaceAIChatStore', () => {
  const DEFAULT_SUGGESTIONS: Suggestion[] = [
    {
      short_message: __('What can you help me with?'),
      long_message: __('What can you help me with?'),
    },
    {
      short_message: __('Add posts about a topic of my choosing.'),
      long_message: __('Add posts about a topic of my choosing.'),
    },
    {
      short_message: __('How do I add custom fields to my posts?'),
      long_message: __('How do I add custom fields to my posts?'),
    },
  ]

  const surfaceOnboardingPanelStore = useSurfaceOnboardingPanelStore()
  const surfaceStore = useSurfaceStore()
  const surfaceStartingStateStore = useSurfaceStartingStateStore()
  const surfaceCurrentUserStore = useSurfaceCurrentUserStore()
  const surfacePostsStore = useSurfacePostsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const xSurfaceAIChatPanel = ref(false)
  const xActionMenu = ref(false)
  const magicWallFirstOpen = ref(false)
  const isInitialAssistantMessage = ref(false)
  const wallContextMessage = ref<string | null>(null)
  const messages = ref<Message[]>([])
  const threadId = ref<string | null>(null)
  const savedThreadId = ref<string | null>(null)
  const source = ref<EventSourceWithHeaders | null>(null)
  const streamingFailureSnackbarId = ref<string | null>(null)

  const suggestions = ref<Suggestion[]>([])
  const suggestionsStatus = ref<SuggestionsStatus>(SuggestionsStatus.SUCCESS)

  const isLoadingSuggestions = computed(() => suggestionsStatus.value === SuggestionsStatus.LOADING)
  const hasSuggestions = computed(() => suggestions.value.length !== 0)

  const assistantName = computed(() => {
    if (surfaceStore.magicWallType == null) return __('Teaching assistant') // this happens for native admins
    const AIRecipeName = getDisplayNameByKey(surfaceStore.magicWallType)
    if (AIRecipeName === 'Custom board' || AIRecipeName === 'Discussion board') {
      return __('AI recipe assistant')
    } else {
      return __(AIRecipeName + ' assistant')
    }
  })

  const baseUrl = environment === 'development' ? 'https://morpheus.padlet.dev' : 'https://morpheus.padlet.com'

  const isEventStreamOpen = computed(() => source.value !== null)
  const hasMessages = computed(() => messages.value.length > 0)
  const isLoadingResponse = ref(false)

  const hasFinishedResponse = computed(() => {
    return !isEventStreamOpen.value && !isLoadingResponse.value
  })

  // magicWallFirstOpen is used by both SurfaceMagicWallFeedbackPopup and SurfaceAIChat
  // The feedback popup should always show up for magic walls. But the surface chat only shows for teachers.
  async function loadMagicWallFirstOpen(showSurfaceChat: boolean): Promise<void> {
    const jsonFormData = safeLocalStorage.getItem('magicWallOptions')
    if (jsonFormData != null) {
      magicWallFirstOpen.value = true
      isInitialAssistantMessage.value = true
      safeLocalStorage.removeItem('magicWallOptions')

      // Return early if we don't want to show the chat panel
      if (!showSurfaceChat) {
        return
      }

      wallContextMessage.value = jsonFormData
      if (threadId.value === null) {
        // if first magic wall open, assistant message should be sent before user message allowed
        isLoadingResponse.value = true
      }
    }
  }

  async function loadThreadMessages(threadId: string, signal?: AbortSignal): Promise<void> {
    try {
      const response = await PadletApi.SurfaceAIChat.getThreadMessages(threadId, { signal })
      const formattedMessages = response.messages
        .filter(
          (message: any) =>
            message.role === 'assistant' ||
            (message.role === 'user' && message.content[0].text.value.includes('# user message #')),
        )
        .map((message: any) => {
          if (message.role === 'user') {
            const content = message.content[0].text.value
            const userMessageIndex: number = content.indexOf('# user message #')
            return {
              role: message.role,
              content: content.slice(userMessageIndex + '# user message #'.length).trim(),
            }
          } else {
            return {
              role: message.role,
              content: message.content[0].text.value,
            }
          }
        })
        .reverse() // Reverse the array to show oldest messages first

      messages.value = formattedMessages
    } catch (error) {
      messages.value = []
      savedThreadId.value = null
      throw error
    }
  }

  async function initializeEventSource(wallId: number, threadId: string): Promise<void> {
    source.value = new EventSourceWithHeaders(
      `${baseUrl}/api/v1/assistant/latest/${wallId}/threads/${threadId}/event-stream`,
      {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
    )
    source.value.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      if (data.event === 'thread.message.created') {
        isLoadingResponse.value = false
      }
      if (data.event === 'thread.message.delta') {
        const messageDelta = data.data.delta.content[0].text.value
        addAssistantMessageDelta(messageDelta)
      }
      if (data.event === 'thread.message.completed') {
        // Log assistant response
        const lastMessage = messages.value[messages.value.length - 1]
        if (lastMessage.role === Role.ASSISTANT && !isInitialAssistantMessage.value) {
          trackEvent('SurfaceAiChat', 'AssistantResponse', lastMessage.content, lastMessage.content.length, {
            message_length: lastMessage.content.length,
            wall_id: wallId,
            thread_id: threadId,
            message_history: messages.value,
          })
        }
        isInitialAssistantMessage.value = false

        // TODO: Figure out how to leave empty line in div. Chaining \n doesn't work with markdown-it
        addAssistantMessageDelta('\n\n')
      }
      if (
        data.event === 'thread.run.completed' ||
        data.event === 'thread.run.failed' ||
        data.event === 'thread.run.expired' ||
        data.event === 'thread.run.cancelled'
      ) {
        closeEventSource()
      }
      if (data.event === 'error') {
        closeEventSource()
        void handleStreamingFailure(threadId)
        streamingFailureSnackbarId.value = globalSnackbarStore.setSnackbar({
          message: __('There was an error with the Assistant. Reloading the thread.'),
          notificationType: SnackbarNotificationType.error,
          persist: true,
        })
      }
    })
    source.value.addEventListener('error', (event) => {
      closeEventSource()
    })
    await source.value.connect()
  }

  function closeEventSource(): void {
    if (source.value != null) {
      source.value.close()
      source.value = null
    }
  }

  const activeRunStatuses = ['in_progress', 'queued', 'requires_action']

  async function handleStreamingFailure(threadId: string): Promise<void> {
    let attemptCount = 1
    try {
      while (attemptCount <= 4) {
        // Give up after 3 attempts to avoid calling the openai API too many times
        if (attemptCount === 4) throw new Error('Waited too long for run to finish')
        const latestRun = await PadletApi.SurfaceAIChat.getLatestRun(threadId)
        const isRunStillActive = activeRunStatuses.includes(latestRun.status)
        if (!isRunStillActive) {
          break
        } else {
          /* If the latest run is still active, wait for it to complete before loading messages and allowing the user to type
          Otherwise the user will get an error when trying to send a message to an active run. */
          await new Promise((resolve) => setTimeout(resolve, 2000))
          attemptCount++
        }
      }
      await loadThreadMessages(threadId, AbortSignal.timeout(7000)) // abort and throw error if loading messages takes too long
    } catch (error) {
      void resetThread() // reset thread if loading messages fails
    } finally {
      if (streamingFailureSnackbarId.value != null) {
        globalSnackbarStore.removeSnackbar(streamingFailureSnackbarId.value)
      }
      isLoadingResponse.value = false
    }
  }

  async function initializeAll(): Promise<void> {
    if (messages.value.length > 0 || savedThreadId.value != null) {
      return
    }
    isLoadingResponse.value = true
    try {
      await initializeSavedThread()
      await initializeWallAssistantMessage()
      if (savedThreadId.value == null && !magicWallFirstOpen.value) {
        await fetchSuggestions()
      }
    } catch (error) {
      handleInitializationError()
    } finally {
      isLoadingResponse.value = false
    }
  }

  function handleInitializationError(): void {
    xSurfaceAIChatPanel.value = false
    globalSnackbarStore.setSnackbar({
      message: __('The Assistant encountered an error. Please try again later.'),
      notificationType: SnackbarNotificationType.error,
    })
  }

  async function fetchSuggestions(): Promise<any> {
    if (surfacePostsStore.currentPostsCount === 0) {
      suggestions.value = DEFAULT_SUGGESTIONS
      suggestionsStatus.value = SuggestionsStatus.SUCCESS
      return
    }
    try {
      suggestionsStatus.value = SuggestionsStatus.LOADING
      const serializedWallResponse = await PadletApi.SurfaceAIChat.getSerializedWall(surfaceStore.wallId)
      const serializedWall: SerializedWall = serializedWallResponse.data.attributes
      const response = await PadletApi.SurfaceAIChat.getSuggestions(
        asciiSafeStringify(serializedWall),
        surfaceStartingStateStore.currentCountry ?? '',
        surfaceCurrentUserStore.currentUser?.lang ?? '',
        surfaceCurrentUserStore.currentUser?.account_type ?? '',
        { signal: AbortSignal.timeout(5000) }, // This signal will cause getSuggestions tothrow a timeout error if the request takes more than 5 seconds
      )
      suggestions.value = response.suggestions
      suggestionsStatus.value = SuggestionsStatus.SUCCESS
    } catch {
      suggestions.value = DEFAULT_SUGGESTIONS
      suggestionsStatus.value = SuggestionsStatus.ERROR
    }
  }

  async function onUserMessage(message: Message): Promise<void> {
    pushUserMessage(message.content)

    pushAssistantMessage('')
    isLoadingResponse.value = true
    // Create thread and send pre message.
    if (threadId.value === null) {
      const response = await PadletApi.SurfaceAIChat.createThread(surfaceStore.wallId)
      threadId.value = response.thread_id
      await PadletApi.SurfaceAIChat.sendMessage(threadId.value as string, {
        role: Role.SYSTEM,
        content: constructPreMessage(),
      })
    }
    // Save thread on user message
    if (savedThreadId.value == null && threadId.value != null) {
      void PadletApi.SurfaceAIChat.saveThreadId(surfaceStore.wallId, threadId.value)
      savedThreadId.value = threadId.value
    }
    // Send message to assistant
    // Need to await here to ensure message is inside thread
    const serializedWallResponse = await PadletApi.SurfaceAIChat.getSerializedWall(surfaceStore.wallId)
    const serializedWall: SerializedWall = serializedWallResponse.data.attributes
    message.content = constructFullMessage(message.content, serializedWall)
    await PadletApi.SurfaceAIChat.sendMessage(threadId.value as string, message)
    if (threadId.value !== null) {
      await initializeEventSource(surfaceStore.wallId, threadId.value)
    }
  }

  async function initializeSavedThread(): Promise<void> {
    // Fetch from rails first, if doesn't exist, we create a new thread on openAi.
    try {
      savedThreadId.value = await PadletApi.SurfaceAIChat.getThreadId(surfaceStore.wallId)
      if (savedThreadId.value != null) {
        threadId.value = savedThreadId.value
        await loadThreadMessages(savedThreadId.value, AbortSignal.timeout(7000))
      }
    } catch (error) {
      savedThreadId.value = null
      if (error.name === 'TimeoutError') {
        // If loading messages takes too long, we set threadId to null to trigger a new thread creation on user message
        // Don't throw error or it will be handled as a general initialization error and the chat panel will  be closed
        threadId.value = null
      } else {
        throw error
      }
    }
  }

  async function initializeWallAssistantMessage(): Promise<void> {
    if (!magicWallFirstOpen.value) {
      return
    }
    pushAssistantMessage('')

    const serializedWallResponse = await PadletApi.SurfaceAIChat.getSerializedWall(surfaceStore.wallId)
    const serializedWall: SerializedWall = serializedWallResponse.data.attributes
    const message = {
      role: Role.SYSTEM,
      content: constructFullMessage(constructPreMessage(), serializedWall, magicWallFirstOpen.value),
    }

    const response = await PadletApi.SurfaceAIChat.createThread(surfaceStore.wallId)
    threadId.value = response.thread_id

    await PadletApi.SurfaceAIChat.sendMessage(threadId.value as string, message)
    if (threadId.value !== null) {
      await initializeEventSource(surfaceStore.wallId, threadId.value)
    }
  }

  function getNameByKey(key: string): string {
    for (const template in magicTemplateEnum) {
      if (magicTemplateEnum[template].key === key) {
        return magicTemplateEnum[template].name
      }
    }
    return 'board'
  }

  function getDisplayNameByKey(key: string): string {
    for (const template in magicTemplateEnum) {
      if (magicTemplateEnum[template].key === key) {
        return magicTemplateEnum[template].shortName ?? magicTemplateEnum[template].name
      }
    }
    return 'board'
  }

  function constructPreMessage(): string {
    const lang = surfaceCurrentUserStore.currentUser?.lang
    const country = surfaceStartingStateStore.currentCountry
    const accountType = surfaceCurrentUserStore.currentUser?.account_type

    let preMessage = '# user context #\n'
    if (lang != null) {
      preMessage += `Preferred language: ${lang}\n`
    }

    if (country != null) {
      preMessage += `Current location: ${country}\n`
    }

    if (accountType != null) {
      preMessage += `Account type: ${accountType}\n`
    }

    if (wallContextMessage.value != null) {
      preMessage += `${formatMagicWallOptions(JSON.parse(wallContextMessage.value))}\n`
    }
    return preMessage.trim()
  }

  function constructFullMessage(
    message: string,
    serializedWall: SerializedWall,
    isFirstMessage: boolean = false,
  ): string {
    const contentsMessage = ['# padlet contents #', asciiSafeStringify(serializedWall)].join('\n\n')

    if (isFirstMessage) {
      return [
        message,
        contentsMessage,
        '# initial instructions #',
        "The first message you send should acknowledge the initial padlet context in a concise way and then provide three suggested messages that the user could respond with based on the context of the conversation. The suggestions must be concise instructions (not questions) written from the user's perspective.",
        `Send this first message in the preferred language of the user. The message should be something like "Hello, I see you just requested a ${getNameByKey(
          surfaceStore.magicWallType,
        ).toLowerCase()} about <concise summary>. Here are three suggestions to help you refine it further:", followed by 3 bullet points with suggestions. Don't suggest changing the wallpaper or adding attachments to posts.`,
      ].join('\n\n')
    } else {
      return [contentsMessage, '# user message #', message].join('\n\n')
    }
  }

  function resetMessages(): void {
    threadId.value = null
    messages.value = []
  }

  function addAssistantMessageDelta(delta: string): void {
    messages.value[messages.value.length - 1].content += delta
  }

  function pushAssistantMessage(content: string): void {
    messages.value.push({
      role: Role.ASSISTANT,
      content,
    })
  }

  function pushUserMessage(content: string): void {
    messages.value.push({
      role: Role.USER,
      content,
    })
  }

  function showChatPanel(): void {
    xSurfaceAIChatPanel.value = true
  }

  function openActionMenu(): void {
    xActionMenu.value = true
  }

  function hidePostActionMenu(): void {
    xActionMenu.value = false
  }

  function clearThread(): void {
    resetMessages()
    void fetchSuggestions()
    savedThreadId.value = null
    void PadletApi.SurfaceAIChat.deleteThreadForWall(surfaceStore.wallId)
  }

  /**
   * Transform object keys and convert the object to AI/human readable string
   * @example
   * input: { student_grade: 5 }; output: 'Student Grade : 5'
   */
  function formatMagicWallOptions(json): string {
    const formattedLines = Object.entries(json)
      .filter(([key, value]) => !['wallType', 'isExample', 'includeImages'].includes(key) && value) // Remove specific keys and empty values
      .map(([key, value]) => {
        const formattedKey = key
          .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
          .replace(/_/g, ' ') // Replace underscores with spaces
          .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
        return `${formattedKey}: "${value as string}"`
      })
    const padletContext = `
# initial padlet context #
This padlet was just created by an LLM based on user input.
The user requested a ${getNameByKey(surfaceStore.magicWallType)} with the following parameters: \n
`
    return `${padletContext}${formattedLines.join('\n')}`
  }

  function toggleChatPanel(): void {
    if (surfaceOnboardingPanelStore.showOnboardingPanel) {
      surfaceOnboardingPanelStore.closeOnboardingPanel()
    }
    xSurfaceAIChatPanel.value = !xSurfaceAIChatPanel.value
  }

  async function resetThread(): Promise<void> {
    // Clear messages
    messages.value = []

    // Clear existing thread IDs
    threadId.value = null
    savedThreadId.value = null

    // Close any open event source
    closeEventSource()

    // Reset loading states
    isLoadingResponse.value = false
    isInitialAssistantMessage.value = false

    // Delete the thread on the server
    if (surfaceStore.wallId != null) {
      await PadletApi.SurfaceAIChat.deleteThreadForWall(surfaceStore.wallId)
    }

    // Fetch new suggestions
    await fetchSuggestions()

    // Create a new thread
    const response = await PadletApi.SurfaceAIChat.createThread(surfaceStore.wallId)
    threadId.value = response.thread_id

    // Show snackbar notification
    globalSnackbarStore.setSnackbar({
      message: __('Assistant timed out. Thread reset.'),
      notificationType: SnackbarNotificationType.error,
    })
  }

  return {
    loadThreadMessages,
    xActionMenu,
    suggestions,
    hasSuggestions,
    assistantName,
    messages,
    threadId,
    savedThreadId,
    hasMessages,
    onUserMessage,
    resetMessages,
    showChatPanel,
    openActionMenu,
    hidePostActionMenu,
    toggleChatPanel,
    clearThread,
    fetchSuggestions,
    xSurfaceAIChatPanel,
    magicWallFirstOpen,
    isEventStreamOpen,
    isLoadingSuggestions,
    isLoadingResponse,
    hasFinishedResponse,
    loadMagicWallFirstOpen,
    initializeAll,
    initializeSavedThread,
    initializeWallAssistantMessage,
    initializeEventSource,
    closeEventSource,
    resetThread,
  }
})
