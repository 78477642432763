// @file utility functions related to posts (wishes)

import { anonymous, isRegistered } from '@@/bits/user_model'
import type { User } from '@@/types'
import type { User as UserArvo } from '@padlet/arvo'

export function getAuthorDisplayName(user?: UserArvo | User): string {
  if (user == null) return ''
  if (!isRegistered(user)) {
    return user.name != null && user.name.trim() !== '' ? user.name.trim() : anonymous
  }
  if (user.name != null && user.name !== '') {
    return user.name
  }
  if (user.username != null && user.username !== '') {
    return user.username
  }
  return ''
}
