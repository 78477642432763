// @file Pinia store for the starting state of the surface
import PadletApi from '@@/surface/padlet_api'
import type { User } from '@@/types'
import { blankState } from '@@/vuexstore/surface'
import type { SurfaceState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceStartingStateStore = defineStore('surfaceStartingState', () => {
  const startingState = ref<SurfaceState>({ ...blankState() })

  const arvoConfig = computed(() => startingState.value.arvoConfig)
  const googleApiKeyForMap = computed<string>(() => startingState.value.constants.googleApiKeyForMap)
  const currentCountryCode = computed<string>(() => startingState.value.constants.currentCountryCode)
  const currentCountry = computed<string>(() => startingState.value.constants.currentCountry)
  const morpheusToken = computed<string>(() => startingState.value.constants.morpheusToken)
  const canMakeInTenant = computed<boolean>(() => startingState.value.canMakeInTenant)
  const user = computed<User>(() => startingState.value.user)

  function intializeState(state: SurfaceState): void {
    startingState.value = state
  }

  async function fetchStartingState(): Promise<void> {
    const response = await PadletApi.PadletStartingState.fetch()
    startingState.value = Object.assign(startingState.value, response)
  }

  return {
    startingState,
    arvoConfig,
    googleApiKeyForMap,
    currentCountryCode,
    currentCountry,
    morpheusToken,
    canMakeInTenant,
    intializeState,
    fetchStartingState,
    user,
  }
})
