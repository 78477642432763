// @file partitioned debounce
/**
 * Debounces a function but "partitioned" according to unique keys derived from the getKey function.
 * Use this to debounce the same operation but applied to separate entities.
 *
 * TODO: once vuex store for drafts is removed, make arguments generic, ie partitionedDebounce<T>
 *
 * @param fn function to debounce
 * @param options.getKey key derived from input of each function invocation. receives same input as fn
 * @param options.wait how long to wait to execute fn
 * @param options.timers Map to store timers used for debouncing
 */
export function partitionedDebounce(
  fn: (...args: any[]) => any,
  {
    getKey = () => 'default', // use the 'default' key if none given
    wait = 1000, // 1s debounce
    timers,
  }: {
    getKey?: (...args: any[]) => string
    wait?: number
    timers: Map<string, any> // mandatory.
  },
): (...args: any[]) => void {
  return (...args: any[]) => {
    const key = getKey(...args)
    clearTimeout(timers.get(key))

    timers.set(
      key,
      setTimeout(() => {
        fn(...args)
        timers.delete(key)
      }, wait),
    )
  }
}
