// @file Store for padlet picker

import { asciiSafeStringify } from '@@/bits/json_stringify'
import { safeLocalStorage } from '@@/bits/safe_storage'
import type { WallVizEnum } from '@@/enums'
import type { AccountKey, FolderId, Wall } from '@@/types'
import type { CollectionKey } from '@@/types/collections'
import type { PadletPickerCreateState } from '@@/vuecomposables/usePadletPickerLastCreateState'
import { uniq } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const WALL_SEARCH_SAFE_STORAGE_KEY = 'wallSearchSuggestions'

export interface CollectionFolder {
  name: string
  id: string | FolderId
  accountKey: AccountKey
  collectionKey: CollectionKey
  walls?: WallWithCurrentUserInfo[]
  icon?: string
  logo?: string
  fallbackLogo?: string
}

export interface WallWithCurrentUserInfo extends Wall {
  currentUserIsBuilder?: boolean
  currentUserCanWrite?: boolean
}

export enum CollectionTab {
  Recents = 'combined_recents',
  Bookmarks = 'favorites',
  Libraries = 'libraries',
  Search = 'search',
}

export type PadletPickerCreateWallViz = WallVizEnum.Wall | WallVizEnum.Whiteboard
export enum PadletSelectionIntent {
  Attachment = 'attachment',
  CopyPost = 'copy_post',
  TransferPost = 'transfer_post',
}
export enum PadletPickerMode {
  Selection = 'selection', // display collection tabs and wall items in the form of cards or rows
  Creation = 'creation', // display library selector and inputs for creating a wall
}

export const usePadletPickerStore = defineStore('padletPicker', () => {
  const currentMode = ref(PadletPickerMode.Selection)
  const lastCreateState = ref<PadletPickerCreateState | null>(null)

  const goIntoCreateMode = (createState: PadletPickerCreateState): void => {
    currentMode.value = PadletPickerMode.Creation
    lastCreateState.value = createState
  }

  const clearLastCreateState = (): void => {
    lastCreateState.value = null
  }

  /** Search Suggestions */
  const searchSuggestionsToBeSynced = ref<string[]>([])

  const searchSuggestions = computed(() =>
    uniq(searchSuggestionsToBeSynced.value.reverse().filter((q) => q != null && q.length > 0)),
  )

  const addSearchSuggestionToSafeStorage = (query: string): void => {
    const stringValue = safeLocalStorage.getItem(WALL_SEARCH_SAFE_STORAGE_KEY)
    const currentSuggestions: string[] = stringValue != null ? JSON.parse(stringValue) : []
    let newSuggestions = currentSuggestions.filter((q) => q !== query)
    newSuggestions.push(query)
    newSuggestions = newSuggestions.slice(-10)
    safeLocalStorage.setItem(WALL_SEARCH_SAFE_STORAGE_KEY, asciiSafeStringify(newSuggestions))
    searchSuggestionsToBeSynced.value = newSuggestions
  }

  function syncSearchSuggestionsWithStorage(): void {
    const stringValue = safeLocalStorage.getItem(WALL_SEARCH_SAFE_STORAGE_KEY)
    const suggestions = stringValue != null ? JSON.parse(stringValue) : []
    searchSuggestionsToBeSynced.value = suggestions
  }

  return {
    // Getters
    searchSuggestions,
    currentMode,
    lastCreateState,

    // Actions
    addSearchSuggestionToSafeStorage,
    syncSearchSuggestionsWithStorage,
    goIntoCreateMode,
    clearLastCreateState,
  }
})
