// @file Surface permissions store
import { reload } from '@@/bits/location'
import { getVuexStore } from '@@/bits/pinia'
import { isRegistered } from '@@/bits/user_model'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfaceSharePanelStore } from '@@/pinia/surface_share_panel'
import { useSurfaceWishArrangementStore } from '@@/pinia/surface_wish_arrangement'
import type { UserId, WallId } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import { GroupByTypes } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useSurfacePermissionsStore = defineStore('surfacePermissions', () => {
  const surfaceVuexStore = getVuexStore<RootState>() // For gradual conversion to pinia

  const surfaceStore = useSurfaceStore()
  const surfaceWishArrangementStore = useSurfaceWishArrangementStore()
  const surfaceSharePanelStore = useSurfaceSharePanelStore()
  const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

  const amIOwner = computed<boolean>(() => surfaceVuexStore?.getters.amIOwner)
  const amICollaborator = computed<boolean>(() => surfaceVuexStore?.getters.amICollaborator)
  const amIRegistered = computed<boolean>(() => isRegistered(surfaceVuexStore?.state.user))
  const canIAdminister = computed<boolean>(() => surfaceVuexStore?.getters.canIAdminister)
  const canIRemake = computed<boolean>(() => surfaceVuexStore?.getters.canIRemake)
  const canIEdit = computed<boolean>(() => surfaceVuexStore?.getters.canIEdit)
  const canIComment = computed<boolean>(() => surfaceVuexStore?.getters.canIComment)
  const canIReact = computed<boolean>(() => surfaceVuexStore?.getters.canIReact)
  const canIWrite = computed<boolean>(() => surfaceVuexStore?.getters.canIWrite)
  const canIModerate = computed<boolean>(() => surfaceVuexStore?.getters.canIModerate)
  const canIPost = computed<boolean>(() => surfaceVuexStore?.getters.canIPost)
  const canIPostWithoutWaitingForApproval = computed<boolean>(
    () => surfaceVuexStore?.getters.canIPostWithoutWaitingForApproval,
  )
  const canIDragSections = computed(
    () =>
      canIModerate.value &&
      surfaceWishArrangementStore.wishGroupBy === GroupByTypes.Section &&
      !surfaceStore.isFrozen &&
      !surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop,
  )
  const canIBookmarkWall = computed<boolean>(() => surfaceVuexStore?.getters.canIBookmarkWall)
  const canIAccessContentDirectly = computed<boolean>(() => surfaceVuexStore?.getters.canIAccessContentDirectly)
  const requireApprovalForPosts = computed<boolean>(() => surfaceVuexStore?.getters.requireApproval)
  const visitorsCanModerate = computed<boolean>(() => surfaceSharePanelStore.visitorsCanModerate)

  function handleCollaboratorChange({
    user_id: collaboratorId,
    wall_id: wallId,
  }: {
    user_id?: UserId
    wall_id?: WallId
  }): void {
    // Event came from wall channel, payload has user_id
    if (collaboratorId != null && collaboratorId === surfaceVuexStore?.state.user.id) {
      reload()
    }
    // Event came from user channel, payload has wall_id
    if (wallId != null && wallId === surfaceVuexStore?.state.wall.id) {
      reload()
    }
  }

  function handleAccessSettingsChange(): void {
    reload()
  }
  return {
    // getters
    amIOwner,
    amICollaborator,
    amIRegistered,
    canIAdminister,
    canIModerate,
    canIRemake,
    canIPost,
    canIPostWithoutWaitingForApproval,
    canIComment,
    canIReact,
    canIWrite,
    canIEdit,
    canIDragSections,
    canIBookmarkWall,
    canIAccessContentDirectly,
    requireApprovalForPosts,
    visitorsCanModerate,

    // Actions
    handleCollaboratorChange,
    handleAccessSettingsChange,
  }
})
