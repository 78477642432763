// @file composer_modal_alert pinia store
import window from '@@/bits/global'
import type { ComposerModalAlertData } from '@@/vuexstore/modules/composer_modal_alert'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useComposerModalAlertStore = defineStore('composerModalAlert', () => {
  // For gradual conversion to pinia
  const surfaceVuexStore = window.app?.$store

  const isComposerAlertOpen = computed<boolean>(() => surfaceVuexStore?.getters['composerModalAlert/isOpen'])
  const title = computed<string>(() => surfaceVuexStore?.state.composerModalAlert.title ?? '')
  const body = computed<string>(() => surfaceVuexStore?.state.composerModalAlert.body ?? '')
  const iconSrc = computed<string | null>(() => surfaceVuexStore?.state.composerModalAlert.iconSrc ?? null)
  const primaryActionText = computed<string>(() => surfaceVuexStore?.state.composerModalAlert.primaryActionText ?? '')
  const secondaryActionText = computed<string>(
    () => surfaceVuexStore?.state.composerModalAlert.secondaryActionText ?? '',
  )
  const isPrimaryActionDanger = computed<boolean>(
    () => surfaceVuexStore?.state.composerModalAlert.isPrimaryActionDanger ?? false,
  )

  function openComposerAlert(payload: ComposerModalAlertData): void {
    void surfaceVuexStore?.dispatch('composerModalAlert/openAlert', payload, { root: true })
  }

  function resetComposerAlert(): void {
    void surfaceVuexStore?.dispatch('composerModalAlert/resetAlert', null, { root: true })
  }

  function executePrimaryActions(): void {
    void surfaceVuexStore?.dispatch('composerModalAlert/executePrimaryActions', null, { root: true })
  }

  function executeSecondaryActions(): void {
    void surfaceVuexStore?.dispatch('composerModalAlert/executeSecondaryActions', null, { root: true })
  }

  return {
    isComposerAlertOpen,
    title,
    body,
    iconSrc,
    primaryActionText,
    secondaryActionText,
    isPrimaryActionDanger,
    openComposerAlert,
    resetComposerAlert,
    executePrimaryActions,
    executeSecondaryActions,
  }
})
