// @file utilities helping with calculating/displaying timestamps post timestamps

import type { Post } from '@@/types'

/**
 * Determines the timestamp to display to indicate when a post was last updated
 */
export function getPostUpdatedAt(post: Post): string | Date {
  const postUpdatedAt = post.content_updated_at ?? post.published_at ?? post.created_at
  if (postUpdatedAt == null) {
    throw new Error("Couldn't provide updated at timestamp for post as required fields are missing.")
  }
  return postUpdatedAt
}

export function getPostPublishedAt(post: Post): string | Date {
  const publishedAt = post.published_at ?? post.created_at
  if (publishedAt == null) {
    throw new Error("Couldn't provide published at timestamp for post as required fields are missing.")
  }
  return publishedAt
}
