/**
 * @file Clipboard related utilites for browser
 */

import { captureException, captureMessage } from '@@/bits/error_tracker'
import { isUrl } from '@@/bits/url'
import type { PostAttributes } from '@@/types'
import { loadPostContentHashString } from '@@/vuexstore/helpers/post'

enum PasteType {
  Link = 'Link',
  PostContentHash = 'PostContentHash',
  Text = 'Text',
  File = 'File',
}

interface PostAttributesWithPasteEvent {
  pasteType: PasteType
  postAttributes: PostAttributes
}

const supportWriteToClipboardAPI = !!navigator?.clipboard?.writeText
const isCopyCommandSupported = !!document.queryCommandSupported && document.queryCommandSupported('copy')
const canCopyToClipboard = supportWriteToClipboardAPI || isCopyCommandSupported

async function copyToClipboard(textToCopy: string): Promise<boolean> {
  if (supportWriteToClipboardAPI) {
    try {
      await navigator.clipboard.writeText(textToCopy)
      return true
    } catch (e) {
      captureException(e)
      return false
    }
  }
  // 1. Create a text area
  const textArea = document.createElement('textarea')
  textArea.id = 'ww-copy-textarea'
  textArea.value = textToCopy
  textArea.style.cssText = `
    position: absolute;
    top: -1000px;
    left: -1000px;
  `
  document.body.appendChild(textArea)
  textArea.select()
  const copyResult = document.execCommand('copy')
  // clean up
  document.body.removeChild(textArea)
  return copyResult
}

const supportReadFromClipboardAPI = !!navigator?.clipboard?.readText

async function getTextFromClipboard(): Promise<string> {
  if (!supportReadFromClipboardAPI) {
    captureMessage('Should only use getTextFromClipboard after checking with supportReadFromClipboardAPI')
    return await Promise.resolve('')
  }

  return await navigator.clipboard.readText()
}

function extractPostAttributesFromPastedText(text: string): PostAttributesWithPasteEvent {
  if (isUrl(text)) {
    return {
      pasteType: PasteType.Link,
      postAttributes: {
        attachment: text,
        wish_content: {
          attachment_props: {
            url: text,
          },
          is_processed: false,
        },
      },
    }
  }

  const postAttributesFromHashString = loadPostContentHashString(text)
  if (postAttributesFromHashString != null) {
    return {
      pasteType: PasteType.PostContentHash,
      postAttributes: { ...postAttributesFromHashString },
    }
  }

  return {
    pasteType: PasteType.Text,
    postAttributes: { body: text },
  }
}

function extractPostAttributesFromPastedFile(file: File): PostAttributesWithPasteEvent {
  return {
    pasteType: PasteType.File,
    postAttributes: { file },
  }
}

function extractPostAttributesArrayFromClipboardData(
  clipboardData: DataTransfer | null,
): PostAttributesWithPasteEvent[] {
  if (clipboardData == null) return []

  const attributes: PostAttributesWithPasteEvent[] = []

  // for of throws errors, hence the old-school loop
  // clipboardData.items is an object that the keys are number
  for (let i = 0; i < clipboardData.items.length; i++) {
    const clipboardItem: DataTransferItem = clipboardData.items[i]
    const itemType = clipboardItem.type

    if (itemType.includes('image')) {
      const file = clipboardItem.getAsFile()
      if (file == null) continue
      attributes.push(extractPostAttributesFromPastedFile(file))
    } else if (itemType === 'text/plain') {
      const text = clipboardData.getData('text')
      // return if text is null or is a whitespace character (spaces, tabs, and line breaks)
      if (!text || !/\S/.test(text)) continue
      attributes.push(extractPostAttributesFromPastedText(text))
    }
  }

  return attributes
}

function removeAttachmentFromPostAttributes(
  attributes: PostAttributesWithPasteEvent[],
): PostAttributesWithPasteEvent[] {
  return attributes
    .filter((item) => item.pasteType !== PasteType.File)
    .map((item) => {
      item.postAttributes.attachment = undefined
      item.postAttributes.wish_content = undefined
      return item
    })
}

function findLatestPastedPostAttributes(
  postAttributesArray: PostAttributesWithPasteEvent[],
  type: PasteType,
): PostAttributesWithPasteEvent | undefined {
  return postAttributesArray.reverse().find((item: PostAttributesWithPasteEvent) => item.pasteType === type)
}

function extractPostAttributesFromClipboardData(
  postAttributesArray: PostAttributesWithPasteEvent[],
  type: PasteType,
): PostAttributesWithPasteEvent | null | undefined {
  if (!postAttributesArray || postAttributesArray.length === 0 || !type) return null

  return findLatestPastedPostAttributes(postAttributesArray, PasteType[type])
}

export {
  PasteType,
  canCopyToClipboard,
  copyToClipboard,
  extractPostAttributesArrayFromClipboardData,
  extractPostAttributesFromClipboardData,
  getTextFromClipboard,
  removeAttachmentFromPostAttributes,
  supportReadFromClipboardAPI,
}
