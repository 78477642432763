/**
 * @file Handles magic padlet logic.
 * */
import { __ } from '@@/bits/intl'
import type { MagicTemplateKey } from '@@/pinia/magic_padlet_panel_store'
import type { MagicTemplateViewConfig } from '@@/types'

const EXAMPLE_PROMPTS = [
  __('8 fun ideas to celebrate Earth Day with my class of 5th graders.'),
  __(
    'a list of 10 popular travel destinations grouped according to the type of travel. Include an interesting historical fact about each location.',
  ),
  __('a typical day in the life of a monk living in a Zen Buddhist monastery in Japan.'),
  __('a chronological list of the 8 most important points in the evolution of humans, written for 12th graders.'),
  __('a map of the 12 most loved national parks in the world'),
  __('12 fictional books that I could study with my 11th grade students. Include the themes of each book.'),
  __(
    'six statements about the book Nineteen Eighty-Four by George Orwell that 8th grade students could write a medium length essay about.',
  ),
  __(
    'an empty template for first-year biology students to summarize concepts learned in lab, ask questions, and share resources.',
  ),
  __('a ranked list of the top 10 most impressive inventions and their history, written for 12th graders.'),
  __('eight fun lesson ideas to teach my 2nd grade class about dinosaurs.'),
  __(
    'a chronological list of the most important events in the Three Kingdoms period of China, with the year included in the post title. Include at least 9 posts.',
  ),
  __(
    'seven fun science experiment ideas I can try with my class of 5th graders. Include what can be learnt from each.',
  ),
  __('15 books that I can recommend to my 10th grade students to read over the summer'),
  __(
    'eight ideas for a craft activity that I can do with my class of 1st graders to create something for mothers day.',
  ),
  __('four columns for students to vote on how they found the class. Each column will represent their mood'),
]

let currentPromptIndex = 0

const getPrompt = (): string => {
  // Update the index to point to the next prompt
  currentPromptIndex = (currentPromptIndex + 1) % EXAMPLE_PROMPTS.length
  const prompt = EXAMPLE_PROMPTS[currentPromptIndex]
  return prompt
}

const getRandomPlaceholder = (): string => {
  const randomIndex = Math.floor(Math.random() * EXAMPLE_PROMPTS.length)
  currentPromptIndex = randomIndex
  return EXAMPLE_PROMPTS[randomIndex]
}

/**
 * Magic template configurations.
 * These configurations are used to render the template on the panel
 * NOTE:
 * - The keys of this object should match the keys in the MagicTemplateKey enum
 * - The refKey in the inputConfig should match the key in the MagicTemplateKey enum and match the parameters in the wall creators on rails.
 */
const MAGIC_TEMPLATES: Record<MagicTemplateKey, MagicTemplateViewConfig> = {
  custom_board: {
    description: __('Generate a board for your class with the help of AI.'),
    inputConfig: [
      {
        type: 'text',
        label: __('I am a...'),
        placeholder: __('teacher'),
        defaultValue: __('teacher'),
        refKey: 'role',
      },
      {
        type: 'multiline-text',
        label: __('I want to create...'),
        placeholder: getRandomPlaceholder(),
        refKey: 'userPrompt',
        hasExampleButton: true,
        exampleFunction: getPrompt,
      },
    ],
  },
  lesson_plan: {
    description: __(
      'Create a detailed lesson plan that includes learning objectives, materials and resources, instruction, assessment, closure, and homework.',
    ),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('World history'),
        refKey: 'subject',
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
      {
        type: 'text',
        label: __('Topic or lesson objectives'),
        placeholder: __('World War I'),
        refKey: 'lessonObjectives',
      },
      {
        type: 'text',
        label: __('Standards to align to'),
        placeholder: __('Common Core, TEKS, etc.'),
        optional: true,
        refKey: 'standards',
      },
      {
        type: 'multiline-text',
        label: __('Additional details'),
        placeholder: __(
          'Class duration, teaching resources, accommodations, methodology, or anything else you’d like to be included.',
        ),
        optional: true,
        refKey: 'additionalDetails',
      },
    ],
  },
  map_of_historical_events: {
    description: __('Create an interactive map showcasing historical events from anywhere in the world.'),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('U.S. History'),
        refKey: 'subject',
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
      {
        type: 'text',
        label: __('Topic or lesson objectives'),
        placeholder: __('Civil rights movement'),
        refKey: 'lessonObjectives',
      },
      {
        type: 'text',
        label: __('Time period'),
        placeholder: __('1940s to 1960s'),
        optional: true,
        refKey: 'timePeriod',
      },
      {
        type: 'text',
        label: __('Geographical focus'),
        placeholder: __('Southern states'),
        optional: true,
        refKey: 'geographicalFocus',
      },
      {
        type: 'multiline-text',
        label: __('Additional details'),
        placeholder: __(
          'Standards to align to, class duration, teaching resources, accommodations, methodology, or anything else you’d like to be included.',
        ),
        optional: true,
        refKey: 'additionalDetails',
      },
    ],
  },
  reading_list: {
    description: __(
      'Create a custom list of reading materials for your class. Provide specific details for a more personalized list.',
    ),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('English'),
        refKey: 'subject',
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
      {
        type: 'text',
        label: __('Topic or theme'),
        placeholder: __('Censorship'),
        refKey: 'topic',
      },
      {
        type: 'difficulty',
        label: __('Reading level'),
        refKey: 'readingLevel',
      },
      {
        type: 'multiline-text',
        label: __('Additional details'),
        placeholder: __('Standards to align to, genre or interest preferences, number of items to include.'),
        optional: true,
        refKey: 'additionalDetails',
      },
    ],
  },
  class_activities: {
    description: __(
      'Add information about your class to generate ideas for activities you can do with Padlet. Select an idea to get started.',
    ),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('World history'),
        refKey: 'subject',
      },
      {
        type: 'text',
        label: __('Topic'),
        placeholder: __('The first moon landing'),
        refKey: 'topic',
        optional: true,
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
    ],
  },
  classroom_activity_ideas: {
    description: __(
      'Create a list of activity ideas for the topic you’re teaching. Provide specific details to get suggestions tailored for your classroom.',
    ),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('Biology'),
        refKey: 'subject',
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
      {
        type: 'text',
        label: __('Topic or lesson objectives'),
        placeholder: __('Lifecycle of a cell'),
        refKey: 'lessonObjectives',
      },
      {
        type: 'text',
        label: __('Classroom resources'),
        placeholder: __('Computers, lab equipment, art supplies...'),
        optional: true,
        refKey: 'classroomResources',
      },
      {
        type: 'multiline-text',
        label: __('Additional details'),
        placeholder: __('Activity types, classroom size, activity duration, teaching and assessment preferences'),
        optional: true,
        refKey: 'additionalDetails',
      },
    ],
  },
  historical_timeline: {
    description: __('Create an interactive timeline to showcase historical events, time periods, or processes.'),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('World history'),
        refKey: 'subject',
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
      {
        type: 'text',
        label: __('Topic or lesson objectives'),
        placeholder: __('Fall of the Roman empire'),
        refKey: 'lessonObjectives',
      },
      {
        type: 'text',
        label: __('Time period'),
        placeholder: __('4th century A.D.'),
        optional: true,
        refKey: 'timePeriod',
      },
      {
        type: 'multiline-text',
        label: __('Additional details'),
        placeholder: __('Standards to align to, key events or milestones to include, level of detail.'),
        optional: true,
        refKey: 'additionalDetails',
      },
    ],
  },
  learning_assessment_polls: {
    description: __(
      'Create a set of interactive polls that allow you to assess your students’ understanding of a specific topic.',
    ),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('Algebra 1'),
        refKey: 'subject',
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
      {
        type: 'text',
        label: __('Topic or lesson objectives'),
        placeholder: __('Variables'),
        refKey: 'lessonObjectives',
      },
      {
        type: 'difficulty',
        label: __('Difficulty level'),
        refKey: 'difficulty',
      },
      {
        type: 'multiline-text',
        label: __('Additional details'),
        placeholder: __('Standards to align to, time limit, topic focus, or anything else you’d like to be included.'),
        optional: true,
        refKey: 'additionalDetails',
      },
    ],
  },
  rubric: {
    description: __('Create a custom rubric for evaluating a project or assessment in your class.'),
    inputConfig: [
      {
        type: 'text',
        label: __('Subject'),
        placeholder: __('English'),
        refKey: 'subject',
      },
      {
        type: 'grade',
        label: __('Grade'),
        refKey: 'grades',
      },
      {
        type: 'text',
        label: __('Assignment title'),
        placeholder: __('Community service graduation requirement essay'),
        refKey: 'assignmentTitle',
      },
      {
        type: 'multiline-text',
        label: __('Assignment description'),
        placeholder: __(
          'Students will write a persuasive essay taking one side of the debate of whether or not community service should be a graduation requirement.',
        ),
        optional: true,
        refKey: 'assignmentDescription',
      },
      {
        type: 'number-range',
        label: __('Rating scale'),
        low: 3,
        high: 6,
        optional: true,
        refKey: 'ratingScale',
      },
      {
        type: 'multiline-text',
        label: __('Additional details'),
        placeholder: __(
          'Specific descriptors or criterion to include, level of detail for the criteria, or standards to align to.',
        ),
        optional: true,
        refKey: 'additionalDetails',
      },
    ],
  },
}

const FIXED_ROLE = 'teacher'

const GRADES = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'Higher Ed.']

const DIFFICULTY_LEVELS = ['Basic', 'Intermediate', 'Advanced']

const CACHED_TEMPLATE_TYPES = ['subject', 'grades', 'role']

const isHigherEd = (grade: string): boolean => grade === 'Higher Ed.'

const getRoleAndRandomPrompt = (): { role: string; prompt: string; index: number } => {
  const randomIndex = Math.floor(Math.random() * EXAMPLE_PROMPTS.length)
  const randomPrompt = EXAMPLE_PROMPTS[randomIndex]

  return {
    role: FIXED_ROLE,
    prompt: randomPrompt,
    index: randomIndex,
  }
}

const getNextPrompt = (index: number): { prompt: string; index: number } => {
  const nextIndex = index === EXAMPLE_PROMPTS.length - 1 ? 0 : index + 1
  return {
    prompt: EXAMPLE_PROMPTS[nextIndex],
    index: nextIndex,
  }
}
// Function to create a string array from low to high inclusive
function rangeToStringArray(low: number, high: number): string[] {
  const array: string[] = []
  for (let i = low; i <= high; i++) {
    array.push(i.toString())
  }
  return array
}

function isExamplePrompt(prompt: string): boolean {
  return EXAMPLE_PROMPTS.includes(prompt)
}

function isStringNumeric(str: string): boolean {
  if (typeof str !== 'string') return false // we only process strings!
  return !Number.isNaN(parseFloat(str)) && Number.isFinite(parseFloat(str))
}

export {
  CACHED_TEMPLATE_TYPES,
  DIFFICULTY_LEVELS,
  getNextPrompt,
  getRoleAndRandomPrompt,
  GRADES,
  isExamplePrompt,
  isHigherEd,
  isStringNumeric,
  MAGIC_TEMPLATES,
  rangeToStringArray,
}
