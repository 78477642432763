/**
 * @file Vuexstore to manage the alert shown inside the active draft post composer modal
 * The idea behind this is similar to the confirmation_dialog module but we cannot just reuse it since both of the dialogs may appear together
 */

import AlertSign from '@@/images/alert_sign.svg'
import type { DispatchableAction } from '@@/types'
import type { Module } from 'vuex'

interface ComposerModalAlertData {
  title: string
  body: string
  iconSrc?: string | null
  primaryActionText?: string
  secondaryActionText?: string
  primaryActions?: Array<DispatchableAction | (() => void)>
  secondaryActions?: DispatchableAction[]
  isPrimaryActionDanger?: boolean
}

interface ComposerModalAlertState extends ComposerModalAlertData {
  isOpen: boolean
}

const initialState = (): ComposerModalAlertState => ({
  isOpen: false,
  title: '',
  body: '',
  iconSrc: AlertSign,
  primaryActionText: '',
  secondaryActionText: '',
  primaryActions: [],
  secondaryActions: [],
  isPrimaryActionDanger: false,
})

const ComposerModalAlertModule: Module<ComposerModalAlertState, object> = {
  namespaced: true,
  state: initialState,
  getters: {
    isOpen: (state): boolean => state.isOpen,
  },
  mutations: {
    RESET_ALERT(state): void {
      Object.assign(state, initialState())
    },
    OPEN_ALERT(state, alertData: ComposerModalAlertData): void {
      Object.assign(state, alertData)
      state.isOpen = true
    },
  },
  actions: {
    openAlert({ commit }, params: ComposerModalAlertData): void {
      commit('OPEN_ALERT', params)
    },
    resetAlert({ commit }): void {
      commit('RESET_ALERT')
    },
    executePrimaryActions({ dispatch, state }): void {
      state.primaryActions?.forEach((action: DispatchableAction | (() => void)): void => {
        if (typeof action === 'function') {
          action()
        } else {
          void dispatch(action.type, action.payload, { root: true })
        }
      })
      void dispatch('resetAlert')
    },
    executeSecondaryActions({ dispatch, state }): void {
      state.secondaryActions?.forEach((actionHash): void => {
        dispatch(actionHash.type, actionHash.payload, { root: true })
      })
      dispatch('resetAlert')
    },
  },
}

export default ComposerModalAlertModule
export type { ComposerModalAlertData, ComposerModalAlertState }
