// @file Custom implementation of WebAPI EventSource to use custom headers for authentication

interface EventDetail {
  data?: string
  error?: Error
}
type EventCallback = (event: any) => void

export class EventSourceWithHeaders {
  private readonly url: string
  private readonly headers: Headers
  private eventListeners: { [key: string]: EventCallback[] }
  private streamReader: ReadableStreamDefaultReader<Uint8Array> | null
  private readonly decoder: TextDecoder
  private buffer: string

  constructor(url: string, headers: { [key: string]: string } = {}) {
    this.url = url
    this.headers = new Headers(headers)
    this.eventListeners = {}
    this.streamReader = null
    this.decoder = new TextDecoder()
    this.buffer = ''
  }

  async connect(): Promise<void> {
    try {
      const response = await fetch(this.url, {
        headers: this.headers,
      })

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`)
      }

      this.streamReader = response.body?.getReader() ?? null
      this.readStream()
    } catch (error) {
      this.dispatchEvent('error', { error })
    }
  }

  private readStream(): void {
    if (this.streamReader == null) return

    this.streamReader
      .read()
      .then(({ done, value }) => {
        if (done) {
          this.dispatchEvent('end')
          return
        }

        this.buffer += this.decoder.decode(value, { stream: true })

        let index: number
        while ((index = this.buffer.indexOf('\n')) >= 0) {
          const line = this.buffer.slice(0, index)
          this.buffer = this.buffer.slice(index + 1)

          if (line.startsWith('data: ')) {
            const data = line.slice(6)
            this.dispatchEvent('message', { data })
          }
        }

        this.readStream()
      })
      .catch((error) => {
        this.dispatchEvent('error', { error })
      })
  }

  private dispatchEvent(type: string, detail: EventDetail = {}): void {
    if (this.eventListeners[type] != null) {
      this.eventListeners[type].forEach((callback) => callback(detail))
    }
  }

  addEventListener(type: string, callback: EventCallback): void {
    if (this.eventListeners[type] == null) {
      this.eventListeners[type] = []
    }
    this.eventListeners[type].push(callback)
  }

  removeEventListener(type: string, callback: EventCallback): void {
    if (this.eventListeners[type] == null) return

    this.eventListeners[type] = this.eventListeners[type].filter((listener) => listener !== callback)
  }

  close(): void {
    if (this.streamReader != null) {
      void this.streamReader.cancel()
      this.streamReader = null
    }
  }
}
