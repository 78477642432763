// @file Surface post properties store
import {
  ALERT_ICON,
  CONFIRM_DELETE_CUSTOM_TEXT_FIELD,
  CONFIRM_DISCARD_SETTING_CHANGES,
} from '@@/bits/confirmation_dialog'
import type { ContentPickerSource } from '@@/bits/content_picker'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import {
  ATTACHMENT_TEXT_CHAR_LIMIT,
  BODY_PLACEHOLDER_CHAR_LIMIT,
  hasCustomProperties,
  isWallCustomPostPropertyOfType,
  SUBJECT_PLACEHOLDER_CHAR_LIMIT,
  VideoLengthLimits,
  willDeletePostAfterRemovingCustomProperty,
} from '@@/bits/post_properties'
import { tailwindFullConfig } from '@@/bits/tailwind'
import { useContentPickerStore } from '@@/pinia/content_picker'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import PadletApi from '@@/surface/padlet_api'
import type {
  Post,
  PostColor,
  VideoLengthLimit,
  WallCustomPostLinkButtonProperty,
  WallCustomPostProperty,
  WallCustomPostSingleSelectProperty,
  WallPostProperties,
} from '@@/types'
import { SettingsSubpanel } from '@@/vuexstore/modules/surface_settings'
import { isEqual } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'

export const MIN_SINGLE_SELECT_OPTIONS = 1
export const MAX_SINGLE_SELECT_OPTIONS = 100
export const MAX_SINGLE_SELECT_OPTION_TEXT_LENGTH = 100

export enum PostPropertiesSubpanel {
  SubjectField = 'subjectField',
  AttachmentField = 'attachmentField',
  BodyField = 'bodyField',
  CustomPostProperties = 'customPostProperties',
}

export const useSurfacePostPropertiesStore = defineStore('surfacePostProperties', () => {
  const surfaceStore = useSurfaceStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceSettingsStore = useSurfaceSettingsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()
  const contentPickerStore = useContentPickerStore()

  /** Panel visibility */
  const openedDirectly = ref(false) // false if opened from settings panel, true if opened directly skipping the settings panel

  /** anywhere -> post properties panel */
  function showPostPropertiesPanelDirectly({
    postPropertiesSubpanel,
    aboveContentPicker,
    showVideoRecorderSettingsSubpanel,
  }: {
    postPropertiesSubpanel?: PostPropertiesSubpanel
    aboveContentPicker?: boolean
    showVideoRecorderSettingsSubpanel?: boolean
  } = {}): void {
    surfaceSettingsStore.showSettingsPanel(SettingsSubpanel.PostProperties)
    if (postPropertiesSubpanel != null) {
      activePostPropertiesSubpanel.value = postPropertiesSubpanel
    }
    if (aboveContentPicker === true) {
      // show the settings/post properties panel above the content picker
      // set content picker's z-index to 1 less than the settings panel
      // note: this only works if we always render content pickers as siblings of the settings panel
      // ie as direct children of `#surface-panels`: <Portal selector="#surface-panels"><ContentPicker/></Portal>
      contentPickerStore.setZIndexOverride(tailwindFullConfig.theme.zIndex.sidepanel - 1)
    }
    if (showVideoRecorderSettingsSubpanel === true) {
      videoRecorderSettingsSubpanelOpenedGlobally.value = true
    }
    openedDirectly.value = true
  }

  /** close post properties main panel -> back to settings main panel OR close entire settings panel */
  function closePostPropertiesPanel(): void {
    if (openedDirectly.value) {
      // Reverse of logic in showPostPropertiesPanelDirectly
      openedDirectly.value = false

      activePostPropertiesSubpanel.value = undefined
      contentPickerStore.setZIndexOverride(null)
      videoRecorderSettingsSubpanelOpenedGlobally.value = false

      surfaceSettingsStore.hideSettingsPanel()
    } else {
      surfaceSettingsStore.goBackToMainPanel()
    }
  }

  // #region Subpanel operations
  const activePostPropertiesSubpanel = ref<PostPropertiesSubpanel>()
  const videoRecorderSettingsSubpanelOpenedGlobally = ref(false)

  function goBackToPostPropertiesPanel(): void {
    activePostPropertiesSubpanel.value = undefined
  }

  /** Close subpanel -> back to post properties panel OR close entire settings panel */
  function closePostPropertiesSubpanel(): void {
    goBackToPostPropertiesPanel()
    if (openedDirectly.value) {
      closePostPropertiesPanel()
    }
  }

  async function saveSettingsAndClosePostPropertiesSubpanel(): Promise<void> {
    return await saveSettings({ onSuccess: () => closePostPropertiesSubpanel() })
  }

  async function discardChangesAndClosePostPropertiesSubpanel(): Promise<void> {
    if (isDirty.value) {
      void useGlobalConfirmationDialogStore().openConfirmationDialog({
        ...CONFIRM_DISCARD_SETTING_CHANGES,
        ...ALERT_ICON,
        afterDiscardActions: [
          () => (previewWallPostProperties.value = wallPostProperties.value),
          () => closePostPropertiesSubpanel(),
        ],
        afterConfirmActions: [async () => await saveSettingsAndClosePostPropertiesSubpanel()],
        buttonScheme: arePostPropertiesInvalid.value
          ? OzConfirmationDialogBoxButtonScheme.DiscardCancel
          : OzConfirmationDialogBoxButtonScheme.SaveDiscardCancel,
        forceFullWidthButtons: true,
        xShadow: true,
      })
    } else {
      previewWallPostProperties.value = wallPostProperties.value
      closePostPropertiesSubpanel()
    }
  }

  /** post properties panel -> custom post properties sub panel */
  function openCustomPostPropertiesPanel(selectedCustomPostProperty?: WallCustomPostProperty): void {
    if (selectedCustomPostProperty != null) {
      activeCustomPostProperty.value = selectedCustomPostProperty
    }
    activePostPropertiesSubpanel.value = PostPropertiesSubpanel.CustomPostProperties
  }

  /** custom post properties sub panel -> post properties panel */
  function closeCustomPostPropertiesPanel(): void {
    activeCustomPostProperty.value = null
    goBackToPostPropertiesPanel()
  }

  // #endregion

  async function saveSettings(options?: { onSuccess?: () => void }): Promise<void> {
    try {
      if (!isDirty.value) return
      const postProperties = await PadletApi.WallPostProperties.updateWallPostProperties(
        surfaceStore.wallId,
        previewWallPostProperties.value,
      )
      setWallPostProperties(postProperties)
      options?.onSuccess?.()
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'savePostPropertiesSettings' })
    }
  }

  async function saveSettingsAndGoBackToMainPanel(): Promise<void> {
    return await saveSettings({ onSuccess: () => surfaceSettingsStore.goBackToMainPanel() })
  }

  function discardChangesAndGoBackToMainPanel(): void {
    if (isDirty.value) {
      void useGlobalConfirmationDialogStore().openConfirmationDialog({
        ...CONFIRM_DISCARD_SETTING_CHANGES,
        ...ALERT_ICON,
        afterDiscardActions: [
          () => (previewWallPostProperties.value = wallPostProperties.value),
          () => closeCustomPostPropertiesPanel(),
        ],
        afterConfirmActions: [async () => await saveSettingsAndGoBackToMainPanel()],
        buttonScheme: OzConfirmationDialogBoxButtonScheme.SaveDiscardCancel,
        forceFullWidthButtons: true,
        xShadow: true,
      })
    } else {
      previewWallPostProperties.value = wallPostProperties.value
      surfaceSettingsStore.goBackToMainPanel()
    }
  }

  async function saveSettingsAndCloseSettingsPanel(): Promise<void> {
    return await saveSettings({ onSuccess: () => surfaceSettingsStore.hideSettingsPanel() })
  }

  function discardChangesAndCloseSettingsPanel(): void {
    if (isDirty.value) {
      void useGlobalConfirmationDialogStore().openConfirmationDialog({
        ...CONFIRM_DISCARD_SETTING_CHANGES,
        ...ALERT_ICON,
        afterDiscardActions: [
          () => (previewWallPostProperties.value = wallPostProperties.value),
          () => surfaceSettingsStore.hideSettingsPanel(),
        ],
        afterConfirmActions: [async () => await saveSettingsAndCloseSettingsPanel()],
        buttonScheme: OzConfirmationDialogBoxButtonScheme.SaveDiscardCancel,
        forceFullWidthButtons: true,
        xShadow: true,
      })
    } else {
      previewWallPostProperties.value = wallPostProperties.value
      surfaceSettingsStore.hideSettingsPanel()
    }
  }

  // #region Predefined post properties
  const wallPostProperties = ref<WallPostProperties>({
    subject_placeholder: null,
    body_placeholder: null,
    subject_enabled: true,
    attachment_settings: true,
    attachment_text: null,
    body_enabled: true,
    color_enabled: true,
    location_enabled: false,
    ar_lenses_enabled: true,
    required_fields: {
      subject: false,
      attachment: false,
      body: false,
    },
    video_length_limit: null,
  })
  const previewWallPostProperties = ref<WallPostProperties>(wallPostProperties.value)

  const subjectPlaceholder = computed(() => previewWallPostProperties.value.subject_placeholder ?? '')
  const bodyPlaceholder = computed(() => previewWallPostProperties.value.body_placeholder ?? '')
  const isSubjectEnabled = computed(() => previewWallPostProperties.value.subject_enabled)
  const attachmentSettings = computed(() => previewWallPostProperties.value.attachment_settings)
  const attachmentText = computed(() => previewWallPostProperties.value.attachment_text ?? '')
  const isBodyEnabled = computed(() => previewWallPostProperties.value.body_enabled)
  const isColorEnabled = computed(() => previewWallPostProperties.value.color_enabled)
  const isLocationEnabled = computed(() => previewWallPostProperties.value.location_enabled)
  const isArLensesEnabled = computed(() => previewWallPostProperties.value.ar_lenses_enabled)
  const requiredPostProperties = computed(() => previewWallPostProperties.value.required_fields)

  const isDirty = computed(() => {
    return !isEqual(previewWallPostProperties, wallPostProperties)
  })
  const arePostPropertiesInvalid = computed(
    () =>
      subjectPlaceholder.value.length > SUBJECT_PLACEHOLDER_CHAR_LIMIT ||
      bodyPlaceholder.value.length > BODY_PLACEHOLDER_CHAR_LIMIT ||
      attachmentText.value.length > ATTACHMENT_TEXT_CHAR_LIMIT ||
      (!isSubjectEnabled.value && attachmentSettings.value === false && !isBodyEnabled.value),
  )

  const shouldHandleDragAndDropToPost = computed(() => attachmentSettings.value !== false)
  const shouldHandlePasteToAddAttachment = computed(() => attachmentSettings.value !== false)

  function showSnackbarForDemoPadletOnboarding(): void {
    globalSnackbarStore.setSnackbar({
      message: __('Cannot edit post properties during the onboarding'),
      notificationType: SnackbarNotificationType.error,
      timeout: 5000,
    })
  }

  function updatePreviewWallPostProperty(
    property: keyof WallPostProperties,
    value: WallPostProperties[keyof WallPostProperties],
  ): void {
    if (surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) {
      showSnackbarForDemoPadletOnboarding()
      return
    }
    // Only use null to indicate that the placeholder is not set.
    const normalizedValue = typeof value === 'string' && value === '' ? null : value
    previewWallPostProperties.value = {
      ...previewWallPostProperties.value,
      [property]: normalizedValue,
    }
  }

  function setWallPostProperties(properties: Partial<WallPostProperties>): void {
    wallPostProperties.value = {
      ...wallPostProperties.value,
      ...properties,
      attachment_settings: properties.attachment_settings ?? wallPostProperties.value.attachment_settings,
    }
    previewWallPostProperties.value = wallPostProperties.value
  }

  function getDisplayedColorForPost(post: Post): PostColor {
    return isColorEnabled.value ? post.color : null
  }

  function isAttachmentTypeEnabled(source: ContentPickerSource): boolean {
    return attachmentSettings.value === true || attachmentSettings.value[source] === true
  }
  // #endregion

  // #region video length limits
  const wallVideoLengthLimit = computed(() => surfaceStore.wallAttributes.video_length_limit as number) // current wall's max limit based on plan
  const videoLengthLimit = computed(() => previewWallPostProperties.value.video_length_limit) // current wall's limit set by user

  const maxPersonalWallVideoLengthLimit = 15 * 60 // see Quotas.rb
  const maxLibraryWallVideoLengthLimit = 30 * 60 // see LibraryQuotas.rb

  const maxPossibleVideoLengthLimit = computed(() => {
    if (surfaceStore.isLibraryWall) {
      return maxLibraryWallVideoLengthLimit
    }
    return maxPersonalWallVideoLengthLimit
  })

  const currentVideoLengthLimit = computed(() => {
    if (
      // No time limit set => return wall's max time limit.
      videoLengthLimit.value == null ||
      // Time limit set is higher than wall's max time limit => return wall's max time limit.
      // This can happen if the builder downgraded their plan.
      videoLengthLimit.value > wallVideoLengthLimit.value
    ) {
      return VideoLengthLimits.find((lengthLimit) => lengthLimit.value === wallVideoLengthLimit.value)
    }
    return VideoLengthLimits.find((lengthLimit) => lengthLimit.value === videoLengthLimit.value)
  })

  const updateVideoLengthLimit = (limit: VideoLengthLimit): void =>
    updatePreviewWallPostProperty('video_length_limit', limit)
  // #endregion

  // #region Custom post properties
  const wallCustomPostProperties = computed(() =>
    wallCustomPostPropertiesRaw.value.sort((propA, propB) => {
      // link buttons should always be last
      if (isWallCustomPostPropertyOfType(propA, 'link_button')) return 1
      if (isWallCustomPostPropertyOfType(propB, 'link_button')) return -1

      return propA.sort_index - propB.sort_index
    }),
  )
  const wallCustomPostPropertiesById = computed<Record<string, WallCustomPostProperty>>(() => {
    const options = {}
    wallCustomPostPropertiesRaw.value.forEach((property) => {
      if (property.id == null) return
      options[property.id] = property
    })
    return options
  })
  const requiredWallCustomPostPropertiesById = computed<Record<string, WallCustomPostProperty>>(() => {
    const options = {}
    wallCustomPostPropertiesRaw.value.forEach((property) => {
      if (property.id == null || !property.required) return
      options[property.id] = property
    })
    return options
  })
  const wallSingleSelectPropsByCustomPropertyId = computed<Record<string, WallCustomPostSingleSelectProperty>>(() => {
    const options = {}
    Object.entries(wallCustomPostPropertiesById.value).forEach(([customPropId, customProp]) => {
      if (isWallCustomPostPropertyOfType(customProp, 'single_select')) {
        options[customPropId] = customProp
      }
    })

    return options
  })

  const wallSingleSelectDefaultsByCustomPropertyId = computed<Record<string, string | null>>(() => {
    const options = {}
    Object.entries(wallSingleSelectPropsByCustomPropertyId.value).forEach(([customPropId, customProp]) => {
      options[customPropId] = customProp.selection_options.find((option) => option.default)?.id ?? null
    })

    return options
  })

  const wallCustomPostPropertiesRaw = ref<WallCustomPostProperty[]>([])
  const activeCustomPostProperty = ref<WallCustomPostProperty | null>(null)
  const isCreatingCustomPostProperty = ref(false)
  const isUpdatingCustomPostProperty = ref(false)
  const isDeletingCustomPostProperty = ref(false)
  const customLinkButtonProperty = computed(
    () =>
      wallCustomPostPropertiesRaw.value.find((property) => property.data_type === 'link_button') as
        | WallCustomPostLinkButtonProperty
        | undefined,
  )

  function shouldShowPostProperties(post: Post): boolean {
    // has location or at least 1 custom property
    return post.location_name != null || hasCustomProperties(post)
  }

  async function createCustomPostProperty(customPostPropertyData: WallCustomPostProperty): Promise<void> {
    if (surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop) {
      showSnackbarForDemoPadletOnboarding()
      return
    }
    try {
      isCreatingCustomPostProperty.value = true
      const customPostProperty = await PadletApi.WallCustomPostProperties.createWallCustomPostProperty(
        surfaceStore.wallId,
        customPostPropertyData,
      )
      insertNewCustomPostProperty(customPostProperty)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'createCustomPostProperty' })
    } finally {
      isCreatingCustomPostProperty.value = false
    }
  }

  async function updateCustomPostProperty(customPostPropertyData: WallCustomPostProperty): Promise<void> {
    try {
      isUpdatingCustomPostProperty.value = true
      const customPostProperty = await PadletApi.WallCustomPostProperties.updateWallCustomPostProperty(
        surfaceStore.wallId,
        customPostPropertyData,
      )
      updateCustomPostPropertyInList(customPostProperty)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'updateCustomPostProperty' })
    } finally {
      isUpdatingCustomPostProperty.value = false
    }
  }

  async function deleteCustomPostProperty(propertyId: string): Promise<void> {
    try {
      isDeletingCustomPostProperty.value = true
      await PadletApi.WallCustomPostProperties.deleteWallCustomPostProperty(surfaceStore.wallId, propertyId)
      removeCustomPostPropertyFromList(propertyId)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'deleteCustomPostProperty' })
    } finally {
      isDeletingCustomPostProperty.value = false
    }
  }

  function removeCustomPostPropertyFromList(propertyId: string): void {
    const targetCustomPostPropIndex = wallCustomPostPropertiesRaw.value.findIndex(
      (customPostProperty) => customPostProperty.id === propertyId,
    )
    if (targetCustomPostPropIndex !== -1) {
      wallCustomPostPropertiesRaw.value.splice(targetCustomPostPropIndex, 1)
    }
  }

  function updateCustomPostPropertyInList(updatedCustomPostProperty: WallCustomPostProperty): void {
    const targetCustomPostPropIndex = wallCustomPostPropertiesRaw.value.findIndex(
      (customPostProperty) => customPostProperty.id === updatedCustomPostProperty.id,
    )
    if (targetCustomPostPropIndex !== -1) {
      wallCustomPostPropertiesRaw.value.splice(targetCustomPostPropIndex, 1, updatedCustomPostProperty)
    }
  }

  function setCustomPostProperties(customPostProperties: WallCustomPostProperty[]): void {
    wallCustomPostPropertiesRaw.value = customPostProperties
  }

  function insertNewCustomPostProperty(customPostProperty: WallCustomPostProperty): void {
    wallCustomPostPropertiesRaw.value.push(customPostProperty)
  }

  function showDeleteCustomPropertyWarning(): void {
    const propertyId = activeCustomPostProperty.value?.id
    if (propertyId == null) return

    let title: string
    let body: string
    if (willDeletePostAfterRemovingCustomProperty(propertyId, surfacePostsStore.allSortedPosts)) {
      title = __('Delete "%{customField}" field?', {
        customField: activeCustomPostProperty.value?.name,
      })
      body = __(
        'This field and its values will be deleted from every post. Posts with only this field will be deleted. Any values entered into "%{customField}" will not be recoverable. This action is permanent.',
        {
          customField: activeCustomPostProperty.value?.name,
        },
      )
    } else {
      title = __('Delete "%{customField}" field?', {
        customField: activeCustomPostProperty.value?.name,
      })
      body = __(
        'This field and its values will be deleted from every post. Any values entered into "%{customField}" will not be recoverable. This action is permanent.',
        {
          customField: activeCustomPostProperty.value?.name,
        },
      )
    }

    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...CONFIRM_DELETE_CUSTOM_TEXT_FIELD,
      title,
      body,
      afterConfirmActions: [
        async () => await deleteCustomPostProperty(propertyId),
        () => closeCustomPostPropertiesPanel(),
      ],
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      xShadow: true,
    })
  }
  // #endregion

  return {
    // state
    activePostPropertiesSubpanel,
    wallPostProperties,
    previewWallPostProperties,
    wallCustomPostProperties,
    isCreatingCustomPostProperty,
    isUpdatingCustomPostProperty,
    isDeletingCustomPostProperty,
    activeCustomPostProperty,

    // getters
    customLinkButtonProperty,
    wallCustomPostPropertiesById,
    wallSingleSelectPropsByCustomPropertyId,
    wallSingleSelectDefaultsByCustomPropertyId,
    requiredPostProperties,
    subjectPlaceholder,
    bodyPlaceholder,
    isSubjectEnabled,
    attachmentSettings,
    attachmentText,
    isBodyEnabled,
    isColorEnabled,
    isLocationEnabled,
    isDirty,
    isArLensesEnabled,
    arePostPropertiesInvalid,
    shouldHandleDragAndDropToPost,
    shouldHandlePasteToAddAttachment,
    requiredWallCustomPostPropertiesById,

    // getter functions
    getDisplayedColorForPost,
    isAttachmentTypeEnabled,
    shouldShowPostProperties,

    // actions
    saveSettings,
    showPostPropertiesPanelDirectly,
    closePostPropertiesPanel,
    goBackToPostPropertiesPanel,
    openCustomPostPropertiesPanel,
    closeCustomPostPropertiesPanel,
    updatePreviewWallPostProperty,
    setWallPostProperties,
    saveSettingsAndGoBackToMainPanel,
    discardChangesAndGoBackToMainPanel,
    discardChangesAndCloseSettingsPanel,
    createCustomPostProperty,
    updateCustomPostProperty,
    deleteCustomPostProperty,
    showDeleteCustomPropertyWarning,
    setCustomPostProperties,
    insertNewCustomPostProperty,
    removeCustomPostPropertyFromList,
    updateCustomPostPropertyInList,

    // Open/close panels
    openedDirectly: readonly(openedDirectly),
    closePostPropertiesSubpanel,
    saveSettingsAndClosePostPropertiesSubpanel,
    discardChangesAndClosePostPropertiesSubpanel,

    // Video length limits
    videoRecorderSettingsSubpanelOpenedGlobally: readonly(videoRecorderSettingsSubpanelOpenedGlobally),
    maxPossibleVideoLengthLimit,
    wallVideoLengthLimit,
    currentVideoLengthLimit,
    updateVideoLengthLimit,
  }
})
