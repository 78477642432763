// @file Post sorting related functions for all formats
import { ww } from '@@/bits/global'
import { $ } from '@@/bits/jquery'
import { isPostPinned } from '@@/bits/post_state'
import { getPostPublishedAt } from '@@/bits/post_timestamps'
import type { SortOrder } from '@@/bits/surface_settings_helper'
import type { AccumulatedReactions, Post, ReactionType, WallCustomPostProperty } from '@@/types'
import { orderBy, sortBy } from 'lodash-es'
import type { Store } from 'vuex'

// Assumption: by the time surface/sorter (i.e. this file) is used,
// the store would have been initialized.
interface SurfaceXstore {
  store: Store<any>
}
const store = (): Store<any> => (ww.xstore as unknown as SurfaceXstore)?.store

function getAbsoluteSortIndex(ts: Date | number = new Date(), wallCreatedAt = store().getters.wallCreatedAt): number {
  const timestamp = ts instanceof Date ? ts.getTime() : ts
  const absSortIndex = 1024 * (timestamp - new Date(wallCreatedAt).getTime())
  const postMaxAbsSortIndexWithBuffer = store().getters['post/postMaxAbsSortIndex'] + 1024
  return Math.max(absSortIndex, postMaxAbsSortIndexWithBuffer)
}

function getSortIndex(): number {
  const absSortIndex = getAbsoluteSortIndex()
  // sections flip = false, timeline -> new posts added to bottom
  // sections flip = false, timeline_v2 -> new posts added to bottom
  // sections flip = true, timeline -> new posts added to bottom
  // sections flip = true, timeline_v2 -> new posts added to top/bottom (you can change this)
  if (store().getters.format === 'timeline') return absSortIndex
  if (store().getters.format === 'timeline_v2' && !store().getters.canUseSections) return -absSortIndex
  const postAtBottom =
    store().getters.newPostLocation === 'bottom' && (store().getters.canConfigNewPostLocation as boolean)
  return postAtBottom ? -absSortIndex : absSortIndex
}

function getTopSortIndex(): number {
  return getAbsoluteSortIndex()
}

function getBottomSortIndex(): number {
  return -getAbsoluteSortIndex()
}

/**
 * Returns the sort_index for a new unpinned post.
 * Unpinning a post should move it to between the last pinned post and the first unpinned post.
 * Optionally returns the gap_size in case we need it to reset sort_index for all posts.
 */
function getNewUnpinSortIndex(): { sortIndex: number; gapSize?: number } {
  const lastPinnedPost = $('.wish[data-pinned="true"]').last()
  const firstUnpinnedPost = $('.wish[data-pinned="false"]').first()

  if (lastPinnedPost.length === 0) {
    // there's no pinned posts left -> return the top sort index
    return { sortIndex: getTopSortIndex() }
  }

  if (firstUnpinnedPost.length === 0) {
    // there's no unpinned posts left -> return the bottom sort index
    return { sortIndex: getBottomSortIndex() }
  }

  // else, return the sort index between these posts
  const prevIndex = parseInt(lastPinnedPost.attr('data-rank') as string, 10)
  const nextIndex = parseInt(firstUnpinnedPost.attr('data-rank') as string, 10)
  const sortIndex = Math.round((prevIndex + nextIndex) / 2)
  const gapSize = Math.abs(prevIndex - nextIndex)
  return { sortIndex, gapSize }
}

interface ComputeNewPostIndexOptions {
  sectionId?: number
  prevId?: string | null
  nextId?: string | null
}

function computePostIndexFromPost(
  post,
  options: ComputeNewPostIndexOptions = {},
): { sortIndex: number | null; sectionId?: number; gapSize?: number | null } {
  const $post = $(post)
  const sectionId = options.sectionId
  let $prev
  if (options.prevId === undefined) {
    $prev = $post.prev('.wish')
  } else if (options.prevId != null) {
    $prev = $(`#${options.prevId}`)
  } else {
    $prev = $([])
  }

  let $next
  if (options.nextId === undefined) {
    $next = $post.next('.wish')
  } else if (options.nextId != null) {
    $next = $(`#${options.nextId}`)
  } else {
    $next = $([])
  }

  if ($post.attr('data-pinned') === 'true') {
    // Dropping a pinned post before an unpinned post -> don't take next unpinned post into account
    if ($next.attr('data-pinned') === 'false') {
      $next = $([])
    }
  } else {
    // Dropping an unpinned post after a pinned post -> don't take previous pinned post into account
    if ($prev.attr('data-pinned') === 'true') {
      $prev = $([])
    }
  }

  let nextIndex: number | null = null
  let prevIndex: number | null = null
  let sortIndex: number | null = null
  if ($next.length === 0 && $prev.length === 0) {
    sortIndex = sectionId === null ? null : getSortIndex()
  } else {
    if ($next.length === 0) {
      nextIndex = getBottomSortIndex()
    } else if ($prev.length === 0) {
      prevIndex = getTopSortIndex()
    }
    if (nextIndex == null) {
      nextIndex = parseInt($next.attr('data-rank'), 10)
    }
    if (prevIndex == null) {
      prevIndex = parseInt($prev.attr('data-rank'), 10)
    }
    if (nextIndex != null && prevIndex != null) {
      sortIndex = Math.round((prevIndex + nextIndex) / 2)
    } else {
      sortIndex = null
    }
  }

  let gapSize: number | null = null

  if (sortIndex != null && prevIndex != null && nextIndex != null) {
    gapSize = Math.abs(prevIndex - nextIndex)
  }

  return {
    sortIndex,
    sectionId,
    gapSize,
  }
}

function computeAndSaveNewPostIndex(post, options: ComputeNewPostIndexOptions = {}): void {
  const { sortIndex, sectionId, gapSize } = computePostIndexFromPost(post, options)
  const $post = $(post)

  if (!sortIndex) return
  store().dispatch('post/sortedPost', {
    postCid: $post.data('post-cid'),
    sortIndex,
    sectionId,
    gapSize,
  })
}

function sortPostsByCustomProp({
  posts,
  customProperty,
  sortOrder,
}: {
  posts: Post[]
  customProperty: WallCustomPostProperty
  sortOrder: SortOrder
}): Post[] {
  // By default we sort posts with undefined custom property values to the end
  const defaultSortValue = sortOrder === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY

  switch (customProperty?.data_type) {
    case 'date': {
      return orderBy(
        posts,
        [
          (post: Post): number | undefined => {
            if (customProperty.id == null || post?.custom_properties == null) {
              return defaultSortValue
            }
            const datePropValue = post.custom_properties[customProperty.id]
            if (datePropValue == null) {
              return defaultSortValue
            }
            return new Date(datePropValue as string).getTime()
          },
          getPostPublishedAt,
        ],
        [sortOrder, 'asc'],
      )
    }
    case 'number': {
      return orderBy(
        posts,
        [
          (post: Post): number | undefined => {
            if (customProperty.id == null || post?.custom_properties == null) {
              return defaultSortValue
            }
            const numberPropValue = post.custom_properties[customProperty.id]
            if (numberPropValue == null) {
              return defaultSortValue
            }
            return numberPropValue as number
          },
          getPostPublishedAt,
        ],
        [sortOrder, 'asc'],
      )
    }
    case 'text': {
      return sortPostsAlphabeticallyByAttribute(
        posts,
        (post: Post): string | undefined => {
          if (customProperty.id == null || post?.custom_properties == null) return undefined
          return post.custom_properties[customProperty.id] as string
        },
        sortOrder,
      )
    }
    case 'single_select': {
      return orderBy(
        posts,
        [
          (post: Post): number | undefined => {
            if (customProperty.id == null || post?.custom_properties == null) {
              return defaultSortValue
            }
            const optionId = post.custom_properties[customProperty.id] as string
            const option = customProperty?.selection_options?.find((option) => option.id === optionId)
            if (option == null) {
              return defaultSortValue
            }
            return option?.sort_index
          },
          getPostPublishedAt,
        ],
        [sortOrder, 'asc'],
      )
    }
    default: {
      return sortPostsBySortIndex(posts)
    }
  }
}

function sortPostsAlphabeticallyByAttribute(
  posts: Post[],
  getAttribute: (post) => string | undefined,
  order: SortOrder,
): Post[] {
  const sortedPosts = [...posts]
  sortedPosts.sort((postA, postB) => {
    let postAAttribute = getAttribute(postA) ?? ''
    let postBAttribute = getAttribute(postB) ?? ''

    // Empty strings should always be sorted last
    const emptyStringCompValue = getEmptyStringComparisonValue(postAAttribute, postBAttribute)
    if (emptyStringCompValue !== 0) return emptyStringCompValue

    // For non-empty strings, sort to back if they are emoji
    const emojiCompValue = getEmojiOnlyStringComparisonValue(postAAttribute, postBAttribute)
    if (emojiCompValue !== 0) return emojiCompValue

    postAAttribute = stripEmojis(postAAttribute)
    postBAttribute = stripEmojis(postBAttribute)

    let comparison = 0
    if (order === 'asc') {
      //  https:developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator#options
      comparison = postAAttribute.localeCompare(postBAttribute, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    } else {
      comparison = postBAttribute.localeCompare(postAAttribute, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    }

    if (comparison === 0) {
      //  Secondary sort: publish date asc
      return new Date(getPostPublishedAt(postA) ?? 0).getTime() - new Date(getPostPublishedAt(postB) ?? 0).getTime()
    }

    return comparison
  })

  return sortedPosts
}

function sortPostsBySortIndex(posts: Post[]): Post[] {
  return sortBy(posts, (post: Post): number | undefined => {
    if (post.sort_index != null) {
      return post.sort_index
    }
    const oldRank = parseInt((post.rank || '') + new Date(post.created_at as string).getTime(), 10)
    return Math.abs(getAbsoluteSortIndex(oldRank))
  }).reverse()
}

// implementation based on https://www.algolia.com/doc/guides/managing-results/must-do/custom-ranking/how-to/bayesian-average/
function calculateBayesianAverage(
  numRatings: number,
  avgRatings: number,
  arithmeticAverage: number,
  confidenceNumber: number,
): number {
  return (numRatings * avgRatings + arithmeticAverage * confidenceNumber) / (numRatings + confidenceNumber)
}

function sortPostsByReactionSum(
  posts: Post[],
  reactionsByWishId: AccumulatedReactions,
  constants?: { reactionsTotalSumAverage: number; confidenceNumber: number },
): Post[] {
  if (reactionsByWishId == null) return posts
  return orderBy(
    posts,
    [
      (post: Post): number | undefined => {
        const postId = post?.id

        if (postId == null) return 0

        const reaction = reactionsByWishId[postId]
        if (reaction == null) return 0

        // Differentiate between posts with no reactions vs reactions that sum to 0
        if (reaction.sum === 0) {
          if (reaction.totalReactionsCount > 0) {
            return 0.5
          }
          return 0
        }

        const bayesianAverageReactionTypes: Array<Exclude<ReactionType, 'like' | 'vote'>> = ['star', 'grade']
        if (bayesianAverageReactionTypes.includes(reaction?.reactionType as Exclude<ReactionType, 'like' | 'vote'>)) {
          // sorting is based on bayesian average
          const reactionsTotalSumAverage = constants?.reactionsTotalSumAverage ?? 0
          const confidenceNumber = constants?.confidenceNumber ?? 100 // backup value

          const totalReactions = reaction?.totalReactionsCount
          const averageReactionSum = reaction?.sum / totalReactions
          const bayesAverage = calculateBayesianAverage(
            totalReactions,
            averageReactionSum,
            reactionsTotalSumAverage,
            confidenceNumber,
          )

          return bayesAverage
        }

        // for likes and votes, sorting is based on number of likes which is captured by sum
        return reaction?.sum
      },
      getPostPublishedAt,
    ],
    ['asc', 'desc'],
  )
}

function sortPostsByCreationDate(posts: Post[]): Post[] {
  return sortBy(posts, getPostPublishedAt)
}

function sortPostsBySubject(posts: Post[], order: SortOrder): Post[] {
  return sortPostsAlphabeticallyByAttribute(posts, (post: Post): string | undefined => post.subject, order)
}

function getEmptyStringComparisonValue(a: string, b: string): number {
  if ((a === '' && b === '') || (a !== '' && b !== '')) {
    return 0
  } else if (a === '') {
    return 1
  } else {
    // b === ''
    return -1
  }
}

function getEmojiOnlyStringComparisonValue(a: string, b: string): number {
  try {
    // eslint-disable-next-line prefer-regex-literals
    const PURE_EMOJI_STRING_REGEX = new RegExp('^\\p{Extended_Pictographic}+$', 'u')

    const isAEmojisOnly = PURE_EMOJI_STRING_REGEX.test(a)
    const isBEmojisOnly = PURE_EMOJI_STRING_REGEX.test(b)
    if (isAEmojisOnly && !isBEmojisOnly) {
      return 1 // a comes after b
    } else if (!isAEmojisOnly && isBEmojisOnly) {
      return -1 // a comes before b
    } else {
      return 0 // don't swap
    }
  } catch {
    return 0
  }
}

function stripEmojis(str: string): string {
  try {
    // eslint-disable-next-line prefer-regex-literals
    const MATCH_EMOJI_REGEX = new RegExp(
      '(?![*#0-9]+)[\\p{Emoji}\\p{Emoji_Modifier}\\p{Emoji_Component}\\p{Emoji_Modifier_Base}\\p{Emoji_Presentation}]\\s*',
      'gu',
    )
    return str.replace(MATCH_EMOJI_REGEX, '')
  } catch {
    return str
  }
}

function recomputeSortIndex(
  curSortIndex: number | undefined,
  postsSortedBySortIndex: Post[],
  isTimelineV1 = false,
): number | undefined {
  if (!curSortIndex || postsSortedBySortIndex.length < 1) {
    return curSortIndex
  }

  // check if a post with sort_index === curSortIndex already exists. If so, compute new sort_index that goes after it
  const beforePostIndex = postsSortedBySortIndex.findIndex((p) => p.sort_index === curSortIndex)

  // no collision
  if (beforePostIndex < 0) {
    return curSortIndex
  }

  const beforePostId = postsSortedBySortIndex[beforePostIndex].id
  const afterPostId =
    beforePostIndex === postsSortedBySortIndex.length - 1 && postsSortedBySortIndex.length === 1
      ? null
      : postsSortedBySortIndex[beforePostIndex + 1].id

  // TimelineV1 sort index is from smallest on the left to largest to the right
  // while it's from largest to smallest on other formats
  const { sortIndex } = computePostIndexFromPost(null, {
    prevId: isTimelineV1 ? `wish-${afterPostId}` : `wish-${beforePostId}`,
    nextId: isTimelineV1 ? `wish-${beforePostId}` : `wish-${afterPostId}`,
  })

  return sortIndex || 0
}

function sortPinnedPostsToFront(posts: Post[]): Post[] {
  return [...posts].sort((postA, postB) => {
    if (isPostPinned(postA) && !isPostPinned(postB)) return -1
    if (!isPostPinned(postA) && isPostPinned(postB)) return 1
    return 0
  })
}

export {
  computeAndSaveNewPostIndex,
  computePostIndexFromPost,
  getBottomSortIndex,
  getNewUnpinSortIndex,
  getSortIndex,
  getTopSortIndex,
  recomputeSortIndex,
  sortPinnedPostsToFront,
  sortPostsByCreationDate,
  sortPostsByCustomProp,
  sortPostsByReactionSum,
  sortPostsBySortIndex,
  sortPostsBySubject,
}
