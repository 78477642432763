/**
 * @file Helper for Slideshow
 */

import type { ImageExportParams } from '@@/bits/export_proxy'
import { sanitizeFilename } from '@@/bits/file_handler'
import { np__, p__, __ } from '@@/bits/intl'
import { transformUrl } from '@@/bits/location'
import { isSmallerThanDesktop2XL, isSmallerThanDesktop3XL, isSmallerThanDesktopBig } from '@@/bits/window'
import { PageType } from '@@/types/slideshow'

/*
 * NAVIGATION
 */

const COVER_PAGE_INDEX = 0

/**
 * Build a link to a slideshow page
 * @param showLink - Link to the underlying padlet
 */
const buildSlideshowLink = (showLink: string, pageType: PageType, entityId?: number | string): string => {
  const entityUrlSuffix = (() => {
    switch (pageType) {
      case 'section':
        return entityId != null ? `/section/${entityId}` : ''
      case 'post':
        return entityId != null ? `/wish/${entityId}` : ''
      case 'cover':
      default:
        return ''
    }
  })()

  return `${showLink}/slideshow${entityUrlSuffix}`
}

const buildSlideshowPostLink = (showLink: string, entityId?: number | string): string =>
  buildSlideshowLink(showLink, PageType.Post, entityId)

const getCurrentPostCid = (entityId: string): string => `c${entityId}`

/*
 * QR CODE
 */

const DEFAULT_QR_CODE_SIZE = 1059

/*
 * AUTOPLAY
 */

const secondDurationLong = (number: number): string => {
  return np__(
    'Represents duration. Used for configuration. For example: 3 seconds',
    '%{number} second',
    '%{number} seconds',
    number,
    { number },
  )
}

const secondDurationShort = (number: number): string => {
  return p__('Represents duration. Used for configuration. For example: 3s', '%{number}s', { number })
}

const secondDurationText = (durationType: string): string => {
  if (durationType === 'auto') {
    return __('Auto')
  } else {
    return ''
  }
}

const AUTOPLAY_DURATION_TYPES = [1, 2, 3, 5, 15, 30, 60, 'auto'] as const
const AUTOPLAY_DURATIONS = AUTOPLAY_DURATION_TYPES.reduce((acc, duration) => {
  acc[duration] = {
    long: typeof duration === 'number' ? secondDurationLong(duration) : secondDurationText(duration),
    short: typeof duration === 'number' ? secondDurationShort(duration) : secondDurationText(duration),
  }
  return acc
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
}, {} as Record<typeof AUTOPLAY_DURATION_TYPES[number], { long: string; short: string }>)

type AutoplayDurationType = keyof typeof AUTOPLAY_DURATIONS

const BASE_SLIDE_DURATION_MS = 2000
const SLIDE_DURATION_PER_WORD_MS = 500
const SLIDE_DURATION_PER_ATTACHMENT_MS = 5000
const calculateAutoSlideDuration = (numWords: number, numAttachments: number): number => {
  return (
    BASE_SLIDE_DURATION_MS + SLIDE_DURATION_PER_WORD_MS * numWords + SLIDE_DURATION_PER_ATTACHMENT_MS * numAttachments
  )
}

const NOTIFICATION_MESSAGES = {
  stoppedAutoplaying: __('Autoplay stopped'),
  startedAutoplayingForTouchDevice: __('Autoplay started, press pause to stop'),
  startedAutoplayingForMouseDevice: __('Autoplay started, press any key to stop'),
}

/*
 * EMBEDS
 */

type EmbedSize = 'small' | 'medium' | 'large'
interface EmbedSizeOption {
  title: string
  width: number
  height: number
  key: EmbedSize
}

const embedSizeOptions: EmbedSizeOption[] = [
  {
    title: __('Small'),
    width: 480,
    height: 320,
    key: 'small',
  },
  {
    title: __('Medium'),
    width: 720,
    height: 480,
    key: 'medium',
  },
  {
    title: __('Large'),
    width: 1080,
    height: 720,
    key: 'large',
  },
]

/*
 * EXPORTS
 */

// Used by our export service to know when to take the screenshot.
// The service will wait for the id to be present in the DOM before taking a screenshot,
// otherwise it may screenshot the loading state.
const SLIDESHOW_CONTENT_LOADED_ID = 'slideshow-content-loaded'
const SLIDESHOW_SLIDE_DEFAULT_EXPORT_HEIGHT = 1080
const SLIDESHOW_SLIDE_DEFAULT_EXPORT_WIDTH = 1920
const SLIDESHOW_DEFAULT_EXPORT_IMAGE_TYPE = 'jpeg'

// Delay is applied *after* the wait for selector resolves
const SLIDESHOW_SLIDE_DEFAULT_DELAY_WITH_ATTACHMENT = 1000
const SLIDESHOW_SLIDE_DEFAULT_DELAY_WITHOUT_ATTACHMENT = 0

const SLIDESHOW_DEFAULT_EXPORT_QUERY_PARAMS: Partial<ImageExportParams> = {
  height: SLIDESHOW_SLIDE_DEFAULT_EXPORT_HEIGHT,
  width: SLIDESHOW_SLIDE_DEFAULT_EXPORT_WIDTH,
  file_type: SLIDESHOW_DEFAULT_EXPORT_IMAGE_TYPE,
  retina: true,
  // KNOWN ISSUE: One of the screenshot providers, Microlink, has issues detecting the selector and may capture the loading spinner instead.
  // Since Microlink is a fallback for Urlbox, we won't rely on it the majority of the time so treating this as low priority _for now_.
  wait_for_selector: `#${SLIDESHOW_CONTENT_LOADED_ID}`,
}

// Used for the name of the pdf or zip, not single image exports
const getSlideshowExportFileName = (title: string, fileContent = 'slideshow'): string =>
  sanitizeFilename(`${title}-${fileContent}`)

const buildSlideUrlForExport = (
  slideUrl: string,
  token: string,
  currentPageLastUpdated: string,
  removeBackground?: boolean,
): string => {
  const searchParams: Record<string, string> = {
    token,
    last_updated: currentPageLastUpdated,
    screenshot: '1', // Enables a minimal UI on slideshow and also adds SLIDESHOW_CONTENT_LOADED_ID
    remove_background: removeBackground === true ? '1' : '0',
  }

  return transformUrl(slideUrl, {
    search: searchParams,
  })
}

/*
 * ANALYTICS
 */

enum SLIDESHOW_ANALYTICS_ID {
  // Navbar
  'slideshow-qr-button' = 'slideshow-qr-button',
  'slideshow-share-button' = 'slideshow-share-button',
  'slideshow-autoplay-button' = 'slideshow-autoplay-button',
  'slideshow-open-in-new-tab-button' = 'slideshow-open-in-new-tab-button',
  'slideshow-enter-fullscreen-button' = 'slideshow-enter-fullscreen-button',
  'slideshow-exit-fullscreen-button' = 'slideshow-exit-fullscreen-button',
  'slideshow-show-qr-code-button' = 'slideshow-show-qr-code-button',
  'slideshow-hide-qr-code-button' = 'slideshow-hide-qr-code-button',
  'slideshow-indicator-button' = 'slideshow-indicator-button',

  // Autoplay
  'slideshow-autoplay-duration-button' = 'slideshow-autoplay-duration-button',
  'slideshow-autoplay-loop-slides-button' = 'slideshow-autoplay-loop-slides-button',

  // More menu
  'slideshow-restart-button' = 'slideshow-restart-button',
  'slideshow-exit-button' = 'slideshow-exit-button',

  // Share panel
  'slideshow-copy-slide-link-button' = 'slideshow-copy-slide-link-button',
  'slideshow-copy-slideshow-link-button' = 'slideshow-copy-slideshow-link-button',
  'slideshow-embed-button' = 'slideshow-embed-button',
  'slideshow-share-via-another-app-button' = 'slideshow-share-via-another-app-button',
  'slideshow-save-current-slide-as-image-button' = 'slideshow-save-current-slide-as-image-button',
  'slideshow-save-every-slide-as-image-button' = 'slideshow-save-every-slide-as-image-button',
  'slideshow-save-as-pdf-button' = 'slideshow-save-as-pdf-button',
  'slideshow-print-button' = 'slideshow-print-button',
  'slideshow-email-button' = 'slideshow-email-button',
  'slideshow-share-on-facebook-button' = 'slideshow-share-on-facebook-button',
  'slideshow-share-on-twitter-button' = 'slideshow-share-on-twitter-button',
  'slideshow-share-on-google-classroom-button' = 'slideshow-share-on-google-classroom-button',
  'slideshow-share-on-zoom-button' = 'slideshow-share-on-zoom-button',

  // Keyboard navigation
  'slideshow-shortcut-left-arrow-key' = 'slideshow-shortcut-left-arrow-key',
  'slideshow-shortcut-up-arrow-key' = 'slideshow-shortcut-up-arrow-key',
  'slideshow-shortcut-page-up-key' = 'slideshow-shortcut-page-up-key',
  'slideshow-shortcut-page-down-key' = 'slideshow-shortcut-page-down-key',
  'slideshow-shortcut-right-arrow-key' = 'slideshow-shortcut-right-arrow-key',
  'slideshow-shortcut-down-arrow-key' = 'slideshow-shortcut-down-arrow-key',
  'slideshow-shortcut-space-key' = 'slideshow-shortcut-space-key',
  'slideshow-shortcut-f-key' = 'slideshow-shortcut-f-key',
  'slideshow-shortcut-r-key' = 'slideshow-shortcut-r-key',
  'slideshow-shortcut-home-key' = 'slideshow-shortcut-home-key',
  'slideshow-shortcut-end-key' = 'slideshow-shortcut-end-key',

  // The 3 ids below should be the only data ids that reference "presentation view"
  // Purposely not renaming them to slideshow otherwise we'll have to be aware of 2 different naming schemes to track the same thing
  // All other data ids should reference "slideshow"
  'presentation-view-more-button' = 'presentation-view-more-button',
  'presentation-view-next-button' = 'presentation-view-next-button',
  'presentation-view-previous-button' = 'presentation-view-previous-button',
}

enum PollSlideshowResizeScale {
  BelowDesktopBig = 1,
  DesktopBig = 1.5,
  Desktop2XL = 2,
  Desktop3XL = 2.5,
}

const pollSlideshowResizeScales = (): number[] => {
  if (isSmallerThanDesktopBig()) {
    return [PollSlideshowResizeScale.BelowDesktopBig]
  }
  if (isSmallerThanDesktop2XL()) {
    return [PollSlideshowResizeScale.BelowDesktopBig, PollSlideshowResizeScale.DesktopBig]
  }
  if (isSmallerThanDesktop3XL()) {
    return [
      PollSlideshowResizeScale.BelowDesktopBig,
      PollSlideshowResizeScale.DesktopBig,
      PollSlideshowResizeScale.Desktop2XL,
    ]
  }
  return [
    PollSlideshowResizeScale.BelowDesktopBig,
    PollSlideshowResizeScale.DesktopBig,
    PollSlideshowResizeScale.Desktop2XL,
    PollSlideshowResizeScale.Desktop3XL,
  ]
}

export {
  AUTOPLAY_DURATIONS,
  buildSlideshowLink,
  buildSlideshowPostLink,
  buildSlideUrlForExport,
  calculateAutoSlideDuration,
  COVER_PAGE_INDEX,
  DEFAULT_QR_CODE_SIZE,
  embedSizeOptions,
  getCurrentPostCid,
  getSlideshowExportFileName,
  NOTIFICATION_MESSAGES,
  pollSlideshowResizeScales,
  SLIDESHOW_ANALYTICS_ID,
  SLIDESHOW_CONTENT_LOADED_ID,
  SLIDESHOW_DEFAULT_EXPORT_IMAGE_TYPE,
  SLIDESHOW_DEFAULT_EXPORT_QUERY_PARAMS,
  SLIDESHOW_SLIDE_DEFAULT_DELAY_WITH_ATTACHMENT,
  SLIDESHOW_SLIDE_DEFAULT_DELAY_WITHOUT_ATTACHMENT,
  SLIDESHOW_SLIDE_DEFAULT_EXPORT_HEIGHT,
  SLIDESHOW_SLIDE_DEFAULT_EXPORT_WIDTH,
}
export type { AutoplayDurationType, EmbedSize, EmbedSizeOption }
