// @file The store for magic padlet panel displayed by DashLayoutPickerPage.vue and LayoutPickerDesktop.vue
import { trackEvent } from '@@/bits/analytics'
import { observeConnectivity } from '@@/bits/connectivity'
import device from '@@/bits/device'
import { captureException, captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { navigateTo } from '@@/bits/location'
import { isExamplePrompt, MAGIC_TEMPLATES } from '@@/bits/magic_padlet_helper'
import { poll } from '@@/bits/polling'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { LibraryMembershipRole, LibraryType, UserAccountType } from '@@/enums'
import type { PopoverAnchor } from '@@/library/v4/components/OzPopoverModal.vue'
import type { Activity } from '@@/pinia/ai_streaming_store'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import type { MagicTemplateFormData } from '@@/surface/padlet_api'
import PadletApi from '@@/surface/padlet_api'
import type { LibraryId, MagicTemplate, MagicWallCreationResult, MagicWallCreationStatusResult } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { FetchError, HTTPMethod } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

enum MagicPadletPanelStatus {
  Initial = 'Initial',
  Creating = 'Creating',
  Errored = 'Errored',
  AtQuota = 'AtQuota',
}

const magicTemplateEnum: Record<string, MagicTemplate> = {
  DiscussionBoard: {
    name: __('Discussion board'),
    key: 'discussion_board',
    icon: 'compass',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_discussion_board.webp',
    originalImageWidth: 100,
    originalImageHeight: 100,
    isNew: true,
  },
  LessonPlan: {
    name: __('Lesson plan'),
    key: 'lesson_plan',
    icon: 'note_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_lesson_plan.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  ClassActivities: {
    name: __('Class activity creator'),
    key: 'class_activities',
    icon: 'pencil_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_class_activity_creator.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
    isNew: true,
  },
  ClassroomActivitiesList: {
    name: __('Ideas for class activities'),
    key: 'classroom_activity_ideas',
    icon: 'lightbulb',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_classroom_activities.webp',
    originalImageWidth: 100,
    originalImageHeight: 100,
    shortName: __('Class activities'),
  },
  HistoricalTimeline: {
    name: __('Timeline of events'),
    key: 'historical_timeline',
    icon: 'recent_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_timeline.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  ReadingList: {
    name: __('Reading list'),
    key: 'reading_list',
    icon: 'library',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_reading_list.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  MapOfHistoricalEvents: {
    name: __('Map of historical events'),
    key: 'map_of_historical_events',
    icon: 'map_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_map.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
    shortName: __('Historical map'),
  },
  LearningAssessmentPolls: {
    name: __('Assessment polls'),
    key: 'learning_assessment_polls',
    icon: 'poll_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_assessment_polls.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  Rubric: {
    name: __('Rubric'),
    key: 'rubric',
    icon: 'grade_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_rubric.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  CustomBoard: {
    name: __('Custom board'),
    key: 'custom_board',
    icon: 'sparkles_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_custom_board.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
}

// Getting the "key" value from magicTemplateEnum and forming a type like "lesson_plan"
export type MagicTemplateKey = {
  [K in keyof typeof magicTemplateEnum]: typeof magicTemplateEnum[K]['key']
}[keyof typeof magicTemplateEnum]

export const useMagicPadletPanelStore = defineStore('magicPadletPanelStore', () => {
  const dashAccountsStore = useDashAccountsStore()
  /**
   * Who can see magic templates
   */
  const xMagicTemplatesSection = computed<boolean>(() => {
    if (isAppUsing('magicPadlet')) return true

    // Tenanted users
    if (dashAccountsStore.isBackpack) {
      // Backpack students should not see the magic templates section
      if (dashAccountsStore.isStudent) return false
      else return true
    }
    if (dashAccountsStore.isBriefcase) return false

    // Personal library
    if (dashAccountsStore.currentLibrary == null) {
      return [UserAccountType.PERSONAL, UserAccountType.TEACHER, UserAccountType.STAFF].includes(
        dashAccountsStore.user.account_type as UserAccountType,
      )
    }

    // Team, School and Classroom libraries
    const libraryType: LibraryType | null = dashAccountsStore.currentLibrary?.libraryType ?? null
    const libraryRole =
      dashAccountsStore.currentLibrary != null
        ? dashAccountsStore.userRolesByLibraryId[dashAccountsStore.currentLibrary.id]
        : null

    const isActiveLibrary: boolean =
      (!dashAccountsStore.currentLibrary.isPlanCancelled &&
        !(dashAccountsStore.currentLibrary.isTrialExpired as boolean)) ||
      dashAccountsStore.currentLibrary?.membershipTier === 'gold'
    if (!isActiveLibrary) return false

    if (libraryType === LibraryType.Classroom || libraryType === LibraryType.School) {
      return [LibraryMembershipRole.Teacher, LibraryMembershipRole.Admin, LibraryMembershipRole.Owner].includes(
        libraryRole as LibraryMembershipRole,
      )
    }

    // return true for team libraries, and return true for any other case
    return true
  })

  // Only show in school/classroom libraries and backpack if user is a student
  const xMagicTemplatesSectionPlaceholder = computed<boolean>(() => {
    if (xMagicTemplatesSection.value) return false

    // Handle backpack logic
    const shouldShowInBackpack = dashAccountsStore.isBackpack && dashAccountsStore.isStudent
    if (shouldShowInBackpack) return true

    // Handle school/classroom library logic
    // Early return if personal library
    if (dashAccountsStore.currentLibrary == null) return false

    const isCurrentLibrarySchoolOrClassroom =
      dashAccountsStore.isCurrentLibrarySchool || dashAccountsStore.isCurrentLibraryClassroom
    const shouldShowInSchoolOrClassroomLibrary =
      isCurrentLibrarySchoolOrClassroom &&
      dashAccountsStore.userRolesByLibraryId[dashAccountsStore.currentLibrary.id] === LibraryMembershipRole.Student

    return shouldShowInSchoolOrClassroomLibrary
  })

  /**
   * Magic Templates Fields
   */
  const refs = reactive({})

  function setRefValue(key: string, value: string | string[]): void {
    if (!(key in refs)) {
      refs[key] = ref(value)
    } else {
      refs[key].value = value
    }
    // Validate form whenever a ref value is updated
    validateForm()
  }

  function getRefValue(key: string): string | string[] {
    return refs[key].value
  }

  function resetRefs(): void {
    for (const key in refs) {
      if (key in refs) {
        delete refs[key]
      }
    }
  }

  const inputConfig = computed(() => {
    return MAGIC_TEMPLATES[magicTemplateSelected.value.key].inputConfig
  })

  /**
   * Validation
   */
  const isFormValid = ref(false)

  function isFieldEmpty(field: string | string[]): boolean {
    if (Array.isArray(field)) {
      return field.length === 0
    } else {
      return field.trim().length === 0
    }
  }

  function validateForm(): void {
    let isValid = true
    for (const config of inputConfig.value) {
      const value = refs[config.refKey].value
      if (config.optional !== true) {
        if (isFieldEmpty(value)) {
          isValid = false
          break
        }
      }
    }
    isFormValid.value = isValid
  }

  /**
   * Magic Template Selector
   */
  const xMagicTemplateSelector = ref(false)
  const magicTemplateSelected = ref<MagicTemplate>(magicTemplateEnum.CustomBoard)
  const magicTemplatesArray = ref<MagicTemplate[]>(Object.values(magicTemplateEnum))

  const isClassActivities = computed(() => {
    return magicTemplateSelected.value.key === 'class_activities'
  })

  const sortedMagicTemplatesByNew = computed(() => {
    const allTemplates = Object.values(magicTemplateEnum)

    const discussionBoard = allTemplates.filter((template) => template.key === 'discussion_board')
    const newTemplates = allTemplates.filter(
      (template) => template.isNew === true && template.key !== 'discussion_board',
    )
    const restOfTemplates = allTemplates.filter(
      (template) => !(template.isNew ?? false) && template.key !== 'discussion_board',
    )

    return [...discussionBoard, ...newTemplates, ...restOfTemplates]
  })

  function toggleMagicTemplateSelector(): void {
    xMagicTemplateSelector.value = !xMagicTemplateSelector.value
  }

  function setMagicTemplateSelected(template: MagicTemplate): void {
    resetRefs()
    magicTemplateSelected.value = template
  }

  /**
   * Magic Template Selector Anchor
   */
  const magicTemplateSelectorAnchor = ref<Partial<PopoverAnchor>>({})
  function updateMagicTemplateSelectorAnchor(anchor: Partial<PopoverAnchor>): void {
    magicTemplateSelectorAnchor.value = anchor
  }

  /**
   * Creation
   */
  const status = ref(MagicPadletPanelStatus.Initial)
  const isInitialStatus = computed(() => status.value === MagicPadletPanelStatus.Initial)
  const isCreatingMagicPadlet = computed(() => status.value === MagicPadletPanelStatus.Creating)
  const isAtQuota = computed(() => status.value === MagicPadletPanelStatus.AtQuota)
  const isExample = ref(false)
  const includeImages = ref(true)
  const isBrowserOffline = ref(false)

  function collectFormData(
    magicTemplateKey: MagicTemplateKey,
    refs: any,
    additionalData: any = {},
  ): MagicTemplateFormData[typeof magicTemplateKey] {
    const formData: MagicTemplateFormData[typeof magicTemplateKey] = {}
    for (const key in refs) {
      if (key === 'userPrompt') {
        isExample.value = isExamplePrompt(refs[key].value)
      }
      formData[key] = refs[key].value
    }
    return { ...formData, ...additionalData }
  }

  async function handleApiResponse(
    currentLibraryId: LibraryId | undefined,
    includeImages: boolean,
    magicTemplateKey: MagicTemplateKey,
    formData: MagicTemplateFormData[typeof magicTemplateKey],
  ): Promise<MagicWallCreationStatusResult> {
    try {
      status.value = MagicPadletPanelStatus.Creating
      const response: JsonAPIResponse<MagicWallCreationResult> = await PadletApi.Wall.createMagic({
        currentLibraryId,
        isExample: isExample.value,
        includeImages,
        magicTemplateKey,
        formData,
      })

      observeConnectivity((isOnline: boolean): void => {
        isBrowserOffline.value = !isOnline
      })

      const statusLink = (response?.data as JsonAPIResource<MagicWallCreationResult>)?.attributes.url
      const { data }: JsonAPIResponse<MagicWallCreationStatusResult> = await poll({
        pollingUrl: statusLink,
        validationCallback: ({ data }) => {
          return data?.attributes?.status !== 'in_progress' || isBrowserOffline.value
        },
        options: { intervalSecs: 5, maxAttempts: 60, method: HTTPMethod.get },
      })

      const statusResult = (data as JsonAPIResource<MagicWallCreationStatusResult>)?.attributes

      if (statusResult.status !== 'success') {
        const errorMessage = `Magic wall creation failed${
          statusResult.status === 'at_quota' ? ' due to quota' : isBrowserOffline.value ? 'due to lost connection' : ''
        }`
        throw new Error(errorMessage)
      }

      return statusResult
    } catch (e) {
      status.value = MagicPadletPanelStatus.Errored
      throw e // Propagate error to be handled externally
    }
  }

  async function generateMagicPadlet({
    currentLibraryId,
    includeImages,
    magicTemplateKey,
  }: {
    currentLibraryId: LibraryId | undefined
    includeImages: boolean
    magicTemplateKey: MagicTemplateKey
  }): Promise<void> {
    try {
      const formData = collectFormData(magicTemplateKey, refs)
      const statusResult = await handleApiResponse(currentLibraryId, includeImages, magicTemplateKey, formData)

      logSuccess({ currentLibraryId, isExample: isExample.value, magicTemplateKey, ...formData })
      setTimeout(() => {
        safeLocalStorage.setItem('magicWallOptions', asciiSafeStringify(formData))
        navigateTo(statusResult.wall_url, { target: device.electronApp ? '_blank' : '_self' })
        if (device.electronApp) {
          closeMagicPadletPanel()
        }
      }, 10)
    } catch (e) {
      if (e instanceof FetchError) {
        captureFetchException(e, { source: 'MagicWallCreation' })
      } else {
        captureException(e)
      }
    }
  }

  function logSuccess(logData: Record<string, any>): void {
    try {
      // Formatting
      for (const key in logData) {
        if (logData[key] === undefined || logData[key] === null || logData[key] === '') {
          delete logData[key]
        }
      }
      if (logData.grades) {
        logData.grades = logData.grades.join(', ')
      }
      if (logData.magicTemplateKey !== 'custom_board') {
        delete logData.role
        delete logData.isExample
      }

      trackEvent('Magic Padlet', 'Created magic padlet', logData.magicTemplateKey, null, logData)
    } catch (e) {
      captureException(e)
    }
  }

  async function generateClassActivityPadlet({
    currentLibraryId,
    includeImages,
    magicTemplateKey,
    topic,
    activity,
  }: {
    currentLibraryId: LibraryId | undefined
    includeImages: boolean
    magicTemplateKey: MagicTemplateKey
    topic: string
    activity: Activity
  }): Promise<void> {
    try {
      const additionalData = {
        topic,
        activityName: activity.name,
        activityDescription: activity.description,
      }
      const formData = collectFormData(magicTemplateKey, refs, additionalData)
      const statusResult = await handleApiResponse(currentLibraryId, includeImages, magicTemplateKey, formData)

      logSuccess({ currentLibraryId, isExample: isExample.value, magicTemplateKey, ...formData })
      setTimeout(() => {
        safeLocalStorage.setItem('magicWallOptions', asciiSafeStringify(formData))
        navigateTo(statusResult.wall_url, { target: device.electronApp ? '_blank' : '_self' })
        if (device.electronApp) {
          closeMagicPadletPanel()
        }
      }, 10)
    } catch (e) {
      if (e instanceof FetchError) {
        captureFetchException(e, { source: 'ClassActivityMagicWallCreation' })
      } else {
        captureException(e)
      }
    }
  }

  function flipImageToggle(): void {
    includeImages.value = !includeImages.value
  }

  /**
   * Panel Actions
   */
  const xMagicPadletPanel = ref(false)

  function openMagicPadletPanel(): void {
    xMagicPadletPanel.value = true
    status.value = MagicPadletPanelStatus.Initial
    isExample.value = false
  }

  function closeMagicPadletPanel(): void {
    xMagicPadletPanel.value = false
  }

  /**
   * Multiline Input Focus
   */
  const isMultilineInputFocused = ref(false)
  function setIsMultilineInputFocused(value: boolean): void {
    isMultilineInputFocused.value = value
  }

  return {
    /**
     * Who can see magic templates
     */
    xMagicTemplatesSection,
    xMagicTemplatesSectionPlaceholder,

    /**
     * Magic Templates Fields
     */
    refs,
    setRefValue,
    getRefValue,
    inputConfig,

    /**
     * Validation
     */
    isFormValid,
    validateForm,

    /**
     * Magic Template Selector
     */
    xMagicTemplateSelector,
    magicTemplateSelected,
    magicTemplatesArray,
    magicTemplateSelectorAnchor,
    toggleMagicTemplateSelector,
    updateMagicTemplateSelectorAnchor,
    setMagicTemplateSelected,
    isClassActivities,
    sortedMagicTemplatesByNew,

    /**
     * Creation
     */
    status,
    isExample,
    includeImages,
    isInitialStatus,
    isCreatingMagicPadlet,
    isAtQuota,
    isBrowserOffline,
    generateMagicPadlet,
    flipImageToggle,
    generateClassActivityPadlet,

    /**
     * Panel Actions
     */
    xMagicPadletPanel,
    openMagicPadletPanel,
    closeMagicPadletPanel,

    /**
     * Multiline Input Focus
     */
    isMultilineInputFocused,
    setIsMultilineInputFocused,
  }
})

export { MagicPadletPanelStatus, magicTemplateEnum }
