// @file Surface share links store
import { CONFIRM_TURN_OFF } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { authWithReferrerUrl, currentUrl, getSearchParam, navigateTo, transformUrl } from '@@/bits/location'
import { getVuexStore } from '@@/bits/pinia'
import PromiseQueue from '@@/bits/promise_queue'
import {
  DEFAULT_SUBMISSION_REQUEST_CONFIRMATION_TEXT,
  getSectionBreakoutDisabledUrl,
  getSectionBreakoutPostUrl,
  getSubmissionRequestConfirmationUrl,
  getSubmissionRequestDisabledUrl,
} from '@@/bits/surface_share_links_helper'
import { isRegistered } from '@@/bits/user_model'
import { unboundedWatchEffect } from '@@/bits/vue'
import { SnackbarNotificationType } from '@@/enums'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalInputDialogStore } from '@@/pinia/global_input_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import PadletApi from '@@/surface/padlet_api'
import type {
  Cid,
  Id,
  PostAttributes,
  WallSectionBreakoutLink,
  WallSubmissionRequestLink,
  WallSubmissionRequestProps,
} from '@@/types'
import { useCopyToClipboard } from '@@/vuecomposables/copy_to_clipboard'
import type { RootState } from '@@/vuexstore/surface/types'
import { keyBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

// replication of options in WallShareLinks::SubmissionRequest::RedirectLocations
export enum SubmissionRequestRedirectLocation {
  Padlet = 'padlet',
  Confirmation = 'confirmation',
  RequestAgain = 'request_again',
}

export const useSurfaceShareLinksStore = defineStore('surfaceShareLinks', () => {
  const surfaceVuexStore = getVuexStore<RootState>() // For gradual conversion to pinia
  const surfaceStore = useSurfaceStore()
  const surfaceSectionsStore = useSurfaceSectionsStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceMapStore = useSurfaceMapStore()
  const globalInputDialogStore = useGlobalInputDialogStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfaceSettingsStore = useSurfaceSettingsStore()
  const queue = new PromiseQueue()

  /* Section Breakout */
  // State
  const sectionBreakoutLinks = ref<Record<number, WallSectionBreakoutLink> | null>(null)

  // Getters
  const sectionBreakoutIdentifier = computed<string | null>(() => surfaceVuexStore?.getters.sectionBreakoutIdentifier)

  // Actions
  async function enableGroupBySections(): Promise<void> {
    try {
      await surfaceSettingsStore.toggleGroupBySection(true)
      void fetchSectionBreakoutLinks()
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e)
    }
  }
  function setSectionBreakoutLinksState(links: WallSectionBreakoutLink[] | null): void {
    if (links !== null) {
      sectionBreakoutLinks.value = keyBy(links, 'wall_section_id')
    } else {
      sectionBreakoutLinks.value = null
    }
  }

  async function fetchSectionBreakoutLinks(): Promise<void> {
    if (surfaceStore.isSectionBreakout || surfaceStore.isSubmissionRequest || !surfacePermissionsStore.canIAdminister) {
      // early exit if not supposed to show section breakout links
      return
    }
    try {
      const links = await PadletApi.SectionBreakout.list(surfaceStore.wallId)
      if (links == null) {
        setSectionBreakoutLinksState(null)
        return
      }
      sectionBreakoutLinks.value = keyBy(links, 'wall_section_id')
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelListSectionBreakoutLinks' })
    }
  }

  async function copySectionBreakoutLinkAndAlert(sectionId: number): Promise<void> {
    if (sectionBreakoutLinks.value === null) return

    await useCopyToClipboard().copyToClipboard({
      text: sectionBreakoutLinks.value[sectionId].link,
      globalSnackbarOptions: {
        message: __('Link copied to clipboard'),
        notificationType: SnackbarNotificationType.success,
      },
    })
  }

  async function copySectionBreakoutPostLinkAndAlert(postId: string): Promise<void> {
    if (sectionBreakoutIdentifier.value !== null) {
      const sectionBreakoutPostUrl = getSectionBreakoutPostUrl(
        surfaceStore.namespace,
        sectionBreakoutIdentifier.value,
        postId,
      )
      await useCopyToClipboard().copyToClipboard({
        text: sectionBreakoutPostUrl,
        globalSnackbarOptions: {
          message: __('Link copied to clipboard'),
          notificationType: SnackbarNotificationType.success,
        },
      })
    }
  }

  async function navigateToSectionBreakoutDisabledPage(): Promise<void> {
    if (sectionBreakoutIdentifier.value !== null) {
      navigateTo(getSectionBreakoutDisabledUrl())
    }
  }

  function showSectionInputDialog(): void {
    const title = __('Section %{number}', {
      number: surfaceSectionsStore.numOfSections + 1,
    })

    const payload = {
      title: __('New section'),
      inputValue: title,
      shouldFadeIn: true,
      inputPlaceholder: 'Section title',
      inputAriaLabel: __('Section title'),
      submitButtonText: 'Create',
      submitActions: [
        async ({ inputValue }) => {
          await surfaceSectionsStore.createSectionWithName(inputValue.trim())
        },
      ],
      validationActions: [
        ({ inputValue }) => {
          globalInputDialogStore.setSubmitButtonDisable(inputValue.trim().length === 0)
        },
      ],
    }
    globalInputDialogStore.openInputDialog(payload)
  }

  // Handler for disabling from an external source, like a realtime message or freezing a padlet
  async function handleSectionBreakoutDisabled(): Promise<void> {
    if (surfaceStore.isSectionBreakout) {
      await navigateToSectionBreakoutDisabledPage()
      return
    }
    setSectionBreakoutLinksState(null)
  }

  async function handleSectionBreakoutUpdated(): Promise<void> {
    return await fetchSectionBreakoutLinks()
  }

  /* Submission Request */
  // State
  const isEnablingSubmissionRequest = ref(false)
  const isSubmissionRequestEnabled = ref(false)
  const submissionRequestLink = ref<string | null>(null)
  const submissionRequestRequiresLogin = ref<boolean | null>(null)
  const submissionRequestRedirectLocation = ref<string | null>(null)
  const submissionRequestConfirmationText = ref<string>()
  const disableSubmissionRequestTimer = ref<ReturnType<typeof setTimeout> | undefined>()
  const disableSubmissionRequestSnackbarUid = ref<string | null>(null)

  // Getters
  const submissionRequestSectionId = computed<number | null>(() => {
    if (!surfaceStore.isSubmissionRequest) return null

    const sectionIdParam = getSearchParam('section')
    if (sectionIdParam == null) return null

    // Validate that section exists
    const sectionId = parseInt(sectionIdParam)
    const section = surfaceSectionsStore.getSectionById(sectionId)
    return section == null ? null : sectionId
  })

  // Actions
  function setSubmissionRequestAttributes(linkAttributes: WallSubmissionRequestLink | null): void {
    if (linkAttributes != null) {
      submissionRequestLink.value = linkAttributes.link
      submissionRequestRequiresLogin.value = linkAttributes.requires_login
      submissionRequestRedirectLocation.value = linkAttributes.redirect_location
      submissionRequestConfirmationText.value =
        linkAttributes?.confirmation_text ?? DEFAULT_SUBMISSION_REQUEST_CONFIRMATION_TEXT
      isSubmissionRequestEnabled.value = true
    } else {
      submissionRequestLink.value = null
      submissionRequestRequiresLogin.value = null
      submissionRequestRedirectLocation.value = null
      submissionRequestConfirmationText.value = undefined
      isSubmissionRequestEnabled.value = false
    }
  }

  async function fetchSubmissionRequestLink(): Promise<void> {
    // if user is not admin & not in submission request view, we perform early exit to avoid showing section breakout links
    if (
      (surfaceStore.isSectionBreakout || !surfacePermissionsStore.canIAdminister) &&
      !surfaceStore.isSubmissionRequest
    ) {
      return
    }

    try {
      const linkAttributes = await PadletApi.SubmissionRequest.fetch(surfaceStore.wallId)

      if (linkAttributes == null) {
        setSubmissionRequestAttributes(null)

        if (surfaceStore.isSubmissionRequest) {
          // Redirect user if is in submissionRequestPage that no longer exists
          void navigateToSubmissionRequestDisabledPage()
        }

        return
      }
      if (surfaceStore.isSubmissionRequest && linkAttributes.requires_login && !isRegistered(surfaceStore.user)) {
        navigateTo(authWithReferrerUrl(currentUrl()))
        return
      }

      setSubmissionRequestAttributes(linkAttributes)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelFetchSubmissionRequestLink' })
    }
  }

  async function enableSubmissionRequest(): Promise<void> {
    // Clear any existing callbacks to disable
    if (disableSubmissionRequestTimer.value !== undefined) clearTimeout(disableSubmissionRequestTimer.value)
    globalSnackbarStore.removeSnackbar(disableSubmissionRequestSnackbarUid.value ?? '')

    const linkAttributes = await PadletApi.SubmissionRequest.enable(
      surfaceStore.wallId,
      DEFAULT_SUBMISSION_REQUEST_CONFIRMATION_TEXT,
    )
    setSubmissionRequestAttributes(linkAttributes)
  }

  async function disableSubmissionRequest(): Promise<void> {
    try {
      await PadletApi.SubmissionRequest.disable(surfaceStore.wallId)

      setSubmissionRequestAttributes(null)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelDisableSubmissionRequest' })
    }
  }

  async function disableSubmissionRequestWithUndo(): Promise<void> {
    const SNACKBAR_DURATION = 3000

    disableSubmissionRequestSnackbarUid.value = globalSnackbarStore.setSnackbar({
      notificationType: SnackbarNotificationType.success,
      message: __('Submission request link closed'),
      actionText: 'Undo',
      persist: true,
      actionTextActions: [
        () => {
          // revert and close snackbar
          isSubmissionRequestEnabled.value = true
          if (disableSubmissionRequestTimer.value !== undefined) clearTimeout(disableSubmissionRequestTimer.value)
          globalSnackbarStore.removeSnackbar(disableSubmissionRequestSnackbarUid.value ?? '')
        },
      ],
    })

    // To hide Submission Request link first to give illusion of deletion
    isSubmissionRequestEnabled.value = false

    // Timeout to close snackbar and disable section breakout links
    disableSubmissionRequestTimer.value = setTimeout(() => {
      globalSnackbarStore.removeSnackbar(disableSubmissionRequestSnackbarUid.value ?? '')
      void disableSubmissionRequest()
    }, SNACKBAR_DURATION)
  }

  function askToDisableSubmissionRequest(): void {
    void useGlobalConfirmationDialogStore().openConfirmationDialog({
      ...CONFIRM_TURN_OFF,
      title: __('Turn off submission request link?'),
      body: __('Current link will no longer work. Turning link back on will create a new link.'),
      afterConfirmActions: [disableSubmissionRequestWithUndo],
    })
  }

  async function toggleSubmissionRequest(): Promise<void> {
    const originalValue = isSubmissionRequestEnabled.value
    try {
      // don't allow changes if mid-change
      if (isEnablingSubmissionRequest.value) return

      if (isSubmissionRequestEnabled.value) {
        await askToDisableSubmissionRequest()
      } else {
        // Only show loading state if toggling to True
        isEnablingSubmissionRequest.value = true
        await enableSubmissionRequest()
        isEnablingSubmissionRequest.value = false
      }
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelToggleSubmissionRequest' })
      isSubmissionRequestEnabled.value = originalValue
    }
  }

  function getSubmissionRequestProps(
    payload: { requiresLogin?: boolean; redirectLocation?: string; confirmationText?: string } = {},
  ): WallSubmissionRequestProps | null {
    const requiresLogin =
      payload.requiresLogin !== undefined ? payload.requiresLogin : submissionRequestRequiresLogin.value
    const redirectLocation =
      payload.redirectLocation !== undefined ? payload.redirectLocation : submissionRequestRedirectLocation.value
    const confirmation_text =
      payload.confirmationText !== undefined ? payload.confirmationText : submissionRequestConfirmationText.value

    if (requiresLogin == null || redirectLocation == null) return null

    return {
      requires_login: requiresLogin,
      redirect_location: redirectLocation,
      confirmation_text: confirmation_text ?? DEFAULT_SUBMISSION_REQUEST_CONFIRMATION_TEXT,
    }
  }

  async function updateSubmissionRequestProps(
    oldProps: WallSubmissionRequestProps,
    newProps: WallSubmissionRequestProps,
  ): Promise<void> {
    try {
      const response = await PadletApi.SubmissionRequest.update(surfaceStore.wallId, {
        requires_login: newProps.requires_login,
        redirect_location: newProps.redirect_location,
        confirmation_text: newProps.confirmation_text,
      })
      submissionRequestConfirmationText.value = response.confirmation_text
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceSharePanelUpdateSubmissionRequestProps' })
      submissionRequestRedirectLocation.value = oldProps.redirect_location
      submissionRequestRequiresLogin.value = oldProps.requires_login
      submissionRequestConfirmationText.value = oldProps.confirmation_text
    }
  }

  async function enqueueToggleSubmissionRequestRequiresLogin(): Promise<void> {
    if (submissionRequestRequiresLogin.value === null) return

    const oldProps = getSubmissionRequestProps()
    const newProps = getSubmissionRequestProps({ requiresLogin: !submissionRequestRequiresLogin.value })

    if (oldProps === null || newProps === null) return

    // Optimistically update new value on the frontend immediately, before queueing the update request to the backend, for faster response
    submissionRequestRequiresLogin.value = !submissionRequestRequiresLogin.value

    void queue.enqueue(
      'updateSubmissionRequestProps',
      async () => await updateSubmissionRequestProps(oldProps, newProps),
    )
  }

  async function enqueueUpdateSubmissionRequestRedirectLocation(redirectLocation: string): Promise<void> {
    if (submissionRequestRedirectLocation.value === null) return
    if (submissionRequestRedirectLocation.value === redirectLocation) return

    const oldProps = getSubmissionRequestProps()
    const newProps = getSubmissionRequestProps({ redirectLocation })

    if (oldProps === null || newProps === null) return

    // Optimistically update new value on the frontend immediately, before queueing the update request to the backend, for faster response
    submissionRequestRedirectLocation.value = redirectLocation

    void queue.enqueue(
      'updateSubmissionRequestProps',
      async () => await updateSubmissionRequestProps(oldProps, newProps),
    )
  }

  async function enqueueUpdateSubmissionConfirmationText(confirmationText: string): Promise<void> {
    if (submissionRequestConfirmationText.value === confirmationText) return

    const oldProps = getSubmissionRequestProps()
    const newProps = getSubmissionRequestProps({ confirmationText })

    if (oldProps === null || newProps === null) return

    // Optimistically update new value on the frontend immediately, before queueing the update request to the backend, for faster response
    submissionRequestConfirmationText.value = confirmationText

    void queue.enqueue(
      'updateSubmissionRequestProps',
      async () => await updateSubmissionRequestProps(oldProps, newProps),
    )
  }

  async function copySubmissionRequestLinkAndAlert(): Promise<void> {
    if (submissionRequestLink.value === null) return

    await useCopyToClipboard().copyToClipboard({
      text: submissionRequestLink.value,
      globalSnackbarOptions: {
        message: __('Link copied to clipboard'),
        notificationType: SnackbarNotificationType.success,
      },
    })
  }

  async function showPostPublishedSnackbar(): Promise<void> {
    void globalSnackbarStore.setSnackbar({
      message: __('Post published'),
      notificationType: SnackbarNotificationType.success,
    })
  }

  async function startNewSubmissionRequest(): Promise<void> {
    if (surfaceStore.isMap) {
      const attributes: { postCid?: Cid; sectionId?: Id } = {}
      if (submissionRequestSectionId.value !== null) {
        attributes.sectionId = submissionRequestSectionId.value
      }
      surfaceMapStore.startPickingLocation(attributes)
    } else {
      const attributes: PostAttributes = {}
      attributes.wall_section_id = submissionRequestSectionId.value ?? surfaceSectionsStore.defaultSectionId
      void surfacePostsStore.startNewPost({ attributes })
    }
  }

  async function executeSubmissionRequestPostSubmitAction(): Promise<void> {
    switch (submissionRequestRedirectLocation.value) {
      case SubmissionRequestRedirectLocation.Padlet:
        navigateTo(surfaceStore.links.show)
        break
      case SubmissionRequestRedirectLocation.Confirmation:
        void navigateToSubmissionRequestConfirmationPage()
        break
      case SubmissionRequestRedirectLocation.RequestAgain:
        void showPostPublishedSnackbar()
        void startNewSubmissionRequest()
        break
    }
  }

  async function copySubmissionRequestSectionLinkAndAlert(sectionId: number): Promise<void> {
    if (submissionRequestLink.value === null) return

    await useCopyToClipboard().copyToClipboard({
      text: transformUrl(submissionRequestLink.value, {
        search: { section: sectionId.toString() },
      }),
      globalSnackbarOptions: {
        message: __('Link copied to clipboard'),
        notificationType: SnackbarNotificationType.success,
      },
    })
  }

  async function navigateToSubmissionRequestDisabledPage(): Promise<void> {
    navigateTo(getSubmissionRequestDisabledUrl())
  }

  async function navigateToSubmissionRequestConfirmationPage(): Promise<void> {
    if (submissionRequestLink.value !== null) {
      navigateTo(getSubmissionRequestConfirmationUrl(submissionRequestLink.value))
    }
  }

  async function handleSubmissionRequestUpdated(): Promise<void> {
    return await fetchSubmissionRequestLink()
  }

  // Should always have section breakout links enabled when sections are enabled
  void unboundedWatchEffect(() => {
    if (surfaceSectionsStore.wallHasSections && sectionBreakoutLinks.value == null) {
      void fetchSectionBreakoutLinks()
    }
  })

  return {
    /* Section Breakout */
    // State
    sectionBreakoutLinks,
    sectionBreakoutIdentifier,

    // Actions
    enableGroupBySections,
    fetchSectionBreakoutLinks,
    copySectionBreakoutLinkAndAlert,
    showSectionInputDialog,
    setSectionBreakoutLinksState,
    copySectionBreakoutPostLinkAndAlert,
    navigateToSectionBreakoutDisabledPage,
    handleSectionBreakoutDisabled,
    handleSectionBreakoutUpdated,

    /* Submission Request */
    // State
    isEnablingSubmissionRequest,
    isSubmissionRequestEnabled,
    submissionRequestLink,
    submissionRequestRequiresLogin,
    submissionRequestRedirectLocation,
    submissionRequestConfirmationText,

    // Getters
    submissionRequestSectionId,

    // Actions
    fetchSubmissionRequestLink,
    setSubmissionRequestAttributes,
    toggleSubmissionRequest,
    enqueueToggleSubmissionRequestRequiresLogin,
    enqueueUpdateSubmissionRequestRedirectLocation,
    enqueueUpdateSubmissionConfirmationText,
    copySubmissionRequestLinkAndAlert,
    copySubmissionRequestSectionLinkAndAlert,
    startNewSubmissionRequest,
    executeSubmissionRequestPostSubmitAction,
    showPostPublishedSnackbar,
    handleSubmissionRequestUpdated,
  }
})
