/*
 * @file Helper to get the correct comment state from backend's data and what's currently in the frontend
 */
import { idsToDeleteInPage } from '@@/vuexstore/helpers/pagination_reconcile'
import type { Comment } from '@padlet/arvo'
import { pickBy } from 'lodash-es'

type CommentId = string | number

// reconcile newly fetched comment data with current state to get the correct state
function reconcileCommentsAfterFetch({
  context,
  newComments,
  isFirstPage,
  isLastPage,
  shouldResetState,
}): Record<CommentId, Comment> {
  const { state, getters } = context
  const isOnlyPage = isFirstPage && isLastPage
  const newCommentEntities = shouldResetState || isOnlyPage ? {} : { ...state.commentEntities }

  // we are paginating the comment API by id so newComments from fetched data only contains 1 page
  // during auto refresh, if a comment id that's supposed to be in the current page exists in the current state but not in newComments means it has been deleted
  // => we have to remove it from newCommentEntities
  if (
    !shouldResetState && // we are only updating the state, i.e auto refresh or appending new page
    getters.isIntialCommentsLoadDone && // Don't try to remove anything when the first page load is not done
    newComments.length
  ) {
    const idsToDelete = idsToDeleteInPage(isFirstPage, isLastPage, newComments, newCommentEntities)
    idsToDelete.forEach((id) => {
      delete newCommentEntities[id]
    })
  }

  newComments.forEach((commentData) => {
    const comment = commentData.attributes
    newCommentEntities[comment.id] = comment
  })

  return newCommentEntities
}

function reconcileCommentsForPostAfterFetch({
  context,
  newComments,
  wishId,
  isFirstPage,
  isLastPage,
  shouldResetState,
}): Record<CommentId, Comment> {
  const { state, getters } = context
  const isOnlyPage = isFirstPage && isLastPage
  const newCommentEntities =
    shouldResetState || isOnlyPage ? {} : pickBy(state.commentEntities, (comment) => comment.wish_id === wishId)

  // we are paginating the comment API by id so newComments from fetched data only contains 1 page
  // during auto refresh, if a comment id that's supposed to be in the current page exists in the current state but not in newComments means it has been deleted
  // => we have to remove it from newCommentEntities
  if (
    !shouldResetState && // we are only updating the state, i.e auto refresh or appending new page
    newComments.length
  ) {
    const idsToDelete = idsToDeleteInPage(isFirstPage, isLastPage, newComments, newCommentEntities)
    idsToDelete.forEach((id) => {
      delete newCommentEntities[id]
    })
  }

  newComments.forEach((commentData) => {
    const comment = commentData.attributes
    newCommentEntities[comment.id] = comment
  })

  return newCommentEntities
}

export { reconcileCommentsAfterFetch, reconcileCommentsForPostAfterFetch }
