// @file The store for the filter logic in templates gallery on the dashboard.
import { __ } from '@@/bits/intl'
import type { OzTabsWithSeparatorButtonProps } from '@@/library/v4/components/OzTabsWithSeparator.vue'
import { useDashGalleryStore } from '@@/pinia/dash_gallery_store'
import type { FilterGroup, TemplateFilters, WallGalleryTemplate } from '@@/types'
import Fuse from 'fuse.js'
import { intersectionBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDashGalleryFilterStore = defineStore('dashGalleryFilterStore', () => {
  const dashGalleryStore = useDashGalleryStore()

  // State
  const selectedCategories = ref<string[]>([])
  const selectedGrades = ref<string[]>([])
  const searchQuery = ref<string>('')
  const isMobileFilterModalOpen = ref<boolean>(false)

  // Getters
  // Extracting all the categories into an array
  const allCategories = computed<string[]>(() => {
    return dashGalleryStore.currentAudienceFilteredTemplates.reduce(
      (categories: string[], template: WallGalleryTemplate) => {
        template.category?.forEach((category) => {
          if (!categories.includes(category) && category !== 'Popular') {
            categories.push(category)
          }
        })
        return categories.sort()
      },
      [],
    )
  })

  // Map of all the categories with a boolean value to indicate if it is selected
  const categoriesSelectedMap = computed(() => {
    return allCategories.value.reduce((selected, category) => {
      selected[category] = selectedCategories.value.includes(category)
      return selected
    }, {})
  })

  // Helper function to sort grades
  function sortGrades(a, b): number {
    if (a === 'K') return -1
    if (b === 'K') return 1
    if (a === 'Higher ed') return 1
    if (b === 'Higher ed') return -1
    return parseInt(a) - parseInt(b)
  }

  // Extracting all the grades into an array
  const allGrades = computed(() => {
    return dashGalleryStore.currentAudienceFilteredTemplates.reduce(
      (grades: string[], template: WallGalleryTemplate) => {
        template.grades?.forEach((grade) => {
          if (!grades.includes(grade)) {
            grades.push(grade)
          }
        })
        return grades.sort(sortGrades)
      },
      [],
    )
  })

  // Map of all the grades with a boolean value to indicate if it is selected
  const gradesSelectedMap = computed(() => {
    return allGrades.value.reduce((selected, grade) => {
      selected[grade] = selectedGrades.value.includes(grade)
      return selected
    }, {})
  })

  const allAudiences: OzTabsWithSeparatorButtonProps[] = [
    {
      text: __('General'),
      key: 'general',
      testId: 'generalTab',
    },
    {
      text: __('Education'),
      key: 'education',
      testId: 'educationTab',
    },
    {
      text: __('Business'),
      key: 'business',
      testId: 'businessTab',
    },
  ]

  const isEducationAudience = (audience: string): boolean => {
    return audience === 'education'
  }

  const isAudienceValid = (audience: string): boolean => {
    if (audience === null) return false
    return allAudiences.some((a) => a.key === audience)
  }

  const audienceSelectedMap = computed(() => {
    return allAudiences.reduce((selected, audience) => {
      selected[audience.key] = audience.key === dashGalleryStore.selectedAudience
      return selected
    }, {})
  })

  const hasSearchQuery = computed(() => {
    return searchQuery.value.length > 0
  })

  const isSelectedCategoriesEmpty = computed(() => {
    return selectedCategories.value.length === 0
  })

  const isSelectedGradesEmpty = computed(() => {
    return selectedGrades.value.length === 0
  })

  const isFilterEmpty = computed(() => {
    return isSelectedCategoriesEmpty.value && isSelectedGradesEmpty.value && !hasSearchQuery.value
  })

  const xGradesFilter = computed(() => {
    return dashGalleryStore.selectedAudience === 'education'
  })

  // Actions
  function updateSelectedCategories(category: string): void {
    const index = selectedCategories.value.indexOf(category)
    if (index === -1) {
      // Add category if not already selected
      selectedCategories.value.push(category)
    } else {
      // Remove category if already selected
      selectedCategories.value.splice(index, 1)
    }
  }

  function updateSelectedGrades(grades: string | string[]): void {
    const gradesArray = Array.isArray(grades) ? grades : [grades]
    gradesArray.forEach((grade) => {
      const index = selectedGrades.value.indexOf(grade)
      if (index === -1) {
        selectedGrades.value.push(grade)
      } else {
        selectedGrades.value.splice(index, 1)
      }
    })
  }

  function updateSearchQuery(query: string): void {
    searchQuery.value = query
  }

  function resetCategories(): void {
    selectedCategories.value = []
  }

  function resetGrades(): void {
    selectedGrades.value = []
  }

  function resetSearch(): void {
    searchQuery.value = ''
  }

  function resetAllFilters(): void {
    resetCategories()
    resetGrades()
    resetSearch()
  }

  function toggleMobileFilterModal(): void {
    isMobileFilterModalOpen.value = !isMobileFilterModalOpen.value
  }

  /**
   * Returns a predicate function for the given filter key in the given group.
   * The predicate function is used to tell if a template matches the filter.
   */
  function getFilterPredicateForGroup<G extends FilterGroup>(
    group: G,
    filterKey: string,
  ): (t: WallGalleryTemplate) => boolean {
    switch (group) {
      // Ensure a boolean value is returned by using `|| []` to handle undefined
      case 'grade':
        return (t: WallGalleryTemplate) => (t.grades ?? []).includes(filterKey)
      case 'category':
        return (t: WallGalleryTemplate) => (t.category ?? []).includes(filterKey)
      default:
        // TypeScript shouldn't let `group` to be none of the above values.
        // But the `case switch` syntax doesn't understand that.
        return () => true
    }
  }

  /**
   * Returns templates that match any of the filters in the given group (OR logic).
   */
  function filterTemplatesByGroup<G extends FilterGroup>(
    templates: WallGalleryTemplate[],
    filters: TemplateFilters,
    group: G,
  ): WallGalleryTemplate[] {
    const filterGroup = filters[group]
    const allFilters = Object.keys(filterGroup)
    const selectedFilters = allFilters.filter((key) => filterGroup[key])
    if (selectedFilters.length === 0) return templates
    const results: WallGalleryTemplate[] = []
    selectedFilters.forEach((key) => {
      results.push(...templates.filter((p) => getFilterPredicateForGroup(group, key)(p)))
    })
    return results
  }

  /**
   * Returns templates that match all set of filters by groups (AND logic).
   */
  function filterTemplates(templates: WallGalleryTemplate[], filters: TemplateFilters): WallGalleryTemplate[] {
    return intersectionBy(
      filterTemplatesByGroup(templates, filters, 'grade'),
      filterTemplatesByGroup(templates, filters, 'category'),
      // We use `publicKey` as the unique identifier for templates since id is not available on dev
      'publicKey',
    )
  }

  /**
   * Returns templates that match the given audience.
   */
  function filterTemplatesByAudience(templates: WallGalleryTemplate[], audience: string): WallGalleryTemplate[] {
    return templates.filter((template) => template.audience === audience)
  }

  /**
   * Using Fuse.js to search through the templates through fuzzy search
   * https://fusejs.io/
   */
  function filterBySearch(templates: WallGalleryTemplate[]): WallGalleryTemplate[] {
    if (!hasSearchQuery.value) return templates
    const fuseOptions = {
      keys: ['title', 'templateSynonyms', 'description'],
      threshold: 0.3, // Setting it low to get more relevant results
    }

    const fuse = new Fuse(templates, fuseOptions)
    // Mapping the results to get the original template object
    return fuse.search(searchQuery.value).map((result) => result.item)
  }

  return {
    // State
    selectedCategories,
    selectedGrades,
    searchQuery,
    isMobileFilterModalOpen,
    // Getters
    allCategories,
    categoriesSelectedMap,
    allGrades,
    gradesSelectedMap,
    allAudiences,
    audienceSelectedMap,
    isSelectedCategoriesEmpty,
    isSelectedGradesEmpty,
    hasSearchQuery,
    isFilterEmpty,
    xGradesFilter,
    // Actions,
    updateSelectedCategories,
    updateSelectedGrades,
    updateSearchQuery,
    resetCategories,
    resetGrades,
    resetSearch,
    resetAllFilters,
    toggleMobileFilterModal,
    filterTemplates,
    filterTemplatesByAudience,
    filterBySearch,
    isAudienceValid,
    isEducationAudience,
  }
})
