// @file Helper functions for working with hashid
// We use methods to check against existing formats that we expect. If a string doesn't follow an existing format
// we assume its a hashid.
// Since hashid is alphanumeric and can have variable length, there's no way fixed way to verify

// Defined in rails/config/initializers/hashids.rb
const MIN_HASH_LENGTH = 16

// Check if a string has a format that resembles an id
function isId(id: string): boolean {
  return /^\d+$/.test(id) || id.length < MIN_HASH_LENGTH
}

// Check if a string has a format that resembles cid
function isCid(cid: string): boolean {
  return /^c\d+$/.test(cid) || cid.length < MIN_HASH_LENGTH
}

// Remove prefix from hashid. Assume format of {hashid_prefix}_{hashid}
function removeHashidPrefix(hashid: string): string {
  return hashid.split('_')[1]
}

export { isId, isCid, removeHashidPrefix }
