// @file Surface drafts store
import { trackEvent } from '@@/bits/analytics'
import { __ } from '@@/bits/intl'
import { POST_BODY_CHARACTER_LIMIT, POST_SUBJECT_CHARACTER_LIMIT } from '@@/bits/numbers'
import { partitionedDebounce } from '@@/bits/partitioned_debounce'
import { addDraftCidIfAbsent, shouldSyncDraftUpdates } from '@@/bits/post_drafts'
import { hasSameCustomProperties, isPostEmpty } from '@@/bits/post_properties'
import PromiseQueue from '@@/bits/promise_queue'
import { getPollFromPost } from '@@/bits/surface_polls'
import { vDel, vSet } from '@@/bits/vue'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useNativeAppStore } from '@@/pinia/native_app'
import { usePostComposerModalStore } from '@@/pinia/post_composer_modal_store'
import { useSurfaceStore } from '@@/pinia/surface'
import { ContributionType, useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import PadletApi from '@@/surface/padlet_api'
import type { AttachmentProps, Cid, DraftPost, Id, Post, PostAttributes } from '@@/types'
import { usePostBuilder } from '@@/vuecomposables/surface_post_builder'
import { usePostAttachmentUploader } from '@@/vuecomposables/usePostAttachmentUploader'
import { hasAttachment } from '@@/vuexstore/helpers/post'
import { cloneDeep, isEmpty, isEqual, omitBy, sortBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum DraftSaveState {
  Unsaved = 'unsaved',
  Saved = 'saved',
  Creating = 'creating',
  Updating = 'updating',
  Deleting = 'deleting',
}

interface DraftSyncState {
  [cid: Cid]:
    | {
        state: DraftSaveState
        loading: boolean
        error: boolean
      }
    | undefined
}
const DEBOUNCE_WAIT = 1500
const DRAFT_SYNC_DEBOUNCE_TIMERS = new Map<string, number>()
function clearDraftSyncDebounceTimer(cid: Cid): void {
  clearTimeout(DRAFT_SYNC_DEBOUNCE_TIMERS.get(cid))
}

const autoSaveQueueKey = (cid: Cid): string => {
  return `syncDraft:${cid}`
}

export const useSurfaceDraftsStore = defineStore('surfaceDrafts', () => {
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceStore = useSurfaceStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfaceSectionsStore = useSurfaceSectionsStore()
  const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
  const surfaceGuestStore = useSurfaceGuestStore()
  const postComposerModalStore = usePostComposerModalStore()

  const { startUploadFile, stopUploadFile } = usePostAttachmentUploader()

  /* ---------------------- */
  /* DRAFT GETTERS          */
  /* ---------------------- */
  const draftByCid = ref<Record<Cid, DraftPost>>({})
  const draftById = computed<Record<Id, DraftPost>>(() =>
    Object.values(draftByCid.value).reduce<Record<Id, DraftPost>>((accumm, draft) => {
      if (draft.id != null) {
        accumm[draft.id] = draft
      }
      return accumm
    }, {}),
  )

  const draftCids = computed<Cid[]>(() => {
    const cids = sortBy(
      Object.keys(draftByCid.value),
      (postCid) => -1 * new Date(draftByCid.value[postCid].created_at ?? 0).valueOf(),
    )
    if (surfaceStore.isSectionBreakout) {
      return cids.filter((cid) => getDraftFromCid(cid)?.wall_section_id === surfaceStore.breakoutSectionId)
    }
    return cids
  })

  function isCidBeingDrafted(cid: Cid): boolean {
    return draftByCid.value[cid] != null
  }
  function getDraftFromCid(cid: Cid): DraftPost | null {
    return draftByCid.value[cid] ?? null
  }

  const getAttachmentPropsFromDraft = (draftCid: Cid): AttachmentProps | null => {
    return draftByCid.value[draftCid]?.wish_content?.attachment_props ?? null
  }

  /* ---------------------- */
  /* ACTIVE DRAFT STATES    */
  /* ---------------------- */
  const activeDraft = computed<DraftPost | null>(() =>
    surfacePostsStore.postBeingEditedCid != null ? draftByCid.value[surfacePostsStore.postBeingEditedCid] : null,
  )
  const activeDraftCid = computed<Cid | null>(() => activeDraft.value?.cid ?? null)
  const isAnyDraftActive = computed<boolean>(() => activeDraftCid.value != null)
  const isActiveDraftNewPost = computed<boolean>(() =>
    activeDraft.value == null ? false : !surfacePostsStore.isExistingPost(activeDraft.value.cid),
  )
  const activeDraftUploadingFile = computed<File | undefined | null>(() => activeDraft.value?.uploadingFile)

  function isCidActiveDraft(cid: Cid): boolean {
    return cid != null && activeDraftCid.value === cid
  }

  /* ---------------------- */
  /* EDIT DRAFTS            */
  /* ---------------------- */

  const postOnlyHasDefaultValues = (cid: Cid): boolean => {
    // remove all default values
    // if post is empty after then post only had default values
    const currentDraft = cloneDeep(draftByCid.value[cid])
    if (currentDraft == null) return false

    // remove default values from custom properties
    const customPropertiesNoDefaultValues = omitBy(currentDraft.custom_properties, (value, key) => {
      if (surfacePostPropertiesStore.wallSingleSelectDefaultsByCustomPropertyId[key] === value) {
        return true
      }
      return false
    })
    currentDraft.custom_properties = customPropertiesNoDefaultValues
    return isPostEmpty(currentDraft)
  }

  const isDraftDirty = (cid: Cid): boolean => {
    const currentDraft = draftByCid.value[cid]
    if (currentDraft == null) return false
    const currentDraftEntity = {
      subject: currentDraft?.subject,
      body: currentDraft?.body,
      attachment: currentDraft?.attachment,
      attachment_caption: currentDraft?.attachment_caption,
      alternative_text: currentDraft?.wish_content?.attachment_props?.alternative_text,
      scheduled_at: currentDraft?.scheduled_at,
      poll: currentDraft != null ? getPollFromPost(currentDraft) : null,
    }
    const existingPost = surfacePostsStore.postEntitiesByCid[cid]
    const existingPostEntity: typeof currentDraftEntity = {
      subject: existingPost?.subject,
      body: existingPost?.body,
      attachment: existingPost?.attachment,
      attachment_caption: existingPost?.attachment_caption,
      alternative_text: existingPost?.wish_content?.attachment_props?.alternative_text,
      scheduled_at: existingPost?.scheduled_at,
      poll: existingPost != null ? getPollFromPost(existingPost) : null,
    }
    return (
      !isEqual(currentDraftEntity, existingPostEntity) ||
      !hasSameCustomProperties(currentDraft, existingPost ?? {}) ||
      currentDraft?.uploadingFile != null
    )
  }

  function startEditingDraft(draftCid: Cid): void {
    // startEditingPost works on both drafts and posts, depending on their cid
    // if cid is draft, it is set as the active draft. if post, it copies an existing post and sets it to the active draft.
    surfacePostsStore.startEditingPost({ postCid: draftCid })
  }

  // stop editing draft, but doesn't discard it. for eg when minimizing composer
  function stopEditingDraft(): void {
    surfacePostsStore.stopEditingPost()
  }

  // handler for when a user clicks "close" on the composer. discards draft currently being edited
  async function cancelEditingDraft(): Promise<void> {
    if (activeDraftCid.value == null) return
    await removeDraft(activeDraftCid.value)
  }

  function removeDraftAttachment(postCid: Cid): void {
    if (draftByCid.value[postCid] == null) return
    updateDraft({ cid: postCid, attachment: '', attachment_caption: null })
    if (draftByCid.value[postCid].wish_content != null) {
      const post = draftByCid.value[postCid]
      vSet(draftByCid.value, postCid, {
        ...post,
        wish_content: {
          ...post.wish_content,
          attachment_props: null,
        },
      })
    }
  }

  function removeDraftCustomProperties(postCid: Cid): void {
    if (draftByCid.value[postCid] == null) return
    updateDraft({ cid: postCid, custom_properties: null, hasCustomPropertyErrors: false })
  }

  function setDraftScheduledAt(cid: Cid, scheduledAt: string): void {
    updateDraft({
      cid,
      scheduled_at: new Date(scheduledAt).toISOString(),
    })
  }

  function removeDraftScheduledAt(cid: Cid): void {
    updateDraft({
      cid,
      scheduled_at: null,
    })
  }

  function uploadFileActiveDraft({ file, maxFileUploadSize }: { file: File; maxFileUploadSize?: number }): void {
    if (activeDraftCid.value == null) return
    startUploadFileInDraft({ file, maxFileUploadSize, cid: activeDraftCid.value })
  }

  function stopUploadFileActiveDraft(): void {
    if (activeDraftCid.value == null) return
    stopUploadFileInDraft(activeDraftCid.value)
  }

  const autosaveDraftToBeUpdated = (newDraft: DraftPost, existingDraft: DraftPost): void => {
    if (
      surfaceStore.isSubmissionRequest || // Don't save draft when making submission request
      surfacePostsStore.isExistingPost(newDraft.cid) || // draft of existing post, don't need to autosave
      (!surfaceGuestStore.shouldEnableAnonymousAttribution && !surfacePermissionsStore.amIRegistered) // Only registered users get saved drafts
    ) {
      return
    }

    if (autoSaveQueueByCid.value[newDraft.cid] == null) {
      vSet(autoSaveQueueByCid.value, newDraft.cid, new PromiseQueue())
    }

    if (draftSyncState.value[newDraft.cid] == null) {
      vSet(draftSyncState.value, newDraft.cid, {
        state: newDraft.id == null ? DraftSaveState.Unsaved : DraftSaveState.Saved,
        loading: false,
        error: false,
      })
    }

    if (shouldSyncDraftUpdates({ newDraft, existingDraft })) {
      debouncedSyncDraft(newDraft)
    }

    // We also need to send surface state when performing autosave
    void useNativeAppStore().postSurfaceState()
  }

  function updateDraft(post: Post | DraftPost | PostAttributes): void {
    if (post.cid == null) return

    const existingDraft = draftByCid.value[post.cid]
    const newDraft = { ...existingDraft, ...post }

    // Always assign a section to the draft.
    if (newDraft.wall_section_id == null) {
      newDraft.wall_section_id =
        surfaceSectionsStore.mostRecentlyTouchedSectionId ?? surfaceSectionsStore.defaultSectionId
    }

    // At the same time, we need to set the wall_section_hashid
    const sectionHashid = surfaceSectionsStore.getSectionById(newDraft.wall_section_id ?? null)?.hashid
    if (sectionHashid != null) {
      newDraft.wall_section_hashid = sectionHashid
    }

    if (newDraft.draftCreatedAt == null) {
      newDraft.draftCreatedAt = Date.now()
    }

    vSet(draftByCid.value, newDraft.cid, newDraft)

    autosaveDraftToBeUpdated(newDraft, existingDraft)
  }

  function updateActiveDraft(post: Post | Partial<DraftPost> | PostAttributes): void {
    updateDraft({ ...activeDraft.value, ...post, cid: activeDraft.value?.cid ?? post.cid })
  }

  const updateDraftWishContentAttachmentProps = ({
    cid,
    url,
    attachmentProps,
  }: {
    cid: Cid
    url: string
    attachmentProps?: AttachmentProps
  }): void => {
    if (!isCidBeingDrafted(cid)) return
    if (attachmentProps != null) {
      updateDraft({
        cid,
        wish_content: {
          is_processed: true,
          attachment_props: attachmentProps,
        },
      })
    } else {
      updateDraft({
        cid,
        wish_content: {
          is_processed: false,
          attachment_props: { url },
        },
      })
    }
  }

  const startUploadFileInDraft = ({
    file,
    maxFileUploadSize,
    cid,
  }: {
    file: File
    maxFileUploadSize?: number
    cid: Cid
  }): void => {
    // if there is not custom maxFileUploadSize, the default limit based on user membership is used
    const maxUploadSize = maxFileUploadSize ?? surfaceStore.uploadLimit
    updateDraft({ cid, maxFileUploadSize: maxUploadSize, uploadingFile: file, uploadProgress: 0 })
    startUploadFile({ file, maxFileUploadSize: maxUploadSize, cid })
  }

  function stopUploadFileInDraft(cid: Cid): void {
    updateDraft({ cid, uploadingFile: null })
    stopUploadFile({ cid })
  }

  const replaceActiveDraftUploadingFile = ({ file }: { file: File }): void => {
    if (activeDraft.value?.attachment != null) {
      // The previous attachment upload/adding has finished, remove the existing attachment
      removeDraftAttachment(activeDraft.value.cid)
    } else if (activeDraftUploadingFile.value != null) {
      // Another file is being uploaded, stop that upload first
      stopUploadFileActiveDraft()
    }

    uploadFileActiveDraft({ file })
  }

  const replaceActiveDraftAttachmentWithLink = ({ link }: { link: string }): void => {
    // replace the active draft's attachment/uploading file when a link is dropped on to it
    if (activeDraft.value?.attachment != null) {
      // An attachment already exists or a file upload is done => remove it
      removeDraftAttachment(activeDraft.value.cid)
    } else if (activeDraftUploadingFile.value != null) {
      // A file is being uploaded, stop that upload first
      stopUploadFileActiveDraft()
    }

    updateActiveDraft({
      attachment: link,
      wish_content: {
        attachment_props: {
          url: link,
        },
        is_processed: false,
      },
    })
  }

  const reassignSections = ({ oldSectionId, newSectionId }: { oldSectionId: Id; newSectionId: Id }): void => {
    const draftsWithOldSection = Object.values(draftByCid.value).filter(
      (draft) => draft.wall_section_id === oldSectionId,
    )
    draftsWithOldSection.forEach((draft) => {
      updateDraft({
        cid: draft.cid,
        wall_section_id: newSectionId,
      })
    })
  }

  /* ---------------------- */
  /* PUBLISH DRAFTS         */
  /* ---------------------- */
  const publishDraft = async ({ cid }: { cid: Cid }): Promise<void> => {
    if (surfaceGuestStore.shouldShowGuestIdModal) {
      if (surfaceStore.isSubmissionRequest === true) {
        postComposerModalStore.minimizeAllComposerModals()
      }
      // Sync draft to make sure the draft has been synced and then it has an id
      await syncDraft(draftByCid.value[cid])
      surfaceGuestStore.showGuestIdModal({
        afterSaveActions: [async () => await publishDraft({ cid })],
        contributionType: ContributionType.DraftPost,
        contributionPayload: getDraftFromCid(cid) as DraftPost,
      })
      return
    } else if (surfaceGuestStore.shouldUpdateNameBeforePublishing) {
      surfaceGuestStore.setUserName(surfaceGuestStore.displayName)
      await surfaceGuestStore.updateSessionUserName({ shouldModerate: false })
    }
    const existingDraft = draftByCid.value[cid]
    if (existingDraft == null) return

    if (!surfacePermissionsStore.canIPost) return

    if (!areRequiredFieldsPresent(existingDraft)) {
      globalSnackbarStore.setSnackbar({
        message: __('Required fields must be filled to publish post'),
        notificationType: SnackbarNotificationType.error,
      })
      return
    }
    const existingPost = surfacePostsStore.postEntitiesByCid[cid]

    /**
     * We compare existingDraft with existingPost so only posts that already exceed character limit
     * can continue to exceed character limit
     */

    if (
      (existingPost?.subject == null || existingPost?.subject.length <= POST_SUBJECT_CHARACTER_LIMIT) &&
      existingDraft.subject != null &&
      existingDraft.subject.length > POST_SUBJECT_CHARACTER_LIMIT
    ) {
      globalSnackbarStore.setSnackbar({
        message: __('Post subject character limit reached'),
        notificationType: SnackbarNotificationType.error,
      })
      return
    }

    if (
      (existingPost?.body == null || existingPost?.body.length <= POST_BODY_CHARACTER_LIMIT) &&
      existingDraft.body != null &&
      existingDraft.body.length > POST_BODY_CHARACTER_LIMIT
    ) {
      globalSnackbarStore.setSnackbar({
        message: __('Post body character limit reached'),
        notificationType: SnackbarNotificationType.error,
      })
      return
    }

    // If a file is being uploaded in the draft, set it to auto publish
    if (existingDraft.uploadingFile != null) {
      autoPublishDraft({ cid })
    } else if (!isPostEmpty(existingDraft)) {
      surfacePostsStore.savePost({ attributes: existingDraft })
    } else {
      void surfacePostsStore.deletePost(cid)
    }
  }

  const areRequiredFieldsPresent = (draft: DraftPost): boolean => {
    const requiredPostFields = surfacePostPropertiesStore.requiredPostProperties
    const requiredCustomProps = surfacePostPropertiesStore.requiredWallCustomPostPropertiesById
    const requiredCustomPropsIds = Object.keys(requiredCustomProps)
    const validSubject = requiredPostFields.subject ? !isEmpty(draft.subject) : true
    const validBody = requiredPostFields.body ? !isEmpty(draft.body) : true
    const validAttachment = requiredPostFields.attachment !== false ? hasAttachment(draft) : true

    const draftCustomProps = Object.entries(draft.custom_properties ?? {})
    // check every required custom prop is present and the value is not null
    const validCustomProps =
      requiredCustomPropsIds.length > 0
        ? requiredCustomPropsIds.every((id) =>
            draftCustomProps.some(([propId, propValue]) => propId === id && propValue != null),
          )
        : true

    return validSubject && validBody && validAttachment && validCustomProps
  }

  const autoPublishDraft = ({ cid }: { cid: Cid }): void => {
    vSet(draftByCid.value[cid], 'shouldAutoPublish', true)
  }

  function stopAutoPublishDraft(cid: Cid): void {
    vSet(draftByCid.value[cid], 'shouldAutoPublish', false)
  }

  async function publishAllPostDrafts(): Promise<void> {
    // reverse to publish the oldest draft first
    // need to reverse on a separate line: https://sonarcloud.io/organizations/padlet/rules?open=typescript%3AS4043&rule_key=typescript%3AS4043
    const reversedDraftCids = [...draftCids.value].reverse()
    reversedDraftCids.forEach((cid) => {
      publishDraft({ cid })
    })
  }
  /* ---------------------- */
  /* CREATE DRAFTS          */
  /* ---------------------- */
  /**
   * Inserts a new draft post without triggering autosave.
   * For autosaving, use updateDraft
   * @param newDraft - The draft post to be inserted.
   */
  function insertDraft(newDraft: DraftPost): void {
    // Don't insert a draft if it already exists
    if (newDraft.id != null && draftById.value[newDraft.id] != null) return

    newDraft = addDraftCidIfAbsent(newDraft)
    newDraft.draftCreatedAt = Date.now()
    vSet(autoSaveQueueByCid.value, newDraft.cid, new PromiseQueue())
    vSet(draftSyncState.value, newDraft.cid, {
      state: newDraft.id == null ? DraftSaveState.Unsaved : DraftSaveState.Saved,
      loading: false,
      error: false,
    })
    vSet(draftByCid.value, newDraft.cid, newDraft)
    setDraftNumber(newDraft.cid)
  }

  const latestPendingDraftCid = ref<Cid | null>(null)

  /**
   * This function builds the post with its given attributes, assigns a suitable wall section, and also starts uploading the draft file if any.
   *
   * @param {Object} options - The options for starting a new draft.
   * @param {Post | DraftPost | PostAttributes} options.attributes - The attributes of the post or draft.
   * @param {boolean} options.shouldStartEditing - Indicates whether the draft should be started in editing mode.
   */
  const startNewDraft = ({
    attributes,
    shouldStartEditing,
  }: {
    attributes: Post | DraftPost | PostAttributes
    shouldStartEditing: boolean
  }): { cid: Cid } => {
    const post = usePostBuilder().buildPost(attributes)
    if (post.wall_section_id == null && surfaceSectionsStore.assignedWallSectionId != null) {
      post.wall_section_id = surfaceSectionsStore.assignedWallSectionId
    }

    surfacePostsStore.updatePostInStore({ ...post, subject: '', body: '', attachment: '' })
    updateDraft({ ...post, shouldStartEditing })
    setDraftNumber(post.cid)

    if (shouldStartEditing) {
      surfacePostsStore.startEditingPost({ postCid: post.cid })
    }

    if (attributes.file != null) {
      startUploadFileInDraft({ file: attributes.file, cid: post.cid })
    }

    return { cid: post.cid }
  }

  const createNewDraftFromPendingUpload = ({ file, postAttributes }: { file: File; postAttributes: Post }): void => {
    const newPostAttributes = usePostBuilder().buildPost(postAttributes)
    surfacePostsStore.updatePostInStore({ ...newPostAttributes, subject: '' })

    // Create the draft
    updateDraft({ ...newPostAttributes, subject: postAttributes.subject ?? '' })
    setDraftNumber(newPostAttributes.cid)

    // Start upload
    startUploadFileInDraft({ file, cid: newPostAttributes.cid })

    if (newPostAttributes.cid === latestPendingDraftCid.value) {
      surfacePostsStore.startEditingPost({ postCid: newPostAttributes.cid })
      latestPendingDraftCid.value = null
    }
  }
  /* ---------------------- */
  /* DELETE DRAFTS          */
  /* ---------------------- */
  const autosaveDraftToBeDeleted = async (postCid: Cid): Promise<void> => {
    // a previous operation (create/update) may be debounced, enqueued or executing.
    // clear debounce and queue, then enqueue a delete operation which deletes the draft and clears autosave state
    clearDraftSyncDebounceTimer(postCid)
    const autoSaveQueue = autoSaveQueueByCid.value[postCid]
    autoSaveQueue?.clear()
    await autoSaveQueue?.enqueue(autoSaveQueueKey(postCid), async function deletePostDraft() {
      const draft = draftByCid.value[postCid]
      if (draft.id != null) {
        void PadletApi.WishDraft.delete(draft.id)
      }
      vDel(autoSaveQueueByCid.value, postCid)
      vDel(draftSyncState.value, postCid)
      vDel(draftByCid.value, postCid)
      void useNativeAppStore().postSurfaceState()
    })
  }

  const removeDraft = async (postCid: Cid): Promise<void> => {
    if (postCid === activeDraftCid.value) {
      stopUploadFileActiveDraft()
      surfacePostsStore.stopEditingPost()
    }

    if (
      surfacePostsStore.isExistingPost(postCid) || // removing a draft of an existing post -> it's not a saved draft, simply remove it from drafts store
      (!surfaceGuestStore.shouldEnableAnonymousAttribution && !surfacePermissionsStore.amIRegistered) || // Only registered users get saved drafts
      surfaceStore.isSubmissionRequest // we don't save drafts if is submission request
    ) {
      vDel(draftByCid.value, postCid)
      return
    }
    await autosaveDraftToBeDeleted(postCid)
  }

  // wraps vuex removeDraft function. Removes a draft given its cid.
  // Calls delete draft API.
  // Optionally removes corresponding post
  async function removeDraftAndDeleteNonExistingPost(cid: Cid): Promise<void> {
    if (!surfacePostsStore.isExistingPost(cid)) {
      // remove draft that's not saved as post yet -> remove corresponding entity in posts store.
      await surfacePostsStore.deletePost(cid)
    }
    await removeDraft(cid)
  }

  async function removeAllPostDrafts(): Promise<void> {
    await Promise.all(draftCids.value.map(removeDraft))
  }

  function resetDrafts(): void {
    draftByCid.value = {}
  }

  /* ---------------------- */
  /* REMOTE OPERATIONS      */
  /* ---------------------- */
  function insertDraftRemote(newDraft: DraftPost): void {
    if (newDraft.id == null) return // a saved draft should always have an ID

    const existingDraft = draftById.value[newDraft.id]
    if (existingDraft != null) return // if draft with id already exists, no need to insert

    insertDraft(newDraft)

    void useNativeAppStore().postSurfaceState()
  }

  function updateDraftRemote(draft: DraftPost): void {
    if (draft.id == null) return

    const existingDraft = draftById.value[draft.id]
    if (existingDraft != null) {
      // if draft with id already exists, update it
      const updatedDraft = { ...existingDraft, ...draft }
      vSet(draftByCid.value, updatedDraft.cid, updatedDraft)
    } else {
      // the draft doesn't exist in store yet; insert it instead.
      insertDraft(draft)
    }

    void useNativeAppStore().postSurfaceState()
  }

  function removeDraftRemote(draft: Pick<DraftPost, 'id'>): void {
    if (draft.id == null) return

    const existingDraft = draftById.value[draft.id]
    if (existingDraft != null) {
      vDel(draftByCid.value, existingDraft.cid)
    }

    void useNativeAppStore().postSurfaceState()
  }

  /* ---------------------- */
  /* FETCH DRAFTS           */
  /* ---------------------- */
  const hasFetchedInitialPostDrafts = ref(false)

  // Fetches list of drafts on the wall and inserts into vuex store
  async function fetchPostDrafts(): Promise<void> {
    const postDrafts = await PadletApi.WishDraft.list({ wallId: surfaceStore.wallId })
    for (const postDraft of postDrafts) {
      insertDraft(postDraft)
    }
    void useNativeAppStore().postSurfaceState() // mobile app needs knowledge of CIDs that surface has assigned to drafts

    hasFetchedInitialPostDrafts.value = true
  }

  /* ---------------------- */
  /* MINIMIZED DRAFTS       */
  /* ---------------------- */
  // when a minimized draft has no subject, we will use "Draft 1", "Draft 2", ... as its title,
  // this counter is to keep track of the number to be used
  const minimizedDraftPlaceholderTitleCount = ref<number>(0)
  const draftShowingMinimizedContextMenuCid = ref<Cid | null>(null)

  /**
   * set the title for minimized draft to "Draft 1", "Draft 2", ... if necessary
   * @param draftCid - The CID of the draft.
   */
  function setDraftNumber(draftCid?: Cid | null): void {
    if (draftCid == null) return

    const draftPost = draftByCid.value[draftCid]
    if (
      draftPost == null ||
      draftPost.minimizedPlaceholderTitle != null ||
      (draftPost.subject != null && draftPost.subject.trim() !== '')
    ) {
      return
    }

    minimizedDraftPlaceholderTitleCount.value += 1
    const post = draftByCid.value[draftCid]
    vSet(draftByCid.value, post.cid, {
      ...post,
      minimizedPlaceholderTitle: __('Draft %{draftNumber}', {
        draftNumber: minimizedDraftPlaceholderTitleCount.value,
      }),
    })
  }

  const openMinimizedContextMenu = ({ cid }: { cid: Cid }): void => {
    draftShowingMinimizedContextMenuCid.value = cid
  }

  const closeMinimizedContextMenu = (): void => {
    draftShowingMinimizedContextMenuCid.value = null
  }

  /* ---------------------- */
  /* AUTOSAVE               */
  /* ---------------------- */
  const autoSaveQueueByCid = ref<Record<Cid, PromiseQueue | undefined>>({})
  const draftSyncState = ref<DraftSyncState>({})

  const isDraftAutoSavePending = (cid: Cid): boolean => {
    // pending debounced sync
    if (DRAFT_SYNC_DEBOUNCE_TIMERS.has(cid)) {
      return true
    }

    // promise queue is not empty
    const autoSaveQueue = autoSaveQueueByCid.value[cid]
    if (autoSaveQueue?.isEmpty === false) {
      return true
    }

    return false
  }

  const createPostDraftForSyncing = async (draft: DraftPost): Promise<void> => {
    const currentSyncState = draftSyncState.value[draft.cid]
    // user may have closed composer and cleared syncState/draft
    if (currentSyncState == null) return

    try {
      vSet(draftSyncState.value, draft.cid, {
        ...currentSyncState,
        state: DraftSaveState.Creating,
      })

      const attributes = await PadletApi.WishDraft.create(draft)
      // user may have closed composer and cleared syncState/draft
      if (draftByCid.value[draft.cid] == null) {
        return
      }

      updateDraft({ cid: draft.cid, id: attributes.id })
      vSet(draftSyncState.value, draft.cid, {
        ...currentSyncState,
        state: DraftSaveState.Saved,
      })

      void trackEvent('Post drafts', 'Created post draft')
    } catch (err) {
      vSet(draftSyncState.value, draft.cid, {
        ...currentSyncState,
        state: DraftSaveState.Unsaved,
      })
    }
  }

  const deletePostDraftForSyncing = async (draft: DraftPost): Promise<void> => {
    const currentSyncState = draftSyncState.value[draft.cid]
    // user may have closed composer and cleared syncState/draft
    if (currentSyncState == null) return

    // Draft may not have an id yet if it's still creating. Double check against more updated draft from store.
    const id = draft.id ?? draftByCid.value[draft.cid]?.id

    if (id == null) return

    try {
      vSet(draftSyncState.value, draft.cid, {
        ...currentSyncState,
        state: DraftSaveState.Deleting,
      })

      await PadletApi.WishDraft.delete(id)

      // user may have closed composer and cleared syncState/draft
      if (draftByCid.value[draft.cid] == null) {
        return
      }

      updateDraft({ cid: draft.cid, id: undefined })

      vSet(draftSyncState.value, draft.cid, {
        ...currentSyncState,
        state: DraftSaveState.Unsaved,
      })
    } catch (err) {
      vSet(draftSyncState.value, draft.cid, {
        ...currentSyncState,
        state: DraftSaveState.Saved,
      })
    }
  }

  const updatePostDraftForSyncing = async (draft: DraftPost): Promise<void> => {
    const currentSyncState = draftSyncState.value[draft.cid]
    // user may have closed composer and cleared syncState/draft
    if (currentSyncState == null) return

    // Draft may not have an id yet if it's still creating. Double check against more updated draft from store.
    const id = draft.id ?? draftByCid.value[draft.cid]?.id

    if (id == null) return

    try {
      vSet(draftSyncState.value, draft.cid, { ...currentSyncState, state: DraftSaveState.Updating })
      const updatedDraft = await PadletApi.WishDraft.update(id, draft)

      // user may have closed composer and cleared syncState/draft
      if (draftByCid.value[draft.cid] == null) return

      updateDraft({ cid: draft.cid, updated_at: updatedDraft.updated_at })
      vSet(draftSyncState.value, draft.cid, { ...currentSyncState, state: DraftSaveState.Saved })
    } catch (err) {
      vSet(draftSyncState.value, draft.cid, { ...currentSyncState, state: DraftSaveState.Saved })
    }
  }

  /**
    State machine: https://whimsical.com/post-drafts-diagrams-QVWje6U8d4TMm9xGhbDfnK@7YNFXnKbZAHvWYYvTex5e

    This should allow us to handle some edge cases,
    Eg: if user makes updates while the draft is still being created,
    this makes sure the next queued operation is an update instead of another create.
    Similarly if the draft is being deleted and the user makes update to the draft,
    then the next enqueued operation would be a create.
    @param draft - The draft to sync.
  */
  const syncDraft = async (draft: DraftPost): Promise<void> => {
    const syncState = draftSyncState.value[draft.cid]
    if (syncState == null) {
      return
    }

    const draftSaverQueue = autoSaveQueueByCid.value[draft.cid]
    if (draftSaverQueue == null) {
      return
    }

    switch (syncState.state) {
      case DraftSaveState.Unsaved:
      case DraftSaveState.Deleting:
        if (isPostEmpty(draft)) return
        await draftSaverQueue.enqueue(autoSaveQueueKey[draft.cid], async () => await createPostDraftForSyncing(draft))
        break
      case DraftSaveState.Saved:
      case DraftSaveState.Creating:
      case DraftSaveState.Updating:
        if (isPostEmpty(draft)) {
          draftSaverQueue.clear()
          await draftSaverQueue.enqueue(autoSaveQueueKey[draft.cid], async () => await deletePostDraftForSyncing(draft))
          break
        }

        draftSaverQueue.clear()
        await draftSaverQueue.enqueue(autoSaveQueueKey(draft.cid), async () => await updatePostDraftForSyncing(draft))
    }
  }

  const debouncedSyncDraft = partitionedDebounce(async (draft) => await syncDraft(draft), {
    getKey: (draft) => draft.cid,
    wait: DEBOUNCE_WAIT,
    timers: DRAFT_SYNC_DEBOUNCE_TIMERS,
  })

  return {
    // getter functions
    isCidBeingDrafted,
    getDraftFromCid,
    isCidActiveDraft,
    postOnlyHasDefaultValues,
    draftByCid,
    draftById,
    activeDraft,
    activeDraftCid,
    activeDraftUploadingFile,
    draftCids,
    isActiveDraftNewPost,
    isAnyDraftActive,
    latestPendingDraftCid,
    autoSaveQueueByCid,
    draftSyncState,
    hasFetchedInitialPostDrafts,
    minimizedDraftPlaceholderTitleCount,
    draftShowingMinimizedContextMenuCid,

    // actions
    isDraftDirty,
    startEditingDraft,
    stopEditingDraft,
    cancelEditingDraft,
    insertDraft,
    updateDraft,
    updateActiveDraft,
    removeDraft,
    removeDraftAndDeleteNonExistingPost,
    fetchPostDrafts,
    removeAllPostDrafts,
    resetDrafts,
    removeDraftAttachment,
    removeDraftCustomProperties,
    publishAllPostDrafts,
    setDraftScheduledAt,
    removeDraftScheduledAt,
    uploadFileActiveDraft,
    stopUploadFileActiveDraft,
    startUploadFileInDraft,
    stopUploadFileInDraft,
    replaceActiveDraftUploadingFile,
    replaceActiveDraftAttachmentWithLink,
    reassignSections,
    updateDraftWishContentAttachmentProps,
    publishDraft,
    autoPublishDraft,
    stopAutoPublishDraft,
    insertDraftRemote,
    updateDraftRemote,
    removeDraftRemote,
    setDraftNumber,
    getAttachmentPropsFromDraft,
    openMinimizedContextMenu,
    closeMinimizedContextMenu,
    isDraftAutoSavePending,
    debouncedSyncDraft,
    syncDraft,
    startNewDraft,
    createNewDraftFromPendingUpload,
  }
})
