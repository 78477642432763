// @file A set of reactivity utilities for Vue 2
import { computed, ref } from 'vue'

/* -----------------
 * Reactive Set
 * Vue 2 doesn't support Set reactivity out of the box.
 * This workaround is taken from https://github.com/bluecanvas/vue-reactive-collection
 ------------------ */

type SetConstructorArgument<T> = readonly T[] | null | Set<T>

class ReactiveSet<T> extends Set<T> {
  constructor(private readonly onMutate: () => void, values?: SetConstructorArgument<T>) {
    super(values)
  }

  /**
   * Appends a new element with a specified value to the end of the Set.
   */
  add(value: T): this {
    super.add(value)
    if (this.onMutate !== undefined) {
      this.onMutate()
    }
    return this
  }

  /**
   * Removes a specified value from the Set.
   * @returns Returns true if an element in the Set existed and has been removed, or false if the element does not exist.
   */
  delete(value: T): boolean {
    const res = super.delete(value)
    this.onMutate()
    return res
  }

  /**
   * Removes all elements from the Set.
   */
  clear(): void {
    super.clear()
    this.onMutate()
  }
}

/**
 * Returns a reactive Set object.
 *
 * Vue 3 migration guide:
 * Replace `useReactiveSet()` with `ref(new Set())`
 */
const useReactiveSet = <T>(values?: SetConstructorArgument<T>): typeof set => {
  const onMutate = (): void => {
    // This self assign triggers reactivity.
    // It is called every time the Set is mutated.
    // eslint-disable-next-line no-self-assign
    set.value = set.value
  }

  const inner = ref(new ReactiveSet<T>(onMutate, values))

  const set = computed<Set<T>>({
    get: () => {
      return inner.value
    },
    set: (set: Set<T>) => {
      inner.value = new ReactiveSet(onMutate, set)
    },
  })

  return set
}

export { useReactiveSet }
