// @file The store for "Learn" page (`DashDesktopLearnMenuPane.vue` and `DashLearn.vue`)
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { clearSearchParam, setSearchParam } from '@@/bits/location'
import { DashStartingStateApi } from '@@/dashboard/padlet_api'
import type { OzMenuRowsRow } from '@@/library/v4/components/OzMenuRows.vue'
import { OzMenuRowsHrefMode, OzMenuRowsRowStyle } from '@@/library/v4/components/OzMenuRows.vue'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useObserveVisibleElementOnScroll } from '@@/vuecomposables/observe_visible_element_on_scroll'
import type { JsonAPIResource } from '@padlet/arvo'
import Fuse from 'fuse.js'
import { findIndex, groupBy, sortBy, uniq } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface ExtendedOzMenuRowsRow extends OzMenuRowsRow {
  section: boolean
}

export enum DashLearnPageFeaturedVideoFilter {
  PADLET_101 = 'Padlet 101',
  AI_FEATURES = 'AI features',
}

export enum DashLearnPageVideoSectionFilter {
  GETTING_STARTED = 'Getting started',
  USE_CASES = 'Use cases',
  CREATING = 'Creating',
  POSTING = 'Posting',
  COLLABORATING = 'Collaborating',
  PRESENTING = 'Presenting',
  ORGANIZING = 'Organizing',
  EVERYTHING_ELSE = 'And more',
}

export enum DashLearnMenuPaneFilter {
  PADLET_101 = 'Video Section',
  AI_FEATURES = 'Video Section',
  GETTING_STARTED = DashLearnPageVideoSectionFilter.GETTING_STARTED,
  USE_CASES = DashLearnPageVideoSectionFilter.USE_CASES,
  CREATING = DashLearnPageVideoSectionFilter.CREATING,
  POSTING = DashLearnPageVideoSectionFilter.POSTING,
  COLLABORATING = DashLearnPageVideoSectionFilter.COLLABORATING,
  PRESENTING = DashLearnPageVideoSectionFilter.PRESENTING,
  ORGANIZING = DashLearnPageVideoSectionFilter.ORGANIZING,
  EVERYTHING_ELSE = DashLearnPageVideoSectionFilter.EVERYTHING_ELSE,
}

export interface ThumbnailImageMetadata {
  url: string
  width: number
  height: number
}

export interface LearnVideo {
  index: number
  skill: string
  translated_skill?: string
  competency: DashLearnPageVideoSectionFilter
  translated_competency?: string
  published: boolean
  new: boolean
  video: {
    videoId: string
    videoDurationInSeconds: number
    thumbnail: {
      dark: ThumbnailImageMetadata
      light: ThumbnailImageMetadata
    }
    videoEmbedUrl: string
  }
}

export const windowHashToLearnPageFilter = {
  '#padlet-101': DashLearnMenuPaneFilter.PADLET_101,
  '#getting-started': DashLearnMenuPaneFilter.GETTING_STARTED,
  '#use-cases': DashLearnMenuPaneFilter.USE_CASES,
  '#creating': DashLearnMenuPaneFilter.CREATING,
  '#posting': DashLearnMenuPaneFilter.POSTING,
  '#collaborating': DashLearnMenuPaneFilter.COLLABORATING,
  '#presenting': DashLearnMenuPaneFilter.PRESENTING,
  '#organizing': DashLearnMenuPaneFilter.ORGANIZING,
  '#everything-else': DashLearnMenuPaneFilter.EVERYTHING_ELSE,
}

export const learnPageFilterToWindowHash = {
  [DashLearnMenuPaneFilter.PADLET_101]: '#padlet-101',
  [DashLearnMenuPaneFilter.GETTING_STARTED]: '#getting-started',
  [DashLearnMenuPaneFilter.USE_CASES]: '#use-cases',
  [DashLearnMenuPaneFilter.CREATING]: '#creating',
  [DashLearnMenuPaneFilter.POSTING]: '#posting',
  [DashLearnMenuPaneFilter.COLLABORATING]: '#collaborating',
  [DashLearnMenuPaneFilter.PRESENTING]: '#presenting',
  [DashLearnMenuPaneFilter.ORGANIZING]: '#organizing',
  [DashLearnMenuPaneFilter.EVERYTHING_ELSE]: '#everything-else',
}

export function getLearnVideosByCompetency(
  competency: DashLearnPageVideoSectionFilter,
  videosGroupedByCompetency: Record<DashLearnPageVideoSectionFilter, LearnVideo[]>,
): LearnVideo[] {
  return videosGroupedByCompetency[competency]
}

export function getCompetencyVideoCount(
  competency: DashLearnPageVideoSectionFilter,
  videosGroupedByCompetency: Record<DashLearnPageVideoSectionFilter, LearnVideo[]>,
): number {
  return getLearnVideosByCompetency(competency, videosGroupedByCompetency).length
}

export function getCompetencyTranslatedTitle(
  competency: DashLearnPageVideoSectionFilter,
  menuPaneCoreSkillsRowItems: OzMenuRowsRow[],
): string {
  return menuPaneCoreSkillsRowItems.find((row) => row.eventPayload === competency)?.text ?? ''
}

export function getFilterFromUrlHash(hash: string): DashLearnMenuPaneFilter {
  return windowHashToLearnPageFilter[hash] ?? DashLearnMenuPaneFilter.PADLET_101
}

export function getExpandedStateForFilter(
  filter: DashLearnPageVideoSectionFilter,
  sectionExpandedState: { DashLearnPageVideoSectionFilter: boolean },
): boolean {
  return sectionExpandedState[filter] ?? false
}

function padSecondWithZeroIfNecessary(seconds: number): string {
  if (seconds >= 10) return seconds.toString()
  return `0${seconds.toString()}`
}
function secondsToMinutesAndSeconds(totalDurationInSeconds: number): [string, string] {
  const minutes = Math.floor(totalDurationInSeconds / 60) // Calculate the full minutes
  const seconds = totalDurationInSeconds % 60 // Get the remaining seconds
  return [minutes.toString(), padSecondWithZeroIfNecessary(seconds)]
}

export const useDashLearnStore = defineStore('dashLearnStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  // State
  const dashLearnData = ref<LearnVideo[]>([])
  const currentFilter = ref<DashLearnMenuPaneFilter>(DashLearnMenuPaneFilter.PADLET_101)
  const activeSelectedSkill = ref<string>('')
  const sectionExpandedState = ref<{ DashLearnPageVideoSectionFilter: boolean } | {}>({})

  const searchQuery = ref<string>('')

  const xVideoPlayer = ref<boolean>(false)

  const learnSectionsElementRef = ref<HTMLElement[]>([])
  const { selectedElementKey, scrollToElement } = useObserveVisibleElementOnScroll(learnSectionsElementRef)
  const isVideoPlayerOpenedInTheFirstLoad = ref<boolean>(false)
  const clickedSidePane = ref<boolean>(false)

  const selectedFeaturedSkill = ref<string>('')
  const featuredVideoPlaylistIndex = ref<number>(0)

  // Actions

  /**
   * ==================== GENERAL ====================
   */
  const fetchLearnVideosIfNecessary = async (): Promise<void> => {
    if (dashLearnData.value.length > 0) return

    try {
      const response = await DashStartingStateApi.fetchPadletLearnVideos()
      const data = response.data as JsonAPIResource<LearnVideo[]>
      dashLearnData.value = data.attributes
      const competencies = uniq(data.attributes.map((data) => data.competency))
      sectionExpandedState.value = competencies.reduce((acc, competency) => {
        acc[competency] = false
        return acc
      }, {})
      selectedFeaturedSkill.value = featuredVideos.value[0].skill
    } catch (error) {
      void globalSnackbarStore.setSnackbar({
        message: 'Error fetching padlet learn videos',
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(error, { source: 'DashFetchLearnVideos' })
    }
  }

  const toggleSectionExpandedState = (filter: DashLearnPageVideoSectionFilter): void => {
    sectionExpandedState.value = {
      ...sectionExpandedState.value,
      [filter]: !(sectionExpandedState.value[filter] as boolean),
    }
  }

  const expandAndScrollToVideoSectionList = (filter: DashLearnMenuPaneFilter): void => {
    if ((Object.values(DashLearnPageVideoSectionFilter) as string[]).includes(filter)) {
      sectionExpandedState.value = {
        ...sectionExpandedState.value,
        [filter]: true,
      }
    }

    setCurrentFilterAndScrollToEl(filter)
  }

  /**
   * ==================== NAVIGATION ====================
   */

  const setLearnSectionsElementRef = (cardSectionEls: HTMLElement[], videoPlayerSectionEl?: HTMLElement): void => {
    if (videoPlayerSectionEl == null) {
      learnSectionsElementRef.value = cardSectionEls
    } else {
      learnSectionsElementRef.value = [videoPlayerSectionEl, ...cardSectionEls]
    }
  }

  const setCurrentFilter = (filter: DashLearnMenuPaneFilter): void => {
    currentFilter.value = filter
  }

  const setCurrentFilterAndScrollToEl = (filter: DashLearnMenuPaneFilter): void => {
    setCurrentFilter(filter)
    if ((Object.values(DashLearnPageFeaturedVideoFilter) as string[]).includes(filter)) {
      scrollToElement('Video Section')
    } else {
      scrollToElement(filter)
    }
  }

  const setFilterToHash = (): void => {
    const hash = window.location.hash
    if (hash === '') return
    const filter = getFilterFromUrlHash(hash)
    setCurrentFilter(filter)
  }

  const setClickedSidePane = (clicked: boolean): void => {
    clickedSidePane.value = clicked
  }

  /**
   * ==================== SEARCH ====================
   */

  function updateSearchQuery(query: string): void {
    searchQuery.value = query
  }

  function resetSearch(): void {
    searchQuery.value = ''
  }

  /**
   * ==================== VIDEOS ====================
   */
  function setActiveFeaturedSkill(skill: string): void {
    selectedFeaturedSkill.value = skill
    featuredVideoPlaylistIndex.value = featuredVideos.value.findIndex((video) => video.skill === skill)
  }

  function setActiveSelectedSkill(skill: string): void {
    activeSelectedSkill.value = skill
    setSearchParam('video_id', skill)
  }

  function resetActiveSelectedSkill(): void {
    activeSelectedSkill.value = ''
    clearSearchParam('video_id')
  }

  function closeVideoPlayer(): void {
    resetActiveSelectedSkill()
    xVideoPlayer.value = false
  }

  function openVideoPlayer(): void {
    xVideoPlayer.value = true
  }

  function setPreviousVideoAsActive(): void {
    const previousSkill = previousVideoData.value?.skill
    if (previousSkill == null) return
    setActiveSelectedSkill(previousSkill)
  }

  function setNextVideoAsActive(): void {
    const nextSkill = nextVideoData.value?.skill
    if (nextSkill == null) return
    setActiveSelectedSkill(nextSkill)
  }

  // Getters
  /**
   * ==================== GENERAL ====================
   */
  const hasSuccessfullyFetchedData = computed((): boolean => {
    return dashLearnData.value.length > 0
  })

  const isFetchingLearnPageData = computed((): boolean => !hasSuccessfullyFetchedData.value)

  const totalVideoDurationForCurrentFilter = computed((): [string, string] => {
    // Filter hardcoded to "GETTING_STARTED" for now
    const totalDurationInSeconds = getLearnVideosByCompetency(
      DashLearnPageVideoSectionFilter.GETTING_STARTED,
      padletLearnVideosGroupedByCompetency.value,
    ).reduce((acc, data) => acc + data.video.videoDurationInSeconds, 0)

    return secondsToMinutesAndSeconds(totalDurationInSeconds)
  })

  const sectionFilters = computed((): DashLearnPageVideoSectionFilter[] => {
    return [
      DashLearnPageVideoSectionFilter.USE_CASES,
      DashLearnPageVideoSectionFilter.CREATING,
      DashLearnPageVideoSectionFilter.POSTING,
      DashLearnPageVideoSectionFilter.COLLABORATING,
      DashLearnPageVideoSectionFilter.PRESENTING,
      DashLearnPageVideoSectionFilter.ORGANIZING,
      DashLearnPageVideoSectionFilter.EVERYTHING_ELSE,
    ]
  })

  /**
   * ==================== NAVIGATION ====================
   */
  const currentFilterPlaylistRowItems = computed<OzMenuRowsRow[]>(() => {
    // Filter hardcoded to "GETTING_STARTED" for now
    return getLearnVideosByCompetency(
      DashLearnPageVideoSectionFilter.GETTING_STARTED,
      padletLearnVideosGroupedByCompetency.value,
    ).map((video) => {
      const [minutes, seconds] = secondsToMinutesAndSeconds(video.video.videoDurationInSeconds)
      const isCurrentVideoSelected = selectedFeaturedSkill.value === video.skill
      return {
        icon: isCurrentVideoSelected ? 'play' : 'play_outline',
        text: video.translated_skill ?? video.skill,
        eventPayload: video.skill,
        rowStyle: isCurrentVideoSelected ? OzMenuRowsRowStyle.Highlighted : OzMenuRowsRowStyle.Default,
        shouldShow: true,
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
        rightLabel: `${minutes}:${seconds} `,
        textTruncated: true,
      }
    })
  })

  const videoCategoriesDisplayedInSections = computed(() => {
    const featuredItemsDisplayedInSections = menuPaneFeaturedRowItems.value.filter((row) => row.section)
    return [...featuredItemsDisplayedInSections, ...menuPaneCoreSkillsRowItems.value]
  })

  const menuPaneFeaturedRowItems = computed<ExtendedOzMenuRowsRow[]>(() =>
    [
      {
        icon: 'graduation_cap_outline',
        text: __('Padlet 101'),
        eventPayload: DashLearnMenuPaneFilter.PADLET_101,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.PADLET_101
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: true,
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.PADLET_101],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
        section: true,
      },
      {
        icon: 'open_book',
        text: __('Use cases'),
        eventPayload: DashLearnMenuPaneFilter.USE_CASES,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.USE_CASES
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: true,
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.USE_CASES],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
        section: true,
      },
      // {
      //   icon: 'sparkles_outline',
      //   text: __('AI features'),
      //   eventPayload: '',
      //   rowStyle:
      //     currentFilter.value === DashLearnPageVideoSectionFilter.POSTING
      //       ? OzMenuRowsRowStyle.Highlighted
      //       : OzMenuRowsRowStyle.Default,
      //   shouldShow: true,
      //   hrefMode: OzMenuRowsHrefMode.HistoryPushState,
      // },
      // TODO: Uncomment when AI videos are ready
    ].filter((row) => row.shouldShow),
  )

  const menuPaneCoreSkillsRowItems = computed<OzMenuRowsRow[]>(() =>
    [
      {
        icon: 'palette_brush',
        text: __('Creating'),
        eventPayload: DashLearnPageVideoSectionFilter.CREATING,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.CREATING
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: competencyTypes.value.includes(DashLearnPageVideoSectionFilter.CREATING),
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.CREATING],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
      },
      {
        icon: 'plus_in_circle',
        text: __('Posting'),
        eventPayload: DashLearnPageVideoSectionFilter.POSTING,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.POSTING
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: competencyTypes.value.includes(DashLearnPageVideoSectionFilter.POSTING),
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.POSTING],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
      },
      {
        icon: 'collaboration',
        text: __('Collaborating'),
        eventPayload: DashLearnPageVideoSectionFilter.COLLABORATING,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.COLLABORATING
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: competencyTypes.value.includes(DashLearnPageVideoSectionFilter.COLLABORATING),
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.COLLABORATING],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
      },
      {
        icon: 'presentation',
        text: __('Presenting'),
        eventPayload: DashLearnPageVideoSectionFilter.PRESENTING,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.PRESENTING
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: competencyTypes.value.includes(DashLearnPageVideoSectionFilter.PRESENTING),
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.PRESENTING],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
      },
      {
        icon: 'organize',
        text: __('Organizing'),
        eventPayload: DashLearnPageVideoSectionFilter.ORGANIZING,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.ORGANIZING
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: competencyTypes.value.includes(DashLearnPageVideoSectionFilter.ORGANIZING),
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.ORGANIZING],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
      },
      {
        icon: 'sweet_treat',
        text: __('Everything else'),
        eventPayload: DashLearnPageVideoSectionFilter.EVERYTHING_ELSE,
        rowStyle:
          currentFilter.value === DashLearnMenuPaneFilter.EVERYTHING_ELSE
            ? OzMenuRowsRowStyle.Highlighted
            : OzMenuRowsRowStyle.Default,
        shouldShow: competencyTypes.value.includes(DashLearnPageVideoSectionFilter.EVERYTHING_ELSE),
        href: learnPageFilterToWindowHash[DashLearnMenuPaneFilter.EVERYTHING_ELSE],
        hrefMode: OzMenuRowsHrefMode.HistoryPushState,
      },
    ].filter((row) => row.shouldShow),
  )

  const padletLearnVideosGroupedByCompetency = computed(
    () =>
      groupBy(dashLearnData.value, (data) => data.competency) as Record<DashLearnPageVideoSectionFilter, LearnVideo[]>,
  )
  const competencyTypes = computed(() => Object.keys(padletLearnVideosGroupedByCompetency.value))
  const observableLearnSection = computed(() => selectedElementKey)

  /**
   * ==================== SEARCH ====================
   */

  const hasSearchQuery = computed((): boolean => {
    return searchQuery.value.length > 0
  })

  const filteredLearnVideoBySearchQuery = computed((): LearnVideo[] => {
    if (!hasSearchQuery.value) return dashLearnData.value
    const fuseOptions = {
      keys: ['skill', 'competency'],
      ignoreLocation: true,
      threshold: 0.2, // Setting it low to get more relevant results
    }

    const fuse = new Fuse(dashLearnData.value, fuseOptions)
    return fuse.search(searchQuery.value).map((result) => result.item)
  })

  /**
   * ==================== VIDEO ====================
   */

  const featuredVideos = computed<LearnVideo[]>(() => {
    // Filter hardcoded to "GETTING_STARTED" for now
    return getLearnVideosByCompetency(
      DashLearnPageVideoSectionFilter.GETTING_STARTED,
      padletLearnVideosGroupedByCompetency.value,
    )
  })

  const currentFeaturedVideoId = computed<string>(
    () => featuredVideos.value[featuredVideoPlaylistIndex.value]?.video.videoId,
  )

  const selectedFeaturedVideoLearnData = computed((): LearnVideo | undefined => {
    return featuredVideos.value.find((data) => data.skill === selectedFeaturedSkill.value)
  })

  const activeLearnVideoData = computed((): LearnVideo | undefined => {
    return dashLearnData.value.find((data) => data.skill === activeSelectedSkill.value)
  })

  const orderedLearnVideos = computed((): LearnVideo[] => {
    const getEnumOrder = (competency: DashLearnPageVideoSectionFilter): number => {
      const values = Object.values(DashLearnPageVideoSectionFilter)
      return values.indexOf(competency)
    }

    // Use lodash to sort the array
    return sortBy(dashLearnData.value, [(item) => getEnumOrder(item.competency), 'index'])
  })

  const currentVideoIndex = computed((): number =>
    findIndex(orderedLearnVideos.value, { skill: activeSelectedSkill.value }),
  )

  const previousVideoData = computed((): LearnVideo | undefined => {
    const previousVideoIndex = currentVideoIndex.value - 1
    if (previousVideoIndex < 0) return undefined
    return orderedLearnVideos.value[previousVideoIndex]
  })

  const xPreviousVideoButton = computed((): boolean => {
    return previousVideoData.value != null
  })

  const nextVideoData = computed((): LearnVideo | undefined => {
    const nextVideoIndex = currentVideoIndex.value + 1
    if (nextVideoIndex >= orderedLearnVideos.value.length) return undefined
    return orderedLearnVideos.value[nextVideoIndex]
  })

  const xNextVideoButton = computed((): boolean => {
    return nextVideoData.value != null
  })

  return {
    // State
    dashLearnData,
    sectionExpandedState,
    currentFilter,
    searchQuery,
    activeSelectedSkill,
    selectedFeaturedSkill,
    xVideoPlayer,
    isVideoPlayerOpenedInTheFirstLoad,
    clickedSidePane,
    featuredVideoPlaylistIndex,

    // Getters
    videoCategoriesDisplayedInSections,
    featuredVideos,
    sectionFilters,
    selectedFeaturedVideoLearnData,
    currentFeaturedVideoId,
    currentFilterPlaylistRowItems,
    totalVideoDurationForCurrentFilter,
    padletLearnVideosGroupedByCompetency,
    menuPaneFeaturedRowItems,
    menuPaneCoreSkillsRowItems,
    competencyTypes,
    hasSearchQuery,
    hasSuccessfullyFetchedData,
    isFetchingLearnPageData,
    activeLearnVideoData,
    orderedLearnVideos,
    previousVideoData,
    nextVideoData,
    xPreviousVideoButton,
    xNextVideoButton,
    currentVideoIndex,
    filteredLearnVideoBySearchQuery,
    observableLearnSection,

    // Actions
    setActiveFeaturedSkill,
    expandAndScrollToVideoSectionList,
    toggleSectionExpandedState,
    fetchLearnVideosIfNecessary,
    setCurrentFilter,
    setCurrentFilterAndScrollToEl,
    setLearnSectionsElementRef,
    updateSearchQuery,
    resetSearch,
    setActiveSelectedSkill,
    resetActiveSelectedSkill,
    closeVideoPlayer,
    openVideoPlayer,
    setPreviousVideoAsActive,
    setNextVideoAsActive,
    setFilterToHash,
    setClickedSidePane,
  }
})
