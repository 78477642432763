// @file utils for surface polls

import { buildUrlFromPath } from '@@/bits/location'
import type { DraftPost, Poll, Post } from '@@/types'
import { captureException } from '@sentry/vue'

export const isPollClosed = (poll: Poll): boolean => {
  return poll.closed_at !== null
}

export const hasPollAttachment = (post: Post | DraftPost): boolean => {
  return post.wish_content?.attachment_props?.type === 'poll'
}

export const getPollFromPost = (post: Post): Poll | null => {
  if (post.wish_content?.attachment_props != null && hasPollAttachment(post)) {
    return post.wish_content?.attachment_props as Poll
  }
  return null
}

export const buildCsvExportsUrl = (publicKey: string, post: Post): string | null => {
  const poll = getPollFromPost(post)
  if (poll != null && post.id != null) {
    return buildUrlFromPath(`padlets/${publicKey}/wishes/${post.id}/polls/${poll.poll_id}/exports/csv`)
  }
  return null
}

export const exportPollResultsAsCSV = async (publicKey: string, post: Post): Promise<void> => {
  const csvExportsUrl = buildCsvExportsUrl(publicKey, post)
  if (csvExportsUrl != null) {
    window.open(csvExportsUrl)
  } else {
    captureException('Poll not found when exporting poll results as CSV')
  }
}
