// @file Section order helper methods
import { $ } from '@@/bits/jquery'
import type { Id, Section } from '@@/types'
import { sortBy } from 'lodash-es'

export default {
  getSortIndex(timestamp?: Date): number {
    if (timestamp == null) {
      timestamp = new Date()
    }
    return new Date(timestamp.toISOString()).getTime() / 1000
  },
  getTopSortIndex(): number {
    return -this.getSortIndex()
  },
  getBottomSortIndex(): number {
    return this.getSortIndex()
  },
  sortSections(sectionsAsArray: Section[]): Section[] {
    return sortBy(sectionsAsArray, (section) => {
      return section.sort_index
    })
  },
  getNewIndex(item: JQuery<HTMLElement> | HTMLElement): {
    sectionId: Id
    sortIndex: number
    gapSize: number
  } | null {
    const $item = $(item)
    const $prev = $item.prev('.surface-section')
    const $next = $item.next('.surface-section')

    const { sortIndex, gapSize } = this.getNewIndexBetween($prev, $next)
    if (sortIndex == null || gapSize == null) {
      // Do nothing
      return null
    }
    return {
      sectionId: $item.data('section-id') ?? $item.data('id'),
      sortIndex,
      gapSize,
    }
  },
  getNewIndexBefore(item: JQuery<HTMLElement>): {
    sortIndex?: number
    gapSize?: number
  } {
    const $prev = item.prev('.surface-section')
    return this.getNewIndexBetween($prev, item)
  },
  getNewIndexAfter(item: JQuery<HTMLElement>): {
    sortIndex?: number
    gapSize?: number
  } {
    const $next = item.next('.surface-section')
    return this.getNewIndexBetween(item, $next)
  },
  getNewIndexBetween(
    $prev: JQuery<HTMLElement>,
    $next: JQuery<HTMLElement>,
  ): {
    sortIndex?: number
    gapSize?: number
  } {
    if ($prev.length === 0 && $next.length === 0) {
      // Do nothing
      return {}
    }

    let prevIndex: number | null = null
    let nextIndex: number | null = null
    if ($prev.length === 0) {
      // moved to top
      prevIndex = this.getTopSortIndex()
    } else if ($next.length === 0) {
      // moved to bottom
      nextIndex = this.getBottomSortIndex()
    }
    if (prevIndex === null) {
      prevIndex = parseInt($prev.attr('data-rank') ?? '')
    }
    if (nextIndex === null) {
      nextIndex = parseInt($next.attr('data-rank') ?? '')
    }

    // New sort index is the average of the two adjacent sections' sort indices
    const sortIndex = Math.round((prevIndex + nextIndex) / 2)
    // Might be NaN, which translates to a nil in controller.
    const gapSize = Math.abs(nextIndex - prevIndex)
    return { sortIndex, gapSize }
  },
}
