// @file Helper for grouping posts
import { POST_COLOR_STRINGS } from '@@/bits/post_color'
import type { Post } from '@@/types'
import { GroupByTypes } from '@padlet/arvo'

type GroupByNone = Lowercase<GroupByTypes.None>
interface Group {
  id: string | number
  [k: string]: any
}

const BLANK_GROUP: Group = { id: GroupByTypes.None }

const ORDERED_COLOR_GROUPS = POST_COLOR_STRINGS.map((c) => {
  return {
    id: c,
  }
})

const groupByToPostAttributeMap = {
  [GroupByTypes.Section]: 'wall_section_id',
  [GroupByTypes.Color]: 'color',
}

const getPostsByGroupByProperty = <T extends Group['id']>(
  posts: Post[],
  sortedGroupByValues: T[],
  groupBy: GroupByTypes,
): { [K in T | GroupByNone]?: Post[] } => {
  if (groupBy === GroupByTypes.None) {
    return {
      none: posts,
    }
  }
  return sortedGroupByValues.reduce<{ [K in T | GroupByNone]?: Post[] }>(
    (obj, groupByValue) => ({
      ...obj,
      [groupByValue]: posts.filter((p) => String(p[groupByToPostAttributeMap[groupBy]]) === String(groupByValue)),
    }),
    {},
  )
}

export { BLANK_GROUP, Group, ORDERED_COLOR_GROUPS, getPostsByGroupByProperty }
