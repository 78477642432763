/*
 * @file handle ajax requests
 */
import { $ } from '@@/bits/jquery'
import getCsrfToken from './csrf_token'

let oauthToken = null

export function setOauthToken(token) {
  oauthToken = token
}

$(document).ajaxSend((event, xhr, settings) => {
  if (settings.type != 'GET') {
    xhr.setRequestHeader('X-CSRF-Token', getCsrfToken())
  }
  if (oauthToken && settings.sendOauthToken) {
    xhr.setRequestHeader('Authorization', `Bearer ${oauthToken}`)
  }
})

export default $.ajax
