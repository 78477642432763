// @file Bulk actions store
import { trackEvent } from '@@/bits/analytics'
import backendState from '@@/bits/backend_state'
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { WAVING_HAND_EMOJI } from '@@/bits/emoji'
import { captureFetchException } from '@@/bits/error_tracker'
import { __, n__ } from '@@/bits/intl'
import { currentHostWithProtocol, navigateTo } from '@@/bits/location'
import { buildSlideshowLink } from '@@/bits/slideshow'
import { isPartOfUnlimitedSandboxesEvent } from '@@/bits/unlimited_sandboxes_event'
import { displayNameForUser } from '@@/bits/user_model'
import { WALL_ACCESS_RIGHT_TO_INTEGER_MAP } from '@@/bits/wall_access_settings_helper'
import { Wall as WallApi } from '@@/dashboard/padlet_api'
import { ApiErrorCode, LibraryType } from '@@/enums'
import anxiousFace from '@@/images/anxious_face.svg'
import { OzContainedButtonColorScheme } from '@@/library/v4/components/OzContainedButton.vue'
import { OzMenuRowsRowStyle } from '@@/library/v4/components/OzMenuRows.vue'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashWallSingleActionsStore } from '@@/pinia/dash_wall_single_actions_store'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import type { ConfirmationDialogPayload } from '@@/pinia/global_confirmation_dialog'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import {
  UpgradeSource as LibraryUpgradeSource,
  UpgradeStep as LibraryUpgradeStep,
  useLibraryPlansStore,
} from '@@/pinia/library_plans'
import { UpgradeSource, usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { useUserAccountsStore } from '@@/pinia/user_accounts_store'
import type { FolderId, Library, LibraryId, WallCamelCase as Wall, WallId } from '@@/types'
import type { CollectionKey } from '@@/types/collections'
import { CollectionKeyTypes } from '@@/types/collections'
import { PageType } from '@@/types/slideshow'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum WallActionType {
  RemoveFromRecents = 'removeFromRecents',
  AddBookmarks = 'addBookmarks',
  RemoveFromFolder = 'removeFromFolder',
  RemoveFromAllFolders = 'removeFromAllFolders',
  LeaveAll = 'leaveAll',
  ArchiveAll = 'archiveAll',
  UnarchiveAll = 'unarchiveAll',
  TrashAll = 'trashAll',
  RestoreAll = 'restoreAll',
  PermanentlyDeleteAll = 'permanentlyDeleteAll',
  OpenSlideShow = 'openSlideShow',
  CopyLinkToPadlet = 'copyLinkToPadlet',
  TransferAll = 'transferAll',
}

export interface WallActionButton {
  text: string
  icon?: string
  handleClick: (() => Promise<void>) | (() => void)
  shouldShow: boolean
  buttonColorScheme: OzContainedButtonColorScheme
  eventPayload?: (() => Promise<void>) | (() => void)
  rowStyle?: OzMenuRowsRowStyle
}
interface WallActionTypeFolderParams {
  folderId?: FolderId
}

export type WallActionTypeParams = WallActionTypeFolderParams

const bulkActionsInProgressMessage = {
  [WallActionType.RemoveFromRecents]: __('Removing padlets from recents...'),
  [WallActionType.AddBookmarks]: __('Bookmarking padlets...'),
  [WallActionType.RemoveFromFolder]: __('Removing padlets from folder...'),
  [WallActionType.RemoveFromAllFolders]: __('Removing all padlets from all folders...'),
  [WallActionType.LeaveAll]: __('Leaving padlets...'),
  [WallActionType.ArchiveAll]: __('Archiving padlets...'),
  [WallActionType.UnarchiveAll]: __('Unarchiving padlets...'),
  [WallActionType.TrashAll]: __('Trashing padlets...'),
  [WallActionType.RestoreAll]: __('Restoring padlets...'),
  [WallActionType.PermanentlyDeleteAll]: __('Permanently deleting padlets...'),
}

const bulkActionsCompletedMessage = {
  [WallActionType.RemoveFromRecents]: __('All padlets removed from recents'),
  [WallActionType.AddBookmarks]: __('All padlets bookmarked'),
  [WallActionType.RemoveFromFolder]: __('All padlets removed from folder'),
  [WallActionType.RemoveFromAllFolders]: __('All padlets removed from all folders'),
  [WallActionType.LeaveAll]: __('All padlets left'),
  [WallActionType.ArchiveAll]: __('All padlets archived'),
  [WallActionType.UnarchiveAll]: __('All padlets unarchived'),
  [WallActionType.TrashAll]: __('All padlets trashed'),
  [WallActionType.RestoreAll]: __('All padlets restored'),
  [WallActionType.PermanentlyDeleteAll]: __('All padlets permanently deleted'),
  [WallActionType.OpenSlideShow]: __('Opened slideshow'),
  [WallActionType.CopyLinkToPadlet]: __('Copied link to padlet'),
}

const bulkActionsUndoingsMessage = {
  [WallActionType.AddBookmarks]: __('Undoing bookmarking padlets...'),
  [WallActionType.RemoveFromFolder]: __('Undoing removing padlets from folder...'),
  [WallActionType.ArchiveAll]: __('Undoing archiving padlets...'),
  [WallActionType.UnarchiveAll]: __('Undoing unarchiving padlets...'),
  [WallActionType.TrashAll]: __('Undoing trashing padlets...'),
  [WallActionType.RestoreAll]: __('Undoing restoring padlets...'),
}

const bulkActionsUndonedMessage = {
  [WallActionType.AddBookmarks]: __('Bookmarking padlets undone'),
  [WallActionType.RemoveFromFolder]: __('Removing padlets from folder undone'),
  [WallActionType.ArchiveAll]: __('Archiving padlets undone'),
  [WallActionType.UnarchiveAll]: __('Unarchiving padlets undone'),
  [WallActionType.TrashAll]: __('Trashing padlets undone'),
  [WallActionType.RestoreAll]: __('Restoring padlets undone'),
}

const singleActionInProgressMessage = {
  [WallActionType.RemoveFromRecents]: __('Removing padlet from recents...'),
  [WallActionType.AddBookmarks]: __('Bookmarking padlet...'),
  [WallActionType.RemoveFromFolder]: __('Removing padlet from folder...'),
  [WallActionType.RemoveFromAllFolders]: __('Removing padlet from all folders...'),
  [WallActionType.LeaveAll]: __('Leaving padlet...'),
  [WallActionType.ArchiveAll]: __('Archiving padlet...'),
  [WallActionType.UnarchiveAll]: __('Unarchiving padlet...'),
  [WallActionType.TrashAll]: __('Trashing padlet...'),
  [WallActionType.RestoreAll]: __('Restoring padlet...'),
  [WallActionType.PermanentlyDeleteAll]: __('Permanently deleting padlet...'),
}

const singleActionCompletedMessage = {
  [WallActionType.RemoveFromRecents]: __('Padlet removed from recents'),
  [WallActionType.AddBookmarks]: __('Padlet bookmarked'),
  [WallActionType.RemoveFromFolder]: __('Padlet removed from folder'),
  [WallActionType.RemoveFromAllFolders]: __('Padlet removed from all folders'),
  [WallActionType.LeaveAll]: __('Padlet left'),
  [WallActionType.ArchiveAll]: __('Padlet archived'),
  [WallActionType.UnarchiveAll]: __('Padlet unarchived'),
  [WallActionType.TrashAll]: __('Padlet trashed'),
  [WallActionType.RestoreAll]: __('Padlet restored'),
  [WallActionType.PermanentlyDeleteAll]: __('Padlet permanently deleted'),
  [WallActionType.OpenSlideShow]: __('Opened slideshow'),
  [WallActionType.CopyLinkToPadlet]: __('Copied link to padlet'),
}

const singleActionUndoingMessage = {
  [WallActionType.AddBookmarks]: __('Undoing bookmarking padlet...'),
  [WallActionType.RemoveFromFolder]: __('Undoing removing padlet from folder...'),
  [WallActionType.ArchiveAll]: __('Undoing archiving padlet...'),
  [WallActionType.UnarchiveAll]: __('Undoing unarchiving padlet...'),
  [WallActionType.TrashAll]: __('Undoing trashing padlet...'),
  [WallActionType.RestoreAll]: __('Undoing restoring padlet...'),
}

const singleActionUndonedMessage = {
  [WallActionType.AddBookmarks]: __('Bookmarking padlet undone'),
  [WallActionType.RemoveFromFolder]: __('Removing padlet from folder undone'),
  [WallActionType.ArchiveAll]: __('Archiving padlet undone'),
  [WallActionType.UnarchiveAll]: __('Unarchiving padlet undone'),
  [WallActionType.TrashAll]: __('Trashing padlet undone'),
  [WallActionType.RestoreAll]: __('Restoring padlet undone'),
}

export const useDashWallBulkActionsStore = defineStore('dashWallBulkActionsStore', () => {
  // External stores
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalAlertDialogStore = useGlobalAlertDialogStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const userAccountsStore = useUserAccountsStore()
  const dashAccountsStore = useDashAccountsStore()
  const personalPlansStore = usePersonalPlansStore()
  const libraryPlansStore = useLibraryPlansStore()
  const dashWallSingleActionsStore = useDashWallSingleActionsStore()

  const currentLibrary = computed((): Library | null => dashAccountsStore.currentLibrary)
  const activeCollectionKey = computed((): CollectionKey => dashCollectionsStore.activeCollectionKey)
  const activeFolderId = computed((): FolderId | null => dashCollectionsStore.activeFolderId)
  const wallIdsByFolderId = computed((): Record<FolderId, WallId[]> => dashCollectionsStore.wallIdsByFolderId)
  const favoritesFolderWallIds = computed((): WallId[] => dashCollectionsStore.favoritesFolderWallIds)

  // State
  const preselectedWallIds = ref<WallId[]>([]) // Used to select walls after being redirected from auth verification page
  const selectedWalls = ref<Wall[]>([])
  const xDashBulkBookmarksModal = ref(false)
  const xDashBulkTransferWallsModal = ref<boolean>(false)
  const shiftClickPivotWallId = ref<WallId | undefined>(undefined)
  const selectedWallsIndexesBeforeShiftClick = ref<number[]>([])

  // Getters
  const numberOfWallsSelected = computed((): number => selectedWalls.value.length)
  const isMultiSelecting = computed((): boolean => numberOfWallsSelected.value > 0)
  const xDashBulkActionToolbar = computed((): boolean => isMultiSelecting.value && !xWallActions.value)
  const xBulkWallActions = computed((): boolean => numberOfWallsSelected.value > 1)
  const xWallActions = computed((): boolean => dashWallSingleActionsStore.xWallActions)
  const isOnlyOnePadletSelected = computed((): boolean => numberOfWallsSelected.value === 1)

  const isCollectionRecents = computed((): boolean => activeCollectionKey.value?.indexKey === 'combined_recents')
  const isCollectionBookmark = computed((): boolean => dashCollectionsStore.isActiveCollectionBookmark)
  const isCollectionShared = computed((): boolean => dashCollectionsStore.isActiveCollectionShared)
  const isCollectionGallery = computed((): boolean => activeCollectionKey.value?.indexKey === 'gallery')
  const isCollectionArchived = computed((): boolean => activeCollectionKey.value?.indexKey === 'archived')
  const isCollectionTrashed = computed((): boolean => activeCollectionKey.value?.indexKey === 'trashed')
  const isCollectionAll = computed((): boolean => activeCollectionKey.value?.indexKey === 'all')
  const isCollectionMade = computed((): boolean => activeCollectionKey.value?.indexKey === 'made')
  const isCollectionPrivate = computed((): boolean => activeCollectionKey.value?.indexKey === 'private_walls')
  const isCollectionTemplates = computed((): boolean => activeCollectionKey.value?.indexKey === 'templates')
  const isCollectionGroups = computed(
    (): boolean => activeCollectionKey.value?.typeKey === CollectionKeyTypes.GroupFolderId,
  )
  const isCurrentAccountUser = computed(
    (): boolean =>
      dashCollectionsStore.currentAccountKey !== null && dashCollectionsStore.currentAccountKey.type === 'user',
  )

  const canArchiveAllSelectedWalls = computed((): boolean => {
    if (!dashAccountsStore.canCurrentAccountArchiveWall) return false
    return selectedWalls.value.every(
      (wall) => wall.archivedAt === null && wall.builder.id === dashAccountsStore.currentUser.id,
    )
  })
  const canTrashAllSelectedWalls = computed((): boolean =>
    selectedWalls.value.every((wall) => wall.builder.id === dashAccountsStore.currentUser.id),
  )
  const canLeaveAllSelectedWalls = computed((): boolean =>
    selectedWalls.value.every((wall) => wall.collaboratorRight > WALL_ACCESS_RIGHT_TO_INTEGER_MAP.none),
  )
  const canTransferAllSelectedPadlets = computed((): boolean => {
    if (!dashAccountsStore.isNativeAccount) return false
    if (dashAccountsStore.transferableAccounts.length === 0) return false
    return selectedWalls.value.every((wall) => wall.builder.id === dashAccountsStore.currentUser.id)
  })
  const isSingleSelectedWallInFolder = computed((): boolean => {
    if (!isOnlyOnePadletSelected.value) return false
    const singleWallId = selectedWalls.value[0].id

    // We check if the wall is in the favourites folder first
    // If it is, we return true since we only need the wall to be in one folder
    const isWallInFavouritesFolder = favoritesFolderWallIds.value.includes(singleWallId)
    if (isWallInFavouritesFolder) return true
    // We then check if the wall is in any of the non-favourites folder
    const isWallInNonFavouritesFolder = Object.values(wallIdsByFolderId.value).some((wallIds) =>
      wallIds.includes(singleWallId),
    )
    return isWallInNonFavouritesFolder
  })

  const allBulkActionButtons = computed((): Partial<Record<WallActionType, WallActionButton>> => {
    return {
      [WallActionType.RemoveFromRecents]: {
        text: __('Remove from recents'),
        icon: 'recent_slash',
        handleClick: async () => await handleClick(WallActionType.RemoveFromRecents),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.RemoveFromRecents),
      },
      [WallActionType.AddBookmarks]: {
        text: __('Add bookmarks'),
        icon: 'star_outline',
        handleClick: () => (xDashBulkBookmarksModal.value = true),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: () => (xDashBulkBookmarksModal.value = true),
      },
      [WallActionType.RemoveFromFolder]: {
        text: __('Remove from folder'),
        icon: 'star_outline_slash',
        handleClick: async () => await handleClick(WallActionType.RemoveFromFolder),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.RemoveFromFolder),
      },
      [WallActionType.LeaveAll]: {
        text: __('Leave all'),
        icon: 'exit',
        handleClick: async () => await handleConfirmationDialog(WallActionType.LeaveAll),
        shouldShow: canLeaveAllSelectedWalls.value,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleConfirmationDialog(WallActionType.LeaveAll),
      },
      [WallActionType.ArchiveAll]: {
        text: __('Archive all'),
        icon: 'archive_outline',
        handleClick: async () => await handleClick(WallActionType.ArchiveAll),
        shouldShow: canArchiveAllSelectedWalls.value,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.ArchiveAll),
      },
      [WallActionType.UnarchiveAll]: {
        text: __('Unarchive all'),
        icon: 'archive_outline',
        handleClick: async () => await handleClick(WallActionType.UnarchiveAll),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.UnarchiveAll),
      },
      [WallActionType.TrashAll]: {
        text: __('Trash all'),
        icon: 'delete',
        handleClick: async () => await handleClick(WallActionType.TrashAll),
        shouldShow: canTrashAllSelectedWalls.value,
        buttonColorScheme: OzContainedButtonColorScheme.SecondaryDestructive,
        eventPayload: async () => await handleClick(WallActionType.TrashAll),
        rowStyle: OzMenuRowsRowStyle.Danger,
      },
      [WallActionType.RestoreAll]: {
        text: __('Restore all'),
        icon: 'import_outline',
        handleClick: async () => await handleClick(WallActionType.RestoreAll),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.RestoreAll),
      },
      [WallActionType.PermanentlyDeleteAll]: {
        text: __('Permanently delete all'),
        handleClick: async () => await handleConfirmationDialog(WallActionType.PermanentlyDeleteAll),
        icon: 'delete',
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.SecondaryDestructive,
        eventPayload: async () => await handleConfirmationDialog(WallActionType.PermanentlyDeleteAll),
        rowStyle: OzMenuRowsRowStyle.Danger,
      },
      [WallActionType.TransferAll]: {
        text: __('Transfer padlets'),
        icon: 'post_transfer',
        handleClick: () => (xDashBulkTransferWallsModal.value = true),
        shouldShow: canTransferAllSelectedPadlets.value,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: () => (xDashBulkTransferWallsModal.value = true),
      },
    }
  })

  const singleWallActionButtons = computed((): Partial<Record<WallActionType, WallActionButton>> => {
    return {
      [WallActionType.RemoveFromRecents]: {
        text: __('Remove from recents'),
        icon: 'recent_slash',
        handleClick: async () => await handleClick(WallActionType.RemoveFromRecents),
        shouldShow: selectedWalls.value.every((wall) => wall.isInRecents),
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.RemoveFromRecents),
      },
      [WallActionType.AddBookmarks]: {
        text: __('Add bookmark'),
        icon: 'star_outline',
        handleClick: () => (xDashBulkBookmarksModal.value = true),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
      },
      [WallActionType.RemoveFromFolder]: {
        text: __('Edit bookmark'),
        icon: 'star_edit',
        handleClick: () => (xDashBulkBookmarksModal.value = true),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.RemoveFromFolder),
      },
      [WallActionType.RemoveFromAllFolders]: {
        text: __('Remove from folder'),
        icon: 'star_outline_slash',
        handleClick: async () => await handleClick(WallActionType.RemoveFromAllFolders),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.RemoveFromAllFolders),
      },
      [WallActionType.LeaveAll]: {
        text: __('Leave padlet'),
        icon: 'exit',
        handleClick: async () => await handleConfirmationDialog(WallActionType.LeaveAll),
        shouldShow: canLeaveAllSelectedWalls.value,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
      },
      [WallActionType.ArchiveAll]: {
        text: __('Archive padlet'),
        icon: 'archive_outline',
        handleClick: async () => await handleClick(WallActionType.ArchiveAll),
        shouldShow: canArchiveAllSelectedWalls.value,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
      },
      [WallActionType.UnarchiveAll]: {
        text: __('Unarchive padlet'),
        icon: 'archive_outline',
        handleClick: async () => await handleClick(WallActionType.UnarchiveAll),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
      },
      [WallActionType.TrashAll]: {
        text: __('Move to trash'),
        icon: 'delete',
        handleClick: async () => await handleClick(WallActionType.TrashAll),
        shouldShow: canTrashAllSelectedWalls.value,
        buttonColorScheme: OzContainedButtonColorScheme.SecondaryDestructive,
      },
      [WallActionType.RestoreAll]: {
        text: __('Restore'),
        icon: 'import_outline',
        handleClick: async () => await handleClick(WallActionType.RestoreAll),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
      },
      [WallActionType.PermanentlyDeleteAll]: {
        text: __('Permanently delete'),
        icon: 'delete',
        handleClick: async () => await handleConfirmationDialog(WallActionType.PermanentlyDeleteAll),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.SecondaryDestructive,
      },
      [WallActionType.OpenSlideShow]: {
        text: __('Open slideshow'),
        icon: 'play_outline',
        handleClick: async () => await handleClick(WallActionType.OpenSlideShow),
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
      },
      [WallActionType.CopyLinkToPadlet]: {
        text: __('Copy link to padlet'),
        handleClick: async () => await handleClick(WallActionType.CopyLinkToPadlet),
        icon: 'link_filled',
        shouldShow: true,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
        eventPayload: async () => await handleClick(WallActionType.CopyLinkToPadlet),
      },
      [WallActionType.TransferAll]: {
        text: __('Transfer padlet'),
        icon: 'post_transfer',
        handleClick: () => (xDashBulkTransferWallsModal.value = true),
        shouldShow: canTransferAllSelectedPadlets.value,
        buttonColorScheme: OzContainedButtonColorScheme.Secondary,
      },
    }
  })

  const collectionActions = computed((): Partial<WallActionButton[]> => {
    if (isCollectionRecents.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]
      return [
        allBulkActionButtons.value[WallActionType.RemoveFromRecents],
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.TrashAll],
      ]
    }
    if (isCollectionBookmark.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[WallActionType.RemoveFromFolder],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]
      return [
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.RemoveFromFolder],
        singleWallActionButtons.value[WallActionType.TrashAll],
      ]
    }
    if (isCollectionAll.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.TransferAll],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.ArchiveAll],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]
      return [
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.TransferAll],
        allBulkActionButtons.value[WallActionType.ArchiveAll],
        allBulkActionButtons.value[WallActionType.TrashAll],
      ]
    }
    if (isCollectionMade.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.TransferAll],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.ArchiveAll],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]

      return [
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.TransferAll],
        allBulkActionButtons.value[WallActionType.ArchiveAll],
        allBulkActionButtons.value[WallActionType.TrashAll],
      ]
    }

    if (isCollectionPrivate.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.TransferAll],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.ArchiveAll],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]

      return [
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.TransferAll],
        allBulkActionButtons.value[WallActionType.ArchiveAll],
        allBulkActionButtons.value[WallActionType.TrashAll],
      ]
    }

    if (isCollectionTemplates.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.TransferAll],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.ArchiveAll],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]

      return [
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.TransferAll],
        allBulkActionButtons.value[WallActionType.ArchiveAll],
        allBulkActionButtons.value[WallActionType.TrashAll],
      ]
    }

    if (isCollectionArchived.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.UnarchiveAll],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]

      return [
        allBulkActionButtons.value[WallActionType.UnarchiveAll],
        allBulkActionButtons.value[WallActionType.TrashAll],
      ]
    }

    if (isCollectionTrashed.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[WallActionType.RestoreAll],
          singleWallActionButtons.value[WallActionType.PermanentlyDeleteAll],
        ]

      return [
        allBulkActionButtons.value[WallActionType.RestoreAll],
        allBulkActionButtons.value[WallActionType.PermanentlyDeleteAll],
      ]
    }

    if (isCollectionGroups.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.ArchiveAll],
          singleWallActionButtons.value[WallActionType.TrashAll],
        ]
      return [
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.ArchiveAll],
        allBulkActionButtons.value[WallActionType.TrashAll],
      ]
    }
    if (isCollectionShared.value) {
      if (isOnlyOnePadletSelected.value)
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
          singleWallActionButtons.value[WallActionType.RemoveFromRecents],
          singleWallActionButtons.value[WallActionType.LeaveAll],
        ]

      return [
        allBulkActionButtons.value[WallActionType.AddBookmarks],
        allBulkActionButtons.value[WallActionType.LeaveAll],
      ]
    }
    if (isCollectionGallery.value) {
      if (isOnlyOnePadletSelected.value) {
        return [
          singleWallActionButtons.value[
            isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
          ],
          singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
          singleWallActionButtons.value[WallActionType.OpenSlideShow],
        ]
      }
      return [allBulkActionButtons.value[WallActionType.AddBookmarks]]
    }

    // Default action
    if (isOnlyOnePadletSelected.value) {
      return [
        singleWallActionButtons.value[
          isSingleSelectedWallInFolder.value ? WallActionType.RemoveFromFolder : WallActionType.AddBookmarks
        ],
        singleWallActionButtons.value[WallActionType.CopyLinkToPadlet],
        singleWallActionButtons.value[WallActionType.OpenSlideShow],
      ]
    }
    return [allBulkActionButtons.value[WallActionType.AddBookmarks]]
  })

  const bulkActions = computed(
    (): Partial<WallActionButton[]> => collectionActions.value.filter((action) => action?.shouldShow),
  )

  // Actions

  function clearPreselectedWallIds(): void {
    preselectedWallIds.value = []
  }

  function selectWall(wall: Wall, event: PointerEvent | MouseEvent | KeyboardEvent): void {
    if (event.shiftKey) {
      handleShiftClick(wall)
      trackEvent('Dashboard multiselect', 'Shift click')
      return
    }

    // We reset the shiftClickPivotWall and selectedWallsIndexesBeforeShiftClick since the user is no longer shift-clicking
    shiftClickPivotWallId.value = undefined
    selectedWallsIndexesBeforeShiftClick.value = []

    const isCurrentWallSelected = selectedWalls.value.find((selectedWall) => selectedWall.id === wall.id) !== undefined
    // Meta key = command key in Mac OS
    // When the command/control key is pressed, the user is selecting multiple cards
    if (event.metaKey || event.ctrlKey) {
      trackEvent('Dashboard multiselect', 'Cmd click')
      // If the current wall is already selected, we remove it
      if (isCurrentWallSelected) {
        selectedWalls.value = selectedWalls.value.filter((selectedWall) => selectedWall.id !== wall.id)
        return
      }

      selectedWalls.value.push(wall)
      return
    }

    if (isCurrentWallSelected) {
      // If the user is not left-clicking, the user is not selecting a wall
      // As such, we do not need to handle this case
      const isLeftClick = 'button' in event && event.button === 0
      if (!isLeftClick) return

      // If the current wall is selected alongside others, only it will remain selected
      if (selectedWalls.value.length > 1) {
        selectedWalls.value = [wall]
      }
      return
    }

    // Select current wall
    selectedWalls.value = [wall]
  }

  function getActiveCollectionSelectedWallsIndexes(): number[] {
    return selectedWalls.value.map((selectedWall) =>
      dashCollectionsStore.activeCollectionWalls.findIndex((collectionWall) => collectionWall.id === selectedWall.id),
    )
  }

  function getIndexesOutsideShiftClickRange(indexes: number[], startIndex: number, endIndex: number): number[] {
    return indexes.filter((index) => index < startIndex || index > endIndex)
  }

  function getWallsWithinShiftClickRangeOrWallsToKeep(
    wallIndexesToKeep: number[],
    startIndex: number,
    endIndex: number,
  ): Wall[] {
    // We select the walls if the wall index is either within the startIndex and endIndex
    // or if the wall index is within wallIndexesToKeep
    return dashCollectionsStore.activeCollectionWalls.filter((_, index) => {
      if (startIndex <= index && index <= endIndex) return true
      if (wallIndexesToKeep.includes(index)) return true
      return false
    })
  }

  function handleShiftClick(wall: Wall): void {
    // If no walls are selected, we select the current wall
    if (selectedWalls.value.length === 0) {
      selectedWalls.value = [wall]
      return
    }

    // We assign the pivot wall the first time a user shift clicks
    if (shiftClickPivotWallId.value === undefined) {
      shiftClickPivotWallId.value = selectedWalls.value[selectedWalls.value.length - 1].id
      selectedWallsIndexesBeforeShiftClick.value = getActiveCollectionSelectedWallsIndexes()
    }

    let shiftClickWallIndex = 0
    let shiftClickPivotWallIndex = 0
    dashCollectionsStore.activeCollectionWalls.forEach((collectionWall, index) => {
      if (collectionWall.id === wall.id) {
        shiftClickWallIndex = index
        return
      }
      if (collectionWall.id === shiftClickPivotWallId.value) {
        shiftClickPivotWallIndex = index
      }
    })

    // The user shift clicks before the pivot wall
    if (shiftClickWallIndex < shiftClickPivotWallIndex) {
      const wallIndexesToKeep = getIndexesOutsideShiftClickRange(
        selectedWallsIndexesBeforeShiftClick.value,
        shiftClickWallIndex,
        shiftClickPivotWallIndex,
      )
      selectedWalls.value = getWallsWithinShiftClickRangeOrWallsToKeep(
        wallIndexesToKeep,
        shiftClickWallIndex,
        shiftClickPivotWallIndex,
      )
      // We update the indexes to only include those that are not within the shift-clicked walls
      selectedWallsIndexesBeforeShiftClick.value = wallIndexesToKeep
      return
    }

    // The user shift clicks after the pivot wall
    const wallIndexesToKeep = getIndexesOutsideShiftClickRange(
      selectedWallsIndexesBeforeShiftClick.value,
      shiftClickPivotWallIndex,
      shiftClickWallIndex,
    )
    selectedWalls.value = getWallsWithinShiftClickRangeOrWallsToKeep(
      wallIndexesToKeep,
      shiftClickPivotWallIndex,
      shiftClickWallIndex,
    )
    // We update the indexes to only include those that are not within the shift-clicked walls
    selectedWallsIndexesBeforeShiftClick.value = wallIndexesToKeep
  }

  function unselectAllWalls(): void {
    selectedWalls.value = []
    // We reset the shiftClickPivotWallId and selectedWallsIndexesBeforeShiftClick
    // since the user could unselect all walls after shift-clicking but not non-shift-click a wall
    shiftClickPivotWallId.value = undefined
    selectedWallsIndexesBeforeShiftClick.value = []
  }

  function handleCloseTransferWallModal(): void {
    unselectAllWalls()
    xDashBulkTransferWallsModal.value = false
  }

  function checkQuotaAndPromptUpgrade(wallActionType: WallActionType): boolean {
    if (isCurrentAccountUser.value) {
      const allSelectedWallsSandbox = selectedWalls.value.every((wall) => wall.viz === 'whiteboard')
      if (isPartOfUnlimitedSandboxesEvent() && allSelectedWallsSandbox) {
        return false
      }
      // If the user's current wall used + the number of selected walls exceeds the walls limit or if user cannot make walls
      // we show the upgrade modal
      if (
        dashAccountsStore.currentUser.quota.walls_used + selectedWalls.value.length >
          dashAccountsStore.currentUser.quota.walls_limit ||
        !dashAccountsStore.currentUser.quota.can_make
      ) {
        void personalPlansStore.quotaTriggeredUpgrade({
          user: dashAccountsStore.currentUser,
          upgradeSource: UpgradeSource.UntrashWallQuota,
        })
        return true
      }
      return false
    }

    if (currentLibrary.value == null) return false

    // We show the upgrade modal if the library is either expired, cancelled, has no more quota
    // or if the library's wallsUsed + the number of selected walls exceeds the walls limit
    if (
      currentLibrary.value.isTrialExpired === true ||
      currentLibrary.value.isPlanCancelled ||
      currentLibrary.value.quota.quotaHit ||
      currentLibrary.value.quota.wallsUsed + selectedWalls.value.length > currentLibrary.value.quota.wallsLimit
    ) {
      if (dashAccountsStore.currentUser.id === currentLibrary.value.ownerId) {
        void libraryPlansStore.fetchPlansAndShowQuotaUpsellModal({
          upgradeStep:
            currentLibrary.value.libraryType === LibraryType.Classroom
              ? LibraryUpgradeStep.ChooseTierPadletQuota
              : LibraryUpgradeStep.ScheduleTeamGold,
          upgradeSource:
            wallActionType === WallActionType.UnarchiveAll || wallActionType === WallActionType.ArchiveAll
              ? LibraryUpgradeSource.UnarchiveWallQuota
              : LibraryUpgradeSource.UntrashWallQuota,
          library: currentLibrary.value,
        })
        return true
      }
      // show an alert to non owners
      const alertBody =
        currentLibrary.value.libraryType === LibraryType.Classroom
          ? __('Please contact a teacher to upgrade your plan.')
          : __('Please contact the owner of the team to upgrade your plan.')
      globalAlertDialogStore.openAlertDialog({
        iconSrc: anxiousFace,
        iconAlt: __('Anxious face'),
        title: __('Unable to restore padlet'),
        body: alertBody,
        xShadow: true,
      })
      return true
    }
    return false
  }

  async function handleBulkAction(
    wallActionType: WallActionType,
    params?: WallActionTypeParams,
    keepSelectedWalls: boolean = false,
  ): Promise<void> {
    // If the user is doing unarchive/restore, we prevent the action if user is out of quota
    if (wallActionType === WallActionType.UnarchiveAll || wallActionType === WallActionType.RestoreAll) {
      if (checkQuotaAndPromptUpgrade(wallActionType)) return
    }

    // We do not want to show the in progress snackbar for CopyLinkToPadlet and OpenSlideShow
    const xInProgressSnackbar =
      wallActionType !== WallActionType.CopyLinkToPadlet && wallActionType !== WallActionType.OpenSlideShow

    let bulkActionInProgressSnackbar: string | undefined
    if (xInProgressSnackbar) {
      bulkActionInProgressSnackbar = globalSnackbarStore.setSnackbar({
        message: isOnlyOnePadletSelected.value
          ? singleActionInProgressMessage[wallActionType]
          : bulkActionsInProgressMessage[wallActionType],
        persist: true,
      })
    }

    const wallActionTypeToAction = {
      [WallActionType.RemoveFromRecents]: async (wall: Wall) =>
        await dashWallSingleActionsStore.removeWallFromRecents(wall.id, false),
      [WallActionType.AddBookmarks]: async (wall: Wall) => {
        const folderId = params?.folderId === undefined ? null : params.folderId // folder is favourites if it is null
        // If wall is already in the folder, we do not add it again
        if (folderId !== null && wallIdsByFolderId.value[folderId].includes(wall.id)) return
        // If wall is already in favourites, we do not add it again
        if (folderId === null && favoritesFolderWallIds.value.includes(wall.id)) return
        await dashWallSingleActionsStore.addBookmark({
          folderId,
          wallId: wall.id,
          throwException: true, // // We have to specify this as true since it is undefined by default as we cannot specify a default value
        })
      },
      [WallActionType.RemoveFromFolder]: async (wall: Wall) => {
        // If the params are undefined, it means that the user is clicking on `Remove from folder` for multiple padlets
        if (params === undefined) {
          await dashWallSingleActionsStore.removeBookmark({
            folderId: activeFolderId.value,
            wallId: wall.id,
            throwException: true, // We have to specify this as true since it is undefined by default as we cannot specify a default value
          })
          return
        }

        const folderId = params.folderId === undefined ? null : params.folderId // folder is favourites if it is null
        await dashWallSingleActionsStore.removeBookmark({
          folderId,
          wallId: wall.id,
          throwException: true, // We have to specify this as true since it is undefined by default as we cannot specify a default value
        })
      },
      [WallActionType.RemoveFromAllFolders]: async (wall: Wall) => {
        const folderIds = Object.keys(wallIdsByFolderId.value)
        await Promise.allSettled(
          folderIds.map(async (folderId) => {
            // We only remove the wall from the folder if is in the folder
            if (wallIdsByFolderId.value[Number(folderId)].includes(wall.id)) {
              await dashWallSingleActionsStore.removeBookmark({
                folderId: Number(folderId),
                wallId: wall.id,
                throwException: true, // We have to specify this as true since it is undefined by default as we cannot specify a default value
              })
            }
          }),
        )

        if (favoritesFolderWallIds.value.includes(wall.id)) {
          // We only remove the wall from the favourites if is in favourites
          await dashWallSingleActionsStore.removeBookmark({
            folderId: null,
            wallId: wall.id,
            throwException: true, // We have to specify this as true since it is undefined by default as we cannot specify a default value
          })
        }
      },
      [WallActionType.LeaveAll]: async (wall: Wall) => await dashWallSingleActionsStore.leaveWall(wall.id),
      [WallActionType.ArchiveAll]: async (wall: Wall) => await dashWallSingleActionsStore.archiveWall(wall.id, false),
      [WallActionType.UnarchiveAll]: async (wall: Wall) => await dashWallSingleActionsStore.unarchiveWall(wall.id),
      [WallActionType.TrashAll]: async (wall: Wall) => await dashWallSingleActionsStore.trashWall(wall.id),
      [WallActionType.RestoreAll]: async (wall: Wall) => await dashWallSingleActionsStore.untrashWall(wall.id),
      [WallActionType.PermanentlyDeleteAll]: async (wall: Wall) =>
        await dashWallSingleActionsStore.permanentlyDeleteWall(wall.id, false),
      [WallActionType.OpenSlideShow]: async (wall: Wall) => {
        const coverPageUrl = buildSlideshowLink(wall.links.show, PageType.Cover)
        navigateTo(coverPageUrl, { target: '_blank' })
      },
      [WallActionType.CopyLinkToPadlet]: async (wall: Wall) =>
        await navigator.clipboard.writeText(`${currentHostWithProtocol()}/${wall.address}`),
    }

    if (xBulkWallActions.value) {
      dashWallSingleActionsStore.hideWallActions()
    }

    const promises = await Promise.allSettled(selectedWalls.value.map(wallActionTypeToAction[wallActionType]))
    const numberOfFailedRequests = promises.filter((promise) => promise.status === 'rejected').length

    handleBukActionTracking(wallActionType)

    if (numberOfFailedRequests === 0) {
      if (bulkActionInProgressSnackbar !== undefined) {
        globalSnackbarStore.removeSnackbar(bulkActionInProgressSnackbar)
      }
      await handleCompletedBulkAction(wallActionType, params, keepSelectedWalls)
      return
    }

    // If there are any failed request, we prevent the user from being able to undo
    // and we show an error snackbar instead
    if (bulkActionInProgressSnackbar !== undefined) {
      globalSnackbarStore.removeSnackbar(bulkActionInProgressSnackbar)
    }
    globalSnackbarStore.setSnackbar({
      message: n__(
        'Something went wrong for %{numberOfFailedRequests} padlet',
        'Something went wrong for %{numberOfFailedRequests} padlets',
        numberOfFailedRequests,
        {
          numberOfFailedRequests,
        },
      ),
      timeout: 2500,
      notificationType: SnackbarNotificationType.error,
    })
    // We should unselect all walls if any of thr request failed
    unselectAllWalls()
    // If the wall action type is related to folders
    // we close the bookmarks modal and fetch folders to fetch the most updated folders
    if (wallActionType === WallActionType.RemoveFromFolder || wallActionType === WallActionType.AddBookmarks) {
      xDashBulkBookmarksModal.value = false
      await dashCollectionsStore.fetchFolders()
    }
  }

  async function handleCompletedBulkAction(
    wallActionType: WallActionType,
    params?: WallActionTypeParams,
    keepSelectedWalls: boolean = false,
  ): Promise<void> {
    // We currently do not support undoing of the following wall action buttons
    const cannotUndoBulkActions =
      wallActionType === WallActionType.RemoveFromRecents ||
      wallActionType === WallActionType.LeaveAll ||
      wallActionType === WallActionType.PermanentlyDeleteAll ||
      wallActionType === WallActionType.RemoveFromAllFolders ||
      wallActionType === WallActionType.OpenSlideShow ||
      wallActionType === WallActionType.CopyLinkToPadlet

    if (cannotUndoBulkActions) {
      globalSnackbarStore.setSnackbar({
        message: isOnlyOnePadletSelected.value
          ? singleActionCompletedMessage[wallActionType]
          : bulkActionsCompletedMessage[wallActionType],
        timeout: 2500,
      })

      if (!keepSelectedWalls) {
        // Once the actions are done we unselect all walls
        unselectAllWalls()
      }
      return
    }

    const currentSelectedWalls = selectedWalls.value
    const bulkActionCompletedSnackbar = globalSnackbarStore.setSnackbar({
      message: isOnlyOnePadletSelected.value
        ? singleActionCompletedMessage[wallActionType]
        : bulkActionsCompletedMessage[wallActionType],
      timeout: 2500,
      actionText: __('Undo'),
      actionTextActions: [
        async (event: Event) => {
          event.stopPropagation()
          globalSnackbarStore.removeSnackbar(bulkActionCompletedSnackbar)
          await handleUndoBulkAction(wallActionType, currentSelectedWalls, params)
          if (requiresWallQuotasRefresh(wallActionType)) {
            await dashAccountsStore.refreshWallQuotas({ forceQuotaRefresh: true })
          }
        },
      ],
    })

    if (!keepSelectedWalls) {
      // Once the actions are done we unselect all walls
      unselectAllWalls()
    }
  }

  async function handleUndoBulkAction(
    wallActionType: WallActionType,
    wallsToUndo: Wall[],
    params?: WallActionTypeParams,
  ): Promise<void> {
    // If the user is undoing archive/trash, we prevent the undo action if user is out of quota
    if (wallActionType === WallActionType.ArchiveAll || wallActionType === WallActionType.TrashAll) {
      if (checkQuotaAndPromptUpgrade(wallActionType)) return
    }

    const bulkActionUndoSnackbar = globalSnackbarStore.setSnackbar({
      message: isOnlyOnePadletSelected.value
        ? singleActionUndoingMessage[wallActionType]
        : bulkActionsUndoingsMessage[wallActionType],
      persist: true,
    })

    const wallActionTypeToAction = {
      [WallActionType.AddBookmarks]: async (wall: Wall) => {
        const folderId = params?.folderId === undefined ? null : params.folderId // folder is favourites if it is null
        // We remove all selected walls from the bookmark, even if the walls was not added during bulk add bookmarks
        await dashWallSingleActionsStore.undoAddBookmark({
          folderId,
          wallId: wall.id,
          throwException: true, // We have to specify this as true since it is undefined by default as we cannot specify a default value
        })
      },
      [WallActionType.RemoveFromFolder]: async (wall: Wall) =>
        await dashWallSingleActionsStore.undoRemoveBookmark({
          folderId: activeFolderId.value,
          wallId: wall.id,
          throwException: true, // We have to specify this as true since it is undefined by default as we cannot specify a default value
        }),
      [WallActionType.ArchiveAll]: async (wall: Wall) =>
        await dashWallSingleActionsStore.undoArchiveWall(wall.id, false),
      [WallActionType.UnarchiveAll]: async (wall: Wall) =>
        await dashWallSingleActionsStore.undoUnarchiveWall(wall.id, false),
      [WallActionType.TrashAll]: async (wall: Wall) => await dashWallSingleActionsStore.undoTrashWall(wall.id, false),
      [WallActionType.RestoreAll]: async (wall: Wall) =>
        await dashWallSingleActionsStore.undoUntrashWall(wall.id, false),
    }

    const promises = await Promise.allSettled(wallsToUndo.map(wallActionTypeToAction[wallActionType]))
    const numberOfFailedRequests = promises.filter((promise) => promise.status === 'rejected').length

    if (numberOfFailedRequests === 0) {
      globalSnackbarStore.removeSnackbar(bulkActionUndoSnackbar)
      await dashCollectionsStore.fetchActiveCollectionWallsNow()
      globalSnackbarStore.setSnackbar({
        message: isOnlyOnePadletSelected.value
          ? singleActionUndonedMessage[wallActionType]
          : bulkActionsUndonedMessage[wallActionType],
        timeout: 1500,
      })
      return
    }

    // If there are any failed request, we prevent the user from being able to undo
    // and we show an error snackbar instead
    globalSnackbarStore.removeSnackbar(bulkActionUndoSnackbar)
    globalSnackbarStore.setSnackbar({
      message: n__(
        'Something went wrong for %{numberOfFailedRequests} padlet',
        'Something went wrong for %{numberOfFailedRequests} padlets',
        numberOfFailedRequests,
        {
          numberOfFailedRequests,
        },
      ),
      timeout: 2500,
      notificationType: SnackbarNotificationType.error,
    })
    // We should unselect all walls if any of thr request failed
    unselectAllWalls()
  }

  function confirmationDialogPayload(wallActionType: WallActionType): Partial<ConfirmationDialogPayload> | undefined {
    if (wallActionType === WallActionType.PermanentlyDeleteAll) {
      if (backendState.is_recently_verified === true) {
        return {
          ...ALERT_ICON,
          title: __('Delete these padlets?'),
          body: __('This action cannot be undone.'),
          confirmButtonText: __('Delete'),
          cancelButtonText: __('Cancel'),
          buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
          shouldFadeIn: false,
          afterConfirmActions: [async () => await handleBulkAction(wallActionType)],
        }
      }

      return {
        shouldFadeIn: false,
        isCodeProtected: false,
        title: __('Log in to verify your account'),
        body: __('For security purposes please log in to your account to verify it’s you.'),
        confirmButtonText: __('Log in'),
        cancelButtonText: __('Cancel'),
        buttonScheme: OzConfirmationDialogBoxButtonScheme.Default,
        afterConfirmActions: [dashWallSingleActionsStore.navigateToVerifyIdentity],
      }
    }

    if (wallActionType === WallActionType.LeaveAll) {
      return {
        ...WAVING_HAND_EMOJI,
        title: __('Leave these padlets?'),
        body: __('You will have to be invited back by the padlets` administrator.'),
        confirmButtonText: __('Leave'),
        cancelButtonText: __('Nevermind'),
        buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
        shouldFadeIn: false,
        afterConfirmActions: [async () => await handleBulkAction(wallActionType)],
      }
    }

    return undefined
  }

  async function handleConfirmationDialog(wallActionType: WallActionType): Promise<void> {
    const payload = confirmationDialogPayload(wallActionType)

    // If the payload is undefined, we perform the bulk action and not trigger the confirmation dialog
    if (payload === undefined) {
      await handleBulkAction(wallActionType)
      return
    }

    globalConfirmationDialogStore.openConfirmationDialog(payload as ConfirmationDialogPayload)
  }

  function handleBukActionTracking(wallActionType: WallActionType): void {
    const wallActionTypeTrackingText: Record<WallActionType, string> = {
      [WallActionType.RemoveFromRecents]: 'Click on remove from recents button',
      [WallActionType.AddBookmarks]: 'Click on add bookmarks button',
      [WallActionType.RemoveFromFolder]: 'Click on remove from folder button',
      [WallActionType.RemoveFromAllFolders]: 'Click on remove from all folders button',
      [WallActionType.LeaveAll]: 'Click on leave all button',
      [WallActionType.ArchiveAll]: 'Click on archive all button',
      [WallActionType.UnarchiveAll]: 'Click on unarchive all button',
      [WallActionType.TrashAll]: 'Click on trash all button',
      [WallActionType.RestoreAll]: 'Click on restore all button',
      [WallActionType.PermanentlyDeleteAll]: 'Click on permanently delete all button',
      [WallActionType.OpenSlideShow]: 'Click on open slideshow bulk action button',
      [WallActionType.CopyLinkToPadlet]: 'Click on copy link to padlet button',
      [WallActionType.TransferAll]: 'Click on transfer padlets button',
    }
    trackEvent('Dashboard multiselect', wallActionTypeTrackingText[wallActionType])
  }

  async function handleClick(wallActionType: WallActionType): Promise<void> {
    await handleBulkAction(wallActionType)
    if (requiresWallQuotasRefresh(wallActionType)) {
      await dashAccountsStore.refreshWallQuotas({ forceQuotaRefresh: true })
    }
  }

  function requiresWallQuotasRefresh(wallActionType: WallActionType): boolean {
    return [
      WallActionType.ArchiveAll,
      WallActionType.UnarchiveAll,
      WallActionType.TrashAll,
      WallActionType.RestoreAll,
    ].includes(wallActionType)
  }

  async function transferWalls(selectedWallIds: WallId[], destinationLibraryId: LibraryId | null): Promise<void> {
    const wallIds: WallId[] = []
    const promises: Array<Promise<void>> = []
    for (const wallId of selectedWallIds) {
      wallIds.push(wallId)
      promises.push(
        WallApi.transfer({
          wallId,
          destinationLibraryId,
        }),
      )
    }

    const results = await Promise.allSettled(promises)
    const successWallIds: WallId[] = []
    const failedResults: PromiseRejectedResult[] = []
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        successWallIds.push(wallIds[index])
      } else {
        failedResults.push(result)
      }
    })

    const destinationLibraryName =
      destinationLibraryId == null
        ? displayNameForUser(dashAccountsStore.user)
        : dashAccountsStore.librariesById[destinationLibraryId].name

    if (successWallIds.length > 0) {
      void userAccountsStore.refreshWallQuotas({ forceQuotaRefresh: true })
      successWallIds.map((wallId) => dashCollectionsStore.removeWallIdFromAllCollections(wallId))

      const snackbarUid = globalSnackbarStore.setSnackbar({
        message: n__(
          'This padlet transferred to %{libraryName}',
          '%{count} padlets transferred to %{libraryName}',
          successWallIds.length,
          {
            count: successWallIds.length,
            libraryName: destinationLibraryName,
          },
        ),
        shouldTruncate: true,
        notificationType: SnackbarNotificationType.success,
        timeout: 5000,
        actionText: __('Undo'),
        actionTextActions: [
          () => {
            globalSnackbarStore.removeSnackbar(snackbarUid)
            void undoTransferWalls(successWallIds, dashAccountsStore.currentLibrary?.id ?? null)
          },
        ],
      })
    }

    if (failedResults.length > 0) {
      const isExceedsWallQuotaError = failedResults.some((result) => {
        const parsedErrorMessage = JSON.parse(result.reason.message)
        return parsedErrorMessage.errors[0].code === ApiErrorCode.EXCEEDS_WALL_QUOTA_LIMIT
      })

      if (isExceedsWallQuotaError) {
        globalSnackbarStore.setSnackbar({
          message: __("You've hit the padlet quota for %{libraryName}", {
            libraryName: destinationLibraryName,
          }),
          shouldTruncate: true,
          timeout: 2500,
          notificationType: SnackbarNotificationType.error,
        })
      } else {
        globalSnackbarStore.setSnackbar({
          message: n__(
            'This padlet could not be transferred to %{libraryName}',
            '%{count} padlets failed to transfer',
            failedResults.length,
            {
              libraryName: destinationLibraryName,
              count: failedResults.length,
            },
          ),
          shouldTruncate: true,
          timeout: 2500,
          notificationType: SnackbarNotificationType.error,
        })
        failedResults.forEach(({ reason }) => {
          const parsedErrorMessage = JSON.parse(reason.message)
          if (
            ![ApiErrorCode.EXCEEDS_WALL_QUOTA_LIMIT, ApiErrorCode.NO_DESTINATION_PERMISSION].includes(
              parsedErrorMessage.errors[0].code,
            )
          ) {
            captureFetchException(reason, { source: 'DashWallBulkActionsTransferWalls' })
          }
        })
      }
    }
  }

  async function undoTransferWalls(selectedWallIds: WallId[], destinationLibraryId: LibraryId | null): Promise<void> {
    const wallIds: WallId[] = []
    const promises: Array<Promise<void>> = []
    for (const wallId of selectedWallIds) {
      wallIds.push(wallId)
      promises.push(
        WallApi.transfer({
          wallId,
          destinationLibraryId,
        }),
      )
    }

    const results = await Promise.allSettled(promises)
    const successWallIds: WallId[] = []
    const failedResults: PromiseRejectedResult[] = []
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        successWallIds.push(wallIds[index])
      } else {
        failedResults.push(result)
      }
    })

    if (successWallIds.length > 0) {
      void userAccountsStore.refreshWallQuotas({ forceQuotaRefresh: true })
      dashCollectionsStore.addWallIdsToCurrentCollection(successWallIds)

      globalSnackbarStore.setSnackbar({
        message: n__('Transferring padlet undone', 'Transferring padlets undone', successWallIds.length),
        shouldTruncate: true,
        notificationType: SnackbarNotificationType.success,
        timeout: 2500,
      })
    }

    if (failedResults.length > 0) {
      globalSnackbarStore.setSnackbar({
        message: __('Failed to undo transfer'),
        shouldTruncate: true,
        notificationType: SnackbarNotificationType.error,
        timeout: 2500,
      })
      failedResults.forEach(({ reason }) => {
        const parsedErrorMessage = JSON.parse(reason.message)
        if (
          ![ApiErrorCode.EXCEEDS_WALL_QUOTA_LIMIT, ApiErrorCode.NO_DESTINATION_PERMISSION].includes(
            parsedErrorMessage.errors[0].code,
          )
        ) {
          captureFetchException(reason, { source: 'DashWallBulkActionsUndoTransferWalls' })
        }
      })
    }
  }

  return {
    // State
    selectedWalls,
    preselectedWallIds,
    xDashBulkBookmarksModal,
    xDashBulkTransferWallsModal,

    // Getters
    bulkActions,
    numberOfWallsSelected,
    isMultiSelecting,
    xDashBulkActionToolbar,
    xBulkWallActions,
    xWallActions,
    isSingleSelectedWallInFolder,
    isOnlyOnePadletSelected,

    // Actions
    clearPreselectedWallIds,
    selectWall,
    unselectAllWalls,
    handleCloseTransferWallModal,
    handleBulkAction,
    handleBukActionTracking,
    transferWalls,
    handleConfirmationDialog,
  }
})
