// @file Surface activity panel store
// TODO: [to-be-migrated][user_contributor]
import { addDays, sameDay } from '@@/bits/date'
import { captureFetchException } from '@@/bits/error_tracker'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getVuexStore } from '@@/bits/pinia'
import { getPostPublishedAt } from '@@/bits/post_timestamps'
import PromiseQueue from '@@/bits/promise_queue'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import PadletApi from '@@/surface/padlet_api'
import type { Cid, Id, Post, PostFeedItemData, User, UserId, WallFollowApiResponse, WallId } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { orderBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum FeedItemType {
  PostCreated = 'PostCreated',
}

export interface FeedItem {
  id: Cid | Id
  type: FeedItemType
  data: PostFeedItemData
  emittedAt: string
}

enum FeedItemGroup {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Earlier = 'Earlier',
}

enum FeedItemsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

enum WallFollowCreationStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Completed = 'Completed',
}

const EARLIER_FEED_ITEMS_LIMIT = 30

export const useSurfaceActivityPanelStore = defineStore('surfaceActivityPanel', () => {
  const surfaceVuexStore = getVuexStore<RootState>()

  const globalSnackbarStore = useGlobalSnackbarStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceUserContributors = useSurfaceUserContributorsStore()

  const queue = new PromiseQueue()

  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */

  const xActivityPanel = ref<boolean>(false)
  const wallFollowCreationStatus = ref<WallFollowCreationStatus>(WallFollowCreationStatus.Initial)
  const feedItemsStatus = ref<FeedItemsStatus>(FeedItemsStatus.Loading)
  const earlierFeedItemsPage = ref<number>(1)

  /* ---------------------- */
  /* GETTERS                */
  /* ---------------------- */

  const isCreatingWallFollow = computed(() => wallFollowCreationStatus.value === WallFollowCreationStatus.Loading)
  const isLoadingFeedItems = computed(() => feedItemsStatus.value === FeedItemsStatus.Loading)
  const earlierFeedItemsCurrentLimit = computed(() => earlierFeedItemsPage.value * EARLIER_FEED_ITEMS_LIMIT)
  const isLastEarlierFeedItemsPage = computed(
    () => earlierFeedItemsCurrentLimit.value >= sortedEarlierFeedItems.value.length,
  )
  // 1. Map each type of feed item.
  const postFeedItems = computed((): PostFeedItemData[] => {
    if (isLoadingFeedItems.value) return []
    return surfacePostsStore.publishedPosts.map((post: Post): PostFeedItemData => {
      const author = surfaceUserContributors.getUserById(post.author_hashid ?? (post.author_id as number)) as User
      return {
        post,
        author,
      }
    })
  })
  // 2. Merge all groups of feed items.
  const feedItems = computed((): FeedItem[] => {
    // Right now, we're only showing one type of feed item.
    // We can update this getter once we add new types!
    return postFeedItems.value.map((postFeedItem: PostFeedItemData): FeedItem => {
      return {
        id: postFeedItem.post.cid,
        type: FeedItemType.PostCreated,
        data: postFeedItem,
        emittedAt: String(getPostPublishedAt(postFeedItem.post)),
      }
    })
  })
  // 3. Group feed items.
  const groupedFeedItems = computed((): Record<FeedItemGroup, FeedItem[]> => {
    const todayFeedItems: FeedItem[] = []
    const yesterdayFeedItems: FeedItem[] = []
    const earlierFeedItems: FeedItem[] = []
    const today = new Date()
    const yesterday = addDays(today, -1)
    for (const feedItem of feedItems.value) {
      const emittedAt = new Date(feedItem.emittedAt)
      if (sameDay(emittedAt, today)) {
        todayFeedItems.push(feedItem)
      } else if (sameDay(emittedAt, yesterday)) {
        yesterdayFeedItems.push(feedItem)
      } else {
        earlierFeedItems.push(feedItem)
      }
    }
    return {
      [FeedItemGroup.Today]: todayFeedItems,
      [FeedItemGroup.Yesterday]: yesterdayFeedItems,
      [FeedItemGroup.Earlier]: earlierFeedItems,
    }
  })
  // 4. Sort each feed item group.
  const getSortedFeedItems = computed(() => (feedItemGroup: FeedItemGroup): FeedItem[] => {
    return orderBy(groupedFeedItems.value[feedItemGroup], 'emittedAt', 'desc')
  })
  const sortedTodayFeedItems = computed((): FeedItem[] => {
    return getSortedFeedItems.value(FeedItemGroup.Today)
  })
  const sortedYesterdayFeedItems = computed((): FeedItem[] => {
    return getSortedFeedItems.value(FeedItemGroup.Yesterday)
  })
  const sortedEarlierFeedItems = computed((): FeedItem[] => {
    return getSortedFeedItems.value(FeedItemGroup.Earlier)
  })
  const limitedSortedEarlierFeedItems = computed((): FeedItem[] => {
    return sortedEarlierFeedItems.value.slice(0, earlierFeedItemsCurrentLimit.value)
  })

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */

  const showActivityPanel = (): void => {
    void fetchAuthors()
    xActivityPanel.value = true
  }

  const closeActivityPanel = (): void => {
    xActivityPanel.value = false
  }

  const fetchAuthors = async (): Promise<void> => {
    feedItemsStatus.value = FeedItemsStatus.Loading
    try {
      await surfaceUserContributors.fetchAndWaitForAuthors()
      feedItemsStatus.value = FeedItemsStatus.Completed
    } catch (e) {
      feedItemsStatus.value = FeedItemsStatus.Errored
    }
  }

  const toggleWallFollow = ({ wallFollowId, follows }: { wallFollowId: Id; follows: boolean }): void => {
    void surfaceVuexStore?.dispatch('updateWallFollows', { wallFollowId, followsWall: follows }, { root: true })
    void queue.enqueue(
      'updateWallFollow',
      async () =>
        await updateWallFollow({
          wallFollowId,
          follows,
        }),
    )
  }

  const updateWallFollow = async ({ wallFollowId, follows }: { wallFollowId: Id; follows: boolean }): Promise<void> => {
    try {
      await PadletApi.WallFollows.update(
        { wallFollowId },
        {
          body: asciiSafeStringify({
            data: {
              attributes: { follows },
            },
          }),
        },
      )
    } catch (e) {
      // If one of the requests fails, we don't want to execute
      // the following requests.
      queue.clear()
      captureFetchException(e, { source: 'WallFollowUpdate' })
      showGenericError()
      void surfaceVuexStore?.dispatch('updateWallFollows', { wallFollowId, followsWall: !follows }, { root: true })
    }
  }

  const createWallFollow = async ({
    wallId,
    userId,
    follows,
  }: {
    wallId: WallId
    userId: UserId
    follows: boolean
  }): Promise<void> => {
    wallFollowCreationStatus.value = WallFollowCreationStatus.Loading
    try {
      const response: JsonAPIResponse<WallFollowApiResponse> = await PadletApi.WallFollows.create({
        body: asciiSafeStringify({
          data: {
            attributes: { wall_id: wallId, user_id: userId, follows },
          },
        }),
      })
      void surfaceVuexStore?.dispatch(
        'updateWallFollows',
        { wallFollowId: Number((response.data as JsonAPIResource<WallFollowApiResponse>).id), followsWall: follows },
        { root: true },
      )
    } catch (e) {
      captureFetchException(e, { source: 'WallFollowCreation' })
      showGenericError()
    } finally {
      wallFollowCreationStatus.value = WallFollowCreationStatus.Completed
    }
  }

  const showGenericError = (): void => {
    globalSnackbarStore.genericFetchError()
  }

  const loadMoreFeedItems = (): void => {
    earlierFeedItemsPage.value = earlierFeedItemsPage.value + 1
  }

  return {
    // Getters
    xActivityPanel,
    isCreatingWallFollow,
    isLastEarlierFeedItemsPage,
    isLoadingFeedItems,
    sortedTodayFeedItems,
    sortedYesterdayFeedItems,
    limitedSortedEarlierFeedItems,

    // Actions
    showActivityPanel,
    closeActivityPanel,
    createWallFollow,
    loadMoreFeedItems,
    toggleWallFollow,
  }
})
