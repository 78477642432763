// @file Helper functions for parsing HTML

// Retrieve image src from element
const extractUrl = (str: string): string | null => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(str, 'text/html')
  const elem = doc.querySelector('iframe,img') as HTMLImageElement
  if (elem) {
    return elem.src
  } else {
    return null
  }
}

// https://stackoverflow.com/a/47140708/8943850
const stripHtml = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent ?? ''
}

export { extractUrl, stripHtml }
