// @file Composable for post sorting
import { shuffle as sortPostsByShuffle } from '@@/bits/random'
import type { SortOrder } from '@@/bits/surface_settings_helper'
import { isSortingBy } from '@@/bits/surface_settings_helper'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceSettingsStore } from '@@/pinia/surface_settings'
import { useSurfaceWishArrangementStore } from '@@/pinia/surface_wish_arrangement'
import {
  sortPinnedPostsToFront,
  sortPostsByCreationDate,
  sortPostsByCustomProp,
  sortPostsByReactionSum,
  sortPostsBySortIndex,
  sortPostsBySubject,
} from '@@/surface/sorter'
import type { Post } from '@@/types'
import { SortByTypes } from '@padlet/arvo'

const POST_SHUFFLE_SEED = Math.random()

export const usePostSorter = (): {
  getSortIndex: typeof getSortIndex
  getTopSortIndex: typeof getTopSortIndex
  getBottomSortIndex: typeof getBottomSortIndex
  getNewIndexBetween: typeof getNewIndexBetween
  sortPosts: typeof sortPosts
} => {
  // We can't use storeToRefs here because this composable is called from a Pinia module
  const surfaceStore = useSurfaceStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceWishArrangementStore = useSurfaceWishArrangementStore()
  const surfaceReactionsStore = useReactionsStore()
  const surfaceSettingsStore = useSurfaceSettingsStore()

  const getAbsoluteSortIndex = (): number => {
    const wallCreatedAt =
      surfaceStore.wallAttributes.created_at != null ? new Date(surfaceStore.wallAttributes.created_at).getTime() : 0
    const absSortIndex = (Date.now() - wallCreatedAt) * 1024
    const postMaxAbsSortIndex = Math.max(...surfacePostsStore.postEntities.map((p) => Math.abs(p.sort_index ?? 0)))
    const postMaxAbsSortIndexWithBuffer = postMaxAbsSortIndex + 1024
    return Math.max(absSortIndex, postMaxAbsSortIndexWithBuffer)
  }

  const getSortIndex = (): number => {
    const absSortIndex = getAbsoluteSortIndex()
    if (surfaceStore.format === 'timeline') return absSortIndex
    const postAtBottom = surfaceStore.newPostLocation === 'bottom' || !surfaceSettingsStore.canConfigNewPostLocation
    return postAtBottom ? -absSortIndex : absSortIndex
  }

  const getTopSortIndex = (): number => {
    return getAbsoluteSortIndex()
  }

  const getBottomSortIndex = (): number => {
    return -getAbsoluteSortIndex()
  }

  const getNewIndexBetween = (prevPost?: Post, nextPost?: Post): { sortIndex: number; gapSize: number } => {
    const prevIndex = prevPost?.sort_index
    const nextIndex = nextPost?.sort_index
    if (prevIndex == null && nextIndex == null) {
      const sortIndex = getSortIndex()
      return { sortIndex, gapSize: sortIndex }
    }
    if (prevIndex == null) {
      const sortIndex = getTopSortIndex()
      return { sortIndex, gapSize: Math.abs(sortIndex - (nextIndex ?? 0)) }
    }
    if (nextIndex == null) {
      const sortIndex = getBottomSortIndex()
      return { sortIndex, gapSize: Math.abs(sortIndex - prevIndex) }
    }
    const sortIndex = Math.round((prevIndex + nextIndex) / 2)
    return { sortIndex, gapSize: Math.min(Math.abs(sortIndex - prevIndex), Math.abs(sortIndex - nextIndex)) }
  }

  /**
   * Sort posts based on the current sort by setting.
   * @param posts An array of posts to sort.
   * @returns The sorted posts.
   */
  const sortPostsBasedOnSettings = (posts: Post[]): Post[] => {
    if (surfaceStore.isTimelineV1) {
      // We don't support advanced sorting in TimelineV1 so we only sort by sort index.
      // Remember to reverse the order for TimelineV1.
      return sortPostsBySortIndex(posts).reverse()
    }

    const wishSortBy = surfaceSettingsStore.isPreviewing
      ? surfaceWishArrangementStore.previewWishSortBy
      : surfaceWishArrangementStore.originalWishSortBy

    if (isSortingBy(wishSortBy, 'reactions')) {
      const sortedPosts = sortPostsByReactionSum(posts, surfaceReactionsStore.accumulatedReactionsByWishId, {
        reactionsTotalSumAverage: surfaceReactionsStore.accumulatedReactionsTotalSumAverage,
        confidenceNumber: surfaceReactionsStore.accumulatedReactionsConfidenceNumber as number,
      })
      return wishSortBy === SortByTypes.ReactionsDesc ? sortedPosts.reverse() : sortedPosts
    }

    if (isSortingBy(wishSortBy, 'date_published')) {
      const sortedPosts = sortPostsByCreationDate(posts)
      return wishSortBy === SortByTypes.DatePublishedSortDesc ? sortedPosts.reverse() : sortedPosts
    }

    if (isSortingBy(wishSortBy, 'post_subject')) {
      return wishSortBy === 'post_subject_asc' ? sortPostsBySubject(posts, 'asc') : sortPostsBySubject(posts, 'desc')
    }

    if (isSortingBy(wishSortBy, 'shuffle')) {
      // provide a copy to the shuffle function so that the original posts array is not mutated
      // because current shuffle algo sorts in place
      const postsCopy = [...posts]
      return sortPostsByShuffle(postsCopy, POST_SHUFFLE_SEED)
    }

    if (isSortingBy(wishSortBy, 'custom_prop')) {
      const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
      const [, propertyId, sortOrder] = wishSortBy.split(':')
      const customProperty = surfacePostPropertiesStore.wallCustomPostPropertiesById[propertyId]
      return sortPostsByCustomProp({
        posts,
        customProperty,
        sortOrder: sortOrder as SortOrder,
      })
    }

    return sortPostsBySortIndex(posts)
  }

  function sortPosts(posts: Post[]): Post[] {
    return sortPinnedPostsToFront(sortPostsBasedOnSettings(posts))
  }

  return {
    getSortIndex,
    getTopSortIndex,
    getBottomSortIndex,
    getNewIndexBetween,
    sortPosts,
  }
}
