// @file The main store for templates gallery on the dashboard.
import { setSearchParam } from '@@/bits/location'
import { snakeToKebabCase } from '@@/bits/sanitize_and_transform_text'
import { unboundedWatchEffect } from '@@/bits/vue'
import { UserAccountType } from '@@/enums'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashGalleryFilterStore } from '@@/pinia/dash_gallery_filter_store'
import { useDashGalleryTemplatesStore } from '@@/pinia/dash_gallery_templates_store'
import type { WallGalleryTemplate } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const TEMPLATE_ITEM_IMAGE_ASPECT_RATIO = 213 / 120
export const DETAILS_MODAL_CAROUSEL_ASPECT_RATIO = 16 / 9

export const useDashGalleryStore = defineStore('dashGalleryStore', () => {
  const dashGalleryFilterStore = useDashGalleryFilterStore()
  const dashGalleryTemplatesStore = useDashGalleryTemplatesStore()
  const dashAccountsStore = useDashAccountsStore()

  // State
  const clickedTemplateId = ref<string>('')
  const xTemplateDetailsModal = ref(false)
  const defaultAudience = computed(() => {
    if (dashAccountsStore.currentUser == null) return 'general'
    if (
      dashAccountsStore.isBackpack ||
      dashAccountsStore.currentUser.account_type === UserAccountType.TEACHER ||
      dashAccountsStore.currentUser.account_type === UserAccountType.STUDENT ||
      dashAccountsStore.currentUser.account_type === UserAccountType.STAFF
    ) {
      return 'education'
    } else if (dashAccountsStore.currentUser.account_type === UserAccountType.BUSINESS) {
      return 'business'
    } else {
      return 'general'
    }
  })
  const selectedAudience = ref(localStorage.getItem('templateGallerySelectedAudience') ?? defaultAudience.value)

  // Getters
  const allTemplates = computed(() => {
    return dashGalleryTemplatesStore.galleryTemplates
  })

  const clickedTemplate = computed(() => {
    return currentTemplates.value.find((template) => template.galleryTemplateId === clickedTemplateId.value)
  })

  // All the templates that are currently being displayed
  const currentTemplates = ref<WallGalleryTemplate[]>(allTemplates.value)
  const currentAudienceFilteredTemplates = computed<WallGalleryTemplate[]>(() => {
    return dashGalleryFilterStore.filterTemplatesByAudience(allTemplates.value, selectedAudience.value)
  })
  const numberOfCurrentTemplates = computed(() => {
    return currentTemplates.value.length
  })
  const isCurrentTemplatesEmpty = computed(() => {
    return numberOfCurrentTemplates.value === 0
  })
  const isFetchingGalleryPageData = computed(() => !dashGalleryTemplatesStore.isGalleryTemplatesLoaded)

  // Actions

  function initialize(): void {
    void dashGalleryTemplatesStore.fetchGalleryTemplates()
  }

  function showTemplateDetailsModal(): void {
    xTemplateDetailsModal.value = true
  }

  function hideTemplateDetailsModal(): void {
    xTemplateDetailsModal.value = false
  }

  function updateClickedTemplateId(templateId: string): void {
    clickedTemplateId.value = templateId
  }

  function getTemplateById(templateId: string): WallGalleryTemplate | undefined {
    return allTemplates.value.find((template) => template.galleryTemplateId === templateId)
  }

  function getPreviousTemplate(currentTemplate: WallGalleryTemplate): WallGalleryTemplate {
    const index = currentTemplates.value.indexOf(currentTemplate)
    if (index === -1) {
      return currentTemplates.value[0]
    } else if (index === 0) {
      return currentTemplates.value[currentTemplates.value.length - 1]
    }
    return currentTemplates.value[index - 1]
  }

  function getNextTemplate(currentTemplate: WallGalleryTemplate): WallGalleryTemplate {
    const index = currentTemplates.value.indexOf(currentTemplate)
    if (index === -1) {
      return currentTemplates.value[currentTemplates.value.length - 1]
    } else if (index === currentTemplates.value.length - 1) {
      return currentTemplates.value[0]
    }
    return currentTemplates.value[index + 1]
  }

  function setSelectedAudience(audience: string): void {
    const audienceParamValue = snakeToKebabCase(encodeURIComponent(audience))
    setSearchParam('audience', audienceParamValue, false)
    selectedAudience.value = audience
    localStorage.setItem('templateGallerySelectedAudience', audience)
  }

  // Watch for changes to the filters and search query and update the current templates
  void unboundedWatchEffect(() => {
    const filteredTemplates = dashGalleryFilterStore.filterTemplates(currentAudienceFilteredTemplates.value, {
      grade: dashGalleryFilterStore.gradesSelectedMap,
      category: dashGalleryFilterStore.categoriesSelectedMap,
    })
    currentTemplates.value = dashGalleryFilterStore.filterBySearch(filteredTemplates)
  })

  return {
    // State
    clickedTemplateId,
    xTemplateDetailsModal,
    selectedAudience,

    // Getters
    allTemplates,
    currentTemplates,
    currentAudienceFilteredTemplates,
    numberOfCurrentTemplates,
    isCurrentTemplatesEmpty,
    clickedTemplate,
    isFetchingGalleryPageData,

    // Actions,
    initialize,
    getTemplateById,
    getPreviousTemplate,
    getNextTemplate,
    updateClickedTemplateId,
    showTemplateDetailsModal,
    hideTemplateDetailsModal,
    setSelectedAudience,
  }
})
