// @file Composable to handle attachment uploading for posts/drafts.
// NOTE: This composable is an extension of `surface_drafts` Pinia store
// so we MUST NOT destructure Pinia stores.
import { __ } from '@@/bits/intl'
import { FILE_TOO_LARGE_ERROR, startUpload, stopUpload, type UploadJob } from '@@/bits/uploader'
import { unboundedWatch } from '@@/bits/vue'
import { useComposerModalAlertStore } from '@@/pinia/composer_modal_alert'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { Cid, UploadedFile } from '@@/types'
import { computed } from 'vue'

export const usePostAttachmentUploader = (): {
  startUploadFile: typeof startUploadFile
  stopUploadFile: typeof stopUploadFile
} => {
  const surfaceStore = useSurfaceStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const composerModalAlertStore = useComposerModalAlertStore()
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const surfacePostsStore = useSurfacePostsStore()

  const uploaders = new Map<Cid, { file: File; uploadJob: UploadJob }>()

  const startUploadFile = ({
    file,
    maxFileUploadSize,
    cid,
  }: {
    file: UploadedFile
    maxFileUploadSize?: number
    cid: Cid
  }): void => {
    // Google Drive files are uploaded by Beethoven, we don't do it here.
    if (file.isGoogleDriveFile === true) return

    const currentDraft = surfaceDraftsStore.draftByCid[cid]
    const uploadJob = startUpload(file, { maxFileSize: maxFileUploadSize })

    uploadJob.on('progress', (progress) => {
      surfaceDraftsStore.updateDraft({ uploadProgress: progress, cid })
    })

    uploadJob.on('done', ({ url }) => {
      deleteUploadedFile({ cid })
      surfaceDraftsStore.updateDraft({
        cid,
        attachment: url,
        wish_content: {
          attachment_props: {
            url,
          },
          is_processed: false,
        },
        uploadingFile: null,
      })
      const updatedCurrentDraft = surfaceDraftsStore.draftByCid[cid]
      if (updatedCurrentDraft?.shouldAutoPublish === true) {
        surfaceDraftsStore.stopAutoPublishDraft(cid) // when upload is done, stop auto publish
        surfacePostsStore.savePost({ attributes: surfaceDraftsStore.draftByCid[cid] })
      }
    })

    uploadJob.on('error', (err) => {
      surfaceDraftsStore.stopUploadFileInDraft(cid)
      surfaceDraftsStore.stopAutoPublishDraft(cid)

      if (err.error === FILE_TOO_LARGE_ERROR) {
        surfaceStore.showOverFileSizeModal({ file })
        return
      }

      const message = __('Sorry, there was an error uploading your file.')

      if (surfaceDraftsStore.isCidActiveDraft(cid)) {
        globalSnackbarStore.setSnackbar({ message })
        return
      }

      composerModalAlertStore.openComposerAlert({
        title: __('There was an error loading the attachment'),
        body: message,
        primaryActionText: __('Try again'),
        secondaryActionText: __('Cancel'),
        primaryActions: [
          () =>
            surfaceDraftsStore.startUploadFileInDraft({
              cid,
              file,
              maxFileUploadSize: currentDraft?.maxFileUploadSize,
            }),
        ],
        secondaryActions: [],
      })
    })

    uploaders.set(cid, { file, uploadJob })
  }

  const deleteUploadedFile = ({ cid }: { cid: Cid }): void => {
    const uploader = uploaders.get(cid)
    if (uploader == null) return
    stopUpload(uploader.file)
    uploaders.delete(cid)
  }

  const stopUploadFile = ({ cid }: { cid: Cid }): void => {
    deleteUploadedFile({ cid })
    surfaceDraftsStore.stopAutoPublishDraft(cid)
    surfaceDraftsStore.updateDraft({ cid, uploadingFile: null })
  }

  const pendingPostReadyToUploadCids = computed<Cid[]>(() => {
    const pendingPostsWithFile = surfacePostsStore.pendingPosts.filter((post) => Boolean(post.file))

    // If there are posts for which sections are yet to be picked,
    // don't start uploading them, filter them out. Only do it for
    // sectioned walls because that's the only occasion where we
    // ask users to pick a section.
    if (surfaceStore.canUseSections && surfaceStore.isSectioned) {
      const pendingPostsWithSections = pendingPostsWithFile.filter((x) => Boolean(x.wall_section_id))
      return pendingPostsWithSections.map((post) => post.cid)
    }

    return pendingPostsWithFile.map((post) => post.cid)
  })

  void unboundedWatch(pendingPostReadyToUploadCids, (cids) => {
    if (cids.length === 0) return
    cids.forEach((cid) => {
      const postAttributes = surfacePostsStore.pendingPostEntitiesByCid[cid]
      if (postAttributes.file == null) return
      surfaceDraftsStore.createNewDraftFromPendingUpload({
        file: postAttributes.file,
        postAttributes,
      })
    })
    surfacePostsStore.removePendingPosts({ cids })
  })

  return { startUploadFile, stopUploadFile }
}
