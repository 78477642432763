// @file Network requests related to things on the padlet page
/* eslint-disable no-return-await, no-template-curly-in-string */
import getCsrfToken from '@@/bits/csrf_token'
import environment from '@@/bits/environment'
import window, { browsingContextUid } from '@@/bits/global'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { currentPathWithoutLeadingSlash, getSearchParams, transformCurrentUrl } from '@@/bits/location'
import type { MAGIC_TEMPLATES } from '@@/bits/magic_padlet_helper'
import { poll } from '@@/bits/polling'
import { getSubmissionRequestTokenHash } from '@@/bits/surface_share_links_helper'
import type { WallAccessRight, WallAccessRole } from '@@/enums'
import type { MagicTemplateKey } from '@@/pinia/magic_padlet_panel_store'
import { useSurfaceStore } from '@@/pinia/surface'
import type { Message, Suggestion as SurfaceAiChatSuggestion } from '@@/pinia/surface_ai_chat_store'
import { useSurfaceStartingStateStore } from '@@/pinia/surface_starting_state'
import { fetchJson, fetchJsonWithRetries } from '@@/surface/api_fetch'
import type {
  AccumulatedReactions,
  AiTalkLanguageResponse,
  AiTalkResponse,
  CanSetWallPrivacyOptions,
  CreateSessionUserApiResponse,
  DraftPost,
  Folder as FolderType,
  GoogleAppLicensingValidateResponse,
  GoogleDriveAuthorizationResult,
  Grade,
  GuidedDiscussionQuestionsResponse,
  GuidedDiscussionResponsesResponse,
  HashId,
  Id,
  JsonApiData,
  JsonApiResponse,
  LibraryId,
  LinkSafetyCheckApiResponse,
  LmsPassbackResponse,
  MagicTemplateInputConfig,
  MagicWallCreationResult,
  ModerateUserNameApiResponse,
  Plan as PlanType,
  Post,
  PrivacyPolicyId,
  SyncStateUserIds,
  User as UserSnakeCase,
  UserGroupId,
  UserGroupWallCollaborator as UserGroupWallCollaboratorType,
  UserId,
  UserLibrarySettingsApiResponse,
  UserMentionSuggestion,
  UserMentionSuggestionAttributes,
  UserWallCollaborator,
  UserWallCollaboratorInvite,
  Wall as WallApiType,
  WallBackground,
  WallCamelCase,
  WallCreationFromTemplateResult,
  WallCustomPostProperty as WallCustomPostPropertyType,
  WallFollowApiResponse,
  WallId,
  WallNameAvailability,
  WallPostProperties as WallPostPropertiesType,
  WallReadTokenResponse,
  WallRemakeLink,
  WallRemakeLinkProps,
  WallSectionBreakoutLink,
  WallSubmissionRequestLink,
  WallSubmissionRequestProps,
  WhiteboardCollaborationSettings as WhiteboardCollaborationSettingsType,
} from '@@/types'
import type { BlocklyScriptBlocks, WhiteboardScript as WhiteboardScriptType } from '@@/types/whiteboard'
import type { SurfaceState } from '@@/vuexstore/surface/types'
import type {
  Comment as CommentType,
  CommentsFetchQuery,
  JsonAPIResource,
  JsonAPIResponse,
  Reaction as ReactionType,
  ReactionsFetchQuery,
  User as UserType,
  Wall as WallType,
  WallBackgroundType,
  Wish as WishType,
} from '@padlet/arvo'
import { fetchComments, fetchReactions, fetchUser } from '@padlet/arvo'
import type { FetchOptions } from '@padlet/fetch'
import { fetchResponse, HTTPMethod } from '@padlet/fetch'
import type { ArvoConfig } from '@padlet/universal-post-editor'
import { mapKeys, snakeCase, template } from 'lodash-es'

export interface VersionedFetchOptions extends FetchOptions {
  apiVersion?: number
}

const API_VERSION = 5
const VIA = 'webapp'

class FetchableObject {
  public static get url(): string {
    return ''
  }

  public static get fetchUrl(): string {
    return ''
  }

  public static buildUrl(url, options): string {
    return template(url)({ ...options, interpolate: /\${([\s\S]+?)}/g })
  }

  public static async readAll(options, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.fetchUrl, options), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async read(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.id}`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async update(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.id}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async create(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async delete(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.id}`, {
      method: HTTPMethod.delete,
      ...fetchOptions,
    })
  }
}

class GoogleAppLicensingSettings extends FetchableObject {
  public static async checkLicense(): Promise<JsonAPIResponse<GoogleAppLicensingValidateResponse>> {
    return await fetchJson(`api/${API_VERSION}/google-app-licensing/validate-or-logout`, {
      method: HTTPMethod.get,
    })
  }
}

class Poll extends FetchableObject {
  private static get updatePollUrl(): string {
    return `/api/\${ apiVersion }/polls/\${ pollId }`
  }

  private static get pollVoteUrl(): string {
    return `/api/\${ apiVersion }/polls/\${ pollId }/vote`
  }

  public static async updatePoll(
    pollParams: Record<string, any>,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<Wish> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.updatePollUrl, { apiVersion, pollId: pollParams.poll_id }), {
      ...otherFetchOptions,
      method: HTTPMethod.patch,
      body: asciiSafeStringify(pollParams),
    })
    const updatedWish = (response.data as JsonAPIResource<Wish>).attributes
    return updatedWish
  }

  public static async vote(
    { pollId, wishId, pollChoiceIds }: { pollId: Id; wishId: Id; pollChoiceIds: Id[] },
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<JsonAPIResponse<Wish>> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.pollVoteUrl, { apiVersion, pollId }), {
      ...otherFetchOptions,
      method: HTTPMethod.post,
      body: asciiSafeStringify({ wish_id: wishId, poll_choice_ids: pollChoiceIds }),
    })
    const updatedWish = (response.data as JsonAPIResource<Wish>).attributes
    return updatedWish
  }
}

class Reaction extends FetchableObject {
  public static async fetchAccumulatedReactions({
    wallId,
  }: {
    wallId: number
  }): Promise<JsonAPIResponse<AccumulatedReactions>> {
    return await fetchJson(`/api/${API_VERSION}/accumulated_reactions?wall_id=${wallId}`, {
      method: HTTPMethod.get,
    })
  }

  public static get url(): string {
    return `/api/${API_VERSION}/reactions`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/reactions?wall_id=\${ wall.id }`
  }

  public static async fetch(query: ReactionsFetchQuery): Promise<JsonAPIResponse<ReactionType>> {
    return await fetchReactions(query)
  }
}

class PostConnection extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/wish_connections`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/wish_connections?wall_id=\${ wall.id }`
  }
}

class Comment extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/comments`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/comments?wall_id=\${ wall.id }`
  }

  public static async fetch(query: CommentsFetchQuery): Promise<JsonAPIResponse<CommentType>> {
    return await fetchComments(query)
  }
}

interface WallCommentsHashidFetchQuery {
  wallHashid: number
  wishHashid?: undefined
  pageStart?: string
}

interface WishCommentsHashidFetchQuery {
  wishHashid: number
  wallHashid?: undefined
  pageStart?: string
}

type CommentsFetchHashidQuery = WallCommentsHashidFetchQuery | WishCommentsHashidFetchQuery

class CommentV2 extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/comments`
  }

  public static get fetchUrlForWall(): string {
    return `/api/\${ apiVersion }/comments?wall_hashid=\${ wallHashid }&page_start=\${ pageStart }`
  }

  public static get fetchUrlForWish(): string {
    return `/api/\${ apiVersion }/comments?wish_hashid=\${ wishHashid }&page_start=\${ pageStart }`
  }

  private static get updateUrl(): string {
    return `/api/\${ apiVersion }/comments/\${ commentId }`
  }

  private static get approveUrl(): string {
    return `/api/\${ apiVersion }/comments/\${ commentId }/approve`
  }

  public static async fetch(query: CommentsFetchHashidQuery, fetchOptions: VersionedFetchOptions = {}): Promise<any> {
    const { apiVersion = 9, ...otherFetchOptions } = fetchOptions
    return await fetchJson(
      this.buildUrl(query.wishHashid != null ? this.fetchUrlForWish : this.fetchUrlForWall, {
        apiVersion,
        wallHashid: query.wallHashid,
        wishHashid: query.wishHashid,
        pageStart: query.pageStart,
      }),
      {
        method: HTTPMethod.get,
        ...otherFetchOptions,
      },
    )
  }

  public static async create(attributes: Partial<CommentType>, fetchOptions: VersionedFetchOptions = {}): Promise<any> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.url, { apiVersion }), {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ attributes }),
      ...otherFetchOptions,
    })
  }

  public static async update(
    commentId: Id,
    attributes: Partial<CommentType>,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<any> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.updateUrl, { apiVersion, commentId }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ attributes }),
      ...otherFetchOptions,
    })
  }

  public static async approve(commentId: Id, fetchOptions: VersionedFetchOptions = {}): Promise<any> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.approveUrl, { apiVersion, commentId }), {
      method: HTTPMethod.patch,
      ...otherFetchOptions,
    })
  }
}

class WallSection extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/wall_sections`
  }

  public static get fetchUrl(): string {
    return `/api/${API_VERSION}/wall_sections?wall_id=\${ wall.id }`
  }

  public static async readAll(options, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.fetchUrl, options), {
      method: HTTPMethod.get,
      query: getSubmissionRequestTokenHash(),
      ...fetchOptions,
    })
  }
}

// TODO: move to @padlet/arvo
class WallTableLayout extends FetchableObject {
  private static readonly rowsEndpoint = '/api/wall_table_layout/rows'
  private static readonly colsEndpoint = '/api/wall_table_layout/cols'
  private static readonly rowAndColEndpoint = '/api/wall_table_layout/row_and_col'

  public static get fetchUrl(): string {
    return '/api/wall_table_layout?wall_id=${ wall.id }'
  }

  public static async createRow(obj): Promise<any> {
    return await fetchJson(this.rowsEndpoint, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
    })
  }

  public static async createCol(obj): Promise<any> {
    return await fetchJson(this.colsEndpoint, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
    })
  }

  public static async createRowAndCol(obj): Promise<any> {
    return await fetchJson(this.rowAndColEndpoint, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
    })
  }

  public static async updateRow(obj): Promise<any> {
    return await fetchJson(`${this.rowsEndpoint}/${obj.id}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
    })
  }

  public static async updateCol(obj): Promise<any> {
    return await fetchJson(`${this.colsEndpoint}/${obj.id}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
    })
  }

  public static async deleteRow(obj): Promise<any> {
    return await fetchJson(`${this.rowsEndpoint}/${obj.id}`, {
      method: HTTPMethod.delete,
    })
  }

  public static async deleteCol(obj): Promise<any> {
    return await fetchJson(`${this.colsEndpoint}/${obj.id}`, {
      method: HTTPMethod.delete,
    })
  }

  public static async putPostInCell(obj): Promise<any> {
    return await fetchJson('/api/wall_table_layout/cell', {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
    })
  }
}

/**
 * Magic Template Form Data Types
 */

// Define a type that represents the form data for each template
type FormDataForTemplate<T extends MagicTemplateInputConfig[]> = {
  [P in T[number]['refKey']]: string
}

// Define a type that maps each template key to its corresponding form data type
export type MagicTemplateFormData = {
  [K in MagicTemplateKey]: FormDataForTemplate<typeof MAGIC_TEMPLATES[K]['inputConfig']>
}

class Wall extends FetchableObject {
  private static readonly API_VERSION = 9

  public static get url(): string {
    return `/api/${this.API_VERSION}/walls`
  }

  private static get convertLayoutUrl(): string {
    return `api/${this.API_VERSION}/walls/\${wallHashid}/layout`
  }

  private static get summaryUrl(): string {
    return `api/${this.API_VERSION}/walls/\${ hashid }/summary`
  }

  public static async fetch(hashid: HashId, fetchOptions: VersionedFetchOptions = {}): Promise<WallType> {
    const { apiVersion = 7, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(`/api/${apiVersion}/walls/${hashid}`, {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
    const wall = {
      ...response.data.attributes,
      links: response.data.links,
    }
    return wall
  }

  public static async update(obj: Partial<WallApiType>, fetchOptions = {}): Promise<any> {
    return await fetchJsonWithRetries(`${this.url}/${obj.hashid ?? ''}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async convertLayout({ wallHashid, newLayout }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.convertLayoutUrl, { wallHashid }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ type: newLayout }),
      ...fetchOptions,
    })
  }

  public static async fetchSummary({ hashid }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.summaryUrl, { hashid, apiVersion: this.API_VERSION }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async create(
    {
      viz,
      libraryId,
      groupBySection,
      createdFrom,
      sourceId,
      title,
      description,
    }: {
      viz: string
      libraryId?: LibraryId
      groupBySection?: boolean
      createdFrom?: string
      sourceId?: string
      title?: string
      description?: string
    },
    fetchOptions = {},
  ): Promise<any> {
    const wishArrangement = groupBySection === true ? { wish_arrangement: { group_by: 'section' } } : {}
    return await fetchJson(`/api/${this.API_VERSION}/walls`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        viz,
        via: VIA,
        library_id: libraryId,
        ...wishArrangement,
        created_from: createdFrom,
        source_id: sourceId,
        title,
        description,
      }),
      ...fetchOptions,
    })
  }

  public static async createMagic(
    {
      currentLibraryId,
      isExample,
      includeImages,
      magicTemplateKey,
      formData,
    }: {
      currentLibraryId: LibraryId | undefined
      isExample: boolean
      includeImages: boolean
      magicTemplateKey: MagicTemplateKey
      formData: MagicTemplateFormData[typeof magicTemplateKey]
    },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<MagicWallCreationResult>> {
    return await fetchJson(`/api/${API_VERSION}/walls/magic_wall`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        library_id: currentLibraryId,
        is_example: isExample,
        include_images: includeImages,
        wall_type: magicTemplateKey,
        ...formData,
      }),
      ...fetchOptions,
    })
  }

  public static async logMagicWallFeedback(feedback: string, wallId: WallId, fetchOptions = {}): Promise<void> {
    await fetchJson(`/api/${API_VERSION}/walls/magic-wall/feedback`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ data: { attributes: { feedback, wallId } } }),
      ...fetchOptions,
    })
  }

  public static async archive(wallId: number): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/archive`, {
      method: HTTPMethod.put,
    })
  }

  public static async markAsTemplate(
    wallId: number,
    template: boolean,
  ): Promise<JsonAPIResponse<{ template: boolean }>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/mark-as-template`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: { template } } }),
    })
  }

  public static async createFromTemplate(
    wallId: number,
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WallCreationFromTemplateResult>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/create-from-template`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async freeze(wallId: number, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/freeze`, {
      method: HTTPMethod.put,
      ...otherFetchOptions,
    })
  }

  public static async unfreeze(wallId: number, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/unfreeze`, {
      method: HTTPMethod.put,
      ...otherFetchOptions,
    })
  }

  public static async trash(wallId: WallId): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/trash`, {
      method: HTTPMethod.patch,
    })
  }

  public static async transfer(params: { wallId: WallId; destinationLibraryId: LibraryId | null }): Promise<void> {
    return await fetchJson('api/1/walls/transfer', {
      method: HTTPMethod.post,
      jsonData: params,
    })
  }

  public static async checkAvailability(name: string, wallHashid?: HashId): Promise<WallNameAvailability> {
    return await fetchJson(`/api/9/walls/check-availability`, {
      method: HTTPMethod.get,
      query: wallHashid !== undefined ? { name, for_wall_hashid: wallHashid } : { name },
    })
  }
}

class WallGradingSettings extends FetchableObject {
  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallGradingSettings>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/grading-settings`, {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
  }

  public static async update(
    wallId: WallId,
    gradingSettings: Partial<WallGradingSettings>,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallGradingSettings>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/grading-settings`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ data: { attributes: gradingSettings } }),
      ...otherFetchOptions,
    })
  }

  public static async deleteContextFiles(wallId: WallId, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/walls/${wallId}/grading-settings/context-files`, {
      method: HTTPMethod.delete,
      ...otherFetchOptions,
    })
  }
}

class Grades extends FetchableObject {
  public static async fetch(
    wallId: WallId,
    userId?: UserId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<Grade[]> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const query: { wallId: string; userId?: string } = {
      wallId: String(wallId),
    }
    if (userId != null) query.userId = userId.toString()
    const response = await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.get,
      query,
      ...otherFetchOptions,
    })

    return response.data.map((d: JsonAPIResource<Grade[]>): Grade[] => d.attributes)
  }

  public static async create(attributes: Grade, fetchOptions: VersionedFetchOptions = {}): Promise<Grade> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ data: { attributes } }),
      ...otherFetchOptions,
    })
    return response.data.attributes as Grade
  }

  public static async update(attributes: Partial<Grade>, fetchOptions: VersionedFetchOptions = {}): Promise<Grade> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes } }),
      ...otherFetchOptions,
    })
    return response.data.attributes as Grade
  }

  public static async delete(attributes: Grade, fetchOptions: VersionedFetchOptions = {}): Promise<void> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    await fetchJson(`/api/${apiVersion}/grades`, {
      method: HTTPMethod.delete,
      body: asciiSafeStringify({ data: { attributes } }),
      ...otherFetchOptions,
    })
  }
}

class LmsPassback extends FetchableObject {
  public static async syncWithLms(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<LmsPassbackResponse>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/grades/sync`, {
      method: HTTPMethod.post,
      query: {
        wallId: String(wallId),
      },
      ...otherFetchOptions,
    })
  }

  public static async fetchUserSyncState(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<SyncStateUserIds>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/grades/user-sync-status`, {
      method: HTTPMethod.get,
      query: {
        wallId: String(wallId),
      },
      ...otherFetchOptions,
    })
  }
}
class AiSuggestedComments extends FetchableObject {
  public static async create({
    wallId,
    gradedUserId,
    fetchOptions = {},
  }: {
    wallId: WallId
    gradedUserId: UserId
    fetchOptions?: VersionedFetchOptions
  }): Promise<string> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const query: { wallId: string; gradedUserId?: string } = {
      wallId: String(wallId),
      gradedUserId: String(gradedUserId),
    }
    const response = await fetchJson(`/api/${apiVersion}/ai-suggested-comment`, {
      method: HTTPMethod.post,
      query,
      ...otherFetchOptions,
    })

    return response.data.attributes.suggestedComment
  }
}

class AiSuggestedCustomization extends FetchableObject {
  public static async create({
    wallId,
    fetchOptions = {},
  }: {
    wallId: WallId
    fetchOptions?: VersionedFetchOptions
  }): Promise<string> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    const query: { wallId: string; gradedUserId?: string } = {
      wallId: String(wallId),
    }
    const response = await fetchJson(`/api/${apiVersion}/ai-suggested-customization`, {
      method: HTTPMethod.post,
      query,
      ...otherFetchOptions,
    })

    return response.data.attributes.suggestedAiCustomization
  }
}

class WallAccessSettings extends FetchableObject {
  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallAccessSettings>> {
    const { apiVersion = 6, ...otherFetchOptions } = fetchOptions
    const path = apiVersion >= 8 ? 'access-settings' : 'access_settings'
    const url = `/api/${apiVersion}/walls/${wallId}/${path}`
    return await fetchJson(url, {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
  }

  public static async update(
    wallId: WallId,
    accessSettings: Partial<WallAccessSettings>,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallAccessSettings>> {
    const { apiVersion = 6, ...otherFetchOptions } = fetchOptions
    const path = apiVersion >= 8 ? 'access-settings' : 'access_settings'
    const url = `/api/${apiVersion}/walls/${wallId}/${path}`
    const body = apiVersion >= 8 ? accessSettings : { data: { attributes: accessSettings } }
    return await fetchJson(url, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify(body),
      ...otherFetchOptions,
    })
  }
}

class WallOnboardingPanel extends FetchableObject {
  public static async fetch(wallId: WallId): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/hide`, {
      method: HTTPMethod.post,
    })
  }

  public static async show(wallId: WallId): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/show`, {
      method: HTTPMethod.post,
    })
  }

  public static async dontShowGalleryAgain(
    wallId: WallId,
    templateId: string,
  ): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/disable-gallery-template`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ template_id: templateId }),
    })
  }

  public static async generateSamplePosts(
    wallId: WallId,
    samplePostIds: string[],
  ): Promise<JsonAPIResponse<WallOnboardingPanel>> {
    return await fetchJson(`/api/1/walls/${wallId}/onboarding-panel/sample-posts`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ sample_post_ids: samplePostIds }),
      headers: {
        'X-UID': '', // remove x-uid header so current_user can listen to 'add_wish' realtime event for the post to show up.
      },
    })
  }
}

class WallOnboardingDemo extends FetchableObject {
  public static async updateCurrentStep(wallId: WallId, currentStep: string): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/onboarding-demo/update-current-step`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ currentStep }),
    })
  }
}

class GuidedTemplates extends FetchableObject {
  public static async generateDiscussionQuestions(
    topic: string,
    grades: string[],
  ): Promise<JsonAPIResponse<GuidedDiscussionQuestionsResponse>> {
    return await fetchJson(`/api/${API_VERSION}/guided-templates/discussion-question`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ topic, grades }),
    })
  }

  public static async generateDiscussionResponses(
    question: string,
    discussionStyle: string,
    topic: string,
    grades: string[],
    sectionOne: string,
    sectionTwo: string,
  ): Promise<JsonAPIResponse<GuidedDiscussionResponsesResponse>> {
    return await fetchJson(`/api/${API_VERSION}/guided-templates/discussion-response`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ question, discussionStyle, topic, grades, sectionOne, sectionTwo }),
    })
  }
}

class WallAttachments extends FetchableObject {
  public static get fetchDownloadAttachmentsZipUrl(): string {
    return `padlets/\${ publicKey }/exports/zip-attachments`
  }

  public static async downloadAttachmentsZip(): Promise<any> {
    return await fetch(
      this.buildUrl(this.fetchDownloadAttachmentsZipUrl, {
        publicKey: useSurfaceStore().publicKey,
      }),
      {
        method: HTTPMethod.get,
      },
    )
  }
}

class WallExports extends FetchableObject {
  public static get fetchDownloadWhiteboardPagesZipUrl(): string {
    return `padlets/\${ publicKey }/exports/zip-whiteboard`
  }

  public static async downloadWhiteboardPagesZip(): Promise<any> {
    return await fetch(
      this.buildUrl(this.fetchDownloadWhiteboardPagesZipUrl, {
        publicKey: useSurfaceStore().publicKey,
      }),
      {
        method: HTTPMethod.get,
      },
    )
  }

  public static get fetchDownloadWhiteboardPagesPdfUrl(): string {
    return `padlets/\${ publicKey }/exports/whiteboard-pdf`
  }

  public static async downloadWhiteboardPagesPdf(): Promise<any> {
    return await fetch(
      this.buildUrl(this.fetchDownloadWhiteboardPagesPdfUrl, {
        publicKey: useSurfaceStore().publicKey,
      }),
      {
        method: HTTPMethod.get,
      },
    )
  }
}

class Wish extends FetchableObject {
  static API_VERSION = 9

  public static get url(): string {
    return `/api/${this.API_VERSION}/wishes`
  }

  public static get fetchSingleWithHashidUrl(): string {
    return `/api/${this.API_VERSION}/wishes/\${ wishHashid }`
  }

  public static get fetchUrl(): string {
    return `/api/${this.API_VERSION}/wishes?wall_hashid=\${ wallHashid }&page_start=\${ pageStart }`
  }

  public static get transferUrl(): string {
    return `/api/${this.API_VERSION}/wishes/\${ wishHashid }/transfer`
  }

  public static get copyUrl(): string {
    return `/api/${this.API_VERSION}/wishes/\${ wishHashid }/copy`
  }

  public static get approveUrl(): string {
    return `/api/${this.API_VERSION}/wishes/\${ wishHashid }/approve`
  }

  public static get rejectUrl(): string {
    return `/api/${this.API_VERSION}/wishes/\${ wishHashid }/reject`
  }

  public static async fetchSingleWithHashid({ wishHashid }, fetchOptions = {}): Promise<JsonAPIResponse<WishType>> {
    return await fetchJson(this.buildUrl(this.fetchSingleWithHashidUrl, { wishHashid }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetch(
    query: { wallHashid: number; pageStart?: number },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WishType>> {
    return await fetchJson(
      this.buildUrl(this.fetchUrl, {
        wallHashid: query.wallHashid,
        pageStart: query.pageStart == null ? '' : query.pageStart,
      }),
      {
        method: HTTPMethod.get,
        ...fetchOptions,
      },
    )
  }

  public static async transfer(
    { wishHashid, wallHashid, wallSectionHashid, wallTitle, wallDescription, libraryId },
    fetchOptions = {},
  ): Promise<any> {
    return await fetchJson(this.buildUrl(this.transferUrl, { wishHashid }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({
        wall_hashid: wallHashid,
        wall_section_hashid: wallSectionHashid,
        wall_title: wallTitle,
        wall_description: wallDescription,
        library_id: libraryId,
      }),
      ...fetchOptions,
    })
  }

  public static async copy(
    { wishHashid, wallHashid, wallSectionHashid, wallTitle, wallDescription, postPosition, libraryId },
    fetchOptions = {},
  ): Promise<any> {
    return await fetchJson(this.buildUrl(this.copyUrl, { wishHashid }), {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wall_hashid: wallHashid,
        wall_section_hashid: wallSectionHashid,
        wall_title: wallTitle,
        wall_description: wallDescription,
        library_id: libraryId,
        ...postPosition,
      }),
      ...fetchOptions,
    })
  }

  public static async create(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
      query: getSubmissionRequestTokenHash(),
      ...fetchOptions,
    })
  }

  public static async approve({ wishHashid }: { wishHashid: HashId }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.approveUrl, { wishHashid }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({}),
      ...fetchOptions,
    })
  }

  public static async reject({ wishHashid }: { wishHashid: HashId }, fetchOptions = {}): Promise<any> {
    return await fetchJson(this.buildUrl(this.rejectUrl, { wishHashid }), {
      method: HTTPMethod.put,
      ...fetchOptions,
    })
  }

  public static async update(obj: Post, fetchOptions = {}): Promise<any> {
    return await fetchJsonWithRetries(`${this.url}/${obj.hashid ?? ''}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async delete(obj: { wishHashid: HashId }, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}/${obj.wishHashid}`, {
      method: HTTPMethod.delete,
      ...fetchOptions,
    })
  }
}

class WishDraft extends FetchableObject {
  public static get url(): string {
    return '/api/1/wish-drafts'
  }

  public static async list({ wallId }: { wallId: WallType['id'] }): Promise<DraftPost[]> {
    const response = await fetchJson(this.url, {
      method: HTTPMethod.get,
      query: {
        wallId: String(wallId),
      },
    })
    return response.data.map((d: any) => d.attributes)
  }

  public static async create(attributes: DraftPost): Promise<DraftPost> {
    const response = await fetchJson(this.url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ attributes }),
    })
    return response.data.attributes
  }

  public static async update(draftId: Id, attributes: DraftPost): Promise<DraftPost> {
    const response = await fetchJson(`${this.url}/${draftId}`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ attributes }),
    })
    return response.data.attributes
  }

  public static async delete(draftId: Id): Promise<void> {
    await fetchJson(`${this.url}/${draftId}`, {
      method: HTTPMethod.delete,
    })
  }
}

// UserContributor is read only
class UserContributor extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/users`
  }

  // The API WallContributorIds but it only return post author ids to match old behaviour for now
  public static async readWallContributorIds(wall, fetchOptions = {}): Promise<any> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wall.id}/contributors`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async read(userId: number | string): Promise<JsonAPIResponse<UserType>> {
    return await fetchUser(userId)
  }
}

class WallUserContributor extends FetchableObject {
  private static get wallUserUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/contributors/\${userId}`
  }

  public static async read(userId: number | string, wallId: WallId): Promise<JsonAPIResponse<UserType>> {
    return await fetchJson(this.buildUrl(this.wallUserUrl, { apiVersion: 1, userId, wallId }), {
      method: HTTPMethod.get,
    })
  }
}

/**
 * @example PadletApi.ContributingStatus.update({wall_id: 1, status: 1})
 */
class ContributingStatus extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/contributing_status`
  }

  public static get pingUrl(): string {
    return `/api/${API_VERSION}/contributing_status/ping`
  }

  public static async ping(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.pingUrl}`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }

  public static async update(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }
}

class BrahmsToken extends FetchableObject {
  public static get url(): string {
    return `/api/${API_VERSION}/brahms_token`
  }

  public static async get(obj, fetchOptions = {}): Promise<any> {
    return await fetchJson(`${this.url}`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(obj),
      ...fetchOptions,
    })
  }
}

class Plan {
  /**
   * Fetch list of available plans for current user (respective to the user's country code/currency)
   */
  public static async fetch(fetchOptions = {}): Promise<JsonAPIResponse<PlanType>> {
    return await fetchJson(`/api/${API_VERSION}/plans`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

const collaboratorCreate = async (
  { wallId, userId, right }: { wallId: Id; userId: Id; right: WallAccessRight },
  fetchOptions = {},
): Promise<any> => {
  return await fetchJson(`/api/${API_VERSION}/wall_collaborators/create`, {
    method: HTTPMethod.post,
    body: asciiSafeStringify({
      wall_id: wallId,
      user_id: userId,
      right,
    }),
    ...fetchOptions,
  })
}

const collaboratorLeavePadlet = async (wallId: Id, fetchOptions = {}): Promise<any> => {
  return await fetchJson(`/api/${API_VERSION}/wall_collaborators/leave`, {
    method: HTTPMethod.post,
    body: asciiSafeStringify({
      wall_id: wallId,
    }),
    ...fetchOptions,
  })
}

class UserGroupWallCollaborator extends FetchableObject {
  public static async invite({
    wallId,
    userGroupId,
    role,
  }: {
    wallId: WallId
    userGroupId: UserGroupId
    role: WallAccessRole | undefined
  }): Promise<JsonAPIResponse<UserGroupWallCollaboratorType>> {
    const url = `/api/8/walls/${wallId}/user-group-collaborators`
    return await fetchJson(url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wallId,
        userGroupId,
        role,
      }),
    })
  }

  public static async update({
    wallId,
    userGroupId,
    role,
  }: {
    wallId: Id
    userGroupId: Id
    role: WallAccessRole
  }): Promise<JsonAPIResponse<UserGroupWallCollaboratorType>> {
    const url = `/api/8/walls/${wallId}/user-group-collaborators/${userGroupId}`
    return await fetchJson(url, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ role }),
    })
  }

  public static async remove({
    wallId,
    userGroupId,
  }: {
    wallId: Id
    userGroupId: Id
  }): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-group-collaborators/${userGroupId}`
    return await fetchJson(url, {
      method: HTTPMethod.delete,
    })
  }
}

class WallCollaborator extends FetchableObject {
  public static async invite({
    wallId,
    email,
    userId,
    role,
  }: UserWallCollaboratorInvite): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-collaborators`
    return await fetchJson(url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wallId,
        email,
        userId,
        role,
        status: 'invited',
      }),
    })
  }

  // Right now, you can only update the collaborator's role.
  // In future, you may be able to update other attributes, such as status.
  public static async update({
    wallId,
    userId,
    role,
  }: {
    wallId: Id
    userId: UserId
    role: WallAccessRole
  }): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-collaborators/${userId}`
    return await fetchJson(url, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ role }),
    })
  }

  public static async remove({
    wallId,
    userId,
  }: {
    wallId: Id
    userId: UserId
  }): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-collaborators/${userId}`
    return await fetchJson(url, {
      method: HTTPMethod.delete,
    })
  }

  public static async search({
    searchQuery,
    wallId,
    limit = 5,
  }: {
    searchQuery: string
    wallId: WallId
    limit?: number
  }): Promise<JsonAPIResponse<UserGroupWallCollaborator | UserWallCollaborator>> {
    const url = transformCurrentUrl(
      {},
      {
        path: '/api/1/wall-collaborators/search',
        search: {
          q: searchQuery,
          wall_id: String(wallId),
          limit: String(limit),
        },
      },
    )
    return await fetchJson(url, {
      method: HTTPMethod.get,
    })
  }

  // Rate-limited: you can only send one invite per collaborator per minute
  // Returns 429 Too many requests if rate-limited
  public static async resendInvitation(privacyPolicyId: PrivacyPolicyId, collaboratorId: UserId): Promise<any> {
    const url = `/privacy_policies/${privacyPolicyId}/reinvite`
    return await fetchJson(url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ invite_user_id: collaboratorId }),
    })
  }
}

class Library extends FetchableObject {
  public static get libraryUrl(): string {
    return `/api/1/libraries?userId=\${ userId }&filter=\${ filter }`
  }

  public static async fetchCreatableLibraries({ userId }, fetchOptions = {}): Promise<JsonAPIResponse<Library>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'wall_creatable' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchCreatableAndVisibleLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<Library>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'wall_creatable_and_visible' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchViewableLibraries({ userId }, fetchOptions = {}): Promise<JsonAPIResponse<Library>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'all_walls_viewable' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchViewableAndVisibleLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<Library>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'all_walls_viewable_and_visible' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

class Wallpaper extends FetchableObject {
  private static get backgroundsUrl(): string {
    return `/api/\${ apiVersion }/backgrounds/\${category}`
  }

  public static get routeMap(): Record<WallBackgroundType, string> {
    return {
      picture: 'pictures',
      solid_color: 'solid_colors',
      gradient: 'gradients',
      pattern: 'patterns',
      art_illustrations: 'art_illustrations',
    }
  }

  private static async fetchBackgrounds(
    { category }: { category: WallBackgroundType },
    fetchOptions = { apiVersion: 7 },
  ): Promise<WallBackground[]> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const { data }: JsonAPIResponse<WallBackground> = await fetchJson(
      this.buildUrl(this.backgroundsUrl, { category: this.routeMap[category], apiVersion }),
      {
        method: HTTPMethod.get,
        ...restFetchOptions,
      },
    )

    if (data != null && Array.isArray(data)) {
      return data.map((jsonResource: JsonAPIResource<WallBackground>): WallBackground => jsonResource.attributes)
    } else {
      return []
    }
  }

  public static async fetchAllBackgrounds(
    fetchOptions = { apiVersion: 7 },
  ): Promise<Record<WallBackgroundType, WallBackground[]>> {
    const BACKGROUND_TYPES: WallBackgroundType[] = [
      'solid_color',
      'gradient',
      'pattern',
      'picture',
      'art_illustrations',
    ]
    const backgroundsGroupedByType: Record<WallBackgroundType, WallBackground[]> = {
      gradient: [],
      pattern: [],
      picture: [],
      solid_color: [],
      art_illustrations: [],
    }
    const promises = BACKGROUND_TYPES.map(async (category) => {
      const bgs = await this.fetchBackgrounds({ category }, fetchOptions)
      backgroundsGroupedByType[category] = bgs
    })

    await Promise.all(promises)
    return backgroundsGroupedByType
  }
}

class UserIntegrations extends FetchableObject {
  public static async fetchGoogleDriveAuthorization(
    id: UserId,
    fetchOptions = { apiVersion: 1 },
  ): Promise<JsonAPIResponse<GoogleDriveAuthorizationResult>> {
    const { apiVersion } = fetchOptions
    return await fetchJson(`/api/${apiVersion}/user/${id}/user_integrations/google_drive`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async refreshGoogleDriveAccessToken(
    id: UserId,
    fetchOptions = {},
  ): Promise<JsonAPIResponse<GoogleDriveAuthorizationResult>> {
    return await fetchJson(`/api/1/user/${id}/user_integrations/google_drive/refresh_access_token`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }
}

class WallPrivacyOptions extends FetchableObject {
  public static get wallPrivacyOptionsUrl(): string {
    return `/api/\${ apiVersion }/surface/walls/\${ wallId }/privacy_options`
  }

  public static async fetchWallPrivacyOptions(
    wallId,
    fetchOptions = { apiVersion: 1 },
  ): Promise<JsonAPIResponse<CanSetWallPrivacyOptions>> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    return await fetchJson(this.buildUrl(this.wallPrivacyOptionsUrl, { wallId, apiVersion }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })
  }
}

class WallCustomPostProperties extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/custom-post-properties`
  }

  public static get urlWithPropertyId(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/custom-post-properties/\${propertyId}`
  }

  public static async createWallCustomPostProperty(
    wallId: number,
    postProperties: WallCustomPostPropertyType,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallCustomPostPropertyType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallCustomPostPropertyType> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.post,
        body: asciiSafeStringify({ data: { attributes: postProperties } }),
        ...otherFetchOptions,
      },
    )
    const newCustomPostProperty = Array.isArray(response.data)
      ? response.data[0]?.attributes
      : response.data?.attributes
    if (newCustomPostProperty == null) throw new Error('Invalid response from server')
    return newCustomPostProperty
  }

  public static async updateWallCustomPostProperty(
    wallId: number,
    updatedPostProperty: WallCustomPostPropertyType,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallCustomPostPropertyType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallCustomPostPropertyType> = await fetchJson(
      this.buildUrl(this.urlWithPropertyId, { wallId, apiVersion, propertyId: updatedPostProperty.id }),
      {
        method: HTTPMethod.patch,
        body: asciiSafeStringify({
          data: {
            attributes: {
              property_id: updatedPostProperty.id,
              ...updatedPostProperty,
            },
          },
        }),
        ...otherFetchOptions,
      },
    )
    const newCustomPostProperty = Array.isArray(response.data)
      ? response.data[0]?.attributes
      : response.data?.attributes
    if (newCustomPostProperty == null) throw new Error('Invalid response from server')
    return newCustomPostProperty
  }

  public static async fetchWallCustomPostProperties(
    wallId: number,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallCustomPostPropertyType[]> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallCustomPostPropertyType[]> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.get,
        query: getSubmissionRequestTokenHash(),
        ...otherFetchOptions,
      },
    )
    if (!Array.isArray(response.data) || response.data == null)
      throw new Error('Custom post properties should be an array')

    const customPostProperties = (response.data as unknown as Array<JsonAPIResource<WallCustomPostPropertyType>>).map(
      ({ attributes }) => {
        return attributes
      },
    )
    return customPostProperties
  }

  public static async deleteWallCustomPostProperty(
    wallId: number,
    propertyId: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<void> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    await fetchResponse(this.buildUrl(this.urlWithPropertyId, { wallId, apiVersion, propertyId }), {
      method: HTTPMethod.delete,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'X-UID': window?.ww?.uid || browsingContextUid,
      },
      ...otherFetchOptions,
    })
  }
}

class WallPostProperties extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/post-properties`
  }

  public static async fetchWallPostProperties(
    wallId: number,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallPostPropertiesType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallPostPropertiesType> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.get,
        query: getSubmissionRequestTokenHash(),
        ...otherFetchOptions,
      },
    )
    const postProperties = Array.isArray(response.data) ? response.data[0]?.attributes : response.data?.attributes
    if (postProperties == null) throw new Error('Invalid response from server')
    return postProperties
  }

  public static async updateWallPostProperties(
    wallId: number,
    postProperties: WallPostPropertiesType,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallPostPropertiesType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WallPostPropertiesType> = await fetchJson(
      this.buildUrl(this.url, { wallId, apiVersion }),
      {
        method: HTTPMethod.put,
        body: asciiSafeStringify({ data: { attributes: postProperties } }),
        ...otherFetchOptions,
      },
    )
    const updatedPostProperties = Array.isArray(response.data)
      ? response.data[0]?.attributes
      : response.data?.attributes
    if (updatedPostProperties == null) throw new Error('Invalid response from server')
    return updatedPostProperties
  }
}

class WhiteboardCollaborationSettings extends FetchableObject {
  public static get url(): string {
    return `/api/\${ apiVersion }/walls/\${ wallHashid }/whiteboard/collaboration-settings`
  }

  public static async fetch(
    wallHashid: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 9 },
  ): Promise<WhiteboardCollaborationSettingsType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WhiteboardCollaborationSettingsType> = await fetchJson(
      this.buildUrl(this.url, { wallHashid, apiVersion }),
      {
        method: HTTPMethod.get,
        ...otherFetchOptions,
      },
    )
    const settings = Array.isArray(response.data) ? response.data[0]?.attributes : response.data?.attributes
    if (settings == null) throw new Error('Invalid response from server')
    return settings
  }

  public static async update(
    wallHashid: string,
    settings: WhiteboardCollaborationSettingsType,
    fetchOptions: VersionedFetchOptions = { apiVersion: 9 },
  ): Promise<WhiteboardCollaborationSettingsType> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response: JsonAPIResponse<WhiteboardCollaborationSettingsType> = await fetchJson(
      this.buildUrl(this.url, { wallHashid, apiVersion }),
      {
        method: HTTPMethod.patch,
        body: asciiSafeStringify({ data: { attributes: settings } }),
        ...otherFetchOptions,
      },
    )
    const updatedSettings = Array.isArray(response.data) ? response.data[0]?.attributes : response.data?.attributes
    if (updatedSettings == null) throw new Error('Invalid response from server')
    return updatedSettings
  }
}

class MentionSuggestions extends FetchableObject {
  public static async fetchMentionSuggestions(
    params: { wallId: number; wishId?: number; q?: string },
    fetchOptions: VersionedFetchOptions & { url?: string } = { apiVersion: 1 },
  ): Promise<UserMentionSuggestion[]> {
    const { url, apiVersion, ...otherFetchOptions } = fetchOptions
    const query: FetchOptions['query'] = {
      wallId: params.wallId.toString(),
    }
    if (params.wishId != null) query.wishId = params.wishId.toString()
    if (params.q != null) query.q = params.q
    const response: JsonAPIResponse<UserMentionSuggestionAttributes> = await fetchJson(
      url ?? this.buildUrl(this.url, { apiVersion }),
      {
        ...otherFetchOptions,
        method: HTTPMethod.get,
        query,
      },
    )
    if (!Array.isArray(response.data)) {
      throw new Error('Mention suggestions should be an array')
    }
    const suggestions: UserMentionSuggestion[] = response.data.map(({ type, attributes }) => ({
      type,
      attributes,
    }))
    return suggestions
  }

  public static get url(): string {
    return `/api/\${ apiVersion }/mention_suggestions`
  }
}

class WallReadToken extends FetchableObject {
  public static get wallReadTokenUrl(): string {
    return `/padlets/\${ apiVersion }/\${ publicKey }/exports/read_token`
  }

  public static async fetchWallReadToken(
    publicKey,
    fetchOptions: VersionedFetchOptions = {},
  ): Promise<JsonAPIResponse<WallReadTokenResponse>> {
    const { apiVersion = 1, ...otherFetchOptions } = fetchOptions
    return await fetchJson(this.buildUrl(this.wallReadTokenUrl, { publicKey, apiVersion }), {
      method: HTTPMethod.get,
      ...otherFetchOptions,
    })
  }
}

class User extends FetchableObject {
  public static async fetchUserLibrarySettings(): Promise<JsonAPIResponse<UserLibrarySettingsApiResponse>> {
    return await fetchJson('/api/1/dashboard_settings/user_library_settings', {
      method: HTTPMethod.get,
    })
  }
}

class SectionBreakout extends FetchableObject {
  public static get sectionBreakoutUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/section-breakout\${ method }`
  }

  public static async list(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSectionBreakoutLink[] | undefined> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.sectionBreakoutUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })

    return response?.data?.map((d: JsonAPIResource<WallSectionBreakoutLink>): WallSectionBreakoutLink => d.attributes)
  }
}

class SubmissionRequest extends FetchableObject {
  public static get submissionRequestUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/submission-request\${ method }`
  }

  public static async enable(
    wallId: WallId,
    confirmationText: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSubmissionRequestLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(
      this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '/enable' }),
      {
        method: HTTPMethod.patch,
        body: asciiSafeStringify({
          confirmation_text: confirmationText,
        }),
        ...restFetchOptions,
      },
    )

    return response.data.attributes
  }

  public static async update(
    wallId: WallId,
    attributes: WallSubmissionRequestProps,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSubmissionRequestLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ attributes }),
      ...restFetchOptions,
    })

    return response.data.attributes
  }

  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallSubmissionRequestLink | undefined> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })

    return response?.data?.attributes
  }

  public static async disable(wallId: WallId, fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<void> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    // using fetchResponse because api does not return json
    await fetchResponse(this.buildUrl(this.submissionRequestUrl, { wallId, apiVersion, method: '/disable' }), {
      method: HTTPMethod.patch,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'X-UID': window?.ww?.uid || browsingContextUid,
      },
      ...restFetchOptions,
    })
  }
}

class WhiteboardScript extends FetchableObject {
  public static get whiteboardScriptUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/whiteboard-script`
  }

  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WhiteboardScriptType[]> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.whiteboardScriptUrl, { wallId, apiVersion }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })

    return response?.data?.attributes
  }

  public static async upsert(
    wallId: WallId,
    shapeId: string,
    script: BlocklyScriptBlocks,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<void> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    await fetchJson(this.buildUrl(this.whiteboardScriptUrl, { wallId, shapeId, apiVersion }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({
        data: {
          type: 'wall_whiteboard_script',
          attributes: {
            script,
            shapeId,
          },
        },
      }),
      ...restFetchOptions,
    })
  }
}
class RemakeLink extends FetchableObject {
  public static get remakeLinkUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/remake-link\${ method }`
  }

  public static async fetch(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallRemakeLink | undefined> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })

    // Return undefined if remake link is not enabled
    return response?.data?.attributes
  }

  public static async enable(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallRemakeLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    const response = await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '/enable' }), {
      method: HTTPMethod.patch,
      ...restFetchOptions,
    })
    return response.data.attributes
  }

  public static async disable(wallId: WallId, fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<void> {
    const { apiVersion, ...restFetchOptions } = fetchOptions

    await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '/disable' }), {
      method: HTTPMethod.patch,
      ...restFetchOptions,
    })
  }

  public static async update(
    wallId: WallId,
    props: WallRemakeLinkProps,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<WallRemakeLink> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    // Convert props to snake_case to send to backend
    const propsBody = mapKeys(props, (value, key) => snakeCase(key))
    const response = await fetchJson(this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '' }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify(propsBody),
      ...restFetchOptions,
    })
    return response.data.attributes
  }

  public static async fetchWallsRemade(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<Array<Pick<WallCamelCase, 'title' | 'builder' | 'links' | 'updatedAt' | 'thumbnail'>>> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const response = await fetchJson(
      this.buildUrl(this.remakeLinkUrl, { wallId, apiVersion, method: '/walls-remade' }),
      {
        method: HTTPMethod.get,
        ...restFetchOptions,
      },
    )
    return response?.data?.attributes
  }
}

class RemakePanel extends FetchableObject {
  static API_VERSION = 9
  public static async fetch(wallHashid: HashId, jsonData: any): Promise<any> {
    return await fetchJson(`api/${this.API_VERSION}/walls/${wallHashid}/remake`, {
      method: HTTPMethod.post,
      jsonData,
    })
  }
}

class SurfaceAIChat extends FetchableObject {
  public static get summarizeUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/ai-chat/summarize`
  }

  public static get baseUrl(): string {
    return environment === 'development' ? 'https://morpheus.padlet.dev' : 'https://morpheus.padlet.com'
  }

  public static get threadUrl(): string {
    return `/api/\${ apiVersion }/walls/\${ wallId }/ai-chat/context`
  }

  public static async getSuggestions(
    serializedWall: string,
    currentCountry: string,
    language: string,
    accountType: string,
    options: { signal?: AbortSignal } = {},
  ): Promise<{ suggestions: SurfaceAiChatSuggestion[] }> {
    const response = await fetch(`${this.baseUrl}/api/v1/surface-chat/suggestions`, {
      method: HTTPMethod.post,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
        'Content-Type': 'application/json',
      },
      body: asciiSafeStringify({
        serialized_wall: serializedWall,
        current_country: currentCountry,
        language,
        account_type: accountType,
      }),
      ...options,
    })
    return await response.json()
  }

  public static async getAssistants(): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/all`, {
      method: HTTPMethod.get,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
    })
    return await response.json()
  }

  public static async createThread(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/threads`, {
      method: HTTPMethod.post,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
      ...fetchOptions,
    })
    return await response.json()
  }

  public static async getThreadId(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.threadUrl, { wallId, apiVersion }), {
      method: HTTPMethod.get,
      ...restFetchOptions,
    })
    return response?.data?.attributes?.threadId
  }

  public static async getLatestRun(threadId: string): Promise<{ status: string; run_id: string | null }> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/${threadId}/latest-run`, {
      method: HTTPMethod.get,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
    })
    return await response.json()
  }

  public static async saveThreadId(
    wallId: WallId,
    threadId: string,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.threadUrl, { wallId, apiVersion }), {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ thread_id: threadId }),
      ...restFetchOptions,
    })
    return response?.data?.attributes?.threadId
  }

  public static async deleteThreadForWall(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const { apiVersion, ...restFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.threadUrl, { wallId, apiVersion }), {
      method: HTTPMethod.delete,
      ...restFetchOptions,
    })
  }

  public static async getThreadMessages(
    threadId: string,
    fetchOptions: VersionedFetchOptions & { signal?: AbortSignal } = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/threads/${threadId}/messages`, {
      method: HTTPMethod.get,
      headers: {
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
      ...fetchOptions,
    })
    return await response.json()
  }

  public static async sendMessage(
    threadId: string,
    message: Message,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}/api/v1/assistant/threads/${threadId}`, {
      method: HTTPMethod.post,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${useSurfaceStartingStateStore().morpheusToken}`,
      },
      body: asciiSafeStringify(message),
      ...fetchOptions,
    })
    return await response.json()
  }

  public static async getSerializedWall(
    wallId: WallId,
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<any> {
    const response = await fetch(this.buildUrl(this.summarizeUrl, { wallId, apiVersion: 1 }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
    return await response.json()
  }
}

class AiTalk extends FetchableObject {
  public static get baseUrl(): string {
    return environment === 'production' ? 'https://morpheus.padlet.com' : 'https://morpheus.padlet.dev'
  }

  public static async generateAudio(
    text: string,
    languageCode: string,
    voiceName: string,
    fetchOptions: FetchOptions = {},
  ): Promise<AiTalkResponse> {
    const authorization = fetchOptions.authorization?.join(' ') ?? ''
    const response = await fetch(`${this.baseUrl}/api/v1/google/tts/create`, {
      method: HTTPMethod.post,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
      body: asciiSafeStringify({ text, language_code: languageCode, voice_name: voiceName }),
      ...fetchOptions,
    })
    return await response.json()
  }

  public static async getLanguage(text: string, fetchOptions: FetchOptions = {}): Promise<AiTalkLanguageResponse> {
    const authorization = fetchOptions.authorization?.join(' ') ?? ''
    const response = await fetch(`${this.baseUrl}/api/v1/language/detect`, {
      method: HTTPMethod.post,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
      body: asciiSafeStringify({ text }),
      ...fetchOptions,
    })
    return await response.json()
  }
}

class Powwow extends FetchableObject {
  private static get updateSessionUserUrl(): string {
    return `/api/\${ apiVersion }/session/users/\${ userId }`
  }

  private static get createSessionUserUrl(): string {
    return `/api/\${ apiVersion }/session/users`
  }

  private static get getCurrentUserUrl(): string {
    return `/api/\${ apiVersion }/session`
  }

  private static get getArvoConfigUrl(): string {
    return `/api/\${ apiVersion }/session/arvo-config`
  }

  public static async updateSessionUserName(
    updateParams: { userId: UserId; name: string; wallId: WallId },
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<UserSnakeCase> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(
      this.buildUrl(this.updateSessionUserUrl, { apiVersion, userId: updateParams.userId }),
      {
        ...otherFetchOptions,
        method: HTTPMethod.patch,
        body: asciiSafeStringify({ data: { attributes: { name: updateParams.name, wallId: updateParams.wallId } } }),
      },
    )
    const user = (response.data as JsonAPIResource<UserSnakeCase>).attributes
    return user
  }

  public static async createSessionUser(
    createParams: { name?: string } = {},
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<CreateSessionUserApiResponse> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions

    const response = await fetchJson(
      this.buildUrl(this.createSessionUserUrl, {
        apiVersion,
      }),
      {
        ...otherFetchOptions,
        method: HTTPMethod.post,
        body: asciiSafeStringify({
          data: { attributes: { name: createParams.name } },
        }),
      },
    )
    const user = (response.data as JsonAPIResource<CreateSessionUserApiResponse>).attributes.user
    const arvoConfig = (response.data as JsonAPIResource<CreateSessionUserApiResponse>).attributes.arvoConfig
    return { user, arvoConfig }
  }

  public static async getCurrentUser(fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<UserSnakeCase> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.getCurrentUserUrl, { apiVersion }), {
      ...otherFetchOptions,
      method: HTTPMethod.get,
    })
    return (response.data as JsonAPIResource<UserSnakeCase>).attributes
  }

  public static async getArvoConfig(fetchOptions: VersionedFetchOptions = { apiVersion: 1 }): Promise<ArvoConfig> {
    const { apiVersion, ...otherFetchOptions } = fetchOptions
    const response = await fetchJson(this.buildUrl(this.getArvoConfigUrl, { apiVersion }), {
      ...otherFetchOptions,
      method: HTTPMethod.get,
    })
    return (response.data as JsonAPIResource<ArvoConfig>).attributes
  }
}

class WallFollows extends FetchableObject {
  public static async create(fetchOptions = {}): Promise<JsonAPIResponse<WallFollowApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/wall_follows`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async update(
    { wallFollowId }: { wallFollowId: Id },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WallFollowApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/wall_follows/${String(wallFollowId)}`, {
      method: HTTPMethod.patch,
      ...fetchOptions,
    })
  }
}

class Folder extends FetchableObject {
  public static async create(attributes: Pick<FolderType, 'name'>): Promise<JsonAPIResponse<FolderType>> {
    return await fetchJson(`/api/${API_VERSION}/folders`, {
      method: HTTPMethod.post,
      jsonData: {
        data: {
          type: 'folder',
          attributes,
        },
      },
    })
  }
}
class PadletStartingState extends FetchableObject {
  public static async fetch(fetchOptions = {}): Promise<SurfaceState> {
    const startingStatePath = transformCurrentUrl(
      {},
      {
        path: `/api/6/padlet-starting-state/${currentPathWithoutLeadingSlash()}`,
        searchParams: getSearchParams(), // We need to pass the search params to the api controllers
      },
    )
    const response = await fetchJson(startingStatePath, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
    return response
  }
}

class ContentModeration extends FetchableObject {
  public static async moderateUserName(
    createParams: { name: string },
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<{ userNameHasViolation: null | boolean }> {
    try {
      const response = await poll({
        pollingUrl: '/api/1/content-moderation/user-name',
        validationCallback: (response: JsonApiResponse<ModerateUserNameApiResponse>) =>
          (response.data as JsonApiData<ModerateUserNameApiResponse>)?.attributes?.userNameHasViolation != null,
        options: {
          intervalSecs: 1,
          maxAttempts: 5,
          method: HTTPMethod.post,
          fetchOptions: {
            body: asciiSafeStringify({
              data: { attributes: { name: createParams.name } },
            }),
          },
        },
      })

      return response.data.attributes
    } catch (e) {
      if (e == null) {
        return { userNameHasViolation: null }
      }
      throw e
    }
  }

  public static async moderateSandboxText(
    wallHashid: string,
    params: { shape_id: string },
  ): Promise<{ userNameHasViolation: null | boolean }> {
    return await fetchJson(`/api/7/content-moderation/whiteboard/${wallHashid}/text`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ data: { attributes: params } }),
    })
  }
}

class LinkSafetyCheck extends FetchableObject {
  public static readonly checkLinkSafetyUrl = `/api/\${ apiVersion }/links/check-safety`

  public static async checkLinkSafety(
    params: { url: string },
    fetchOptions: VersionedFetchOptions = { apiVersion: 1 },
  ): Promise<LinkSafetyCheckApiResponse> {
    const response = await fetchJson(this.buildUrl(this.checkLinkSafetyUrl, fetchOptions), {
      method: HTTPMethod.get,
      query: params,
    })
    return response.data.attributes
  }
}

export default {
  Reaction,
  PostConnection,
  Comment,
  CommentV2,
  GoogleAppLicensingSettings,
  Grades,
  AiSuggestedComments,
  AiSuggestedCustomization,
  WallAccessSettings,
  WallGradingSettings,
  WallSection,
  WallTableLayout,
  WallOnboardingPanel,
  WallOnboardingDemo,
  Wall,
  Wish,
  WishDraft,
  UserContributor,
  WallUserContributor,
  ContributingStatus,
  BrahmsToken,
  Plan,
  collaboratorCreate,
  collaboratorLeavePadlet,
  Library,
  LinkSafetyCheck,
  Wallpaper,
  WallPrivacyOptions,
  WallCustomPostProperties,
  WallPostProperties,
  MentionSuggestions,
  UserIntegrations,
  Poll,
  WallReadToken,
  User,
  UserGroupWallCollaborator,
  WallCollaborator,
  SectionBreakout,
  SubmissionRequest,
  WhiteboardCollaborationSettings,
  WhiteboardScript,
  GuidedTemplates,
  RemakeLink,
  RemakePanel,
  SurfaceAIChat,
  AiTalk,
  Powwow,
  LmsPassback,
  WallFollows,
  Folder,
  PadletStartingState,
  ContentModeration,
  WallAttachments,
  WallExports,
}
