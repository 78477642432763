/**
 * @file Helper for surface share links
 */

import { __ } from '@@/bits/intl'
import { buildUrlFromPath } from '@@/bits/location'
import type { FetchOptionQueryHash } from '@padlet/fetch/src/types'

export const DEFAULT_SUBMISSION_REQUEST_CONFIRMATION_TEXT = __('Thank you for your contribution!')

export function getSectionBreakoutPath(wallNamespace: string, sectionBreakoutIdentifier: string): string {
  return `/${wallNamespace}/breakout-link/${sectionBreakoutIdentifier}`
}

export function getSectionBreakoutUrl(wallNamespace: string, sectionBreakoutIdentifier: string): string {
  const sectionBreakoutPath = getSectionBreakoutPath(wallNamespace, sectionBreakoutIdentifier)
  return buildUrlFromPath(sectionBreakoutPath)
}

export function getSectionBreakoutPostPath(
  wallNamespace: string,
  sectionBreakoutIdentifier: string,
  postId: string,
): string {
  return getSectionBreakoutPath(wallNamespace, sectionBreakoutIdentifier) + `/wish/${postId}`
}

export function getSectionBreakoutPostUrl(
  wallNamespace: string,
  sectionBreakoutIdentifier: string,
  postId: string,
): string {
  return getSectionBreakoutUrl(wallNamespace, sectionBreakoutIdentifier) + `/wish/${postId}`
}

export function getSectionBreakoutDisabledUrl(): string {
  return buildUrlFromPath(`/section-breakout-disabled`)
}

export function getSubmissionRequestDisabledUrl(): string {
  return buildUrlFromPath(`/submission-request-disabled`)
}

export function getSubmissionRequestConfirmationUrl(submissionRequestLink: string): string {
  return submissionRequestLink + '/confirmation'
}

export function isNavigatedFromSubmissionRequest(): boolean {
  return document.referrer?.includes('/submission-request/')
}

/* Submission request token */
let submissionRequestToken = ''

export const getSubmissionRequestTokenHash = (): FetchOptionQueryHash => {
  if (submissionRequestToken === '') {
    // meta tag injected into doodle.html.erb
    // Used to skip auth for requests made in Submission Request view as permissions for Submission Request differ from the wall
    const submissionRequestTags = document.getElementsByName('submission-request-token')
    if (submissionRequestTags.length > 0) {
      const firstSubmissionRequestTag = submissionRequestTags[0] as HTMLMetaElement
      submissionRequestToken = firstSubmissionRequestTag.content
    }
  }

  return submissionRequestToken.length === 0 ? {} : { submission_request_token: submissionRequestToken }
}
