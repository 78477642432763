// @file The store for layout pickers displayed by DashLayoutPickerPage.vue and LayoutPickerDesktop.vue
import { trackEvent } from '@@/bits/analytics'
import { setCookie } from '@@/bits/cookie'
import device from '@@/bits/device'
import { captureException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { getAndClearSearchParam, navigateTo } from '@@/bits/location'
import { isPartOfUnlimitedSandboxesEvent } from '@@/bits/unlimited_sandboxes_event'
import { displayNameForUser } from '@@/bits/user_model'
import { unboundedWatch } from '@@/bits/vue'
import {
  GuidedTemplateType,
  LibraryMembershipRole,
  LibrarySubscriptionStatus,
  LibraryType,
  UserAccountType,
} from '@@/enums'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useDashGalleryTemplatesStore } from '@@/pinia/dash_gallery_templates_store'
import { useDashLayoutPickerSearchStore } from '@@/pinia/dash_layout_picker_search_store'
import { useDashSuggestedTemplatesStore } from '@@/pinia/dash_suggested_templates_store'
import PadletApi from '@@/surface/padlet_api'
import type {
  Library,
  LibraryAccess,
  LibraryId,
  User,
  WallCreationFromTemplateResult,
  WallGalleryTemplate,
  WallTemplate,
} from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { sortBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum WallCreatedFrom {
  Blank = 'blank',
  GalleryTemplate = 'gallery_template',
  GuidedTemplate = 'guided_template',
  Whiteboard = 'whiteboard',
  Other = 'other',
  Error = 'error',
}

interface GalleryTemplatesToShow {
  category: string | null
  templates: WallGalleryTemplate[]
}

const STARK_INDUSTRIES_TEAM_ID = 4

export const useDashLayoutPickerStore = defineStore('dashLayoutPickerStore', () => {
  const dashAccountsStore = useDashAccountsStore()
  const dashCollectionsStore = useDashCollectionsStore()
  const dashGalleryTemplatesStore = useDashGalleryTemplatesStore()
  const dashSuggestedTemplatesStore = useDashSuggestedTemplatesStore()
  const dashLayoutPickerSearchStore = useDashLayoutPickerSearchStore()

  // State
  const isCreatingWall = ref(false)
  const errorCreatingWall = ref(false)
  const xLibrarySelectionMenu = ref(false)
  const librarySelected = ref<Library | null>(null)
  const xLayoutPicker = ref(false)
  const personalLibraryWallTemplates = ref<WallTemplate[]>([])
  const nonPersonalLibraryWallTemplatesByLibraryId = ref<Record<LibraryId, WallTemplate[]>>({})
  const hasRequestedAnotherFreePadlet = ref(false)

  const galleryTemplates = computed(() => dashGalleryTemplatesStore.galleryTemplates)

  // Getters

  const isSelectedLibrarySchool = computed(() => librarySelected.value?.libraryType === LibraryType.School)
  const isSelectedLibraryClassroom = computed(() => librarySelected.value?.libraryType === LibraryType.Classroom)
  const isSelectedLibraryTeam = computed(() => librarySelected.value?.libraryType === LibraryType.Team)
  const isSelectedPersonalAccount = computed(() => librarySelected.value === null)
  const selectedNotCreatableLibrary = computed(
    (): boolean =>
      !(librarySelected.value == null) &&
      !dashAccountsStore.wallCreatableLibrariesArray.includes(librarySelected.value as Library),
  )
  const cannotMakeWallBecauseOfTeamLibraryPermissions = computed(
    (): boolean => isSelectedLibraryTeam.value && selectedNotCreatableLibrary.value,
  )
  const cannotMakeWallBecauseOfSchoolLibraryPermissions = computed(
    (): boolean => isSelectedLibrarySchool.value && selectedNotCreatableLibrary.value,
  )
  const cannotMakeWallBecauseOfClassroomLibraryPermissions = computed(
    (): boolean => isSelectedLibraryClassroom.value && selectedNotCreatableLibrary.value,
  )
  const cannotMakeWallBecauseOfTenantPermissions = computed(
    (): boolean => dashAccountsStore.currentUserOptions?.canMakeInTenant === false,
  )
  const currentLibrarySubscriptionStatus = computed((): LibrarySubscriptionStatus | null => {
    if (librarySelected.value === null || librarySelected.value === undefined) return null
    if (librarySelected.value.isDeprecatedFreeTier) return null
    if (librarySelected.value.membershipTier === 'gold') return LibrarySubscriptionStatus.Paying
    if (librarySelected.value.membershipTier === 'trial') return LibrarySubscriptionStatus.OnTrial
    if (librarySelected.value.isPlanCancelled) return LibrarySubscriptionStatus.Cancelled
    if (librarySelected.value.isTrialExpired as boolean) return LibrarySubscriptionStatus.TrialExpired
    return null
  })
  const atQuota = computed(
    (): boolean =>
      (librarySelected.value == null && !dashAccountsStore.currentUser.quota.can_make) ||
      (!(librarySelected.value == null) && librarySelected.value.quota?.quotaHit),
  )

  const xUpgradeButton = computed((): boolean => {
    if (cannotMakeWallBecauseOfTenantPermissions.value) {
      return false
    }

    return (
      librarySelected.value == null ||
      dashAccountsStore.userRolesByLibraryId[librarySelected.value?.id] === LibraryMembershipRole.Owner
    )
  })
  const xGetAnotherFreePadletButton = computed((): boolean => {
    if (!isSelectedPersonalAccount.value) return false
    if (!dashAccountsStore.isNativeAccount) return false
    if (!dashAccountsStore.canCreateWall) return false
    if (hasRequestedAnotherFreePadlet.value) return false
    if (
      dashAccountsStore.currentUser.membership_tier === 'neon' &&
      (dashAccountsStore.currentUser.quota.walls_used === 3 || dashAccountsStore.currentUser.quota.walls_used === 4)
    ) {
      return true
    }
    if (
      dashAccountsStore.currentUser.membership_tier === 'gold' &&
      (dashAccountsStore.currentUser.quota.walls_used === 20 || dashAccountsStore.currentUser.quota.walls_used === 21)
    ) {
      return true
    }
    return false
  })

  const cannotMakeWall = computed(
    (): boolean =>
      selectedNotCreatableLibrary.value ||
      cannotMakeWallBecauseOfTenantPermissions.value ||
      atQuota.value ||
      currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.Cancelled ||
      currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.TrialExpired ||
      xGetAnotherFreePadletButton.value,
  )

  const unableToCreateWallText = computed((): string => {
    if (xGetAnotherFreePadletButton.value) return __('You’ve reached your padlet quota.')
    if (cannotMakeWallBecauseOfTeamLibraryPermissions.value) {
      // Cannot make walls because of permissions
      return __(
        "You do not have Maker permissions in this team. <br> If you'd like to make padlets please ask an Admin to change your role.",
      )
    }

    if (cannotMakeWallBecauseOfSchoolLibraryPermissions.value || cannotMakeWallBecauseOfTenantPermissions.value) {
      return __('An admin has disabled permissions for making new padlets.')
    }

    if (cannotMakeWallBecauseOfClassroomLibraryPermissions.value) {
      return __('A teacher has disabled permissions for making new padlets.')
    }

    // At quota for personal libraries (accounts)
    if (librarySelected.value == null) {
      return isPartOfUnlimitedSandboxesEvent()
        ? __(
            'You’ve reached your quota for boards but you can upgrade to create more. Create unlimited sandboxes until October 1.',
          )
        : __('You’ve reached your padlet quota. Upgrade to continue making padlets.')
    }

    /**
     * For trial libraries (team and classroom), we don't limit the quota, so there's no need to check for quota
     */

    const currentUserLibraryRole = dashAccountsStore.userRolesByLibraryId[librarySelected.value.id]

    // Trial expired
    if (currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.TrialExpired) {
      if (isSelectedLibraryClassroom.value) {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your classroom’s free trial has ended. Upgrade to continue making new padlets.')
        } else if (currentUserLibraryRole === LibraryMembershipRole.Teacher) {
          // We want to show the string "the classroom owner" for classroom teachers for clarity
          return __(
            'Your classroom’s free trial has ended. Ask the classroom owner to upgrade to continue making padlets.',
          )
        } else {
          return __('Your classroom’s free trial has ended. Ask a teacher to upgrade to continue making padlets.')
        }
      } else {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your team’s free trial has ended. Upgrade to continue making new padlets.')
        } else {
          return __('Your team’s free trial has ended. Ask the team owner to upgrade to continue making padlets.')
        }
      }
    }

    // Subscription cancelled
    if (currentLibrarySubscriptionStatus.value === LibrarySubscriptionStatus.Cancelled) {
      if (isSelectedLibraryClassroom.value) {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your classroom’s subscription has been cancelled. Upgrade to continue making new padlets.')
        } else if (currentUserLibraryRole === LibraryMembershipRole.Teacher) {
          // We want to show the string "the classroom owner" for classroom teachers for clarity
          return __(
            'Your classroom’s subscription has been cancelled. Ask the classroom owner to upgrade to continue making padlets.',
          )
        } else {
          return __(
            'Your classroom’s subscription has been cancelled. Ask a teacher to upgrade to continue making padlets.',
          )
        }
      } else {
        if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
          return __('Your team’s subscription has been cancelled. Upgrade to continue making new padlets.')
        } else {
          return __(
            'Your team’s subscription has been cancelled. Ask the team owner to upgrade to continue making padlets.',
          )
        }
      }
    }

    /**
     * For non-trial libraries.
     */

    // At quota school libraries
    if (isSelectedLibrarySchool.value) {
      if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
        return __('Your school has reached its padlet quota. Upgrade to continue making padlets.')
      } else {
        return __(
          'Your school has reached its padlet quota. Ask the school owner to upgrade to continue making padlets.',
        )
      }
    }

    // At quota for team libraries
    if (isSelectedLibraryTeam.value) {
      if (currentUserLibraryRole === LibraryMembershipRole.Owner) {
        return __('Your team has reached its padlet quota. Upgrade to continue making padlets.')
      } else {
        return __('Your team has reached its padlet quota. Ask the team owner to upgrade to continue making padlets.')
      }
    }

    // At quota for personal libraries (accounts)
    return isPartOfUnlimitedSandboxesEvent()
      ? __(
          'You’ve reached your quota for boards but you can upgrade to create more. Create unlimited sandboxes until October 1.',
        )
      : __('You’ve reached your padlet quota. Upgrade to continue making padlets.')
  })
  const emptyLibraryAccess: LibraryAccess = {
    canViewLibraryInfo: false,
    canUpdateLibraryInfo: false,
    canSetupLti: false,
    canUpdatePermissions: false,
    canViewLibraryMemberships: false,
    canViewLibraryAnalytics: false,
    canManageMembers: false,
    canManageBilling: false,
    canTrashLibrary: false,
    canUseLibraryAsOwner: false,
    canUseLibraryAsAdmin: false,
    canUseLibraryAsTeacher: false,
    canViewLibrarySecurity: false,
    canInviteSchoolAdmins: false,
    canInviteSchoolTeachers: false,
    canInviteSchoolStudents: false,
    canInviteMembers: false,
    canViewGallery: false,
    canManageContentSafetySettings: false,
  }
  const currentLibrary = computed((): Partial<Library> => {
    return {
      name:
        librarySelected.value != null ? librarySelected.value.name : displayNameForUser(dashAccountsStore.currentUser),
      avatar: librarySelected.value != null ? librarySelected.value?.avatar : dashAccountsStore.currentUser.avatar,
      libraryType: librarySelected.value != null ? librarySelected.value.libraryType : LibraryType.Solo,
      libraryAccess: librarySelected.value != null ? librarySelected.value.libraryAccess : emptyLibraryAccess,
    }
  })

  const currentLibraryWallTemplates = computed((): WallTemplate[] => {
    return librarySelected.value === null
      ? personalLibraryWallTemplates.value
      : nonPersonalLibraryWallTemplatesByLibraryId.value[librarySelected.value.id]
  })

  const showEducationGalleryTemplates = computed((): boolean => {
    // Show all gallery templates for Stark Industries team (our internal team library) for testing purposes
    if (librarySelected.value?.id === STARK_INDUSTRIES_TEAM_ID) return true
    if (dashAccountsStore.isBackpack) return true
    if (
      dashAccountsStore.currentUser.account_type === UserAccountType.TEACHER ||
      dashAccountsStore.currentUser.account_type === UserAccountType.STUDENT ||
      dashAccountsStore.currentUser.account_type === UserAccountType.STAFF
    )
      return true
    return false
  })

  const showBusinessGalleryTemplates = computed((): boolean => {
    if (dashAccountsStore.isBriefcase) return true
    if (dashAccountsStore.currentUser.account_type === UserAccountType.BUSINESS) return true
    return false
  })

  const galleryTemplatesByAudience = computed((): WallGalleryTemplate[] => {
    // Hide general gallery templates for Stark Industries team (our internal team library)
    if (librarySelected.value?.id === STARK_INDUSTRIES_TEAM_ID)
      return galleryTemplates.value.filter((template) => template.audience !== 'general')

    if (showEducationGalleryTemplates.value)
      return galleryTemplates.value.filter((template) => template.audience === 'education')
    if (showBusinessGalleryTemplates.value)
      return galleryTemplates.value.filter((template) => template.audience === 'business')
    return galleryTemplates.value.filter((template) => template.audience === 'general')
  })

  const galleryTemplatesByCategory = computed((): Record<string, WallGalleryTemplate[]> => {
    return galleryTemplatesByAudience.value.reduce<Record<string, WallGalleryTemplate[]>>(
      (templatesByCategory, template) => {
        if (template.category == null || template.category.length === 0) {
          return templatesByCategory
        }

        template.category.forEach((category) => {
          if (!(category in templatesByCategory)) {
            templatesByCategory[category] = []
          }
          templatesByCategory[category].push(template)
        })

        return templatesByCategory
      },
      {},
    )
  })

  const galleryTemplatesSearchResults = computed(() => {
    return dashLayoutPickerSearchStore.filterBySearch(
      galleryTemplatesByAudience.value,
      dashLayoutPickerSearchStore.searchTerm,
    )
  })

  const galleryTemplatesBySortedCategories = computed<GalleryTemplatesToShow[]>(() => {
    // Adding null to the first element to conditionally render "Search results" title
    if (dashLayoutPickerSearchStore.hasSearchTerm) {
      return [{ category: null, templates: galleryTemplatesSearchResults.value ?? [] }]
    }

    // Apply sorting logic when not searching - Popular first if it exists, then alphabetically
    let sortedCategories
    const allCategories = Object.keys(galleryTemplatesByCategory.value)

    if (showEducationGalleryTemplates.value) {
      const nonPopularCategories = sortBy(
        allCategories.filter((category) => category !== 'Popular'),
        (category) => category.toLowerCase(),
      )
      sortedCategories = ['Popular', ...nonPopularCategories]
    } else {
      sortedCategories = sortBy(allCategories, (category) => category.toLowerCase())
    }

    return sortedCategories.map((category) => ({
      category,
      templates: galleryTemplatesByCategory.value[category] ?? [],
    }))
  })

  // Actions
  async function initializeState({
    currentUserWallTemplates,
    libraryToWallTemplates,
  }: {
    currentUserWallTemplates: WallTemplate[]
    libraryToWallTemplates: Record<LibraryId, WallTemplate[]>
  }): Promise<void> {
    personalLibraryWallTemplates.value = currentUserWallTemplates
    nonPersonalLibraryWallTemplatesByLibraryId.value = libraryToWallTemplates
    if (isAppUsing('suggestedTemplates')) {
      void dashGalleryTemplatesStore.fetchGalleryTemplates()
      void dashSuggestedTemplatesStore.fetchSuggestedTemplatesData()
    } else {
      await dashGalleryTemplatesStore.fetchGalleryTemplates()
    }
    setInitialLibrarySelected()
  }

  void unboundedWatch(
    [() => dashAccountsStore.wallViewableLibrariesArray, () => dashAccountsStore.currentLibrary],
    () => {
      // Re-set the initial library when the wallViewableLibrariesArray is fetched fully
      // Context: when you first open the layout picker (especially visit the mobile layout picker), the wallViewableLibrariesArray is not fetched yet. So the initial library is not set correctly
      setInitialLibrarySelected()
    },
  )

  function setInitialLibrarySelected(): void {
    if (isAppUsing('padletHome')) {
      librarySelected.value = dashAccountsStore.currentLibrary
      return
    }

    // Show the corresponding library if the user is in a library view on the dashboard
    if (dashCollectionsStore.currentAccountKey?.type === 'library') {
      const currentlyOpenLibrary = dashAccountsStore.wallViewableLibrariesArray.find(
        (library) => library.id === dashCollectionsStore.currentAccountKey?.id,
      )
      if (currentlyOpenLibrary === undefined) return
      librarySelected.value = currentlyOpenLibrary
    } else if (
      dashCollectionsStore.currentAccountKey?.type === 'user' &&
      !dashCollectionsStore.isActiveCollectionCrossLibrary
    ) {
      librarySelected.value = null
    } else if (
      dashAccountsStore.isNativeAccount &&
      dashCollectionsStore.isActiveCollectionCrossLibrary &&
      dashAccountsStore.defaultLibrary !== undefined
    ) {
      // Show the default library on cross collection views, e.g. Recents, Bookmarks, Shared, and Gallery.
      librarySelected.value = dashAccountsStore.defaultLibrary
    }
  }

  // Processing and retry stages
  async function createWall({
    viz,
    groupBySection,
    sourceId,
    createdFrom = WallCreatedFrom.Blank,
    title,
    description,
  }: {
    viz: string
    groupBySection?: boolean
    sourceId?: string
    createdFrom?: string
    title?: string
    description?: string
  }): Promise<void> {
    isCreatingWall.value = true

    try {
      // Create the payload for PadletApi.Wall.create dynamically

      const createPayload = {
        viz,
        libraryId: librarySelected.value?.id,
        groupBySection,
        createdFrom,
        ...(sourceId !== null && { sourceId }),
        ...(title !== null && { title }),
        ...(description !== null && { description }),
      }

      const response = await PadletApi.Wall.create(createPayload)
      // To show the onboarding flow when user first visit the padlet
      setCookie('first_view', 'true', { path: `/${response.address as string}` })
      navigateTo(response.links.show as string, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        closeLayoutPicker()
      }
    } catch (e) {
      captureException(e)
      isCreatingWall.value = false
      errorCreatingWall.value = true
    }
  }

  async function createWallWithDiscussionBoard(): Promise<void> {
    // This was taken from the discussion board template https://padlet.com/gallerytemplates/discussion-topic-goes-here-cd8if1zoeurjmn4o
    const DISCUSSION_BOARD_TEMPLATE_ID = 205981623
    isCreatingWall.value = true
    try {
      const response = await PadletApi.Wall.createFromTemplate(DISCUSSION_BOARD_TEMPLATE_ID, {
        jsonData: {
          title: __('[discussion topic goes here]'),
          description: __('Post your response to the discussion topic by clicking the plus button below.'),
          sourceId: GuidedTemplateType.DiscussionBoard,
          createdFrom: WallCreatedFrom.GuidedTemplate,
          with_same_privacy: false,
          with_original_authors: false,
          library_id: librarySelected.value?.id ?? null,
        },
      })
      const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)?.attributes
        .url
      navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        closeLayoutPicker()
      }
    } catch (e) {
      captureException(e)
      isCreatingWall.value = false
      errorCreatingWall.value = true
    }
  }

  async function createGridWithoutSectionsWall(): Promise<void> {
    await createWall({ viz: 'grid', groupBySection: false })
  }

  async function createWhiteboard(): Promise<void> {
    await createWall({ viz: 'whiteboard', createdFrom: WallCreatedFrom.Whiteboard })
  }

  async function createWallFromTemplate(wallTemplate: WallTemplate): Promise<void> {
    isCreatingWall.value = true
    try {
      // Format for template title is `wall-title - August 23, 2023`
      const currentDateLongFormat = new Date().toLocaleDateString('default', {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      })
      const response = await PadletApi.Wall.createFromTemplate(wallTemplate.id, {
        jsonData: {
          title: `${wallTemplate.title} - ${currentDateLongFormat}`,
          description: wallTemplate.description,
          with_posts: true,
          with_same_privacy: false,
          with_original_authors: false,
          library_id: librarySelected.value?.id ?? null,
        },
      })
      const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)?.attributes
        .url
      navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        closeLayoutPicker()
      }
    } catch (e) {
      captureException(e)
      isCreatingWall.value = false
      errorCreatingWall.value = true
    }
  }

  // Similar to createWallFromTemplate, however with different jsonData.
  // TODO: update WallProps for onboarding upon click.
  async function createWallFromGalleryTemplate(wallGalleryTemplate: WallGalleryTemplate): Promise<void> {
    isCreatingWall.value = true
    try {
      const response = await PadletApi.Wall.createFromTemplate(wallGalleryTemplate.id, {
        jsonData: {
          with_posts: true,
          with_same_privacy: false,
          with_original_authors: false,
          library_id: librarySelected.value?.id ?? null,
          created_from: WallCreatedFrom.GalleryTemplate,
          source_id: wallGalleryTemplate.galleryTemplateId,
        },
      })
      const wallCreatedFromTemplateUrl = (response?.data as JsonAPIResource<WallCreationFromTemplateResult>)?.attributes
        .url
      navigateTo(wallCreatedFromTemplateUrl, { target: device.electronApp ? '_blank' : '_self' })
      if (device.electronApp) {
        closeLayoutPicker()
      }
      trackEvent('Template', 'Create wall from gallery template', wallGalleryTemplate.title)
    } catch (e) {
      captureException(e)
      isCreatingWall.value = false
      errorCreatingWall.value = true
    }
  }

  function openLayoutPicker(): void {
    setInitialLibrarySelected()

    xLayoutPicker.value = true
    // Log when a user opens the layout picker
    trackEvent('Dashboard', 'Opened layout picker')
  }

  function closeLayoutPicker(): void {
    getAndClearSearchParam('create')
    xLayoutPicker.value = false
    librarySelected.value = null
    dashLayoutPickerSearchStore.clearSearchTerm()
    if (isCreatingWall.value) {
      isCreatingWall.value = false
    }
    errorCreatingWall.value = false
  }

  function reopenPickerAfterWallCreationError(): void {
    isCreatingWall.value = !isCreatingWall.value
    errorCreatingWall.value = !errorCreatingWall.value
  }

  function toggleLibrarySelectionMenu(): void {
    xLibrarySelectionMenu.value = !xLibrarySelectionMenu.value
  }

  function updateLibrarySelected(payload: { librarySelected: Library; user: User }): void {
    librarySelected.value = payload.librarySelected
    xLibrarySelectionMenu.value = !xLibrarySelectionMenu.value
  }

  function openRequestTemplatePageInNewTab(): void {
    const requestTemplateUrl = 'https://airtable.com/appC2oLHaoENxKrjF/shrZfmR4VZV3x0A8C'
    navigateTo(requestTemplateUrl, { target: '_blank' })
  }

  function getAnotherFreePadlet(): void {
    hasRequestedAnotherFreePadlet.value = true
  }

  return {
    // State
    isCreatingWall,
    errorCreatingWall,
    xLibrarySelectionMenu,
    currentLibrary,
    librarySelected,
    xLayoutPicker,
    galleryTemplates,
    galleryTemplatesSearchResults,

    // Getters
    selectedNotCreatableLibrary,
    cannotMakeWallBecauseOfSchoolLibraryPermissions,
    cannotMakeWallBecauseOfTeamLibraryPermissions,
    currentLibrarySubscriptionStatus,
    cannotMakeWallBecauseOfTenantPermissions,
    atQuota,
    xUpgradeButton,
    cannotMakeWall,
    unableToCreateWallText,
    isSelectedLibrarySchool,
    isSelectedLibraryClassroom,
    isSelectedLibraryTeam,
    isSelectedPersonalAccount,
    currentLibraryWallTemplates,
    galleryTemplatesByCategory,
    galleryTemplatesByAudience,
    galleryTemplatesBySortedCategories,
    xGetAnotherFreePadletButton,

    // Actions
    initializeState,
    setInitialLibrarySelected,
    openLayoutPicker,
    closeLayoutPicker,
    createWall,
    createWallFromTemplate,
    createWallFromGalleryTemplate,
    createGridWithoutSectionsWall,
    createWhiteboard,
    createWallWithDiscussionBoard,
    reopenPickerAfterWallCreationError,
    toggleLibrarySelectionMenu,
    updateLibrarySelected,
    openRequestTemplatePageInNewTab,
    getAnotherFreePadlet,
  }
})
