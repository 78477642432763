// Our own notifications registration, etc
import { fetchJson } from '@padlet/fetch'
import device from '@@/bits/device'

// ======================================================================================
// WEB PUSH
// ======================================================================================
/**
 * Convert a base64 string to an array. Taken from
 * https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
 * @param {string} base64string - Base64 string.
 * @return {Uint8[]} Array of 8 bit integers representing the base64 string.
 */
const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

/**
 * Public/private key pair taken from https://web-push-codelab.glitch.me/.
 * Base 64 URL safe encoded.
 * Refer to https://developers.google.com/web/fundamentals/codelabs/push-notifications/#initialize_state
 */
const PUBLIC_KEY = 'BFZ6K_Tw__LTwX1kglcPYycsbSzgBIN5OfQwsDLHlo6PEl-fRqpEtUnrsikmxDPqG2h2uUs96_xp4omypTPUl5o'

/**
 * Can be used by another function later in the session, should we
 * not have access to the service worker registration.
 */
let serviceWorkerRegistration = null

const createSubscriptionInBackend = (subscription) => {
  let token
  let metadata
  // If subscription is just a string, it's a Safari subscription
  // for push notifications; otherwise it's a normal web pushable subscription.
  if (typeof subscription === 'string') {
    token = subscription
    metadata = null
  } else {
    token = JSON.stringify(subscription)
    metadata = subscription.toJSON()
  }
  const body = JSON.stringify({ token, metadata })
  return fetchJson(`/api/device_tokens`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    credentials: 'same-origin',
    body,
  })
}

/**
 * Get status of whether user has permitted Padlet to send
 * notifications.
 * @return {Promise} A Promise that returns a string, either
 * "granted", "denied", "default", or 'notapplicable'.
 */
export const getNotificationPermission = () => {
  if (device.safariPush) {
    const permissionData = window.safari.pushNotification.permission(`web.com.padlet`)
    // permissionData is an Object that looks like this
    // { deviceToken: '<safari_device_token_string>', permission: '<default | denied | granted>' }
    if (permissionData) {
      return Promise.resolve(permissionData.permission)
    }
  } else if (!!window.Notification && 'requestPermission' in window.Notification) {
    // requestPermission() will return null in safari
    return window.Notification.requestPermission() || Promise.reject(new Error('Cannot request permission to web push'))
  }
  // Not a webpushable device
  return Promise.resolve('notapplicable')
}

export const isSubscribedToPushNotifications = () => {
  return getNotificationPermission().then((permission) => {
    if (permission === 'granted') {
      return true
    }
    return false
  })
}

const getSubscription = (swRegistration) => {
  return (swRegistration || serviceWorkerRegistration).pushManager.getSubscription()
}

const askToSubscribeWebPush = (swRegistration) => {
  return (swRegistration || serviceWorkerRegistration).pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(PUBLIC_KEY),
    })
    .then((subscription) => {
      // does this in the background
      getNotificationPermission()
      // returns this
      return createSubscriptionInBackend(subscription)
    })
    .catch((err) => {
      console.warn(`Failed to subscribe user: `, err)
      return getNotificationPermission()
    })
}

const getNotificationServiceWorkerRegistration = () => {
  if (serviceWorkerRegistration) {
    return Promise.resolve(serviceWorkerRegistration)
  }

  if (navigator.serviceWorker) {
    return navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.active === navigator.serviceWorker.controller) {
          serviceWorkerRegistration = registration
        }
      })
      if (!serviceWorkerRegistration) {
        console.warn(`No service worker registration found`)
      }
      return serviceWorkerRegistration
    })
  } else {
    // console.log('NO SERVICE WORKER')
  }

  return Promise.resolve(null)
}

/**
 * Registers for web push in supported browsers.
 * @return {Promise} Resolves to a Fetch Response object if it's successful. Rejects if it fails.
 */
const registerForWebPush = () => {
  // If already granted, just get the device token and send to the backend.
  // Otherwise, ask to subscribe
  return getNotificationServiceWorkerRegistration()
    .then((swRegistration) => {
      if (swRegistration) {
        // Get subscription
        return getSubscription(swRegistration).then((subscription) => {
          // if subscription is null, it means there's no subscription
          // Ask for permission to show notifications.
          if (subscription) {
            return createSubscriptionInBackend(subscription)
          } else {
            return askToSubscribeWebPush(swRegistration)
          }
        })
      } else {
        console.warn(`No service worker registered`)
        throw new Error(`No service worker registered`)
      }
    })
    .catch((err) => {
      console.warn(`Could not subscribe: `, err)
      throw err
    })
}

// ======================================================================================
// SAFARI PUSH
// ======================================================================================

function getHostname() {
  if (window.location.hostname.match(/\.dev$/)) return 'padlet.dev'
  if (window.location.hostname.match(/\.io$/)) return 'padlet.io'
  return 'padlet.com'
}

const askToSubscribeSafariPush = (user) => {
  return new Promise((resolve) => {
    const requestCallback = (pmData) => {
      resolve(pmData)
    }
    const hostname = getHostname()
    const websiteServiceUrl = `https://${hostname}/api/safari_push`
    // this is a new web service URL and its validity is unknown
    // requestPermission here POSTs to https://padlet.[com|dev]/api/safari_push/v1/pushPackages/web.com.padlet and expects a zip file to be returned.
    // The zip file contains the following files:

    //  - website.json
    //  - manifest.json
    //  - signature
    //  - icon.iconset/ <-- a folder containing icons
    //
    // The uses of each file can be found at
    // https://developer.apple.com/library/archive/documentation/NetworkingInternet/Conceptual/NotificationProgrammingGuideForWebsites/PushNotifications/PushNotifications.html
    // but briefly:

    // 1. website.json
    // Contains the following fields:

    //  - websiteServiceURL: the prefix for all the calls Safari will make with Padlet to get information, register devices with device tokens, etc
    //  - urlFormatString: a URL with placeholders that Safari will use to interpolate with data from push notifications.
    //                     This URL is where users will go to when they click on the notification
    //
    //  - allowedDomains: an Array of domains with protocol, e.g. ["https://padlet.com", "http://padlet.com"]
    //
    //  - authenticationToken: because some of the requests that Safari sends to Padlet will not have cookies,
    //                         there's no way to identify the user to associate with that request. authenticationToken
    //                         serves to authenticate the user; it's used in Authorization headers of those requests.
    // 2. manifest.json
    // Contains SHA512 hashes of the files archived in the ZIP file.

    // 3. signature
    // A computed signature to verify the files in the ZIP file.

    // 4. icon.iconset/
    // A directory containing Padlet icons in various sizes.
    //
    // Once the zip file is returned and the content checks out, it will call the callback requestCallback.
    return window.safari.pushNotification.requestPermission(
      websiteServiceUrl, // must be https, does not need to be the same domain as the website requesting
      'web.com.padlet', // website push ID
      { user_id: user.id.toString() }, // data to send to authenticate user
      requestCallback, // callback
    )
  })
}

const checkSafariPermission = (permissionData, user) => {
  let { deviceToken, permission } = permissionData
  if (permission === 'default') {
    if (!user) return Promise.reject(new Error(`User must be an object`))
    // here we run checkSafariPermission again. Safari's implementation of a prompt
    // doesn't allow the user to dismiss it. The user must either allow or deny.
    // Because permission will never be "default" again, this doesn't loop
    // indefinitely.
    return askToSubscribeSafariPush(user).then(checkSafariPermission)
  } else if (permission === 'denied') {
    return Promise.reject(new Error('Permission denied'))
  } else if (permission === 'granted') {
    return createSubscriptionInBackend(deviceToken)
  }
}

const registerForSafariPush = (user) => {
  const permissionData = window.safari.pushNotification.permission(`web.com.padlet`)
  // permissionData is an Object that looks like this
  // { deviceToken: '<safari_device_token_string>', permission: '<default | denied | granted>' }
  if (permissionData) {
    return checkSafariPermission(permissionData, user)
  }
  return Promise.reject(new Error(`Safari did not return permission data`))
}

export const supportsPushNotifications = () => {
  // mobile app doesn't support
  if (device.app) return Promise.resolve(false)
  return Promise.resolve(device.browserPush)
}

/**
 * Will register the device for push notifications, which includes sending
 * device tokens to the backend.
 * @param {Object} user - An object representing the user.
 * @param {Object} user.id - The currently logged-in user's ID.
 * @return {Promise} The returned Promise is resolved to a Fetch Response object if successful.
 * It rejects to null if it fails.
 */
export const registerForPushNotifications = (user) => {
  // don't register if loaded on a mobile app
  if (device.app) {
    return Promise.reject(new Error('mobile app no registration'))
  }
  if (device.safariPush) {
    return registerForSafariPush(user)
  } else if (device.webPush) {
    return registerForWebPush()
  }
  return Promise.reject(new Error('Device cannot be registered for web push'))
}
