// @file A fat module with
// - Post CRUD-related functions acting as a black-box to backend APIs for post operations.
// - Helpers related to abovementioned purpose.
import { trackEvent } from '@@/bits/analytics'
import { captureMessage } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { POST_BODY_CHARACTER_LIMIT, POST_SUBJECT_CHARACTER_LIMIT } from '@@/bits/numbers'
import { hasCustomProperties } from '@@/bits/post_properties'
import PromiseQueue from '@@/bits/promise_queue'
import { SAVE_CHANGES_FAILED } from '@@/bits/snackbar_helper'
import { isSortingBy, SortByTypes } from '@@/bits/surface_settings_helper'
import { isUrl } from '@@/bits/url'
import { SnackbarNotificationType, SurfaceFilter } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import PadletApi from '@@/surface/padlet_api'
import { getSortIndex } from '@@/surface/sorter'
import type { AccumulatedReactionData, Cid, HashId, Id, Post, PostAttributes, UpdatePostArgs } from '@@/types'
import type { PostState } from '@@/vuexstore/modules/post'
import type { RootState } from '@@/vuexstore/surface/types'
import type { PostColor } from '@padlet/arvo'
import { cloneDeep, debounce, uniqueId } from 'lodash-es'
import type { ActionContext, Commit, Dispatch } from 'vuex'
/********************************
 * CONSTANTS
 ********************************/
const PASTEABLE_ATTRIBUTES = ['subject', 'body', 'attachment', 'color']
const VALID_COLORS: PostColor[] = ['red', 'orange', 'green', 'blue', 'purple']
const COPIED_POST_HASH_KEY = 'padlet'
const WHITESPACE_REGEXT = /^\s*$/
const POST_SHUFFLE_SEED = Math.random()

// Post actions in the post menu.
enum PostActionType {
  AddPostBefore = 'AddPostBefore',
  AddPostAfter = 'AddPostAfter',
  ExpandPost = 'ExpandPost',
  OpenPostInNewTab = 'OpenPostInNewTab',
  OpenPostInBrowserFromZoom = 'OpenPostInBrowserFromZoom',
  ClosePoll = 'ClosePoll',
  OpenPoll = 'OpenPoll',
  ExportPollResultsCSV = 'ExportPollResultsCSV',
  CopyPostLink = 'CopyPostLink',
  OpenAttachment = 'OpenAttachment',
  DownloadAttachment = 'DownloadAttachment',
  CopyAttachmentLink = 'CopyAttachmentLink',
  StartSlideshowFromThisPost = 'StartSlideshowFromThisPost',
  OpenLocationInMapApp = 'OpenLocationInMapApp',
  EditPost = 'EditPost',
  ConnectToPost = 'ConnectToPost',
  DisconnectFromPost = 'DisconnectFromPost',
  BringToFront = 'BringToFront',
  SendToBack = 'SendToBack',
  TransferPost = 'TransferPost',
  DuplicatePost = 'DuplicatePost',
  DuplicatePostToCurrentWall = 'DuplicatePostToCurrentWall',
  SetAsPadletCover = 'SetAsPadletCover',
  UnsetAsPadletCover = 'UnsetAsPadletCover',
  DeletePost = 'DeletePost',
  ChangePostColor = 'ChangePostColor',
  ReportPost = 'ReportPost',
  PinPost = 'PinPost',
  UnpinPost = 'UnpinPost',
  // Only for mobile app native post action menu
  SharePostLink = 'SharePostLink',
  ShareAttachmentLink = 'ShareAttachmentLink',
  ShareStaticAttachment = 'ShareStaticAttachment',
}

/********************************
 * HELPERS
 ********************************/
// HELPERS
const isNew = (post: Post | PostAttributes): boolean => !post.id

/** @deprecated use isPostEmpty from bits/post_properties.ts instead */
const isEmpty = (post: PostAttributes | undefined): boolean =>
  post == null ||
  ((post.subject == null || post.subject === '' || WHITESPACE_REGEXT.test(post.subject)) &&
    (post.body == null || post.body === '' || WHITESPACE_REGEXT.test(post.body)) &&
    (post.attachment == null || post.attachment === '' || WHITESPACE_REGEXT.test(post.attachment)) &&
    !hasRichAttachment(post) &&
    !hasCustomProperties(post))
const isNonEmpty = (post: PostAttributes): boolean => !isEmpty(post)

const isColorValid = (color: PostColor): boolean => VALID_COLORS.includes(color)
const hasAttachment = (post: Post): boolean => {
  return !(post.wish_content?.attachment_props == null) || !!post.attachment
}
const hasRichAttachment = (post: PostAttributes | undefined): boolean => {
  return post?.wish_content?.attachment_props?.type != null
}

const checkIfShouldPostShow = (post: Post, filter: SurfaceFilter): boolean => {
  if (filter === SurfaceFilter.All) return true
  const isPublished = post.published ?? true
  return filter === SurfaceFilter.Published ? isPublished : !isPublished
}

const filterPostWithFilter = (postEntitiesByCid: Record<Cid, Post>, filter: SurfaceFilter): Record<Cid, Post> => {
  if (filter === SurfaceFilter.Published || filter === SurfaceFilter.Submitted) {
    return Object.keys(postEntitiesByCid)
      .filter((cid) => checkIfShouldPostShow(postEntitiesByCid[cid], filter))
      .reduce((res, key) => ((res[key] = postEntitiesByCid[key]), res), {})
  }
  return postEntitiesByCid
}

function dumpPostContentHashString(post: PostAttributes): string {
  const attributes = PASTEABLE_ATTRIBUTES.reduce((hash, key) => {
    if (post[key]) hash[key] = post[key]
    return hash
  }, {})
  return Object.keys(attributes).length > 0 ? asciiSafeStringify({ [COPIED_POST_HASH_KEY]: attributes }) : ''
}

function isPasteablePost(postAttributes: PostAttributes): boolean {
  const hasDisallowedAttribute = Object.keys(postAttributes).some(
    (attribute: string): boolean => !PASTEABLE_ATTRIBUTES.includes(attribute),
  )
  if (hasDisallowedAttribute) return false
  if (isEmpty(postAttributes)) return false
  if (postAttributes.color && !isColorValid(postAttributes.color)) return false
  if (postAttributes.attachment && !isUrl(postAttributes.attachment)) return false
  return true
}

function loadPostContentHashString(text: string): PostAttributes | null {
  try {
    const parsedItem = JSON.parse(text)
    if (typeof parsedItem !== 'object') return null
    const parsedItemValue = parsedItem[COPIED_POST_HASH_KEY]
    if (!parsedItemValue || !isPasteablePost(parsedItemValue)) return null
    return parsedItem.padlet as PostAttributes
  } catch {
    return null
  }
}

function getPostCidsToDeleteInPage(
  isFirstPage: boolean,
  isLastPage: boolean,
  newPosts: any[],
  newPostEntitiesByCid: any,
): Cid[] {
  // the array returned from the back end is guaranteed to be ordered by id (DESC)
  const newPostIdList = newPosts.map((post) => post.attributes.id)

  // last page should cover the smallest post id
  const lookupRangeStart = isLastPage ? 0 : newPostIdList[newPosts.length - 1]

  // first page should cover the biggest post id
  const lookupRangeEnd = isFirstPage ? Infinity : newPostIdList[0]

  return Object.keys(newPostEntitiesByCid).filter((postCid: string): boolean => {
    const post = newPostEntitiesByCid[postCid]

    // Keep unsaved posts
    if (isNew(post)) {
      return false
    }

    const postId = newPostEntitiesByCid[postCid].id

    // Keep everything not inside the page's range
    if (postId > lookupRangeEnd || postId < lookupRangeStart) {
      return false
    }

    // Anything that's inside the page's range but not in newPostIdList
    // means they are deleted in the backend but the front end does not know yet
    return !newPostIdList.includes(postId)
  })
}

// reconcile newly fetched post data with current state to get the correct state
function reconcilePostsAfterFetch(context: any, newPosts: any[], options: Record<string, any>): Record<Cid, Post> {
  const { state, getters } = context

  const { isFirstPage, isLastPage, shouldResetState } = options
  const getPostById = getters.getPostByServerId

  const isOnlyPage = isFirstPage && isLastPage
  const newPostEntitiesByCid = shouldResetState || isOnlyPage ? {} : { ...getters.postEntitiesByCid }

  // When we are just updating the state (no reset), there is 1 case we should care about:
  // Auto refresh is triggered after our page goes to the background and then comes to the top again (e.g. user switch browser tabs)
  // There maybe records deleted in the backend but not in the frontend => delete them
  // skip this check for the initial posts fetch when the page is first loaded
  if (!shouldResetState && getters.isInitialPaginatedDataLoadDone && newPosts.length > 0) {
    const cidsToDelete = getPostCidsToDeleteInPage(isFirstPage, isLastPage, newPosts, newPostEntitiesByCid)
    cidsToDelete.forEach((cid: string): void => {
      delete newPostEntitiesByCid[cid]
    })
  }

  newPosts.forEach((postData) => {
    if (getters.isPostMarkedAsDeleted(postData.attributes)) {
      delete newPostEntitiesByCid[addCidIfAbsent(postData.attributes).cid]
      return
    }

    const post = postData.attributes
    // In rare cases some posts aren't saved with a sort_index. We assign them one on fetch.
    if (post.sort_index == null) {
      post.sort_index = getSortIndex()
    }

    const existingPost = getPostById(post.id)

    if (!existingPost) {
      // Add new post
      const postWithCid = addCidIfAbsent(post)
      newPostEntitiesByCid[postWithCid.cid] = postWithCid
    } else if (isPostRequestPending(existingPost, context)) {
      // Don't overwrite if there are changes that will be persisted
      newPostEntitiesByCid[existingPost.cid] = existingPost
    } else {
      // Update the post otherwise
      newPostEntitiesByCid[existingPost.cid] = {
        ...post,
        cid: existingPost.cid,
      }
    }
  })

  // Don't let unsaved posts disappear.
  getters.postsArray.forEach((post) => {
    if (isNew(post) && !newPostEntitiesByCid[post.cid]) {
      newPostEntitiesByCid[post.cid] = post
    }
  })

  return newPostEntitiesByCid
}

function sortPostsArray(
  context: any,
  postsArray: Post[],
  sortFunctions: {
    sortPostsBySortIndex: (posts: Post[]) => Post[]
    sortPostsByReactionSum: (
      posts: Post[],
      postIdsByReactionSum: Array<AccumulatedReactionData & { wishId: Id }>,
      constants?: { reactionsTotalSumAverage: number; confidenceNumber: number },
    ) => Post[]
    sortPostsByCreationDate: (posts: Post[]) => Post[]
    sortPostsBySubject: (posts: Post[], order: 'asc' | 'desc') => Post[]
    sortPostsByShuffle: (posts: Post[], seed: number) => Post[]
  },
): Post[] {
  const {
    sortPostsBySortIndex,
    sortPostsByReactionSum,
    sortPostsByCreationDate,
    sortPostsBySubject,
    sortPostsByShuffle,
  } = sortFunctions
  const { getters, rootGetters } = context
  const postsToShow = postsArray.filter(
    (post: Post) => post.id != null || !useSurfaceDraftsStore().isCidBeingDrafted(post.cid),
  )

  if (rootGetters.isTimelineV1 as boolean) {
    // We reverse the posts because in Timeline new posts are added at the end
    return sortPostsBySortIndex(postsToShow).reverse()
  }

  const wishSortBy: SortByTypes = (rootGetters['settings/isPreviewing'] as boolean)
    ? rootGetters['settings/wishSortBy']
    : rootGetters.wishSortBy
  if (isSortingBy(wishSortBy, 'reactions')) {
    const sortedPosts = sortPostsByReactionSum(postsToShow, getters.accumulatedReactionsByWishId, {
      reactionsTotalSumAverage: getters.accumulatedReactionsTotalSumAverage,
      confidenceNumber: getters.accumulatedReactionsConfidenceNumber,
    })

    if (wishSortBy === SortByTypes.ReactionsDesc) {
      return sortedPosts.reverse()
    }

    return sortedPosts
  } else if (isSortingBy(wishSortBy, 'date_published')) {
    const sortedPosts = sortPostsByCreationDate(postsToShow)
    if (wishSortBy === SortByTypes.DatePublishedSortDesc) {
      return sortedPosts.reverse()
    }

    return sortedPosts
  } else if (isSortingBy(wishSortBy, 'post_subject')) {
    if (wishSortBy === 'post_subject_asc') {
      return sortPostsBySubject(postsToShow, 'asc')
    } else {
      return sortPostsBySubject(postsToShow, 'desc')
    }
  } else if (isSortingBy(wishSortBy, 'shuffle')) {
    return sortPostsByShuffle(postsToShow, POST_SHUFFLE_SEED)
  }

  return sortPostsBySortIndex(postsToShow)
}
/********************************
 * POST SAVING
 ********************************/

interface CommitAndDispatch {
  commit: Commit
  dispatch: Dispatch
  rootGetters?: any
}

enum PostConfiguration {
  All,
  BodyAndOrAttachment,
  BodyOrAttachment,
}

const DEFAULT_RETRY_DELAY = 500
function wrapMethodWithRetries<T>(
  method: () => Promise<T>,
  maxRetries = 0,
  delayInMs = DEFAULT_RETRY_DELAY,
): () => Promise<T> {
  let retriesLeft = maxRetries

  return async (): Promise<T> =>
    await new Promise((resolve, reject): void => {
      async function methodWithRetries(): Promise<any> {
        try {
          const result = await method()
          return resolve(result)
        } catch (e) {
          const isClientError = e.status >= 400 && e.status < 500
          const toRetry = !isClientError && retriesLeft > 0
          if (toRetry) {
            retriesLeft -= 1
            setTimeout((): void => {
              methodWithRetries()
            }, delayInMs)
          } else {
            reject(e)
          }
          return null
        }
      }
      methodWithRetries()
    })
}

// Cached PostSavers
const postSaverById: { [id: number]: PostSaver } = {}
const postSaverByCid: { [cid: string]: PostSaver } = {}

// These are the standard requests. You may define your own strings as custom keys.
enum PostRequestKey {
  Create = 'Create',
  // Update post with attributes at the time when request is enqueued
  UpdateCurrent = 'UpdateCurrent',
  // Update post with attributes at the time when request is executed
  UpdateLatest = 'UpdateLatest',
  Delete = 'Delete',
}

interface CustomSavePostRequest {
  key: string
  method: (post?: Post) => Promise<any>
}
interface Cancelable {
  cancel: () => void
  flush: () => void
}

/*****************************************************

PostSaver ensures that only one post update/create event is being made at a time.
After instantiation, you may call `save` on it, or enqueue a request:

```
const saver = new PostSaver(post, dispatch)
saver.save() // create immediately if new
// ...
saver.save() // does not fire request if one is still in progress
// ...
saver.save() // updates post if post has been persisted
saver.save() // debounces updates
saver.customSave({ key: 'A_POST_RELATED_REQUEST', method: () => { ... } }) // custom request queued
saver.save() // update request is queue after custom request
```

*****************************************************/

class PostSaver {
  public isUpdateDebouncing = false
  private readonly q: PromiseQueue
  private readonly dispatch!: Dispatch
  private readonly getters!: any
  // Store both id and cid so that we can use the same queue before and after the post is saved
  private postId!: Id | null
  private postHashid!: HashId | null
  private readonly postCid!: Cid
  private _debouncedEnqueueUpdate!: null | ((() => void) & Cancelable) // lazily created

  public constructor(
    { id, cid, hashid }: Post,
    { dispatch: PostModuleDispatch, getters: PostModuleGetters, rootGetters },
  ) {
    this.postId = id ?? null
    this.postCid = cid
    this.postHashid = hashid ?? null
    this.dispatch = PostModuleDispatch
    this.getters = PostModuleGetters
    this.q = new PromiseQueue()
  }

  public get isIdle(): boolean {
    return this.q.isEmpty && !this.isUpdateDebouncing
  }

  public save(): Post {
    const post = this.post
    if (isEmpty(post)) return post

    if (post.sort_index == null) {
      post.sort_index = getSortIndex()
    }

    if (post.scheduled_at != null) {
      trackEvent('Posts', 'Scheduled post', null, null, {
        currentTime: new Date(),
        scheduledAt: post.scheduled_at, // track timestamp here; post.scheduled_at may have changed at analysis time
      })
    }

    if (isNew(post) && this.q.currentKey !== PostRequestKey.Create) {
      void this.q.enqueue(PostRequestKey.Create, this.createPost.bind(this))
    } else {
      this.debouncedEnqueueUpdate()
    }
    return post
  }

  public delete(): void {
    this.q.enqueue(PostRequestKey.Delete, this.deletePost.bind(this))
  }

  public async customSave({ key, method }: CustomSavePostRequest): Promise<any> {
    // Ensure post has been is created
    if (isNew(this.post) && this.q.currentKey !== PostRequestKey.Create) {
      this.q.enqueue(PostRequestKey.Create, this.createPost.bind(this))
    }
    // Post should be updated to current state before the new request is made
    // Convert UpdateLatest request to an UpdateCurrent request to lock in the current state
    this.flushUpdates()
    if (this.q.lastQueuedKey === PostRequestKey.UpdateLatest) {
      this.q.unqueue()
      const currentPost = cloneDeep(this.post)
      const updateCurrentPost = async (): Promise<any> => await this.updatePost(currentPost)
      this.q.enqueue(PostRequestKey.UpdateCurrent, updateCurrentPost)
    }

    // Wrap method to pass in latest post. Request might require id but was queued before post was saved.
    const wrappedMethod = async (): Promise<any> => {
      // If post has been deleted, skip subsequent operations.
      if (this.isPostMarkedAsDeleted) return await Promise.resolve()
      return await method(this.post)
    }

    // Finally, queue the method.
    return await this.q.enqueue(key, wrappedMethod)
  }

  public flushUpdates() {
    if (this._debouncedEnqueueUpdate == null) return
    this._debouncedEnqueueUpdate.flush()
  }

  // PRIVATE

  // Get the latest post
  private get post(): Post {
    return this.getters.getLiveOrDeletedPost({ cid: this.postCid, id: this.postId })
  }

  private get postOrder(): Cid[] {
    return this.getters.postOrder
  }

  private get isPostMarkedAsDeleted(): Post {
    return this.getters.isPostMarkedAsDeleted({ cid: this.postCid, id: this.postId })
  }

  private debouncedEnqueueUpdate(): void {
    this.isUpdateDebouncing = true
    if (this._debouncedEnqueueUpdate == null) {
      this._debouncedEnqueueUpdate = debounce(this.enqueueUpdate, 500)
    }
    return this._debouncedEnqueueUpdate()
  }

  private enqueueUpdate(): void {
    this.isUpdateDebouncing = false
    if (this.q.lastQueuedKey === PostRequestKey.UpdateLatest) return
    this.q.enqueue(PostRequestKey.UpdateLatest, this.updatePost.bind(this))
  }

  private async createPost(): Promise<any> {
    if (this.isPostMarkedAsDeleted) return

    const post = this.post

    // By the time the queue executes this, the post may have already been created.
    if (!isNew(post)) return

    // Get the true post order from the current postOrder that is in the store state.
    // Then get the array of postOrder that only contains the cid of the current postOrder
    // And only posts with ID (not pending creation)
    const postCreationParams: Post & UpdatePostArgs = post
    try {
      const createPostWithRetries = wrapMethodWithRetries(
        async (): Promise<{ data: { attributes: Post & UpdatePostArgs } }> =>
          await PadletApi.Wish.create(postCreationParams),
        1,
      )
      const createPostResult = (await createPostWithRetries()).data.attributes
      const { id, hashid } = createPostResult

      void this.dispatch('createdPostSuccess', { ...post, ...createPostResult })
      this.postId = id as Id
      this.postHashid = hashid as HashId
      postSaverById[id as Id] = this

      return { id }
    } catch (e) {
      if (e.status === 403) {
        void this.dispatch('showReloadNotification', null, { root: true }) // Ask to reload if no permissions
      } else {
        this.showPostOperationErrorSnackbar(post)
      }
      const context = {
        response: e.message,
        attachment: post.attachment,
        requestBody: asciiSafeStringify(postCreationParams),
      }
      captureMessage(`CreatedPostError ${e.status || ''}`, { context })
      return null
    }
  }

  private async updatePost(post?: Post): Promise<any> {
    if (this.isPostMarkedAsDeleted) return

    // If not specific version of the post is provided, fetch the latest from the store
    const clientPost = post != null ? { ...post, id: this.post.id } : this.post
    if (isEmpty(clientPost)) return null
    try {
      const savedPost = (await PadletApi.Wish.update(clientPost)).data.attributes // Already retries
      void this.dispatch('updatedPostSuccess', { ...clientPost, ...savedPost })
      return savedPost
    } catch (e) {
      const context = { response: e.message, postId: clientPost.id }
      if (e.status === 404) {
        void this.dispatch('refresh', null, { root: true }) // Refresh if post deleted
      } else if (e.status === 403) {
        void this.dispatch('showReloadNotification', null, { root: true }) // Ask to reload if no permissions
        captureMessage('UpdatedPostError 403', { context })
      } else {
        this.showPostOperationErrorSnackbar(clientPost)
        captureMessage(`UpdatedPostError ${e.status || ''}`, { context })
      }
      return null
    }
  }

  private async deletePost(): Promise<void> {
    try {
      if (this.postHashid == null) return await Promise.resolve()
      await PadletApi.Wish.delete({ wishHashid: this.postHashid })
    } catch (e) {
      const context = { response: e.message, postId: this.postId }
      if (e.status === 404) {
        void this.dispatch('refresh', null, { root: true })
      } else if (e.status === 403) {
        void this.dispatch('showReloadNotification', null, { root: true }) // Ask to reload if no permissions
        captureMessage('DeletedPostError 403', { context })
      } else {
        this.showSaveFailureSnackbar()
        captureMessage(`DeletedPostError ${e.status || ''}`, { context })
      }
    }
  }

  private showPostOperationErrorSnackbar(post: Post): void {
    if ((post.subject ?? '').length > POST_SUBJECT_CHARACTER_LIMIT) {
      this.showSaveFailureSnackbar(__('Post subject character limit reached'))
    } else if ((post?.body ?? '').length > POST_BODY_CHARACTER_LIMIT) {
      this.showSaveFailureSnackbar(__('Post body character limit reached'))
    } else {
      this.showSaveFailureSnackbar()
    }
  }

  private showSaveFailureSnackbar(message?: string): void {
    if (message != null) {
      useGlobalSnackbarStore().setSnackbar({
        message,
        notificationType: SnackbarNotificationType.error,
      })
    } else {
      useGlobalSnackbarStore().setSnackbar(SAVE_CHANGES_FAILED)
    }
  }
}

// Assumes post has a Cid.
function getOrCreatePostSaver(post: Post, context): PostSaver {
  // Prefer to look up post by server id since cid changes after initial creation.
  // Assume that when post is successfully saved, it will be populated in postSaverById.
  let saver = (post.id && postSaverById[post.id]) || postSaverByCid[post.cid]
  if (saver) return saver

  saver = new PostSaver(post, context)
  if (post.id) {
    postSaverById[post.id] = saver
  } else {
    postSaverByCid[post.cid] = saver
  }
  return saver
}

function isPostRequestPending(post: Post, context): boolean {
  const postSaver = getOrCreatePostSaver(post, context)
  return !postSaver.isIdle
}

const savePost = (context: ActionContext<PostState, RootState>, post: PostAttributes & UpdatePostArgs): Post => {
  const { dispatch, getters } = context
  // Assumes that: (1) post has a cid
  // Ensures that: (1) post is added to the store, (2) post in the store is latest version
  if (post.cid == null) {
    throw new Error('helpers/post.ts#savePost: cid must be defined')
  }
  // check whether post has already been created
  if (getters.isExistingPost(post.cid) === false) {
    // creating a new post from draft: add a new post to store.
    void dispatch('addPost', post)
  } else {
    // update an existing post: we directly update the store.
    void dispatch('updatePostInStore', post)
  }

  return getOrCreatePostSaver(post as Post, context).save()
}

async function customSavePost(context, post: Post, request: CustomSavePostRequest): Promise<any> {
  return await getOrCreatePostSaver(post, context).customSave(request)
}

function deletePost(context: CommitAndDispatch, post: Post): void {
  context.dispatch('removePost', post)
  getOrCreatePostSaver(post, context).delete()
}

/**
 * Delete all posts using PadletApi abstraction
 */
function deleteAllPosts(context: CommitAndDispatch, posts: Post[]): void {
  posts.forEach((post): void => {
    deletePost(context, post)
  })
}
/********************************
 * ID/CID HELPERS
 ********************************/
/**
 * Maintains the cid of a post if it's present. Generate one otherwise.
 */
function addCidIfAbsent(post: PostAttributes): Post {
  if (post.cid) return post as Post
  const cid = post.id ? `c${post.id}` : uniqueId('c_new')
  return { ...post, cid }
}

export {
  addCidIfAbsent,
  checkIfShouldPostShow,
  customSavePost,
  deleteAllPosts,
  deletePost,
  dumpPostContentHashString,
  filterPostWithFilter,
  getOrCreatePostSaver,
  getPostCidsToDeleteInPage,
  hasAttachment,
  isEmpty,
  isNew,
  isNonEmpty,
  loadPostContentHashString,
  PostActionType,
  PostConfiguration,
  reconcilePostsAfterFetch,
  savePost,
  sortPostsArray,
  VALID_COLORS,
}
export type { CustomSavePostRequest }
