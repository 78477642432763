/**
 * @file Provides facilities for resumable uploads to Google Cloud Storage.
 *
 * @author SY Quek
 */
// CONSTANTS
const ONE_MB = 1024 * 1024
const DEFAULT_CHUNK_SIZE = 2 * ONE_MB
const MAXIMUM_RETRY_WAIT_MS = 32 * 1000 // maximum wait 32 seconds to retry

interface UploadAttributes {
  signed_init_url: string
  fields: {
    filename: string
    hard_file_size_limit?: boolean
  }
}

type ProgressListener = (gru: GCSResumableUpload, fractionCompleted: number) => void
type ErrorListener = (gru: GCSResumableUpload, error: Error) => void
type CompletionListener = (gru: GCSResumableUpload) => void

type UploadEventType = 'progress' | 'error' | 'completed'
export type GCSError = 'SESSION_EXPIRED' | 'START_FROM_BEGINNING' | 'RETRY' | 'QUERY_AND_RETRY' | 'LIKELY_BLOCKED'

interface UploadListeners {
  progress?: ProgressListener
  error?: ErrorListener
  completion?: CompletionListener
}

export interface GCSResumableUpload {
  attributes: UploadAttributes
  file: File
  resumableSessionUri: string
  listeners: UploadListeners
  abortController: AbortController
  cancelled?: boolean
  hardFileSizeLimit?: boolean
}

export enum GCSResponse {
  UploadCompletedOK = 200,
  UploadCompletedCREATED = 201,
  Resume = 308,
  SessionExpired = 400,
  Restart = 404,
  RequestTimeout = 408,
  ServerError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

interface UploadState {
  isSuccess: boolean
  status?: number
  lastByteUploaded?: number
  gcsError?: GCSError
}

export class GCSResumableError extends Error {
  gcsError: GCSError
  constructor(message: string, gcsError: GCSError) {
    super(message)
    this.gcsError = gcsError
  }
}

export const INVALID_RESUMABLE_SESSION_URI = ''

/**
 * Check if current environment supports resumable uploads.
 * @return {boolean} True if supports resumable uploads. False otherwise.
 */
export const supportsGcsResumableUploads = (): boolean => {
  if (!!window) return typeof window.AbortController !== 'undefined'
  return false // return false in a window-less environment
}

/**
 * Creates a resumable upload session URL by a POST request to a signed GCS URL (or the initialization URL).
 * @param {File} file - The file to be uploaded.
 * @param {UploadAttributes} attributes - The upload manifest as returned by the backend.
 * @return {Promise<GCSResumableUpload>} The resumable upload object.
 */
export const createResumableUpload = async (file: File, attributes: UploadAttributes): Promise<GCSResumableUpload> => {
  const {
    signed_init_url: signedInitializationUrl,
    fields: { hard_file_size_limit: hardFileSizeLimit },
  } = attributes

  let resumableSessionUri = INVALID_RESUMABLE_SESSION_URI

  const requestHeaders = {
    'Content-Length': '0',
    'Content-Type': file.type,
    'x-goog-resumable': 'start',
    // the right content-disposition can have some browsers download the
    // file when loading it in a tab.
    'Content-Disposition': `inline`,
  }

  // X-Upload-Content-Length must mirror what was initially submitted when
  // we generated the signedInitializationUrl.
  if (hardFileSizeLimit) {
    requestHeaders['x-upload-content-length'] = file.size
  }

  try {
    const response = await fetch(signedInitializationUrl, {
      method: 'POST',
      mode: 'cors',
      headers: requestHeaders,
    })
    const { headers } = response
    resumableSessionUri = headers.get('Location') || INVALID_RESUMABLE_SESSION_URI
  } catch (e) {
    console.error(`Could not create resumable upload URL`)
    console.error(e)
  }

  return {
    attributes,
    file,
    resumableSessionUri,
    listeners: {},
    abortController: new AbortController(),
    hardFileSizeLimit,
  }
}

export const subscribeUploadEvent = (
  gru: GCSResumableUpload,
  eventType: UploadEventType,
  listener: ProgressListener | ErrorListener | CompletionListener,
): GCSResumableUpload => {
  if (!['completion', 'error', 'progress'].includes(eventType)) throw new Error(`Invalid event: ${eventType}`)
  gru.listeners[eventType] = listener
  return gru
}

// Read Google's algorithm for backoffs.
// https://cloud.google.com/storage/docs/exponential-backoff
const backoff = async (n: number, maximumBackOffMs: number): Promise<void> => {
  const rand = Math.floor(Math.random() * 999) + 1 // returns a random integer between 1 and 1000
  const waitMs = Math.min(2 ** n * 1000 + rand, maximumBackOffMs)
  return new Promise((resolve): void => {
    console.log(`Backing off for ${waitMs} ms`)
    setTimeout(() => {
      resolve()
    }, waitMs)
  })
}

export const cancelResumableUpload = (gru: GCSResumableUpload): void => {
  const { abortController, resumableSessionUri, file } = gru
  abortController.abort()
  gru.cancelled = true
  // DELETE IT, no need to wait for response
  // Deleting the upload session ensures no one else can add to it.
  // https://cloud.google.com/storage/docs/performing-resumable-uploads#cancel-upload
  // As of last test, this returns a 503
  // fetch(resumableSessionUri, {
  // method: 'DELETE',
  // mode: 'cors',
  // headers: {
  // 'Content-Length': '0',
  // },
  // body: '',
  // })
}

const callErrorSubscriber = (gru: GCSResumableUpload, errorObj: Error): void => {
  const {
    listeners: { error },
  } = gru
  if (error) {
    error(gru, errorObj)
  }
}

const callProgressSubscriber = (gru: GCSResumableUpload, fractionCompleted: number): void => {
  const {
    listeners: { progress },
  } = gru
  if (progress) {
    progress(gru, fractionCompleted)
  }
}

const callCompletedSubscriber = (gru: GCSResumableUpload): void => {
  const {
    listeners: { completion },
  } = gru
  if (completion) {
    completion(gru)
  }
}

/**
 * The Range/Content-Range header looks like this:
 * `Range: bytes=0-26214399`
 *
 * Parse the header's value and extract the last byte received by Google.
 * @param {string} contentRangeHeader - The value of the content range header, or, using the example above,
 * `bytes=0-26214399`
 * @return {Array<number>} An array with 2 elements. The first element is usually 0. The second is the last byte
 * uploaded as received by Google Cloud Storage.
 */
const extractByteRangeFromContentRange = (contentRangeHeader: string): Array<number> => {
  const regex = /^bytes=(\d+)-(\d+)$/
  const byteRangeMatches = regex.exec(contentRangeHeader)
  if (byteRangeMatches) {
    return [parseInt(byteRangeMatches[1], 10), parseInt(byteRangeMatches[2], 10)]
  }
  throw new Error(`Dont recognize this format of content range: ${contentRangeHeader}`)
}

/**
 * Requests to Google Cloud Storage for resumable uploads have a predictable response. We translate that response
 * into a state of the upload as seen by Google Cloud Storage.
 * @param {string} color1 - The first color, in hexadecimal format.
 * @param {string} color2 - The second color, in hexadecimal format.
 * @return {string} The blended color.
 */
const translateResponseIntoUploadState = (gru: GCSResumableUpload, response: Response): UploadState => {
  const { file } = gru
  const fileSize = file.size
  const { status, headers } = response

  // Incomplete --- continue uploading
  // The official documentation for the XML API does not specify that a 308 response (Resume) is returned
  // and what it implies. It is specified in the JSON API instead. 308 is consistently returned
  // when querying the state of the upload, or when the upload of a chunk is successful.
  const continueUploadingNextChunk = status === GCSResponse.Resume
  if (continueUploadingNextChunk) {
    // yes chunk successful
    // parse the range header and get the next startbyte
    // However, if there's no Range header, we should either query for status  or just retry the
    // upload for the previous chunk.
    const contentRangeHeader = (headers.get('Content-Range') || headers.get('Range')) as string
    if (contentRangeHeader) {
      const [_, lastByteUploaded] = extractByteRangeFromContentRange(contentRangeHeader)
      return { lastByteUploaded, isSuccess: true }
    }
    return { isSuccess: false, gcsError: 'RETRY' }
  }

  // Upload completed -- stop uploading
  const uploadCompleted = status === GCSResponse.UploadCompletedOK || status === GCSResponse.UploadCompletedCREATED
  if (uploadCompleted) {
    const lastByteUploaded = fileSize - 1
    return { lastByteUploaded, isSuccess: true }
  }

  // ------------------------------------------------------------------------
  // ERROR HANDLING
  // Read: https://cloud.google.com/storage/docs/resumable-uploads#practices
  // ------------------------------------------------------------------------

  // Upload not found -- start from scratch
  const startFromScratch = status === GCSResponse.Restart
  if (startFromScratch) {
    return { lastByteUploaded: -1, status, gcsError: 'START_FROM_BEGINNING', isSuccess: false }
  }

  // session expired -- need to request a whole new resumable upload session URI
  const sessionExpired = status === GCSResponse.SessionExpired
  if (sessionExpired) {
    return { isSuccess: false, status, gcsError: 'SESSION_EXPIRED' }
  }

  // Query how much Google Cloud Storage has received and receive it.
  const queryAndRetry = status === GCSResponse.ServerError || status === GCSResponse.ServiceUnavailable
  if (queryAndRetry) {
    return { isSuccess: false, status, gcsError: 'QUERY_AND_RETRY' }
  }

  // retry on 408, 500, 502, 503, 504 ... but here we retry on almost everything
  return { isSuccess: false, status, gcsError: 'RETRY' }
}

/**
 * As stated here: https://cloud.google.com/storage/docs/performing-resumable-uploads#upload-file
 * Using this call will return the last byte uploaded on Google Cloud for this particular upload session.
 *
 * @param {GCSResumableUpload} gru - Information about this particular upload session.
 * @param {string} gru.resumableSessionUri - The session's URI.
 * @param {File} gru.file - The file that we're uploading.
 * @param {AbortController} gru.abortController - The controller that allows us to abort the upload.
 * @return {Promise<UploadState>} Resolves to a Promise containing the state of the upload on Google Cloud.
 */
const queryUploadState = async (gru: GCSResumableUpload): Promise<UploadState> => {
  const { resumableSessionUri, file, abortController } = gru
  const { signal } = abortController
  const headers = {
    'Content-Range': `bytes */${file.size}`,
    'Content-Length': '0',
  }
  const response = await fetch(resumableSessionUri, {
    method: 'PUT',
    headers,
    mode: 'cors',
    signal,
  })
  return translateResponseIntoUploadState(gru, response)
}

/**
 * Upload a chunk of a file to Google Cloud Storage via a resumable upload.
 * @param {GCSResumableUpload} gru - The state of the upload for the whole file.
 * @param {Blob} chunk - A slice of a file to be uploaded.
 * @param {number} startByte - Index into all the bytes in the file that this slice starts from.
 * @return {Promise<UploadState>} Resolves to a promise.
 */
const uploadChunk = async (gru: GCSResumableUpload, chunk: Blob, startByte: number): Promise<UploadState> => {
  const { resumableSessionUri, file, abortController, hardFileSizeLimit } = gru
  const { signal } = abortController // allows us to cancel
  const fileSize = file.size
  const endByte = startByte + chunk.size - 1
  // Here we differ from the official documentation for the XML API: https://cloud.google.com/storage/docs/performing-resumable-uploads#upload-file
  // We are not required to send the Content-Range, but we do, because
  // (a) it is supported in the JSON API
  // (b) it is probably better to be explicit about the range of bytes we're sending in this chunk
  const headers = {
    'Content-Type': file.type,
    'Content-Length': chunk.size.toString(),
    'Content-Range': `bytes ${startByte}-${endByte}/${fileSize}`,
  }

  // Limits the maximum sum total of bytes uploaded for the resumable upload to avoid abuse.
  if (hardFileSizeLimit) {
    headers['X-Upload-Content-Length'] = fileSize
  }
  const response = await fetch(resumableSessionUri, {
    method: 'PUT',
    mode: 'cors',
    headers,
    body: chunk,
    signal,
  })

  return translateResponseIntoUploadState(gru, response)
}

/**
 * We can intelligently compute the size of a chunk here.
 * The logic for now is:
 * 1. If the remaining chunk is bigger than 2 times of the preferred chunk size, upload
 * preferredChunkSize bytes.

 * 2. Else if the remaining chunk is smaller than 2 times of the preferred chunk size,
 * just upload all of the remaining chunk.
 *  
 * @param {number} fileSize - Size of the file to upload in bytes.
 * @param {number} lastByteUploaded - The last byte that was uploaded as an index into all the bytes in the file.
 * @param {number} preferredChunkSize - The preferred size of a chunk that we would like to upload in bytes.
 * @return {number} The computed size of the chunk for the next upload.
 */
const computeChunkSizeBasedOnUploadState = (
  fileSize: number,
  lastByteUploaded: number,
  preferredChunkSize: number,
): number => {
  const bytesRemaining = fileSize - lastByteUploaded - 1
  if (bytesRemaining >= 2 * preferredChunkSize) return preferredChunkSize
  return bytesRemaining
}

// Determines if something is the end of the file based on the byte that was uploaded.
const isEof = (fileSize: number, lastByteUploaded: number): boolean => {
  return lastByteUploaded === fileSize - 1
}

/**
 * Start the resumable upload here.
 *
 * It runs a loop and progressively uploads chunks of a file to GCS. The loop is guaranteed to
 * terminate based on a maximum number of iterations. It retries if there are recoverable errors
 * and errors out if it encounters one that can't be retried.
 *
 * It uses an exponential backoff algorithm to retry.
 *
 * @param {GCSResumableUpload} gru - The state of the upload.
 * @return {Promise<void>} Resolves to a promise that does nothing.
 */
export const startResumableUpload = async (gru: GCSResumableUpload): Promise<void> => {
  const { file } = gru
  const fileSize = file.size

  // These variables control the slice of the file that we upload.
  let lastByteUploaded = -1
  let startByte = 0

  // In the event of a retry, we should delay the subsequent request a certain
  // amount of time before we do so (in accordance with truncated exponential backoff).
  // The time to wait before the subsequent request is usually
  // Math.min( 2^(backoffExponent) + random_milliseconds, MAX_BACKOFF_WAIT )
  //
  // We will increment this base everytime we retry, and reset it to 0 everytime we succeed.
  let backoffExponent = 0

  // In the event of an error, we should call subscribers with this error.
  let uploadError: Error | null = null

  // CONTROL FLOW:
  // These variables limit the number of attempts at uploading.
  // We allow a maximum of 60% request failure rate. with a minimum of 5 tries at uploading
  // the whole file.
  let requestsSoFar = 0
  const maxRequestsBeforeCutting = Math.max(5, Math.ceil((fileSize / DEFAULT_CHUNK_SIZE) * 1.6))

  for (; requestsSoFar <= maxRequestsBeforeCutting; requestsSoFar++) {
    const currentChunkSize = computeChunkSizeBasedOnUploadState(fileSize, lastByteUploaded, DEFAULT_CHUNK_SIZE)
    const chunk = file.slice(startByte, startByte + currentChunkSize)
    try {
      const { isSuccess, lastByteUploaded: lastByteUploadedThisRound, gcsError } = await uploadChunk(
        gru,
        chunk,
        startByte,
      )
      if (isSuccess) {
        if (lastByteUploadedThisRound) {
          lastByteUploaded = lastByteUploadedThisRound
        }

        // The next chunk should start with this byte.
        // If Google Cloud Storage did not return a lastByteUploaded, we will
        // retry the last chunk
        startByte = lastByteUploaded + 1

        // inform observers that progress has been made
        callProgressSubscriber(gru, (lastByteUploaded + 1) / fileSize)

        // reset backoff for future failures
        backoffExponent = 0
      } else {
        // Start from scratch
        if (gcsError === 'START_FROM_BEGINNING') {
          lastByteUploaded = -1
        }

        // HARD STOP -- requires that we request a new upload URI
        if (gcsError === 'SESSION_EXPIRED') {
          const error = new Error(`Session expired`)
          callErrorSubscriber(gru, error)
          return
        }

        // Query for however much GCS has received from us and upload the difference.
        if (gcsError === 'QUERY_AND_RETRY') {
          // query for the current upload state before retrying
          const { lastByteUploaded: lastByteUploadedThisRound } = await queryUploadState(gru)
          if (lastByteUploadedThisRound) {
            lastByteUploaded = lastByteUploadedThisRound
            startByte = lastByteUploaded + 1
          }
          // inform observers that progress has been made
          callProgressSubscriber(gru, (lastByteUploaded + 1) / fileSize)
        }

        // Backoff before retrying
        // From: https://cloud.google.com/storage/docs/exponential-backoff
        // Truncated exponential backoff is a standard error handling strategy for network applications in which a client
        // periodically retries a failed request with increasing delays between requests. Clients should use truncated exponential
        // backoff for all requests to Cloud Storage that return HTTP 5xx and 429 response codes, including uploads and downloads
        // of data or metadata.
        await backoff(backoffExponent, MAXIMUM_RETRY_WAIT_MS)
        backoffExponent += 1
      }
      // Reset -- a request made it.
      // So we don't call the error handler.
      uploadError = null
    } catch (e) {
      // -----------------------
      // ERROR HANDLING
      // -----------------------
      // - Network errors
      // - User cancelled upload
      // - Firewall issues

      if (e.name === 'AbortError') {
        // Upload was cancelled by the user mid-request
        break
      }

      console.error(e)

      // We could be blocked from uploading by a Firewall or CORS issues, etc
      // We try to detect based on heuristic. If we're blocked, terminate the loop and
      // report an error.
      const isBlockedFromUploading = backoffExponent >= 2 && lastByteUploaded === -1
      if (isBlockedFromUploading) {
        uploadError = new GCSResumableError(`No progress with upload`, 'LIKELY_BLOCKED')
        break
      } else {
        // Retry in a bid to recover
        uploadError = e
        await backoff(backoffExponent, MAXIMUM_RETRY_WAIT_MS)
        backoffExponent += 1
      }
    }

    // File completely uploaded. Terminate loop
    if (isEof(fileSize, lastByteUploaded)) break

    // Upload was cancelled by the user. Terminate loop
    if (gru.cancelled) break
  }

  // Completed all loops...

  const exhaustedAttemptsToUpload = requestsSoFar >= maxRequestsBeforeCutting
  // FINISH UP by either indicating an error...
  if (uploadError || exhaustedAttemptsToUpload) {
    const error = uploadError || new Error(`Exceeded ${maxRequestsBeforeCutting} requests to upload`)
    console.error(error)
    callErrorSubscriber(gru, error)
  }

  // ... or indicating that the file had completely uploaded
  if (isEof(fileSize, lastByteUploaded)) callCompletedSubscriber(gru)
}
