// @file utility functions related to post drafts
import type { DraftPost } from '@@/types'
import { isEqual, uniqueId } from 'lodash-es'

export function shouldSyncDraftUpdates({
  newDraft,
  existingDraft,
}: {
  newDraft: DraftPost
  existingDraft?: DraftPost
}): boolean {
  const newAttributes = [
    newDraft.subject,
    newDraft.body?.replace(/&nbsp;/g, ' '),
    newDraft.attachment,
    newDraft.attachment_caption,
    newDraft.wish_content,
    newDraft.color,
    newDraft.wall_section_id,
    newDraft.scheduled_at,
    newDraft.custom_properties,
  ]

  const oldAttributes = [
    existingDraft?.subject,
    existingDraft?.body?.replace(/&nbsp;/g, ' '),
    existingDraft?.attachment,
    existingDraft?.attachment_caption,
    existingDraft?.wish_content,
    existingDraft?.color,
    existingDraft?.wall_section_id,
    existingDraft?.scheduled_at,
    existingDraft?.custom_properties,
  ]

  // convert undefined into null for comparison
  const normalizedNewAttributes = newAttributes.map((v) => (v == null ? null : v))
  const normalizedOldAttributes = oldAttributes.map((v) => (v == null ? null : v))

  return normalizedNewAttributes.some((value, index) => !isEqual(normalizedOldAttributes[index], value))
}

// don't couple cid to c_{id} for drafts. avoid confusion with posts.
export function addDraftCidIfAbsent(postDraft: DraftPost): DraftPost {
  if (postDraft.cid == null) {
    postDraft.cid = uniqueId('c_new')
  }
  return postDraft
}
