// @file Surface collections pinia store
import type { WallDateSortProperty } from '@@/bits/collections_helper'
import {
  assertPresent,
  devLog,
  extractEmojiFromFolderName,
  getAccountCollectionValue,
  getCollectionFetchLimits,
  getCollectionWallDateSortProperty,
  getEmptyCollectionCopy,
  isCollectionSearch,
  setAccountCollectionValue,
  transformAccountCollectionValue,
} from '@@/bits/collections_helper'
import { captureException, captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { transformCurrentUrl } from '@@/bits/location'
import PromiseQueue from '@@/bits/promise_queue'
import { vSet } from '@@/bits/vue'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { useUserAccountsStore } from '@@/pinia/user_accounts_store'
import { fetchJson } from '@@/surface/api_fetch'
import PadletApi from '@@/surface/padlet_api'
import type {
  AccountKey,
  Folder,
  FolderId,
  Library,
  LibraryId,
  SharedCollectionAccountKey,
  UserGroupId,
  UserId,
  Wall,
  WallId,
  WallViz,
} from '@@/types'
import type {
  CollectionKey,
  CollectionsSubtree,
  CollectionsTree,
  WallSortAttribute,
  WallSortDirection,
} from '@@/types/collections'
import { CollectionKeyTypes } from '@@/types/collections'
import type { JsonAPIResource } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'
import { keyBy, sortBy, uniq } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

// Avoid race conditions that cause walls and folders to dis/appear etc unexpectedly.
const q = new PromiseQueue()

export const useSurfaceCollectionsStore = defineStore('surfaceCollectionsStore', () => {
  // External stores
  const globalSnackbarStore = useGlobalSnackbarStore()
  const currentUserStore = useSurfaceCurrentUserStore()
  const userAccountsStore = useUserAccountsStore()

  function genericFetchError(payload: { error: any; source: string }): void {
    captureNonNetworkFetchError(payload.error, { source: payload.source })
    globalSnackbarStore.genericFetchError()
  }

  // Consts
  const DEFAULT_COLLECTION_KEY: CollectionKey = { typeKey: 'filter', indexKey: 'made' }

  // State

  // Cursors
  const activeAccountKeyCursor = ref<AccountKey | null>(null)
  const activeCollectionKeyCursor = ref<CollectionKey | null>(DEFAULT_COLLECTION_KEY)
  const activeUserIdCursor = ref<UserId | null>(null)
  const activeWallIdCursor = ref<WallId | null>(null)
  const activeSharedCollectionAccountKey = ref<SharedCollectionAccountKey | null>(null)
  const usersCollectionsWallIds = ref<Record<UserId, CollectionsTree<WallId[]>>>({})
  const librariesCollectionsWallIds = ref<Record<LibraryId, CollectionsTree<WallId[]>>>({})
  const foldersArray = computed((): Folder[] => Object.values(foldersById.value))
  const galleryViz = ref<WallViz | 'all'>('all')
  const activeSortAttribute = ref<WallSortAttribute>('date')
  const activeSortDirection = ref<WallSortDirection | null>(null)

  // By ID
  const foldersById = ref<Record<FolderId, Folder>>({})
  const wallsById = ref<Record<WallId, Wall>>({})

  // User Collections
  const isUsersCollectionsFetched = ref<Record<UserId, CollectionsTree<boolean>>>({})
  const isUsersCollectionsFetching = ref<Record<UserId, CollectionsTree<boolean>>>({})
  const isUsersCollectionsFetchErrored = ref<Record<UserId, CollectionsTree<boolean>>>({})

  // Library Collections
  const currentLibrary = computed((): Partial<Library> => {
    if (currentAccountKey.value == null || currentAccountKey.value.type === 'user')
      return userAccountsStore.defaultPersonalLibrary
    return userAccountsStore.librariesById[currentAccountKey.value.id] ?? userAccountsStore.defaultPersonalLibrary
  })
  const isLibrariesCollectionsFetching = ref<Record<LibraryId, CollectionsTree<boolean>>>({})
  const isLibrariesCollectionsFetchErrored = ref<Record<LibraryId, CollectionsTree<boolean>>>({})
  const isLibrariesCollectionsFetched = ref<Record<LibraryId, CollectionsTree<boolean>>>({})

  // Getters
  const isActiveWallBookmarked = computed<boolean>(() => isWallIdBookmarked(activeWallId.value as WallId))
  const activeWallId = computed<WallId | null>(() => activeWallIdCursor.value)
  const activeUserFolders = computed<Folder[]>(() => {
    // Admins can access any folder in the tenant, including those owned by others.
    // We need to filter those out.
    const folders = activeUserFolderIds.value
      .map((id: FolderId) => foldersById.value[id])
      .filter((folder: Folder) => folder)
      .map((folder) => {
        const trimmedFolderName = folder.name.trim()

        // If the folder name starts with an emoji, we set the emoji as the icon
        const emoji = extractEmojiFromFolderName(trimmedFolderName)
        if (folder.icon == null && emoji !== '') {
          return { ...folder, icon: emoji, name: trimmedFolderName.substring(emoji.length) }
        }
        return folder
      })
    return sortBy(folders, (folder) => folder.name.toLowerCase())
  })
  const activeWallFolderIds = computed((): FolderId[] => {
    const wallId = activeWallId.value
    const userId = activeUserIdCursor.value
    if (userId == null || wallId == null) return []

    const foldersHash = usersCollectionsWallIds.value[userId]?.folderId
    if (foldersHash === undefined) return []

    const containingFolderIds = activeUserFolderIds.value.filter((folderId: FolderId) => {
      const isWallInFolder = foldersHash[folderId]?.find((id) => id === wallId)
      return isWallInFolder
    })
    return containingFolderIds
  })
  const isActiveWallInFavorites = computed((): boolean => isWallIdInFavorites(activeWallId.value as WallId))
  const currentAccountKey = computed((): AccountKey | null => activeAccountKeyCursor.value)
  const currentUserAccountKey = computed((): AccountKey | null =>
    currentUserStore.currentUser == null ? null : { type: 'user', id: currentUserStore.currentUser?.id },
  )
  const activeSortDirectionGetter = computed((): WallSortDirection => {
    return activeSortDirection.value != null
      ? activeSortDirection.value
      : activeSortAttribute.value === 'date'
      ? ('desc' as WallSortDirection)
      : ('asc' as WallSortDirection)
  })

  const getCollectionWalls = ({
    collectionKey,
    accountKey,
  }: {
    collectionKey: CollectionKey
    accountKey?: AccountKey
  }): Wall[] => {
    const accKey: AccountKey | null = accountKey ?? currentAccountKey.value
    if (accKey == null) return []
    const { type, id } = accKey
    const isLibrary = type === 'library'

    // Backend filtering
    let wallIds: number[] | null = []
    if (!isAppUsing('padletPickerV2')) {
      const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
      wallIds = getAccountCollectionValue(wallIdsTree, id, collectionKey) ?? []
    } else {
      if (collectionKey == null || currentUserAccountKey.value == null) return []
      // The search wall ids collection belongs to the userCollectionsWallIds tree. On the new padlet home,
      // we've added a new search collection that available for all user types. We should still use usersCollectionsWallIds
      // even if the current active account key is a library.
      if (!isCollectionSearch(collectionKey)) {
        const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
        wallIds = getAccountCollectionValue(wallIdsTree, id, collectionKey) ?? []
      } else {
        wallIds =
          getAccountCollectionValue(usersCollectionsWallIds.value, currentUserAccountKey.value.id, collectionKey) ?? []
      }
    }

    // Folder wallIds might include IDs for walls that are not yet loaded.
    const walls = wallIds.map((wallId: WallId) => wallsById.value[wallId]).filter((wall: Wall) => wall)

    // Frontend filtering
    const identityFilter = (): true => true
    const galleryFilter = (wall: Wall): boolean => {
      const viz = galleryViz.value
      return viz === 'all' || viz === wall.viz
    }
    const currentFilter = collectionKey.indexKey === 'gallery' ? galleryFilter : identityFilter
    const frontendFilteredWalls = walls.filter(currentFilter)

    // only sort on the frontend when all walls have been fetched
    if (isActiveCollectionFetched.value === true) {
      const sorter = {
        name: (wall: Wall): string => (wall.title ?? '').trim(),
        date: (wall: Wall): number => +new Date(wall[activeCollectionDateSortAttribute.value]),
      }[activeSortAttribute.value]
      const sortedFrontendFilteredWalls = sortBy(frontendFilteredWalls, sorter)
      if (activeSortDirectionGetter.value === 'desc') sortedFrontendFilteredWalls.reverse()
      return sortedFrontendFilteredWalls
    }

    return frontendFilteredWalls
  }
  const activeCollectionWalls = computed((): Wall[] => getCollectionWalls({ collectionKey: activeCollectionKey.value }))

  const getIsCollectionFetching = ({
    accountKey,
    collectionKey,
  }: {
    accountKey: AccountKey
    collectionKey: CollectionKey
  }): boolean | null => {
    const { type, id } = accountKey
    const isLibrary = type === 'library'
    if (isAppUsing('padletPickerV2') && collectionKey != null) {
      const isFetching =
        isLibrary && !isCollectionSearch(collectionKey)
          ? isLibrariesCollectionsFetching.value
          : isUsersCollectionsFetching.value
      const accountId = isLibrary && !isCollectionSearch(collectionKey) ? id : activeUserIdCursor.value
      return getAccountCollectionValue(isFetching, accountId, collectionKey) ?? true
    } else {
      const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
      return getAccountCollectionValue(isFetching, id, collectionKey)
    }
  }

  const isActiveCollectionFetching = computed((): boolean | null => {
    if (currentAccountKey.value == null) return true
    return getIsCollectionFetching({ accountKey: currentAccountKey.value, collectionKey: activeCollectionKey.value })
  })

  const getIsCollectionFetched = ({
    accountKey,
    collectionKey,
  }: {
    accountKey: AccountKey
    collectionKey: CollectionKey
  }): boolean | null => {
    const { type, id } = accountKey
    const isLibrary = type === 'library'
    if (isAppUsing('padletPickerV2') && collectionKey != null) {
      const isFetched =
        isLibrary && !isCollectionSearch(collectionKey)
          ? isLibrariesCollectionsFetched.value
          : isUsersCollectionsFetched.value
      const accountId = isLibrary && !isCollectionSearch(collectionKey) ? id : activeUserIdCursor.value
      return getAccountCollectionValue(isFetched, accountId, collectionKey) ?? false
    } else {
      const isFetched = isLibrary ? isLibrariesCollectionsFetched.value : isUsersCollectionsFetched.value
      return getAccountCollectionValue(isFetched, id, collectionKey)
    }
  }

  const isActiveCollectionFetched = computed((): boolean | null => {
    if (currentAccountKey.value == null) return true
    return getIsCollectionFetched({ accountKey: currentAccountKey.value, collectionKey: activeCollectionKey.value })
  })

  const getIsCollectionFetchErrored = ({
    accountKey,
    collectionKey,
  }: {
    accountKey: AccountKey
    collectionKey: CollectionKey
  }): boolean | null => {
    const { type, id } = accountKey
    const isLibrary = type === 'library'
    if (isAppUsing('padletPickerV2') && collectionKey != null) {
      const isErrored =
        isLibrary && !isCollectionSearch(collectionKey)
          ? isLibrariesCollectionsFetchErrored.value
          : isUsersCollectionsFetchErrored.value
      const accountId = isLibrary && !isCollectionSearch(collectionKey) ? id : activeUserIdCursor.value
      return getAccountCollectionValue(isErrored, accountId, collectionKey) ?? false
    } else {
      const isErrored = isLibrary ? isLibrariesCollectionsFetchErrored.value : isUsersCollectionsFetchErrored.value
      return getAccountCollectionValue(isErrored, id, collectionKey)
    }
  }

  const isActiveCollectionFetchErrored = computed((): boolean | null => {
    if (currentAccountKey.value == null) return true
    return getIsCollectionFetchErrored({
      accountKey: currentAccountKey.value,
      collectionKey: activeCollectionKey.value,
    })
  })
  const activeUserFolderIds = computed((): FolderId[] => {
    const userId = activeUserIdCursor.value as UserId
    const foldersTree = usersCollectionsWallIds.value[userId]?.folderId ?? {}
    return Object.keys(foldersTree).map((folderId) => parseInt(folderId))
  })
  const activeUserGroupFolderIds = computed((): FolderId[] => {
    const userId = activeUserIdCursor.value as UserId
    const foldersTree = usersCollectionsWallIds.value[userId]?.groupFolderId ?? {}
    return Object.keys(foldersTree).map((groupId) => parseInt(groupId))
  })
  const activeLibraryGroupFolderIds = computed((): FolderId[] => {
    const accountKey = activeAccountKeyCursor.value as AccountKey
    const foldersTree = librariesCollectionsWallIds.value[accountKey.id]?.groupFolderId ?? {}
    return Object.keys(foldersTree).map((groupId) => parseInt(groupId))
  })
  const activeGroupFolderIds = computed((): FolderId[] => {
    return activeAccountKeyCursor.value?.type === 'user'
      ? activeUserGroupFolderIds.value
      : activeLibraryGroupFolderIds.value
  })
  const activeCollectionKey = computed((): CollectionKey => {
    const collectionKeyCursor = activeCollectionKeyCursor.value

    // If activeFolderId populated from local storage is not valid for this user, ignore it for now.
    if (
      collectionKeyCursor?.typeKey === 'folderId' &&
      activeUserFolderIds.value.find((id: FolderId) => String(id) === String(collectionKeyCursor?.indexKey)) !==
        undefined
    ) {
      return collectionKeyCursor
    } else if (
      collectionKeyCursor?.typeKey === CollectionKeyTypes.GroupFolderId &&
      activeGroupFolderIds.value.find((id: UserGroupId) => String(id) === String(collectionKeyCursor.indexKey)) !==
        undefined
    ) {
      return collectionKeyCursor
    } else if (collectionKeyCursor?.typeKey === 'filter') {
      return collectionKeyCursor
    } else if (isAppUsing('padletPickerV2') && collectionKeyCursor?.typeKey === 'search') {
      return collectionKeyCursor
    } else {
      return DEFAULT_COLLECTION_KEY
    }
  })

  const activeCollectionDateSortAttribute = computed((): WallDateSortProperty => {
    return getCollectionWallDateSortProperty(activeCollectionKey.value)
  })

  const emptyActiveCollectionCopy = computed((): [string, string] => {
    return getEmptyCollectionCopy(activeCollectionKey.value)
  })

  // Helper methods
  function isWallIdInFavorites(wallId: WallId): boolean {
    const userId = activeUserIdCursor.value
    if (userId == null || wallId == null) return false

    const filtersHash = usersCollectionsWallIds.value[userId]?.filter ?? {}
    const favoritesWallIds = filtersHash.favorites ?? []
    return favoritesWallIds.includes(wallId)
  }

  function isWallIdBookmarked(wallId: WallId): boolean {
    if (wallId == null) return false
    if (isWallIdInFavorites(wallId)) return true
    const userId = activeUserIdCursor.value
    if (userId == null) return false
    const foldersHash = usersCollectionsWallIds.value[userId]?.folderId ?? {}
    const containingFolder = Object.values(foldersHash).find((folderWallIds) => folderWallIds.includes(wallId))
    return !(containingFolder == null)
  }

  function addWallIdsToCollection(payload: {
    accountKey: AccountKey
    collectionKey: CollectionKey
    wallIds: WallId[]
  }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
    const addWallIds = (oldWallIds): WallId[] => uniq([...(oldWallIds ?? []), ...payload.wallIds])
    transformAccountCollectionValue(wallIdsTree, id, payload.collectionKey, addWallIds)
  }

  function removeWallIdFromCollection(payload: {
    accountKey: AccountKey | null
    collectionKey: CollectionKey
    wallId: WallId
  }): void {
    const { type, id } = payload.accountKey as AccountKey
    const isLibrary = type === 'library'
    const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
    const removeWallId = (oldWallIds): WallId[] => {
      if (oldWallIds === null) {
        return []
      }

      return oldWallIds.filter((oldWallId) => oldWallId !== payload.wallId)
    }
    transformAccountCollectionValue(wallIdsTree, id, payload.collectionKey, removeWallId)
  }

  function setCollectionWallIds(payload: {
    accountKey: AccountKey
    collectionKey: CollectionKey
    wallIds: WallId[]
  }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const wallIdsTree = isLibrary ? librariesCollectionsWallIds.value : usersCollectionsWallIds.value
    setAccountCollectionValue(wallIdsTree, id, payload.collectionKey, payload.wallIds)
  }

  function cacheFolders(folders): void {
    foldersById.value = { ...foldersById.value, ...keyBy(folders, 'id') }
  }

  function transformAndUpdateUserCollectionType<T>(
    userId: UserId,
    collectionKeyType: CollectionKeyTypes,
    transform: (subtree: CollectionsSubtree<T>) => CollectionsSubtree<T>,
  ): void {
    if (userId == null) return
    const collectionTree = usersCollectionsWallIds.value[userId] ?? {}

    vSet(collectionTree, collectionKeyType, transform(collectionTree[collectionKeyType]))
    usersCollectionsWallIds.value = { ...usersCollectionsWallIds.value, [userId]: collectionTree }
  }

  function updateFolderListForUser(payload: { userId: UserId; folderIds: FolderId[] }): void {
    transformAndUpdateUserCollectionType<Record<FolderId, WallId[]>>(
      payload.userId,
      CollectionKeyTypes.FolderId,
      (oldFoldersState) => {
        oldFoldersState = oldFoldersState ?? {}
        return payload.folderIds.reduce((newFoldersState, folderId) => {
          newFoldersState[folderId] = oldFoldersState[folderId] ?? []
          return newFoldersState
        }, {})
      },
    )
  }

  function validateFolderNameUnique(name: string): { success: boolean } {
    const isNameTaken = foldersArray.value.map((folder) => folder.name).includes(name?.trim())
    if (!isNameTaken) return { success: true }
    globalSnackbarStore.setSnackbar({
      notificationType: SnackbarNotificationType.error,
      message: __('Name is taken by another folder. Please pick another name.'),
    })
    return { success: false }
  }

  function addFolderForUser(payload: { userId: UserId; folderId: FolderId }): void {
    const addFolderId = (oldFoldersState): CollectionsSubtree<WallId[]> => ({
      ...oldFoldersState,
      [payload.folderId]: [],
    })
    transformAndUpdateUserCollectionType(payload.userId, CollectionKeyTypes.FolderId, addFolderId)
  }

  function fetchWallsHalt(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    setAccountCollectionValue(isFetching, id, payload.collectionKey, false)
  }

  async function haltFetchCollectionWalls(): Promise<void> {
    const key = 'fetchCollectionWalls'
    // Plant the unqueueKey event first so that the next promise in line doesn't start executing
    // once we resolve the current promise
    const unqueueKeyPromise = q.unqueueKey(key)

    // Give the halt signal to the promise that is in flight.
    if (q.currentKey === key) {
      const inFlightPromise = q.resolveCurrentPromiseNow([])
      inFlightPromise?.metadata?.haltChain()
    }

    // Terminate queued fetches
    return await unqueueKeyPromise.then((unqueuedFetches) => {
      unqueuedFetches.forEach((unqueuedFetch) => {
        const { accountKey, collectionKey } = unqueuedFetch.metadata
        fetchWallsHalt({ accountKey, collectionKey })
      })
    })
  }

  function fetchWallsStart(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isErrored = isLibrary ? isLibrariesCollectionsFetchErrored.value : isUsersCollectionsFetchErrored.value
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    // Reset error to false only if previous fetch resulted in error for this collectionKey
    if (getAccountCollectionValue(isErrored, id, payload.collectionKey) != null) {
      setAccountCollectionValue(isErrored, id, payload.collectionKey, false)
    }
    setAccountCollectionValue(isFetching, id, payload.collectionKey, true)
  }

  function cacheWalls(walls: Wall[]): void {
    wallsById.value = { ...wallsById.value, ...keyBy(walls, 'id') }
  }

  function fetchWallsComplete(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    const isFetched = isLibrary ? isLibrariesCollectionsFetched.value : isUsersCollectionsFetched.value
    setAccountCollectionValue(isFetched, id, payload.collectionKey, true)
    setAccountCollectionValue(isFetching, id, payload.collectionKey, false)
  }

  function fetchWallsError(payload: { accountKey: AccountKey; collectionKey: CollectionKey }): void {
    const { type, id } = payload.accountKey
    const isLibrary = type === 'library'
    const isErrored = isLibrary ? isLibrariesCollectionsFetchErrored.value : isUsersCollectionsFetchErrored.value
    const isFetching = isLibrary ? isLibrariesCollectionsFetching.value : isUsersCollectionsFetching.value
    setAccountCollectionValue(isFetching, id, payload.collectionKey, false)
    setAccountCollectionValue(isErrored, id, payload.collectionKey, true)
  }

  async function fetchCollectionWalls({
    accountKey,
    collectionKey,
    accumulatedWallIds,
    pageSize = 100,
    hasNextPage,
    nextPage,
    fetchNextPage = true,
    onCollectionWallsFetching,
    onCollectionWallsFetchedSuccess,
    onCollectionWallsFetchError,
  }: {
    accountKey: AccountKey
    collectionKey: CollectionKey
    accumulatedWallIds?: WallId[]
    pageSize?: number
    hasNextPage?: boolean
    nextPage?: number | null
    fetchNextPage?: boolean
    onCollectionWallsFetching?: (payload: { accountKey: AccountKey; collectionKey: CollectionKey }) => void
    onCollectionWallsFetchedSuccess?: (payload: {
      accountKey: AccountKey
      collectionKey: CollectionKey
      wallIds: WallId[]
      hasNextPage: boolean
    }) => void
    onCollectionWallsFetchError?: (payload: { accountKey: AccountKey; collectionKey: CollectionKey }) => void
  }): Promise<void> {
    devLog('fetchCollectionWalls', { accountKey, collectionKey, accumulatedWallIds, hasNextPage, nextPage })

    let isChainHalted = false
    const haltChain = (): boolean => (isChainHalted = true)
    const isFirstFetchInChain = nextPage === 0 || nextPage == null
    if (isFirstFetchInChain) {
      fetchWallsStart({ accountKey, collectionKey })
    }

    onCollectionWallsFetching?.({ accountKey, collectionKey })

    const promise = async (): Promise<void> => {
      try {
        const urlObject: { path: string; search: Record<string, any> } = {
          path: '/api/9/walls',
          search: {},
        }

        if (collectionKey.typeKey === CollectionKeyTypes.GroupFolderId) {
          urlObject.search.userGroupId = collectionKey.indexKey
        } else if (collectionKey.typeKey === CollectionKeyTypes.FolderId) {
          urlObject.search.folderId = collectionKey.indexKey
        } else if (collectionKey.typeKey === CollectionKeyTypes.Filter) {
          urlObject.search.filter = collectionKey.indexKey
          if (accountKey.type === 'user') {
            urlObject.search.userId = accountKey.id
          } else if (accountKey.type === 'library') {
            if (collectionKey.indexKey === 'trashed') {
              urlObject.search.userId = activeUserIdCursor.value
            }
            urlObject.search.libraryId = accountKey.id
          }

          if (activeSharedCollectionAccountKey.value?.type === 'user') {
            urlObject.search.targetUserId = activeSharedCollectionAccountKey.value?.id
          } else if (activeSharedCollectionAccountKey.value?.type === 'library') {
            urlObject.search.targetLibraryId = activeSharedCollectionAccountKey.value?.id
          }
        }

        // match page[size] and page[number] in backend
        urlObject.search['page[size]'] = pageSize
        urlObject.search['page[number]'] = nextPage == null ? 0 : nextPage
        urlObject.search.include_current_user_metadata = true

        const sortAttributeMapping = {
          lastPresentAt: '-last_present_at',
          updatedAt: '-updated_at',
          title: 'title',
          createdAt: '-created_at',
          trashedAt: '-trashed_at',
        }

        urlObject.search.sort = sortAttributeMapping[getCollectionWallDateSortProperty(collectionKey)]

        // Fetch current page
        const result = await fetchJson(transformCurrentUrl({}, urlObject))

        const wallsNodes = result.data as Array<JsonAPIResource<Wall>>
        const walls = wallsNodes.map((wall) => wall.attributes)
        const wallIds = walls.map((wall) => wall.id)

        // Show new walls as they come. Deleted walls will be hidden later after the last fetch in the chain.
        cacheWalls(walls)
        addWallIdsToCollection({ accountKey, collectionKey, wallIds })

        const hasNextPage: boolean = result?.meta?.page?.hasNextPage ?? false
        const newAccumulatedWallIds = uniq([...(accumulatedWallIds ?? []), ...wallIds])
        onCollectionWallsFetchedSuccess?.({ accountKey, collectionKey, wallIds: newAccumulatedWallIds, hasNextPage })

        if (!isChainHalted && Boolean(hasNextPage) && fetchNextPage) {
          void fetchCollectionWalls({
            accountKey,
            collectionKey,
            accumulatedWallIds: newAccumulatedWallIds,
            pageSize,
            hasNextPage,
            nextPage: result?.meta?.page?.nextPage,
            fetchNextPage,
          })
        } else {
          // If walls have been removed from current collection, reflect that fact.
          setCollectionWallIds({ accountKey, collectionKey, wallIds: newAccumulatedWallIds })
          fetchWallsComplete({ accountKey, collectionKey })
        }
      } catch (error) {
        captureException(error)
        fetchWallsError({ accountKey, collectionKey })
        onCollectionWallsFetchError?.({ accountKey, collectionKey })
      }
    }
    return await q.enqueue('fetchCollectionWalls', promise, { metadata: { accountKey, collectionKey, haltChain } })
  }

  async function fetchActiveCollectionWalls(): Promise<void> {
    const accountKey = activeAccountKeyCursor.value as AccountKey
    const collectionKey = activeCollectionKeyCursor.value as CollectionKey

    const fetchCollectionWallsParams: {
      accountKey: AccountKey
      collectionKey: CollectionKey
      pageSize?: number
      fetchNextPage?: boolean
    } = {
      accountKey,
      collectionKey,
    }

    // for certain collections we only fetch a max number of walls
    const fetchLimits = getCollectionFetchLimits(collectionKey)
    if (fetchLimits != null) {
      // use a single fetch if such a limit exists
      fetchCollectionWallsParams.pageSize = fetchLimits
      fetchCollectionWallsParams.fetchNextPage = false
    }

    await fetchCollectionWalls(fetchCollectionWallsParams)
  }

  // Actions
  async function addBookmark(payload: { wallId: WallId; folderId?: FolderId }): Promise<void> {
    // folderId is allowed to be blank
    const folderId = payload.folderId
    const wallId = payload.wallId
    if (!assertPresent({ wallId })) return

    await fetchJson(`/api/1/bookmarks`, {
      method: HTTPMethod.post,
      jsonData: { wallId, folderId },
    })

    const accountKey: AccountKey = { type: 'user', id: Number(activeUserIdCursor.value) }
    const collectionKey: CollectionKey =
      folderId !== undefined
        ? { typeKey: 'folderId', indexKey: folderId }
        : { typeKey: 'filter', indexKey: 'favorites' }
    addWallIdsToCollection({ accountKey, collectionKey, wallIds: [wallId] })
  }

  async function removeBookmark(payload: { wallId: WallId; folderId?: FolderId }): Promise<void> {
    // folderId is allowed to be blank
    const folderId = payload.folderId
    const wallId = payload.wallId
    if (!assertPresent({ wallId })) return

    await fetchJson(`/api/1/bookmarks`, {
      method: HTTPMethod.delete,
      jsonData: { wallId, folderId },
    })

    const accountKey: AccountKey = { type: 'user', id: Number(activeUserIdCursor.value) }
    const collectionKey: CollectionKey =
      folderId !== undefined
        ? { typeKey: 'folderId', indexKey: folderId }
        : { typeKey: 'filter', indexKey: 'favorites' }
    removeWallIdFromCollection({ accountKey, collectionKey, wallId })
  }

  function setActiveUser(userId: UserId): void {
    activeUserIdCursor.value = userId
  }

  function switchAccountKey(payload: AccountKey): void {
    activeAccountKeyCursor.value = payload
  }

  function setActiveWall(wallId: WallId): void {
    activeWallIdCursor.value = wallId
  }

  async function fetchBookmarks(payload: { wallId: WallId }): Promise<void> {
    if (!assertPresent(payload)) return

    const response = await fetchJson(`/api/1/bookmarks`, {
      method: HTTPMethod.get,
      query: { wallId: String(payload.wallId) },
    })

    const folderIds = response.data.map((record) => record.attributes.folderId)
    folderIds.forEach((folderId) => {
      const accountKey: AccountKey = { type: 'user', id: Number(activeUserIdCursor.value) }
      const collectionKey: CollectionKey =
        folderId !== undefined
          ? { typeKey: 'folderId', indexKey: folderId }
          : { typeKey: 'filter', indexKey: 'favorites' }
      addWallIdsToCollection({ accountKey, collectionKey, wallIds: [payload.wallId] })
    })
  }

  async function fetchFolders(): Promise<void> {
    const userId = Number(activeUserIdCursor.value)
    const accountKey: AccountKey = { type: 'user', id: userId }
    const folders: Folder[] = []

    try {
      const response = await fetchJson(`/api/1/folders`)
      response.data.forEach(({ attributes: { id, name, wallIds } }) => {
        folders.push({ id, name })
        const collectionKey: CollectionKey = { typeKey: 'folderId', indexKey: id }
        setCollectionWallIds({ accountKey, collectionKey, wallIds })
      })
      cacheFolders(folders)
      const folderIds = folders.map((folder) => folder.id)
      updateFolderListForUser({ userId, folderIds })
    } catch (error) {
      genericFetchError({ error, source: 'SurfaceCollectionFetchFolders' })
    }
  }

  async function createFolderForActiveUser(payload: { name: string }): Promise<void> {
    const userId = activeUserIdCursor.value as UserId
    const name = payload.name.trim()
    if (!assertPresent({ userId, name })) return

    const { success }: { success: boolean } = validateFolderNameUnique(name)
    if (!success) return
    try {
      const response = await PadletApi.Folder.create({ name })
      const folder = (response.data as JsonAPIResource<Folder>).attributes
      cacheFolders([folder])
      addFolderForUser({ userId, folderId: folder.id })
      globalSnackbarStore.setSnackbar({
        notificationType: SnackbarNotificationType.success,
        message: __('Created new folder'),
      })
      // Add current active wall (if any) to the new folder created
      if (activeWallId.value != null) {
        await addBookmark({ folderId: folder.id, wallId: activeWallId.value })
        return
      }
    } catch (error) {
      genericFetchError({ error, source: 'SurfaceCollectionsCreateFolderForActiveUser' })
    }
  }

  async function fetchActiveCollectionWallsNow(): Promise<void> {
    await haltFetchCollectionWalls()
    void fetchActiveCollectionWalls()
  }

  function setAccountCollectionCursor(payload: { collectionKey: CollectionKey; accountKey: AccountKey }): void {
    activeAccountKeyCursor.value = payload.accountKey
    activeCollectionKeyCursor.value = payload.collectionKey
  }
  /*
   * ==================== SEARCH====================
   */

  async function fetchSearchResults(query: string): Promise<Wall[]> {
    const userId = activeUserIdCursor.value as UserId
    if (userId == null) return []

    const collectionKey: CollectionKey = {
      typeKey: 'search',
      indexKey: query,
    }

    setAccountCollectionValue(isUsersCollectionsFetchErrored.value, userId, collectionKey, false)

    if (query === '') return []

    try {
      const searchEndpoint = 'api/1/search/padlets.json'
      setAccountCollectionValue(isUsersCollectionsFetching.value, userId, collectionKey, true)
      const searchResults = await fetchJson(searchEndpoint, {
        query: {
          q: query,
        },
      })
      const walls = searchResults.hits
      setAccountCollectionValue(isUsersCollectionsFetched.value, userId, collectionKey, true)
      return walls
    } catch (e) {
      setAccountCollectionValue(isUsersCollectionsFetchErrored.value, userId, collectionKey, true)
      return []
    } finally {
      setAccountCollectionValue(isUsersCollectionsFetching.value, userId, collectionKey, false)
    }
  }

  return {
    // Getters
    isActiveWallBookmarked,
    activeWallId,
    activeUserFolders,
    activeWallFolderIds,
    isActiveWallInFavorites,
    activeCollectionWalls,
    isActiveCollectionFetching,
    isActiveCollectionFetched,
    isActiveCollectionFetchErrored,
    currentAccountKey,
    activeCollectionKey,
    usersCollectionsWallIds,
    activeCollectionDateSortAttribute,
    emptyActiveCollectionCopy,
    currentUserAccountKey,
    currentLibrary,

    // Getter Functions
    getIsCollectionFetching,
    getIsCollectionFetched,
    getIsCollectionFetchErrored,
    getCollectionWalls,
    getEmptyCollectionCopy,

    // Actions
    addBookmark,
    removeBookmark,
    setActiveUser,
    switchAccountKey,
    setActiveWall,
    fetchBookmarks,
    fetchFolders,
    createFolderForActiveUser,
    fetchActiveCollectionWallsNow,
    setAccountCollectionCursor,
    fetchCollectionWalls,
    fetchSearchResults,
  }
})
