// @file Google Drive store
import { trackEvent } from '@@/bits/analytics'
import { getCurrentUser } from '@@/bits/current_user'
import { __ } from '@@/bits/intl'
import { loadJs } from '@@/bits/load'
import { ApiErrorCode, SnackbarNotificationType } from '@@/enums'
import googleDriveColorSvg from '@@/images/google-drive-color.svg'
import type { ConfirmationDialogPayload } from '@@/pinia/global_confirmation_dialog'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import PadletApi from '@@/surface/padlet_api'
import type { GoogleDriveAuthorizationResult } from '@@/types'
import { useGoogleDrive } from '@@/vuecomposables/google_drive'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum GoogleDriveFetchingStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export const useGoogleDrivePickerStore = defineStore('googleDrivePicker', () => {
  // State
  const googleDriveFetchingStatus = ref(GoogleDriveFetchingStatus.Loading)
  const isUserLinkedWithGoogleDrive = computed(() => googleDriveEmail.value != null)
  const googleDriveEmail = ref<string | null>(null)
  const googleDriveAccessToken = ref<string | null>(null)
  const isGoogleDrivePickerOpen = ref<boolean>(false)

  // Getters

  const isFetchingGoogleDriveUserInformation = computed(
    () => googleDriveFetchingStatus.value === GoogleDriveFetchingStatus.Loading,
  )

  const isErroredFetchingGoogleDriveUserInformation = computed(
    () => googleDriveFetchingStatus.value === GoogleDriveFetchingStatus.Errored,
  )

  function setGoogleDriveEmail(email: string): void {
    googleDriveEmail.value = email
  }

  function setGoogleDriveAccessToken(token: string): void {
    googleDriveAccessToken.value = token
  }

  function setIsGoogleDrivePickerOpen(isOpen: boolean): void {
    isGoogleDrivePickerOpen.value = isOpen
  }

  async function fetchGoogleDriveInfo(): Promise<void> {
    const currentUser = getCurrentUser()
    try {
      googleDriveFetchingStatus.value = GoogleDriveFetchingStatus.Loading

      // Load Google APIs
      await loadJs('https://apis.google.com/js/api.js')

      // Load Google Drive user authorization
      const response = await PadletApi.UserIntegrations.fetchGoogleDriveAuthorization(currentUser.id)
      const googleDriveInfo = (response.data as JsonAPIResource<GoogleDriveAuthorizationResult> | undefined)?.attributes
      if (googleDriveInfo != null) {
        googleDriveEmail.value = googleDriveInfo.linkedEmail
        googleDriveAccessToken.value = googleDriveInfo.accessToken
      }
      googleDriveFetchingStatus.value = GoogleDriveFetchingStatus.Completed
    } catch (e) {
      const error = JSON.parse(e.message)?.errors[0]
      if (error.code === ApiErrorCode.TOKEN_EXPIRED) {
        await refreshAccessToken(currentUser)
      } else {
        void useGlobalSnackbarStore().genericFetchError()
        googleDriveFetchingStatus.value = GoogleDriveFetchingStatus.Errored
      }
    }
  }

  async function refreshAccessToken(currentUser): Promise<void> {
    try {
      const refreshedResponse = await PadletApi.UserIntegrations.refreshGoogleDriveAccessToken(currentUser.id)

      const googleDriveInfo = (refreshedResponse?.data as JsonAPIResource<GoogleDriveAuthorizationResult>)?.attributes
      googleDriveEmail.value = googleDriveInfo.linkedEmail
      googleDriveAccessToken.value = googleDriveInfo.accessToken

      googleDriveFetchingStatus.value = GoogleDriveFetchingStatus.Completed
    } catch (e) {
      googleDriveFetchingStatus.value = GoogleDriveFetchingStatus.Errored

      const error = JSON.parse(e.message)?.errors[0]
      if (error.code === ApiErrorCode.TOKEN_EXPIRED || error.code === ApiErrorCode.UPDATE_FAILED) {
        void useGlobalSnackbarStore().setSnackbar({
          message: __('Your Google Drive credentials have expired. Please try again to re-authorize'),
          timeout: 5000,
          notificationType: SnackbarNotificationType.error,
        })
      } else {
        void useGlobalSnackbarStore().genericFetchError()
      }
    }
  }

  function promptConnectWithGoogleDriveModal(onCloseDialog): void {
    const currentUser = getCurrentUser()
    const payload: ConfirmationDialogPayload = {
      iconSrc: googleDriveColorSvg,
      iconAlt: __('Google Drive icon'),
      title: __('Google Drive is not connected yet'),
      body: __(
        'Connect your Google account to import or link files from Google Drive. You will only have to do this once.',
      ),
      customBodyClasses: 'px-6',
      confirmButtonText: __('Connect'),
      cancelButtonText: __('Cancel'),
      afterConfirmActions: [
        async () => {
          const result = await useGoogleDrive().requestGoogleDriveAuthorization({
            tenantId: currentUser.tenant_id,
            onCloseBeforeAuth: onCloseDialog,
          })
          trackEvent('GoogleDrive', 'Connection completed')
          // Set Google Drive Email in frontend for use in UI
          useGoogleDrivePickerStore().setGoogleDriveEmail(result.email)
          useGoogleDrivePickerStore().setGoogleDriveAccessToken(result.token)
        },
      ],
      afterCancelActions: [onCloseDialog],
      forceFullWidthButtons: true,
    }
    useGlobalConfirmationDialogStore().openConfirmationDialog(payload)
  }

  return {
    // State
    isUserLinkedWithGoogleDrive,
    googleDriveEmail,
    googleDriveAccessToken,
    isGoogleDrivePickerOpen,

    // Getters
    isErroredFetchingGoogleDriveUserInformation,
    isFetchingGoogleDriveUserInformation,

    // Actions
    setGoogleDriveEmail,
    setGoogleDriveAccessToken,
    setIsGoogleDrivePickerOpen,
    fetchGoogleDriveInfo,
    promptConnectWithGoogleDriveModal,
  }
})
