// @file Vuex Store module for all things related to posts or posting on a padlet.

import { trackEvent } from '@@/bits/analytics'
import appCan from '@@/bits/app_can'
import { getDisplayAttributes } from '@@/bits/beethoven'
import device from '@@/bits/device'
import { captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { isPostPublished, isPostScheduled } from '@@/bits/post_state'
import { ACCESS_WITHDRAWN } from '@@/bits/snackbar_helper'
import { getPollFromPost, hasPollAttachment } from '@@/bits/surface_polls'
import { vDel, vSet } from '@@/bits/vue'
import { SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAttachmentsStore } from '@@/pinia/surface_attachments'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useReactionsStore } from '@@/pinia/surface_reactions'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import PadletApi from '@@/surface/padlet_api'
import { recomputeSortIndex, sortPostsBySortIndex } from '@@/surface/sorter'
import type { AttachmentProps, Cid, Id, Poll, PollChoice, Post, PostAttributes, UpdatePostArgs } from '@@/types'
import { usePostBuilder } from '@@/vuecomposables/surface_post_builder'
import type { CustomSavePostRequest } from '@@/vuexstore/helpers/post'
import {
  addCidIfAbsent,
  customSavePost,
  deleteAllPosts,
  deletePost,
  isEmpty,
  isNew,
  isNonEmpty,
  PostConfiguration,
  reconcilePostsAfterFetch,
  savePost,
} from '@@/vuexstore/helpers/post'
import type { RootState } from '@@/vuexstore/surface/types'
import type { JsonAPIResource, JsonAPIResponse, Wish } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'
import { FetchOptionCredentials, FetchOptionMode } from '@padlet/fetch/src/types'
import { storeToRefs } from 'pinia'
import type { ActionContext, Module } from 'vuex'

interface PostState {
  chatAttachment: string | null
  newestPostCid: Cid | null
  pendingPostEntitiesByCid: Record<Cid, Post>
  postBeingEditedCid: Cid | null
  postEntitiesByCid: Record<Cid, Post>
  isInitialPaginatedDataLoadDone: boolean
  postsMarkedAsDeleted: Post[]
  postOrder: Cid[]
  mostRecentlyChangedGeolocationPostCid: Cid | null
}

const defaultState = (): PostState => ({
  chatAttachment: null,
  newestPostCid: null,
  pendingPostEntitiesByCid: {},
  postBeingEditedCid: null,
  postEntitiesByCid: {},
  isInitialPaginatedDataLoadDone: false,
  postsMarkedAsDeleted: [],
  postOrder: [],
  mostRecentlyChangedGeolocationPostCid: null,
})

const postModule: Module<PostState, RootState> = {
  namespaced: true,
  state: defaultState,
  getters: {
    /*
     * All posts & drafts
     */
    postEntitiesByCid: (state) => state.postEntitiesByCid, // source of truth for wishes on a surface
    postCids: (state, getters): Cid[] => Object.keys(getters.postEntitiesByCid),
    postsArray: (state, getters): Post[] => Object.values(getters.postEntitiesByCid),
    postsBySectionId: (state, getters, _, rootGetters): Record<string, Post> => {
      if (rootGetters.isSectioned) {
        const postsBySectionId = {}
        getters.postCids.forEach((postCid: string): void => {
          const post = state.postEntitiesByCid[postCid]
          if (!post.wall_section_id || isEmpty(post)) return
          postsBySectionId[post.wall_section_id] = postsBySectionId[post.wall_section_id] || []
          postsBySectionId[post.wall_section_id].push(post)
        })
        return postsBySectionId
      }
      return {}
    },
    postCidsBySection: (state, getters, _, rootGetters): Record<string, string> => {
      if (rootGetters.isSectioned) {
        const postCidsBySection = {}
        getters.postCids.forEach((pid: string): void => {
          const p = state.postEntitiesByCid[pid]
          if (!p.wall_section_id || isEmpty(p)) return
          postCidsBySection[p.wall_section_id] = postCidsBySection[p.wall_section_id] || []
          postCidsBySection[p.wall_section_id].push(pid)
        })
        return postCidsBySection
      }
      return {}
    },

    /*
     * Saved posts (no drafts)
     */
    postEntitiesById: (state, getters) =>
      getters.postsArray.reduce((hash, post) => {
        if (post.id) {
          hash[post.id] = post
        }
        return hash
      }, {}),
    savedPostEntitiesByCid: (state) => {
      const entities = {}
      for (const cid in state.postEntitiesByCid) {
        const post = state.postEntitiesByCid[cid]
        if (!isNew(post) && isNonEmpty(post)) {
          entities[cid] = state.postEntitiesByCid[cid]
        }
      }
      return entities
    },
    savedPostCids: (state, getters): Cid[] => Object.keys(getters.savedPostEntitiesByCid),
    savedPostsArray: (state, getters): Post[] => Object.values(getters.savedPostEntitiesByCid),
    publishedPosts: (_, getters): Post[] => getters.savedPostsArray.filter((post) => post.published ?? true),
    savedPostsSortedBySortIndex(_, getters, _rootState, rootGetters): Post[] {
      if (rootGetters.isTimelineV1 as boolean) {
        return sortPostsBySortIndex(getters.savedPostsArray).reverse()
      }
      return sortPostsBySortIndex(getters.savedPostsArray)
    },
    isExistingPost: (_, getters) => (cid: Cid) => getters.savedPostEntitiesByCid[cid] != null,

    /*
     * Pending posts-related collections
     */
    pendingPostEntitiesByCid: (state) => state.pendingPostEntitiesByCid,
    pendingPostCids: (state): Cid[] => Object.keys(state.pendingPostEntitiesByCid),
    pendingPosts: (state): Post[] => Object.values(state.pendingPostEntitiesByCid),

    /*
     * Cached post getter methods
     */
    getPostByCid:
      (state): Function =>
      (cid: Cid): Post | null =>
        state.postEntitiesByCid[cid],
    getPostByServerId:
      (_, getters): Function =>
      (id: Id): Post | null =>
        getters.postEntitiesById[id],
    getPost:
      (_, getters): Function =>
      ({ id, cid }: { id: Id; cid: Cid }): Post | null =>
        getters.getPostByCid(cid) || getters.getPostByServerId(id),
    getLiveOrDeletedPost:
      (_, getters): Function =>
      ({ id, cid }: { id: Id; cid: Cid }): Post | null =>
        getters.getPost({ id, cid }) || getters.findDeletedPost({ cid, id }),
    getAttachmentPropsFromPost:
      (_, getters): Function =>
      (cid: Cid): AttachmentProps | null =>
        getters.getPostByCid(cid)?.wish_content?.attachment_props ?? null,

    // Used by map format to pan to a post that has just changed geolocation, handled by a watcher in surface_container_map.vue
    mostRecentlyChangedGeolocationPostCid: (state): Cid | null => state.mostRecentlyChangedGeolocationPostCid,

    // Others
    postOrder: (state) => state.postOrder,
    postMaxAbsSortIndex: (state, getters): number =>
      Math.max(...getters.postsArray.map((post) => Math.abs(post.sort_index))),
    isInitialPaginatedDataLoadDone: (state) => state.isInitialPaginatedDataLoadDone,
    postBeingEditedCid: (state) => state.postBeingEditedCid,
    postBeingEdited: (state): Post | null => {
      return state.postBeingEditedCid ? state.postEntitiesByCid[state.postBeingEditedCid] : null
    },
    chatAttachment: (state) => state.chatAttachment,
    newestPostCid: (state) => state.newestPostCid,
    newestPost: (state, getters): Post => getters.getPostByCid(state.newestPostCid),
    assignedWallSectionId: (_state, getters, _rootState, rootGetters): Id | null => {
      if (rootGetters.isSectionBreakout as boolean) return rootGetters.breakoutSectionId
      const usableSections = useSurfaceSectionsStore().usableSections
      if (usableSections.length === 0) return null
      const mostRecentlyTouchedSection = usableSections.find(
        (s) => s.id === useSurfaceSectionsStore().mostRecentlyTouchedSectionId,
      )
      if (mostRecentlyTouchedSection?.id != null) return mostRecentlyTouchedSection.id
      return useSurfaceSectionsStore().defaultSectionId ?? usableSections[0].id
    },
    accumulatedReactionsByWishId() {
      const { accumulatedReactionsByWishId } = storeToRefs(useReactionsStore())
      return accumulatedReactionsByWishId.value
    },
    accumulatedReactionsTotalSumAverage() {
      const { accumulatedReactionsTotalSumAverage } = storeToRefs(useReactionsStore())
      return accumulatedReactionsTotalSumAverage.value
    },
    accumulatedReactionsConfidenceNumber() {
      const { accumulatedReactionsConfidenceNumber } = storeToRefs(useReactionsStore())
      return accumulatedReactionsConfidenceNumber.value
    },
    postAuthorIds(_, getters): Id[] {
      const savedPosts = Object.values<Post>(getters.savedPostEntitiesByCid)
      const uniqueIds = new Set<Id | undefined>(savedPosts.map((post) => post.author_id))
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return Array.from(uniqueIds).filter((id) => id != null) as Id[]
    },
    postConfiguration(state, getters, rootState, rootGetters): PostConfiguration {
      if (rootGetters.isTable) return PostConfiguration.BodyOrAttachment
      return PostConfiguration.All
    },
    canUserViewPost(state, getters, rootState, rootGetters): (post: PostAttributes) => boolean {
      return (post: PostAttributes): boolean => {
        return (
          post.published ||
          rootGetters.canIModerate ||
          (rootState.user.id !== null && post.author_id === rootState.user.id)
        )
      }
    },
    findDeletedPost(state): (post: PostAttributes) => Post | undefined {
      return (post: PostAttributes): Post | undefined => {
        return state.postsMarkedAsDeleted.find(
          // Post might have been deleted before they can be created,
          // so it's possible that p.id and post.id are both undefined.
          (p: PostAttributes) => p.cid === post.cid || (p.id && post.id && p.id === post.id),
        )
      }
    },
    isPostMarkedAsDeleted(state, getters): (post: PostAttributes) => boolean {
      return (post: PostAttributes): boolean => !!getters.findDeletedPost(post)
    },
    isPostAlive(state, getters): ({ post, postCid }: { post: PostAttributes; postCid: Cid }) => boolean {
      return ({ post, postCid }: { post: PostAttributes; postCid: Cid }): boolean => {
        post = post || getters.getPostByCid(postCid)
        if (!post) return false
        if (getters.isPostMarkedAsDeleted(post)) return false
        return true
      }
    },
  },
  mutations: {
    FETCH_POSTS_SUCCESS(state, { newPostEntitiesByCid, isInitialPaginatedDataLoadDone }): void {
      state.postEntitiesByCid = newPostEntitiesByCid || {}
      if (isInitialPaginatedDataLoadDone) state.isInitialPaginatedDataLoadDone = true
    },
    // Updates pending post with the given attributes,
    // e.g. assign a table cell to dragged and dropped files before they are uploaded.
    UPDATE_PENDING_POST(state, postAttributes): void {
      const post = state.pendingPostEntitiesByCid[postAttributes.cid]
      if (!post) return
      const posts = {
        ...state.pendingPostEntitiesByCid,
        [post.cid]: { ...post, ...postAttributes },
      }
      state.pendingPostEntitiesByCid = posts
    },
    ADD_PENDING_POST(state, postAttributes): void {
      const post = addCidIfAbsent(postAttributes)
      vSet(state.pendingPostEntitiesByCid, post.cid, post)
    },
    REMOVE_PENDING_POST(state, { cid }): void {
      const newPendingPostEntities = { ...state.pendingPostEntitiesByCid }
      delete newPendingPostEntities[cid]
      state.pendingPostEntitiesByCid = newPendingPostEntities
    },
    REMOVE_PENDING_POSTS(state, { cids }): void {
      const newPendingPostEntities = { ...state.pendingPostEntitiesByCid }
      cids.forEach((cid): void => {
        delete newPendingPostEntities[cid]
      })
      state.pendingPostEntitiesByCid = newPendingPostEntities
    },
    REMOVE_ALL_PENDING_POSTS_WITHOUT_SECTION(state): void {
      const newPendingPostEntities = { ...state.pendingPostEntitiesByCid }
      const ppids = Object.keys(state.pendingPostEntitiesByCid)
      ppids.forEach((ppid): void => {
        const pendingPost = newPendingPostEntities[ppid]
        if (!pendingPost.wall_section_id) {
          delete newPendingPostEntities[ppid]
        }
      })
      state.pendingPostEntitiesByCid = newPendingPostEntities
    },
    REJECT_POSTS_WITH_FILE_SIZE_EXCEEDED(state, file): void {
      if (file) {
        const newPendingPostEntities = { ...state.pendingPostEntitiesByCid }
        const ppids = Object.keys(state.pendingPostEntitiesByCid)
        ppids.forEach((ppid): void => {
          const pendingPost = newPendingPostEntities[ppid]
          if (pendingPost.file === file) {
            delete newPendingPostEntities[ppid]
          }
        })
        state.pendingPostEntitiesByCid = newPendingPostEntities
      }
    },
    START_NEW_POST(state, postAttributes): void {
      const post = addCidIfAbsent(postAttributes)
      vSet(state.postEntitiesByCid, post.cid, post)
      if (postAttributes.wall_section_id != null) {
        useSurfaceSectionsStore().updateMostRecentlyTouchedSection(postAttributes.wall_section_id)
      }
      state.postBeingEditedCid = post.cid
    },
    // When a new post is created by another user and we are notified via realtime,
    // we call this instead of START_NEW_POST because START_NEW_POST opens the post for editing.
    CREATED_POST(state, postAttributes): void {
      const post = addCidIfAbsent(postAttributes)
      vSet(state.postEntitiesByCid, post.cid, post)
      if (
        postAttributes.wall_section_id != null &&
        postAttributes.wall_section_id !== useSurfaceSectionsStore().mostRecentlyTouchedSectionId
      ) {
        useSurfaceSectionsStore().updateMostRecentlyTouchedSection(postAttributes.wall_section_id)
      }
    },
    SAVE_POST_ID(state, { id, cid, hashid }): void {
      const post = state.postEntitiesByCid[cid]
      if (post) {
        vSet(post, 'id', id)
        vSet(post, 'hashid', hashid)
      }
    },
    MARK_NEWEST_POST(state, cid): void {
      state.newestPostCid = cid
    },
    DELETED_POST(state, post: Post): void {
      if (state.postBeingEditedCid === post.cid) {
        state.postBeingEditedCid = null
      }
      state.postsMarkedAsDeleted.push(post)
      vDel(state.postEntitiesByCid, post.cid)
    },
    DELETED_POST_TO_WAIT_FOR_AUTO_MODERATION(state, post: Post): void {
      // Should be the same as DELETED_POST except for updating postsMarkedAsDeleted
      if (state.postBeingEditedCid === post.cid) {
        state.postBeingEditedCid = null
      }
      vDel(state.postEntitiesByCid, post.cid)
    },
    UPDATED_POST_ORDER(state, postOrder: Cid[]): void {
      state.postOrder = postOrder
    },
    UPDATED_POST(state, post: Post): void {
      vSet(state.postEntitiesByCid, post.cid, post)
    },
    UPDATED_POST_SUCCESS(state, post): void {
      vSet(state.postEntitiesByCid, post.cid, post)
    },
    UPDATED_POST_ERROR(state, post): void {
      // TODO: recover to old post
      vSet(state.postEntitiesByCid, post.cid, post)
    },
    START_EDITING_POST(state, postCid): void {
      state.postBeingEditedCid = postCid
    },
    STOP_EDITING_POST(state): void {
      state.postBeingEditedCid = null
    },
    CHANGE_POST_TEXT(state, { postCid, attributes }: { postCid: Cid; attributes: PostAttributes }): void {
      let postToBeSaved: Post = state.postEntitiesByCid[postCid]
      postToBeSaved = Object.assign(postToBeSaved, attributes)
      vSet(state.postEntitiesByCid, postCid, postToBeSaved)
    },
    // Deprecated method that uses sortIndex
    UPDATE_POST_SORT_INDEX(state, { postCid, sortIndex }): void {
      if (sortIndex && state.postEntitiesByCid[postCid]) {
        vSet(state.postEntitiesByCid[postCid], 'sort_index', sortIndex)
      }
    },
    UPDATE_POST_SECTION(state, { postCid, sectionId }): void {
      if (sectionId) {
        vSet(state.postEntitiesByCid[postCid], 'wall_section_id', sectionId)
        const section = useSurfaceSectionsStore().getSectionById(sectionId)
        vSet(state.postEntitiesByCid[postCid], 'wall_section_hashid', section?.hashid)
      }
    },
    MOVED_POST(state, { postCid, position }): void {
      if (typeof position.left === 'number') {
        vSet(state.postEntitiesByCid[postCid], 'left', position.left)
      }
      if (typeof position.top === 'number') {
        vSet(state.postEntitiesByCid[postCid], 'top', position.top)
      }
    },
    RESIZED_POST(state, { postCid, size }): void {
      if (size && size.width) {
        vSet(state.postEntitiesByCid[postCid], 'width', size.width)
      }
    },
    APPROVE_POST(state, { postCid }): void {
      vSet(state.postEntitiesByCid[postCid], 'published', true)
    },
    REMOVE_POST_COLOR(state, { postCid }): void {
      vSet(state.postEntitiesByCid[postCid], 'color', null)
    },
    CHANGE_POST_COLOR(state, { postCid, color }): void {
      vSet(state.postEntitiesByCid[postCid], 'color', color)
    },
    SAVE_ATTACHMENT_CHAT(state, attachment): void {
      if (!state.postBeingEditedCid) {
        state.chatAttachment = attachment
      } else {
        vSet(state.postEntitiesByCid[state.postBeingEditedCid], 'attachment', attachment)
      }
    },
    SAVE_CAPTION_AS_TITLE(state, title): void {
      if (state.postBeingEditedCid) {
        const post = state.postEntitiesByCid[state.postBeingEditedCid]
        // do not overwrite posts that already have subject, setting caption as title is low priority
        if (post.subject) return
        vSet(state.postEntitiesByCid[state.postBeingEditedCid], 'subject', title)
      }
    },
    SAVE_ATTACHMENT(state, attachment): void {
      if (state.postBeingEditedCid) {
        vSet(state.postEntitiesByCid[state.postBeingEditedCid], 'attachment', attachment)
      }
    },
    REMOVE_ATTACHMENT(state, postCid): void {
      if (postCid) {
        vSet(state.postEntitiesByCid[postCid], 'attachment', null)
      } else if (state.postBeingEditedCid) {
        vSet(state.postEntitiesByCid[state.postBeingEditedCid], 'attachment', null)
      }
    },
    SAVE_FILE(state, file): void {
      if (state.postBeingEditedCid) {
        vSet(state.postEntitiesByCid[state.postBeingEditedCid], 'file', file)
      }
    },
    CHANGE_ATTACHMENT(state, { postCid, attributes }): void {
      vSet(state.postEntitiesByCid[postCid || attributes.cid], 'attachment', attributes.attachment)
    },
    SET_MOST_RECENTLY_CHANGED_GEOLOCATION_POST_CID(state, cid): void {
      state.mostRecentlyChangedGeolocationPostCid = cid
    },
  },
  actions: {
    /* ------------
     * CR-D
     * ------------ */
    async pingPosts({ rootGetters, dispatch }): Promise<void> {
      // Only send the network request to update the cache, do nothing with the response
      try {
        await PadletApi.Wish.fetch({ wallHashid: rootGetters.wallHashid })
      } catch (e) {
        // Don't capture exception when it's 401, show a snackbar instead
        if (e.status === 401) {
          useGlobalSnackbarStore().setSnackbar(ACCESS_WITHDRAWN)
        } else {
          captureFetchException(e, { source: 'pingPosts' })
        }
      }
    },
    // shouldResetState is a flag to signal that this fetch should reset the post list
    // only reset before we save the first page of posts to store, follow up pages should only update the state
    async fetchPosts({ dispatch, rootGetters, rootState }, { shouldResetState }): Promise<any> {
      if (useSurfaceStore().isSubmissionRequest) return

      try {
        if (
          !rootState.shouldFallbackWsDataFetchToRest &&
          isAppUsing('realtimeFetching') &&
          // For FCS, we use the preloaded wishes api to fetch the first page of wishes instead of realtime
          !isAppUsing('fullClientSurface')
        ) {
          const { data, meta }: JsonAPIResponse<Wish> = await dispatch('realtime/fetchWishes', {}, { root: true })
          void dispatch('fetchPostsSuccess', {
            data,
            isFirstPage: true,
            isLastPage: meta?.next == null || meta?.next === '',
            shouldResetState,
          })

          if (meta?.next != null && meta?.next !== '') {
            void dispatch('fetchPostsForPage', { pageStart: meta.next })
          }
        } else {
          const { data, meta }: JsonAPIResponse<Wish> = await PadletApi.Wish.fetch(
            {
              wallHashid: rootGetters.wallHashid,
            },
            // 2026-07-04: For padlet embedded in LTI, we need to pass Authorization Header otherwise the request will fail
            // as there is no user in session for authentication
            useSurfaceStore().isEmbedded !== true
              ? {
                  method: HTTPMethod.get,
                  // preload send cookies with its request for resources from the same origin
                  credentials: FetchOptionCredentials.include,
                  // default mode is same-origin, preload uses no-cors
                  mode: FetchOptionMode.noCors,
                }
              : {},
          )
          void dispatch('fetchPostsSuccess', {
            data,
            isFirstPage: true,
            isLastPage: meta?.next == null || meta?.next === '',
            shouldResetState,
          })
          if (meta?.next != null && meta?.next !== '') {
            void dispatch('fetchPostsForPage', { pageStart: meta.next })
          }
        }
      } catch (e) {
        // Don't capture exception when it's 401, show a snackbar instead
        if (e.status === 401) {
          useGlobalSnackbarStore().setSnackbar(ACCESS_WITHDRAWN)
        } else {
          captureFetchException(e, { source: 'fetchPosts' })
        }
      }
    },
    async fetchSinglePostWithHashid({ dispatch }, { wishHashid }): Promise<Wish | null> {
      try {
        const { data }: JsonAPIResponse<Wish> = await PadletApi.Wish.fetchSingleWithHashid({ wishHashid })
        const post = (data as JsonAPIResource<Wish>).attributes
        void dispatch('updatePostInStore', post)
        return post
      } catch (e) {
        if (e.status === 401) {
          useGlobalSnackbarStore().setSnackbar(ACCESS_WITHDRAWN)
        } else {
          captureFetchException(e, { source: 'fetchPosts' })
        }
        return null
      }
    },
    async fetchPostsForPage({ dispatch, rootGetters, rootState }, { pageStart }): Promise<void> {
      let response
      try {
        if (!rootState.shouldFallbackWsDataFetchToRest && isAppUsing('realtimeFetching')) {
          response = await dispatch('realtime/fetchWishes', { pageStart }, { root: true })
        } else {
          response = await PadletApi.Wish.fetch({
            wallHashid: rootGetters.wallHashid,
            pageStart,
          })
        }
      } catch (e) {
        captureFetchException(e, { source: 'fetchPostsPage' })
      }

      const { data, meta }: JsonAPIResponse<Wish> = response
      const nextPageCursor = meta?.next

      void dispatch('fetchPostsSuccess', {
        data,
        isFirstPage: meta?.isFirstPage,
        isLastPage: nextPageCursor == null || nextPageCursor === '',
        shouldResetState: false,
      })
      if (nextPageCursor != null && nextPageCursor !== '') {
        void dispatch('fetchPostsForPage', { pageStart: nextPageCursor })
      }
    },
    fetchPostsSuccess(context, { data, isFirstPage, isLastPage, shouldResetState }): void {
      const { commit, dispatch } = context
      const newPosts = data || []
      const newPostEntitiesByCid = reconcilePostsAfterFetch(context, newPosts, {
        isFirstPage,
        isLastPage,
        shouldResetState,
      })

      commit('FETCH_POSTS_SUCCESS', { newPostEntitiesByCid, isInitialPaginatedDataLoadDone: isLastPage })

      if (isLastPage) {
        useSurfacePostActionStore().recalibrate()
        // Publish the comment or reaction draft created when the user is not logged in
        const surfaceGuestStore = useSurfaceGuestStore()
        if (surfaceGuestStore.shouldEnableAnonymousAttribution) {
          void surfaceGuestStore.publishCommentOrReactionDraft()
        }
      }
    },
    refreshPosts({ dispatch }, payload: JsonAPIResponse<Wish>): void {
      const { data, meta } = payload
      void dispatch('fetchPostsSuccess', {
        data,
        isFirstPage: meta?.isFirstPage,
        isLastPage: meta?.next == null || meta?.next === '',
        shouldResetState: false,
      })
    },
    createPendingPost({ commit }, { postAttributes }): void {
      commit('ADD_PENDING_POST', postAttributes)
    },
    /**
     * Start a new post (either by the pink button or double-clicking)
     * Similar to createPost, but createPost creates the post and submits it immediately
     * startNewPost will creates the post locally with empty content and set it as being
     * edited.
     * @param context - vuex action context
     * @param payload - vuex payload
     * @param payload.attributes - attributes of the post to be created
     */
    startNewPost(
      { rootState, rootGetters, state, commit, dispatch, getters },
      { attributes }: { attributes: PostAttributes } = {
        attributes: { wall_section_id: undefined, file: null },
      },
    ): { cid: Cid } | undefined {
      if (rootGetters['composerModalAlert/shouldShow']) {
        return
      }

      // the app has implemented a native new post button which calls "startNewPost" when tapped together with the new post modal
      // in map format, we should only open the native location picker when the button is tapped (same behavior as the add post button in surface_map_location_picker.vue)

      // if there is no pre-filled attribute, it means the action is triggered from the add post button
      const triggeredFromNewPostButton = !attributes || Object.keys(attributes).length === 0

      if (rootGetters.isApp && appCan('newPostModal') && rootGetters.isMap && triggeredFromNewPostButton) {
        void useSurfaceMapStore().startPickingLocation()
        return
      }
      let newPostAttributes = { ...attributes }
      if (rootGetters.canUseSections && !newPostAttributes.wall_section_id) {
        newPostAttributes.wall_section_id = getters.assignedWallSectionId
      }
      // Add hashid
      if (newPostAttributes.wall_section_id != null) {
        newPostAttributes.wall_section_hashid = useSurfaceSectionsStore().getSectionById(
          newPostAttributes.wall_section_id,
        )?.hashid
      }
      useSurfaceDraftsStore().setDraftNumber(getters.postBeingEditedCid)

      newPostAttributes = usePostBuilder().buildPost(newPostAttributes)

      // We skip this for location selector V2 because we want to start a new post always
      if (rootGetters.isMap && newPostAttributes.location_point == null && !isAppUsing('locationSelectorV2')) {
        dispatch('savePost', { attributes: newPostAttributes })
      } else {
        commit('START_NEW_POST', newPostAttributes)
        // `startNewPost` is more deliberate than `startNewDraft` in that we want to edit the post immediately.
        useSurfaceDraftsStore().updateActiveDraft({ ...getters.postBeingEdited, shouldStartEditing: true })
      }

      // usePostBuilder().buildPost() always returns CID
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { cid: newPostAttributes.cid! }
    },
    /**
     * Create post immediately. Example scenarios: paste/drop to post and in composer mode
     * @param context - vuex action context
     * @param payload - vuex payload
     * @param payload.attributes - attributes of the post to be created
     */
    createPost(
      context: ActionContext<PostState, RootState>,
      { attributes } = { attributes: { wall_section_id: null, file: null } },
    ): void {
      // when a new post is to be created there are a few scenarios:
      // 1. We have all the data we need
      // 2. We have a pending file upload
      // 3. We have a pending section id
      // 4. Both 2 and 3

      const { commit, dispatch, rootState, rootGetters } = context

      let newPostAttributes = addCidIfAbsent(attributes)
      const usableSections = useSurfaceSectionsStore().usableSections

      // if the post is missing a section and there are more than one sections,
      // (scenario 3 or 4)
      if (rootGetters.canUseSections && !newPostAttributes.wall_section_id && usableSections.length > 1) {
        // add post to the pending list if posts are grouped by section
        // (user will be asked to pick a section for the post)
        if (rootGetters.isSectioned) {
          commit('ADD_PENDING_POST', newPostAttributes)
          return
        }

        // or assign the post to the default section
        const defaultSectionId = useSurfaceSectionsStore().defaultSectionId
        newPostAttributes.wall_section_id = defaultSectionId
      }

      // if there is only one section, use that section
      if (rootGetters.canUseSections && !newPostAttributes.wall_section_id && usableSections.length === 1) {
        newPostAttributes.wall_section_id = usableSections[0].id
      }

      // if there is a pending file, add to pending
      // (scenario 2)
      if ('file' in newPostAttributes && newPostAttributes.file != null) {
        commit('ADD_PENDING_POST', newPostAttributes)
        return
      }

      // otherwise, create a new post
      // (scenario 1)
      newPostAttributes = usePostBuilder().buildPost(newPostAttributes)
      savePost(context, newPostAttributes)
      dispatch('markNewestUserCreatedPost', newPostAttributes.cid)
    },
    markNewestUserCreatedPost({ commit, dispatch, getters, rootGetters }, postCid): void {
      commit('MARK_NEWEST_POST', postCid)
      const postWithPopupBeingOpenedCid = useSurfaceMapStore().postWithPopupBeingOpenedCid
      const isAPopupOpened = postWithPopupBeingOpenedCid && getters.isPostAlive(postWithPopupBeingOpenedCid)
      if (rootGetters.isMap && !isAPopupOpened) {
        useSurfaceMapStore().openPostPopup({ postCid })
      }
    },
    /**
     * Used in composer mode (post being edited in the composer instead inside the
     * post itself real-timely)
     * The action is used when user press save after finish editing
     * @param context - vuex action context
     * @param payload - vuex payload
     * @param payload.attributes - attributes of the post to be created
     */
    async savePost(
      context: ActionContext<PostState, RootState>,
      { attributes, updatePostArgs }: { attributes: Post; updatePostArgs?: UpdatePostArgs },
    ): Promise<Post> {
      if (device.app) {
        // for backwards compatibility with older mobile app versions
        const postSaved = await useSurfaceGuestStore().savePostAsGuestIfNecessary({ attributes, updatePostArgs })
        if (postSaved !== null) return postSaved
      }

      const { state, getters, commit, dispatch, rootState, rootGetters } = context
      let postToBeSaved: Post
      let existingPost: PostAttributes | null = null
      if (attributes.cid == null || getters.isExistingPost(attributes.cid) === false) {
        // if new post -> post will be created
        // If sort_index presents, it means the post is being added between two existing posts.
        // We ensure no collision by recomputing the sort_index.
        if (attributes.sort_index != null) {
          attributes.sort_index = recomputeSortIndex(
            attributes.sort_index,
            getters.savedPostsSortedBySortIndex,
            rootGetters.isTimelineV1,
          )
        } else {
          // Set to undefined to be automatically assigned a sort_index depending on new post position setting.
          attributes.sort_index = undefined
        }

        postToBeSaved = usePostBuilder().buildPost(attributes)
        updatePostArgs ||= {}
        savePost(context, { ...postToBeSaved, ...updatePostArgs })
        void dispatch('markNewestUserCreatedPost', postToBeSaved.cid)
      } else {
        existingPost = state.postEntitiesByCid[attributes.cid]
        // post is being edited ->  post will be updated. Make sure we use the post's ID to update
        postToBeSaved = { ...existingPost, ...attributes, id: existingPost.id }

        // ensure local state for post.published is correctly updated
        // this is because the UI makes certain assumptions about what to display based on post.published
        // post.published is ultimately set server-side, but we want to optimistically update the client here
        // so it displays the right UI immediately instead of waiting for the server.
        if (rootGetters.canIPostWithoutWaitingForApproval === false || isPostScheduled(postToBeSaved)) {
          // updating a post need to be re-approved
          postToBeSaved.published = false
        } else {
          postToBeSaved.published = true
        }

        savePost(context, postToBeSaved)
      }
      void useSurfaceDraftsStore().removeDraft(postToBeSaved.cid)
      if (postToBeSaved.cid === state.postBeingEditedCid) {
        void dispatch('stopEditingPost')
      }

      // in map format, we should pan to the new location if the post's location changed
      // For now just update mostRecentlyChangedGeolocationPostCid and there will be a watcher inside surface_map_google_marker to handle the panning
      if (rootGetters.isMap) {
        if (
          existingPost == null ||
          existingPost.location_point == null ||
          postToBeSaved.location_point?.latitude !== existingPost.location_point.latitude ||
          postToBeSaved.location_point?.longitude !== existingPost.location_point.longitude
        ) {
          commit('SET_MOST_RECENTLY_CHANGED_GEOLOCATION_POST_CID', postToBeSaved.cid)
        }
      }
      return postToBeSaved
    },
    savePostAttributes(context, postAttributes): void {
      savePost(context, postAttributes)
    },
    /* ------------
     * POST MODIFICATION OPERATIONS
     * ------------ */
    startEditingPost({ commit, dispatch, getters, rootGetters }, { postCid }): void {
      if (rootGetters['composerModalAlert/shouldShow']) {
        return
      }
      useSurfaceDraftsStore().setDraftNumber(getters.postBeingEditedCid)
      void dispatch('stopEditingPost')
      commit('START_EDITING_POST', postCid)
      if (useSurfaceDraftsStore().activeDraft == null) {
        useSurfaceDraftsStore().updateActiveDraft(getters.postBeingEdited)
      }
    },
    stopEditingPost({ commit, dispatch, state }): void {
      if (!state.postBeingEditedCid) return
      commit('STOP_EDITING_POST')
    },
    syncPostBeingEditedCidForMap({ dispatch, state, rootGetters }, { cid }: Post): void {
      // We need to do this so that on mobile, the postBeingEditedCid is updated
      // to the cid of the post that is just created and saved.
      // TODO: Make mobile map native, then remove this.
      if (rootGetters.isMap) {
        if (cid === state.postBeingEditedCid) {
          dispatch('startEditingPost', { postCid: cid })
        }
      }
    },
    resetMostRecentlyChangedGeolocationPostCid({ commit }): void {
      // in map format, we need to pan to a post whose location has just changed (handled by a watcher in surface_container_map.vue)
      // we need to reset mostRecentlyChangedGeolocationPostCid after panning so it can still pan when a post changes location twice in a row
      commit('SET_MOST_RECENTLY_CHANGED_GEOLOCATION_POST_CID', null)
    },
    changePostText(context, { postCid, attributes }): void {
      const { state, commit } = context
      commit('CHANGE_POST_TEXT', { postCid, attributes })
      const postToBeSaved = state.postEntitiesByCid[postCid]
      if (isEmpty(postToBeSaved)) {
        // Do nothing
        // We do not save empty post, but when the edit is over,
        // stopEditingPost will handle the empty post and delete it
      } else {
        savePost(context, postToBeSaved)
      }
    },
    completePostUpload(context, { file, status }): void {
      const { commit, dispatch, getters, rootState, rootGetters } = context
      let completedPostAttributes = getters.pendingPosts.find((post): boolean => post.file === file)
      commit('REMOVE_PENDING_POST', completedPostAttributes)
      if (status !== 'error' && status !== 'cancel') {
        completedPostAttributes = {
          ...completedPostAttributes,
          attachment: status,
        }
        completedPostAttributes = usePostBuilder().buildPost(completedPostAttributes)
        savePost(context, completedPostAttributes)
        dispatch('markNewestUserCreatedPost', completedPostAttributes.cid)
      }
    },
    updateAfterPostCreated(context, { postCid, gapSize }: { postCid: Cid; gapSize?: number | null }): void {
      const post = context.state.postEntitiesByCid[postCid]
      if (!post) {
        return
      }
      /*
        One of the following state transitions will occur:
        - New >> Creating >> UpdateWaiting
        - Creating >> UpdateWaiting >> UpdateWaiting
        - UpdateWaiting >> UpdateWaiting >> UpdateWaiting
        - Updating >> UpdateWaiting >> UpdateWaiting
        - Created >> DebouncingUpdate >> DebouncingUpdate
        - DebouncingUpdate >> DebouncingUpdate >> DebouncingUpdate

        Per https://github.com/padlet/mozart/pull/1561, removing one of these 2 lines will break it.
        On the other hand, this will debounce the save request appropriately and not create unnecessary requests.
      */
      savePost(context, { ...post, gap_size: gapSize })
      savePost(context, { ...post, gap_size: gapSize })
    },
    movedPost({ commit, dispatch }, { postCid, position }): void {
      commit('MOVED_POST', { postCid, position })
      dispatch('updateAfterPostCreated', { postCid })
    },
    updatePostOrder({ commit }, { postOrder }): void {
      commit('UPDATED_POST_ORDER', postOrder)
    },
    updatePostSection({ commit }, { postCid, sectionId }): void {
      commit('UPDATE_POST_SECTION', { sectionId, postCid })
    },
    updatePostSortIndex({ commit }, { postCid, sortIndex }): void {
      commit('UPDATE_POST_SORT_INDEX', { postCid, sortIndex })
    },
    sortedPost({ dispatch, getters }, { postCid, sortIndex, sectionId, previousCid, nextCid, to, gapSize }): void {
      dispatch('updatePostSortIndex', { postCid, sortIndex })
      dispatch('updatePostSection', { postCid, sectionId })
      dispatch('updateAfterPostCreated', { postCid, gapSize })
    },
    resizedPost({ commit, dispatch }, { postCid, size }): void {
      commit('RESIZED_POST', { postCid, size })
      dispatch('updateAfterPostCreated', { postCid })
    },

    // Post to delete may or may not be persisted yet.
    deletePost({ commit, getters, dispatch, rootGetters }, { post, postCid }): void {
      const { getPostByServerId: getById, getPostByCid: getByCid } = getters
      const postToDelete = post && post.id ? getById(post.id) : getByCid(postCid || (post && post.cid))
      if (postToDelete == null) return
      deletePost({ commit, dispatch, rootGetters }, postToDelete)
      void useSurfaceDraftsStore().removeDraft(postToDelete.cid)
      dispatch('removeConnectionsForPost', { postId: postToDelete.id }, { root: true })
    },
    approvePost({ state, commit }, { postCid }): void {
      commit('APPROVE_POST', { postCid })
      const wishHashid = state.postEntitiesByCid[postCid]?.hashid
      if (wishHashid != null) {
        void PadletApi.Wish.approve({ wishHashid })
      }
    },
    removePostColor({ commit, dispatch }, { postCid }): void {
      commit('REMOVE_POST_COLOR', { postCid })
      dispatch('updateAfterPostCreated', { postCid })
    },
    changePostColor({ commit, dispatch }, { postCid, newBgColor }): void {
      commit('CHANGE_POST_COLOR', { postCid, color: newBgColor })
      dispatch('updateAfterPostCreated', { postCid })
    },
    /**
     * Does not trigger delete post on server with PadletApi
     */
    removePost({ commit, dispatch, getters }, { id, cid }): void {
      const post = id != null ? getters.getPostByServerId(id) : getters.getPostByCid(cid)
      if (post == null) return
      void useSurfaceDraftsStore().removeDraft(post.cid)
      commit('DELETED_POST', post)
    },
    removePostToWaitForAutoModeration({ commit, dispatch, getters, rootState }, { id, cid }): void {
      const post = id != null ? getters.getPostByServerId(id) : getters.getPostByCid(cid)
      if (post == null) return
      if (post.author_id?.toString() === rootState?.user?.id?.toString()) return
      void useSurfaceDraftsStore().removeDraft(post.cid)
      commit('DELETED_POST_TO_WAIT_FOR_AUTO_MODERATION', post)
    },
    addPost({ commit, dispatch }, postAttributes): void {
      commit('CREATED_POST', postAttributes)
      useSurfaceUserContributorsStore().fetchUser(postAttributes.author_hashid ?? postAttributes.author_id)
    },
    async postPollVote(
      { dispatch },
      {
        wishId,
        pollId,
        pollChoiceIds,
      }: {
        wishId: Id
        pollId: Id
        pollChoiceIds: number[]
      },
    ): Promise<void> {
      try {
        const updatedWish = await PadletApi.Poll.vote({ wishId, pollId, pollChoiceIds })
        await dispatch('updatePostInStore', updatedWish)
      } catch (error) {
        useGlobalSnackbarStore().setSnackbar({
          notificationType: SnackbarNotificationType.error,
          message: __('Something went wrong. Please try again later.'),
        })
      }
    },
    /* ------------
     * REMOTE
     * ------------ */
    async editPollRemote(
      { dispatch, getters },
      {
        wish_id,
        wish_updated_at,
        wish_content_updated_at,
        poll,
      }: {
        wish_id: Id
        wish_updated_at: string
        wish_content_updated_at: string
        poll: Poll
      },
    ): Promise<void> {
      const oldPost: Post | null = getters.getPostByServerId(wish_id)
      if (
        oldPost == null ||
        oldPost.id !== wish_id ||
        oldPost?.wish_content?.attachment_props?.poll_id !== poll.poll_id
      )
        return
      const newPost = {
        ...oldPost,
        updated_at: wish_updated_at,
        content_updated_at: wish_content_updated_at,
        wish_content: {
          ...oldPost.wish_content,
          attachment_props: {
            ...oldPost.wish_content.attachment_props,
            ...poll,
          },
        },
      }
      await dispatch('updatePostInStore', newPost)
    },
    async newPollVoteRemote(
      { dispatch, getters, rootState },
      {
        wish_id,
        poll_id,
        wish_updated_at,
        wish_content_updated_at,
        poll_choice_ids,
        total_votes_by_poll_choice_id,
        voter_id,
      }: {
        wish_id: Id
        poll_id: Id
        wish_updated_at: string
        wish_content_updated_at: string
        poll_choice_ids: Id[]
        total_votes_by_poll_choice_id: Record<Id, number>
        voter_id: Id
      },
    ): Promise<void> {
      const oldPost: Post | null = getters.getPostByServerId(wish_id)
      if (oldPost == null || oldPost.id !== wish_id || oldPost?.wish_content?.attachment_props?.poll_id !== poll_id)
        return

      const isSameUser = voter_id === rootState.user.id
      const oldPollChoices: PollChoice[] = oldPost.wish_content.attachment_props.poll_choices
      const newPollChoices = oldPollChoices.map((pollChoice) => {
        if (poll_choice_ids.includes(pollChoice.poll_choice_id)) {
          return {
            ...pollChoice,
            total_votes: total_votes_by_poll_choice_id[pollChoice.poll_choice_id] ?? pollChoice.total_votes + 1,
            user_voted: isSameUser || pollChoice.user_voted,
          }
        }
        return pollChoice
      })
      const newPost = {
        ...oldPost,
        updated_at: wish_updated_at,
        content_updated_at: wish_content_updated_at,
        wish_content: {
          ...oldPost.wish_content,
          attachment_props: {
            ...oldPost.wish_content.attachment_props,
            poll_choices: newPollChoices,
            user_voted: isSameUser || oldPost.wish_content.attachment_props?.user_voted,
          },
        },
      }
      await dispatch('updatePostInStore', newPost)
    },
    newPostRemote({ dispatch, getters, rootGetters }, post): void {
      if (getters.canUserViewPost(post) || rootGetters.xUnpublishedPostPosition) {
        dispatch('addPost', post)
      }
    },
    async editPostRemote(
      { dispatch, getters, rootGetters, rootState, state },
      remotePost: PostAttributes & { shouldSkipFreshUpdateCheck?: boolean },
    ): Promise<void> {
      const { shouldSkipFreshUpdateCheck, ...post } = remotePost
      const oldPost = getters.getPostByServerId(post.id)
      if (oldPost == null) {
        // If old post doesn't exist, try to create it instead
        void dispatch('newPostRemote', post)
        return
      }

      const isFreshUpdate =
        oldPost.updated_at != null &&
        post.updated_at != null &&
        new Date(oldPost.updated_at) < new Date(post.updated_at)
      if (shouldSkipFreshUpdateCheck === false && !isFreshUpdate) return

      if (getters.canUserViewPost(post) === true || rootGetters.xUnpublishedPostPosition === true) {
        const updatedPost: Post = { ...oldPost, ...post }
        if (hasPollAttachment(oldPost as Post) && hasPollAttachment(post as Post)) {
          // if updated wish has a poll, updated wish will have poll vote status data from the
          // user that sent the edit_wish message not necessarily the current user. so check if users and polls are the same
          // if they are not dont update poll
          const oldPoll = getPollFromPost(oldPost as Post)
          const updatedPoll = getPollFromPost(post as Post)
          const sameVoter = rootState.user.id === getPollFromPost(post as Post)?.voter_id
          const samePoll = oldPoll?.poll_id === updatedPoll?.poll_id

          // we check if incoming poll update is the same as previous poll and if user is the same as previous poll
          // if the user is not the same skip poll update so other user does not overwrite current user's vote data
          // if poll is the same skip update so we dont block the case if poll was removed and then new poll was added in wish update
          const skipPollUpdate = !sameVoter && samePoll
          if (
            skipPollUpdate &&
            updatedPost.wish_content != null // for typing. hasPollAttachment already ensures this
          ) {
            updatedPost.wish_content.attachment_props = oldPost.wish_content.attachment_props
          }
        }

        await dispatch('updatePostInStore', updatedPost)

        // A user can be composing a post that has been updated remotely.
        // If post becomes published, the composer should reflect that.
        if (updatedPost.cid === state.postBeingEditedCid && !isPostPublished(oldPost) && isPostPublished(updatedPost)) {
          useSurfaceDraftsStore().updateActiveDraft({
            published: true,
            published_at: updatedPost.published_at,
            scheduled_at: null,
          })
        }
      } else {
        await dispatch('removePost', oldPost)
      }
    },
    editPostOrderRemote({ getters, dispatch }, postOrderInIds: Id[]): void {
      const getPostById = getters.getPostByServerId
      const postOrder: Cid[] = postOrderInIds
        .map((id: Id): Cid | null => {
          const post = getPostById(id)
          if (post) {
            return post.cid
          }
          return null
        })
        .filter((cid: Cid | null): boolean => {
          return cid !== null
        }) as Cid[]
      dispatch('updatePostOrder', { postOrder })
    },
    batchEditPostRemote(
      { dispatch },
      { wishes, is_backfilling_wall_section }: { wishes: PostAttributes[]; is_backfilling_wall_section?: boolean },
    ): void {
      // In `editPostRemote`, we check if the new post sent via realtime is actually
      // newer than the current post locally by comparing the updated_at timestamp.
      // However, when we backfill wall sections for the posts, we want to skip this
      // check because we'll just update the wall_section_id anyway. Later `editPostRemote`
      // calls can correct the updated_at timestamp.
      const shouldSkipFreshUpdateCheck = is_backfilling_wall_section === true
      wishes.forEach((post) => {
        dispatch('editPostRemote', { ...post, shouldSkipFreshUpdateCheck })
      })
    },
    deletePostRemote({ dispatch }, post): void {
      dispatch('removePost', post)
    },
    clearAllPosts({ getters, commit, dispatch, rootGetters }): void {
      // Don't delete new posts being drafted
      deleteAllPosts(
        { commit, dispatch, rootGetters },
        getters.postsArray.filter((post) => !isNew(post)),
      )
    },
    saveAttachmentCaption({ commit, rootGetters, dispatch, getters }, { caption }): void {
      if (rootGetters.xNewComposerModalPanel && !!getters.postBeingEditedCid) {
        // if the post modal is opened, save to the active draft
        useSurfaceDraftsStore().updateActiveDraft({ attachment_caption: caption })
      } else {
        commit('SAVE_CAPTION_AS_TITLE', caption)
      }
    },
    saveContent(context, { content }): void {
      const { commit, dispatch, state, getters, rootGetters } = context
      if (content.link) {
        if (rootGetters.format === 'chat') {
          commit('SAVE_ATTACHMENT_CHAT', content.link)
        } else if (rootGetters.xNewComposerModalPanel && !!getters.postBeingEditedCid) {
          // if the post modal is opened, save attachment to the current draft
          useSurfaceDraftsStore().updateActiveDraft({
            attachment: content.link,
            wish_content: {
              attachment_props: {
                url: content.link,
                alternative_text: content.altText,
              },
              is_processed: false,
            },
          })
        } else {
          const post = state.postBeingEditedCid && state.postEntitiesByCid[state.postBeingEditedCid]
          if (!post) {
            dispatch('startNewPost', { attributes: { subject: '📹', attachment: content.link } })
          } else {
            commit('SAVE_ATTACHMENT', content.link)
            if (isEmpty(post)) {
              // do nothing
            } else {
              savePost(context, post)
            }
          }
        }
      } else {
        commit('SAVE_FILE', content.file)
      }
    },
    removePostAttachment({ state, commit, dispatch, rootGetters }, { postCid } = { postCid: null }): void {
      commit('REMOVE_ATTACHMENT', postCid)
      const post = state.postEntitiesByCid[postCid || state.postBeingEditedCid]
      if (post && isEmpty(post)) {
        // do nothing
      } else {
        dispatch('updateAfterPostCreated', { postCid: postCid || state.postBeingEditedCid })
      }
    },
    changePostAttachment({ commit, dispatch }, { postCid, attributes }): void {
      commit('CHANGE_ATTACHMENT', { postCid, attributes })
      dispatch('updateAfterPostCreated', { postCid })
    },
    pickSection(context, { sectionId }): void {
      const { getters, rootState, rootGetters, commit, dispatch } = context
      const postsToRemoveFromPending: any[] = []
      getters.pendingPosts.forEach((postAttributes): void => {
        // if a pending post has a wall_section_id, do nothing
        // it is likely waiting for something else (a file to complete uploading)
        if (!postAttributes.wall_section_id) {
          postAttributes.wall_section_id = sectionId
          // if the post has a pending file upload, do nothing
          let finalPostAttributes: PostAttributes = {}
          if (postAttributes.file) {
            // do nothing
            // if the post is blank, start a new post
          } else if (!postAttributes.subject && !postAttributes.body && !postAttributes.attachment) {
            finalPostAttributes = usePostBuilder().buildPost(postAttributes)
            useSurfaceDraftsStore().setDraftNumber(getters.postBeingEditedCid)
            dispatch('stopEditingPost')
            commit('START_NEW_POST', finalPostAttributes)
            postsToRemoveFromPending.push(postAttributes)
            // otherwise, looks like the post has everything we need, create a new one
          } else {
            finalPostAttributes = usePostBuilder().buildPost(postAttributes)
            // for the new posting flow, we only create a new draft without saving the post
            if (!rootGetters.isApp) {
              // setting of shouldStartEditing should match what we use for handling objects dropped in vuecomposables/useDropToCreatePost.ts
              useSurfaceDraftsStore().startNewDraft({
                attributes: finalPostAttributes,
                shouldStartEditing: !useSurfaceDraftsStore().isAnyDraftActive,
              })
            } else {
              savePost(context, finalPostAttributes)
              dispatch('markNewestUserCreatedPost', finalPostAttributes.cid)
            }
            postsToRemoveFromPending.push(postAttributes)
          }
        }
      })
      // remove posts from pending list that were either added or created
      commit('REMOVE_PENDING_POSTS', { cids: postsToRemoveFromPending.map((post) => post.cid) })
    },
    cancelPickingSection({ rootGetters, commit }): void {
      // no section was picked, remove all pending posts that need a section
      if (rootGetters.canUseSections) {
        commit('REMOVE_ALL_PENDING_POSTS_WITHOUT_SECTION')
      }
    },
    rejectPostsWithFileSizeExceeded({ commit }, file): void {
      commit('REJECT_POSTS_WITH_FILE_SIZE_EXCEEDED', file)
    },
    updatePostInStore({ commit, getters }, post: Post): void {
      if (post.cid) {
        commit('UPDATED_POST', post)
      } else {
        const postInStore = getters.getPostByServerId(post.id)
        if (!postInStore && getters.isPostMarkedAsDeleted(post)) return
        commit('UPDATED_POST', addCidIfAbsent({ ...post, cid: postInStore?.cid }))
      }
    },
    updatePendingPost({ commit }, postAttributes: PostAttributes): void {
      commit('UPDATE_PENDING_POST', postAttributes)
    },
    removePendingPost({ commit }, post: Post): void {
      commit('REMOVE_PENDING_POST', post)
    },
    removePendingPosts({ commit }, { cids }): void {
      commit('REMOVE_PENDING_POSTS', { cids })
    },
    createdPostSuccess(context, post: Post & UpdatePostArgs): void {
      const { commit, dispatch, getters, rootGetters } = context
      if (getters.isPostMarkedAsDeleted(post)) return
      commit('SAVE_POST_ID', post)
      commit('UPDATED_POST', post)
      // Log that the user has created a post. We are doing this to see user journeys.
      let label: string | null = null
      if (useSurfaceStore().isSubmissionRequest) {
        label = 'submissionRequest'
      } else if (useSurfaceStore().isSectionBreakout) {
        label = 'sectionBreakout'
      }
      trackEvent('Surface', 'Created post', label, null, { wishId: post.id })
      dispatch('syncPostBeingEditedCidForMap', post)

      // in map format, user already picked a location for their post, we don't want location data from thr attachment makes the post jump around
      // => only set location from attachment if we are not in map format or if we are in map format but the location is not set
      // use "== null" to catch both null and undefined but still treat the number 0 differently
      if (!rootGetters.isMap || post?.location_point?.latitude == null || post?.location_point?.longitude == null) {
        dispatch('updatePostLatLongFromAttachment', post)
      }

      if (rootGetters.isSubmissionRequest === true) {
        void useSurfaceShareLinksStore().executeSubmissionRequestPostSubmitAction()
      }
    },
    async updatePostLatLongFromAttachment(context, post: Post): Promise<void> {
      const { dispatch, rootGetters } = context
      if (!post || !post.attachment) return

      const data = await getDisplayAttributes(post.attachment)
      if (data == null) return

      useSurfaceAttachmentsStore().updateLinkDisplayAttributes({ url: post.attachment, attributes: data })

      const latlong = data.metadata?.latlong
      if (latlong == null) return

      savePost(context, {
        ...post,
        location_point: {
          longitude: latlong[1],
          latitude: latlong[0],
        },
      })
    },
    updatedPostSuccess({ commit }, post: Post): void {
      commit('UPDATED_POST_SUCCESS', post)
    },
    async enqueueRequest(
      context,
      { post, customRequest }: { post: Post; customRequest: CustomSavePostRequest },
    ): Promise<any> {
      return await customSavePost(context, post, customRequest)
    },
  },
}

export default postModule
export type { PostState }
