// @file Vuex Store module for all things related to the posting on the map format
// that needs more than just post.ts module and also to rendering the map in the browser.
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { Cid, Id, LocationMenuPlaceInfo, PinDropSuggestion } from '@@/types'
import type { MapState, RootState } from '@@/vuexstore/surface/types'
import { cloneDeep } from 'lodash-es'
import type { Module } from 'vuex'

const mapModule: Module<MapState, RootState> = {
  namespaced: true,
  state: {
    map: null,
    mapDoesFitMarkersBounds: false,
    isDraggingLocationPickerPin: false,
    isDraggingPosts: false,
    pinDropSuggestions: null,
    pinDropLocation: null,
    isPickingLocation: false,
    postWithPopupBeingOpenedCid: null,
    postWithLocationBeingEditedCid: null,
    postWithMarkerHighlightedCid: null,
    sectionIdOfPostBeingAdded: null,
    overlappingMarkerSpiderfier: null,
    isClusteringEnabled: false,
    markerClusterer: null,
    isPreviewPanelShown: true,
    recentLocationMenuSearches: [],
  },
  getters: {
    mapDoesFitMarkersBounds: (state): boolean => state.mapDoesFitMarkersBounds,
    map: (state): google.maps.Map | null => state.map,
    pinDropSuggestions: (state): PinDropSuggestion[] | null => state.pinDropSuggestions,
    pinDropLocation: (state): google.maps.LatLng | null => state.pinDropLocation,
    isPickingLocation: (state): boolean => state.isPickingLocation,
    postWithPopupBeingOpenedCid: (state): Cid | null => state.postWithPopupBeingOpenedCid,
    postWithLocationBeingEditedCid: (state): Cid | null => state.postWithLocationBeingEditedCid,
    postWithMarkerHighlightedCid: (state): Cid | null => state.postWithMarkerHighlightedCid,
    sectionIdOfPostBeingAdded: (state): Id | null => state.sectionIdOfPostBeingAdded,
    overlappingMarkerSpiderfier: (state): any => state.overlappingMarkerSpiderfier,
    markerClusterer: (state): any => (state.isClusteringEnabled ? state.markerClusterer : null),
    isClusteringEnabled: (state): boolean => state.isClusteringEnabled,
    offsetForMapPopup: (state, getters, rootState, rootGetters): any => {
      // We are still using the old layout in embedded padlets, so we need to use the old measures.
      if (rootState.isEmbedded) {
        return {
          top: rootState.headerHeight + 24 || 160, // space for the surface header + post action menu
          bottom: rootState.isApp ? 160 : 80, // enough space for the marker and the popup tip
          left: 20, // little empty space on the left
          right: 60, // space on the right for the controls: FAB, zoom and reset viewport
        }
      }

      const desktopSidebarWidth = 72
      const desktopMapControlHeight = 32 + 20 // 32px for the map control, 20px for its bottom margin
      const mobileBottomBarHeight = 52
      const mobilePostListHeight = 100 + 8 // 8px for its bottom margin
      const mobileMapControlWidth = 32 + 16 // 32px for the map control, 16px for its left margin
      const toolTipAndMarkerHeight = 60
      const padding = 24 // additional padding so the popup won't touch the edges of the map
      const isRtl = rootGetters.dir === 'rtl'
      return {
        top: rootGetters.headerHeight + padding,
        bottom: rootGetters.isApp
          ? mobilePostListHeight + toolTipAndMarkerHeight + padding
          : useWindowSizeStore().isSmallerThanTabletLandscape
          ? toolTipAndMarkerHeight + padding - mobileBottomBarHeight / 2 - mobilePostListHeight / 2
          : desktopMapControlHeight + toolTipAndMarkerHeight + padding,
        [isRtl ? 'right' : 'left']: useWindowSizeStore().isSmallerThanTabletLandscape
          ? mobileMapControlWidth + padding
          : padding,
        [isRtl ? 'left' : 'right']: useWindowSizeStore().isSmallerThanTabletLandscape
          ? padding
          : desktopSidebarWidth + padding,
      }
    },
    isPreviewPanelShown: (state): boolean => state.isPreviewPanelShown,
    recentLocationMenuSearches: (state): LocationMenuPlaceInfo[] => state.recentLocationMenuSearches.reverse(),
    isDraggingLocationPickerPin: (state): boolean => state.isDraggingLocationPickerPin,
    isDraggingPosts: (state): boolean => state.isDraggingPosts,
  },
  mutations: {
    ENABLE_MAP_FITTING_MARKERS_BOUNDS(state): void {
      state.mapDoesFitMarkersBounds = true
    },
    DISABLE_MAP_FITTING_MARKERS_BOUNDS(state): void {
      state.mapDoesFitMarkersBounds = false
    },
    SET_MAP_BEING_USED(state, map): void {
      state.map = map
    },
    SET_MAP_PIN_DROP_SUGGESTIONS(state, suggestions): void {
      state.pinDropSuggestions = suggestions
    },
    SET_MAP_PIN_DROP_LOCATION(state, location): void {
      state.pinDropLocation = location
    },
    SHOW_PICK_LOCATION_PANEL(state): void {
      state.isPickingLocation = true
      state.postWithPopupBeingOpenedCid = null
    },
    HIDE_PICK_LOCATION_PANEL(state): void {
      state.isPickingLocation = false
    },
    START_PICKING_NEW_LOCATION(state, postCid): void {
      state.postWithLocationBeingEditedCid = postCid
    },
    STOP_PICKING_NEW_LOCATION(state): void {
      state.postWithLocationBeingEditedCid = null
    },
    SET_OVERLAPPING_MARKER_SPIDERFIER(state, { spiderfier, infoWindow }): void {
      state.overlappingMarkerSpiderfier = spiderfier
    },
    SET_MARKER_CLUSTERER(state, { clusterer }): void {
      state.markerClusterer = clusterer
    },
    OPEN_POST_POPUP(state, postCid): void {
      state.postWithPopupBeingOpenedCid = postCid
    },
    CLOSE_POST_POPUP(state): void {
      state.postWithPopupBeingOpenedCid = null
    },
    HIGHLIGHT_POST_MARKER(state, postCid): void {
      state.postWithMarkerHighlightedCid = postCid
    },
    SET_SECTION_ID_OF_POST_BEING_ADDED(state, sectionId: Id | null): void {
      state.sectionIdOfPostBeingAdded = sectionId
    },
    TOGGLE_MAP_PREVIEW_PANEL(state): void {
      state.isPreviewPanelShown = !state.isPreviewPanelShown
    },
    SET_RECENT_LOCATION_MENU_SEARCHES(state, newPlaces: LocationMenuPlaceInfo[]): void {
      // need cloneDeep because newLocations is an array of objects
      state.recentLocationMenuSearches = cloneDeep(newPlaces)
    },
    START_DRAGGING_LOCATION_PICKER_PIN(state): void {
      state.isDraggingLocationPickerPin = true
    },
    STOP_DRAGGING_LOCATION_PICKER_PIN(state): void {
      state.isDraggingLocationPickerPin = false
    },
    START_DRAGGING_POSTS(state): void {
      state.isDraggingPosts = true
    },
    STOP_DRAGGING_POSTS(state): void {
      state.isDraggingPosts = false
    },
  },
  actions: {
    enableMapFittingMarkersBounds({ commit }): void {
      commit('ENABLE_MAP_FITTING_MARKERS_BOUNDS', true)
    },
    disableMapFittingMarkersBounds({ commit }): void {
      commit('DISABLE_MAP_FITTING_MARKERS_BOUNDS', false)
    },
    setMapBeingUsed({ commit }, { map }): void {
      commit('SET_MAP_BEING_USED', map)
    },
    setMapPinDropSuggestions({ commit }, { suggestions, location }): void {
      commit('SET_MAP_PIN_DROP_LOCATION', location)
      commit('SET_MAP_PIN_DROP_SUGGESTIONS', suggestions)
    },
    clearMapPinDropSuggestions({ commit }): void {
      commit('SET_MAP_PIN_DROP_LOCATION', null)
      commit('SET_MAP_PIN_DROP_SUGGESTIONS', null)
    },
    startPickingLocation(
      { commit },
      { postCid, sectionId }: { postCid: Cid | null; sectionId: Id | null } = { postCid: null, sectionId: null },
    ): void {
      commit('SHOW_PICK_LOCATION_PANEL')
      commit('SET_SECTION_ID_OF_POST_BEING_ADDED', sectionId)
      if (postCid != null) commit('START_PICKING_NEW_LOCATION', postCid)
    },
    startPickingNewLocation({ commit }, { postCid }): void {
      commit('START_PICKING_NEW_LOCATION', postCid)
    },
    stopPickingNewLocation({ commit }): void {
      commit('STOP_PICKING_NEW_LOCATION')
    },
    hidePickLocationPanel({ commit }): void {
      commit('HIDE_PICK_LOCATION_PANEL')
    },
    stopPickingLocation({ commit }): void {
      commit('HIDE_PICK_LOCATION_PANEL')
      commit('STOP_PICKING_NEW_LOCATION')
    },
    pickLocation({ state, dispatch, rootGetters }, { postCid, latLng, name }): void {
      if (!postCid && !state.postWithLocationBeingEditedCid) {
        dispatch('pickLocationAndCreateNewPost', { latLng, name })
      } else {
        dispatch('pickLocationAndUpdateLocation', { postCid, latLng, name })
      }
    },
    pickLocationAndCreateNewPost({ commit, dispatch, getters }, { latLng, name }): void {
      void dispatch(
        'post/startNewPost',
        {
          attributes: {
            locationPoint: {
              longitude: latLng.lng(),
              latitude: latLng.lat(),
            },
            subject: name,
            locationName: name,
            wall_section_id: getters.sectionIdOfPostBeingAdded,
          },
        },
        { root: true },
      )
      commit('SET_SECTION_ID_OF_POST_BEING_ADDED', null)

      // Delay hiding the panel to offload the frontend animation
      // Map have to fit, pan, create marker, create post.
      setTimeout((): void => {
        dispatch('stopPickingLocation')
      }, 500)
    },
    pickLocationAndUpdateLocation({ state, dispatch, rootGetters }, { postCid, latLng, name }) {
      const postToChangeCid = postCid || state.postWithLocationBeingEditedCid
      if (postToChangeCid) {
        const postWithLocationBeingEdited = rootGetters['post/getPostByCid'](postToChangeCid)
        const newLocationPoint = latLng
          ? {
              longitude: latLng.lng(),
              latitude: latLng.lat(),
            }
          : postWithLocationBeingEdited.location_point
        const newAttributes = {
          ...postWithLocationBeingEdited,
          location_point: newLocationPoint,
          location_name: name,
        }
        if (postWithLocationBeingEdited.location_name === postWithLocationBeingEdited.subject) {
          newAttributes.subject = name
        }
        dispatch('post/savePost', { attributes: newAttributes }, { root: true })
      }
      // Delay hiding the panel to offload the frontend animation
      // Map have to fit, pan, create marker, create post.
      setTimeout((): void => {
        dispatch('stopPickingLocation')
      }, 500)
    },
    setMarkerOverlayLayer({ commit }, { spiderfier, clusterer, infoWindow }): void {
      commit('SET_OVERLAPPING_MARKER_SPIDERFIER', { spiderfier, infoWindow })
      commit('SET_MARKER_CLUSTERER', { clusterer })
    },
    openPostPopup({ commit }, { postCid }): void {
      commit('OPEN_POST_POPUP', postCid)
    },
    closePostPopup({ commit }): void {
      commit('CLOSE_POST_POPUP')
    },
    highlightPostMarker({ commit }, { postCid }): void {
      commit('HIGHLIGHT_POST_MARKER', postCid)
    },
    toggleMapPreviewPanel({ commit }): void {
      commit('TOGGLE_MAP_PREVIEW_PANEL')
    },
    saveRecentlySearchedLocations({ commit }, { newPlace }: { newPlace: LocationMenuPlaceInfo }): void {
      const rawValue = safeLocalStorage.getItem('locationMenuRecentSearches')
      const currentPlaces: LocationMenuPlaceInfo[] = rawValue ? JSON.parse(rawValue) : []

      let newPlaces = currentPlaces.filter((place: LocationMenuPlaceInfo) => place.id !== newPlace.id)

      newPlaces.push(newPlace)
      newPlaces = newPlaces.slice(-10)

      safeLocalStorage.setItem('locationMenuRecentSearches', asciiSafeStringify(newPlaces))

      commit('SET_RECENT_LOCATION_MENU_SEARCHES', newPlaces)
    },
    syncRecentlySearchedLocationsFromStorage({ commit }): void {
      const rawValue = safeLocalStorage.getItem('locationMenuRecentSearches')
      const places: LocationMenuPlaceInfo[] = rawValue ? JSON.parse(rawValue) : []
      commit('SET_RECENT_LOCATION_MENU_SEARCHES', places)
    },
    startDraggingLocationPickerPin({ commit }): void {
      commit('START_DRAGGING_LOCATION_PICKER_PIN')
    },
    stopDraggingLocationPickerPin({ commit }): void {
      commit('STOP_DRAGGING_LOCATION_PICKER_PIN')
    },
    startDraggingPosts({ commit }): void {
      commit('START_DRAGGING_POSTS')
    },
    stopDraggingPosts({ commit }): void {
      commit('STOP_DRAGGING_POSTS')
    },
  },
}

export default mapModule
