/**
 * @file Snackbar-related text
 */
import { __ } from '@@/bits/intl'
import { reload } from '@@/bits/location'
import type { GlobalSnackbarNotification } from '@@/pinia/global_snackbar'

export const ACCESS_WITHDRAWN: GlobalSnackbarNotification = {
  message: __('You are not authorized to access this padlet anymore.'),
  actionText: __('Refresh'),
  actionTextActions: [() => reload()],
}

export const SAVE_CHANGES_FAILED: GlobalSnackbarNotification = {
  message: __("Sorry, we couldn't save your changes. Please refresh the page."),
  actionText: __('Refresh'),
  actionTextActions: [() => reload()],
}

export default {
  ACCESS_WITHDRAWN,
  SAVE_CHANGES_FAILED,
}
