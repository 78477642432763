// @file state and actions for the Grading panel
import { trackEvent } from '@@/bits/analytics'
import { captureException, captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { clearSearchParam, setSearchParam } from '@@/bits/location'
import { numberToHumanSize } from '@@/bits/numbers_helper'
import { FILE_TOO_LARGE_ERROR, UploadJob } from '@@/bits/uploader'
import { vSet } from '@@/bits/vue'
import {
  Gradable,
  GradeCategory,
  LmsSyncState,
  SnackbarNotificationType,
  UserSyncState,
  WallGradeCalculationMethods,
} from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import PadletApi from '@@/surface/padlet_api'
import type {
  CalculatedWallGrade,
  Grade,
  Id,
  JsonApiData,
  LmsPassbackResponse,
  SyncStateUserIds,
  WallGradingContextFile,
  WallGradingSettings,
} from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceGradingPanelStore = defineStore('surfaceGradingPanel', () => {
  const surfaceStore = useSurfaceStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  // Constants
  const MAX_FILE_UPLOAD_SIZE = 31457280 // 30MB

  // State
  const xSurfaceGradingPanel = ref(false)
  const uploadJob = ref<UploadJob | null>(null)
  const isUploadingContextFile = ref<boolean>(false)

  const isAwaitingGradingPanelApiResponse = ref<boolean>(true)
  const gradingSettings = ref<WallGradingSettings | null>(null)
  const grades = ref<Grade[]>([])
  const syncStateToUserId = ref<SyncStateUserIds | null>(null)
  const gradingCommentsByUserIdMap = ref<Record<string, string>>({})
  const isSuggestedCommentLoadingByUserIdMap = ref<Record<string, boolean>>({})
  const calculatedWallGrades = computed((): CalculatedWallGrade[] => {
    const userGradesMap = new Map<string, number[]>()

    // Populate the grades map
    grades.value.forEach((grade) => {
      if (grade.category !== GradeCategory.Score || grade.gradableType === Gradable.Wall) {
        return
      }

      const userId = grade.gradedUserId?.toString()
      const value = Number(grade.value)

      if (!userGradesMap.has(userId)) {
        userGradesMap.set(userId, [])
      }

      userGradesMap.get(userId)?.push(value)
    })

    // Clear the calculatedWallGrades array
    const calculatedWallGrades: CalculatedWallGrade[] = []

    // Populate calculatedWallGrades from the userGradesMap
    userGradesMap.forEach((userGrades, userId) => {
      const calculatedGrade = {
        authorId: Number(userId),
        grades: userGrades,
        calculatedGrade: 0,
      }

      const calculationMethod = gradingSettings.value?.gradeCalculationMethod
      if (calculationMethod === WallGradeCalculationMethods.Average) {
        calculatedGrade.calculatedGrade = userGrades.reduce((a, b) => a + b, 0) / userGrades.length
      } else if (calculationMethod === WallGradeCalculationMethods.Highest) {
        calculatedGrade.calculatedGrade = Math.max(...userGrades)
      }
      calculatedWallGrades.push(calculatedGrade)
    })

    return calculatedWallGrades
  })
  const lmsPassbackErrorMessage = ref<string | null>(null)

  // Getters
  const maxScore = computed<number>(() => gradingSettings.value?.maxScore ?? 0)
  const wallGradeCalculationMethod = computed<WallGradeCalculationMethods>(
    () => gradingSettings.value?.gradeCalculationMethod as WallGradeCalculationMethods,
  )
  const lmsPassbackEnabled = computed<boolean>(() => gradingSettings.value?.lmsPassbackEnabled ?? false)
  const showLmsPassbackToggle = computed<boolean>(() => gradingSettings.value?.showLmsPassback ?? false)
  const userSyncStateMap = computed<any>(() => {
    const syncMap = {}

    if (syncStateToUserId.value === null) {
      return syncMap
    }

    syncStateToUserId.value.failedUserIds?.forEach((userId) => {
      syncMap[userId] = UserSyncState.Failed
    })

    syncStateToUserId.value.successfulUserIds?.forEach((userId) => {
      syncMap[userId] = UserSyncState.Synced
    })
    syncStateToUserId.value.skippedUserIds?.forEach((userId) => {
      syncMap[userId] = UserSyncState.Skipped
    })

    return syncMap
  })

  const aiCustomization = computed<string>(() => gradingSettings.value?.aiCustomization ?? '')
  const contextFiles = computed<WallGradingContextFile[]>(() => gradingSettings.value?.contextFiles ?? [])

  function getPostGrade(postId: number): Grade | null {
    const postGrade = grades.value.find((grade) => grade.gradableType === Gradable.Wish && grade.gradableId === postId)
    return postGrade ?? null
  }

  function getWallGrade(authorId: Id): Grade | null {
    const wallGrade = grades.value.find(
      (grade) => grade.gradableType === Gradable.Wall && grade.gradedUserId === authorId,
    )
    return wallGrade ?? null
  }

  function getCalculatedWallGrade(authorId: Id): number | null {
    const calculatedWallGrade = calculatedWallGrades.value.find((grade) => grade.authorId === authorId)
    return calculatedWallGrade?.calculatedGrade ?? null
  }

  function updateLmsSyncStateOnGradeChange(authorId: Id): void {
    if (gradingSettings.value?.lmsSyncState !== LmsSyncState.AwaitingFirstSync) {
      gradingSettings.value = {
        ...gradingSettings.value,
        lmsSyncState: LmsSyncState.NeedsSync,
      }
    }
  }

  async function createPostGrade(
    payload: {
      wishId: string
      wishAuthorId: string
      score: number
    },
    authorRegistered: boolean,
  ): Promise<Grade | null> {
    try {
      const postGrade = {
        gradableType: Gradable.Wish,
        gradableId: Number(payload.wishId),
        gradedUserId: Number(payload.wishAuthorId),
        category: GradeCategory.Score,
        value: payload.score.toString(),
      }
      const grade = await PadletApi.Grades.create(postGrade)
      trackEvent('Surface Grading Panel', 'Add Wish Grade', surfaceStore.wallId, {
        wall_id: surfaceStore.wallId,
        wish_id: payload.wishId,
        value: payload.score.toString(),
      })
      grades.value.push(postGrade)

      if (authorRegistered) {
        updateLmsSyncStateOnGradeChange(postGrade.gradedUserId)
      }
      populateStudentGradingCommentState()
      return grade
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating grade'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      return null
    }
  }

  function populateStudentGradingCommentState(): void {
    const userIds = new Set<string>()

    // Populate the grades map
    // Populate the user IDs set
    grades.value.forEach((grade) => {
      const userId = grade.gradedUserId?.toString()

      // Add the user ID to the set
      if (userId != null && !userIds.has(userId)) {
        userIds.add(userId)
      }
    })

    userIds.forEach((userId) => {
      vSet(gradingCommentsByUserIdMap.value, userId, getWallGrade(Number(userId))?.value ?? '')
      vSet(isSuggestedCommentLoadingByUserIdMap.value, userId, false)
    })
  }

  async function createWallGrade(
    payload: {
      wallId: string
      gradedUserId: string
      comment: string
    },
    authorRegistered: boolean,
  ): Promise<Grade | null> {
    try {
      const wallGrade = {
        gradableType: Gradable.Wall,
        gradableId: Number(payload.wallId),
        gradedUserId: Number(payload.gradedUserId),
        category: GradeCategory.Comment,
        value: payload.comment,
      }
      const grade = await PadletApi.Grades.create(wallGrade)
      trackEvent('Surface Grading Panel', 'Add Wall Grade', surfaceStore.wallId, {
        wall_id: surfaceStore.wallId,
        comment: payload.comment,
      })
      grades.value.push(wallGrade)
      if (authorRegistered) {
        updateLmsSyncStateOnGradeChange(wallGrade.gradedUserId)
      }
      populateStudentGradingCommentState()
      return grade
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error creating grade'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      return null
    }
  }

  async function createSuggestedComment(gradedUserId: string): Promise<string | null> {
    try {
      const suggestedCommentGradePayload = {
        wallId: surfaceStore.wallId,
        gradedUserId: Number(gradedUserId),
      }
      const comment = await PadletApi.AiSuggestedComments.create(suggestedCommentGradePayload)
      return comment
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error suggesting overall feedback with AI. Please try again later.'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      return null
    }
  }

  async function createSuggestedAiCustomization(): Promise<string | null> {
    try {
      const suggestedAiCustomizationPayload = {
        wallId: surfaceStore.wallId,
      }
      const aiCustomization = await PadletApi.AiSuggestedCustomization.create(suggestedAiCustomizationPayload)
      return aiCustomization
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error suggesting customization with AI. Please try again later.'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      return null
    }
  }

  async function updateGrade(grade: Grade, authorRegistered: boolean): Promise<Grade | null> {
    try {
      grades.value = grades.value.filter(
        (currentGrade) =>
          currentGrade.gradableType !== grade.gradableType ||
          currentGrade.gradableId !== grade.gradableId ||
          currentGrade.gradedUserId !== grade.gradedUserId,
      )
      grades.value.push(grade)
      if (authorRegistered) {
        updateLmsSyncStateOnGradeChange(grade.gradedUserId)
      }
      return await PadletApi.Grades.update(grade)
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error updating grade'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
      return null
    }
  }

  function updateGradingComment(gradedStudentUserId: string, newComment: string): void {
    vSet(gradingCommentsByUserIdMap.value, gradedStudentUserId, newComment)
  }

  function updateIsSuggestedCommentLoading(gradedStudentUserId: string, isSuggestedCommentLoading: boolean): void {
    vSet(isSuggestedCommentLoadingByUserIdMap.value, gradedStudentUserId, isSuggestedCommentLoading)
  }

  async function deleteGrade(grade: Grade, authorRegistered): Promise<void> {
    try {
      const gradeApiResponse = await PadletApi.Grades.delete(grade)

      grades.value = grades.value.filter(
        (currentGrade) =>
          currentGrade.gradableType !== grade.gradableType ||
          currentGrade.gradableId !== grade.gradableId ||
          currentGrade.gradedUserId !== grade.gradedUserId,
      )

      if (authorRegistered != null) {
        updateLmsSyncStateOnGradeChange(grade.gradedUserId)
      }
      return gradeApiResponse
    } catch (e) {
      captureException(e)
      globalSnackbarStore.setSnackbar({
        message: __('Error deleting grade'),
        notificationType: SnackbarNotificationType.error,
        timeout: 8000,
      })
    }
  }
  const lmsSyncState = computed<LmsSyncState>(() => {
    return gradingSettings.value?.lmsSyncState ?? LmsSyncState.AwaitingFirstSync
  })

  const lastSyncTime = computed<string>(() => {
    if (gradingSettings.value?.lmsLastSynced !== undefined) {
      // Localize time in the format MM-DD-YY, HH:II AM/PM
      const date = new Date(gradingSettings.value.lmsLastSynced)
      const ten = function (i: number): string {
        return (i < 10 ? '0' : '') + i.toString()
      }
      const YY = date.getFullYear().toString().slice(-2)
      const MM = ten(date.getMonth() + 1)
      const DD = ten(date.getDate())
      const HH = date.getHours()
      const II = ten(date.getMinutes())
      const p = date.getHours() < 12 ? 'AM' : 'PM'
      return `${MM}-${DD}-${YY}, ${HH}:${II} ${p}`
    }

    return __('Never')
  })

  const syncMessage = computed<string>(() => {
    switch (lmsSyncState.value) {
      case LmsSyncState.Loading:
        return __('Syncing with LMS')
      case LmsSyncState.AwaitingFirstSync:
        return __('Not Synced')
      case LmsSyncState.Synced: {
        return __('Last synced: %{lastSynced}', { lastSynced: lastSyncTime.value })
      }
      case LmsSyncState.NeedsSync:
        return (
          lmsPassbackErrorMessage.value ??
          __('Last synced: %{lastSynced}', {
            lastSynced: lastSyncTime.value,
          })
        )
      case LmsSyncState.Failed:
        return lmsPassbackErrorMessage.value ?? __('Failed to sync with LMS')
      default:
        return __('Syncing with LMS')
    }
  })

  // Actions
  async function initialize(): Promise<void> {
    isAwaitingGradingPanelApiResponse.value = true

    try {
      const wallId = surfaceStore.wallId
      if (useSurfacePermissionsStore().canIAdminister) {
        const [wallGradingSettingsResponse, gradesResponse, userSyncStateResponse] = await Promise.all([
          PadletApi.WallGradingSettings.fetch(wallId),
          PadletApi.Grades.fetch(wallId),
          PadletApi.LmsPassback.fetchUserSyncState(wallId),
        ])
        grades.value = gradesResponse
        gradingSettings.value = wallGradingSettingsResponse as WallGradingSettings
        syncStateToUserId.value = userSyncStateResponse as SyncStateUserIds
        if (gradingSettings.value.lmsSyncErrorMessage !== '') {
          lmsPassbackErrorMessage.value = gradingSettings.value.lmsSyncErrorMessage ?? null
        }
      } else if (surfaceStore.user.id != null) {
        const [wallGradingSettingsResponse, gradesResponse] = await Promise.all([
          PadletApi.WallGradingSettings.fetch(wallId),
          PadletApi.Grades.fetch(wallId, surfaceStore.user.id),
        ])
        gradingSettings.value = wallGradingSettingsResponse as WallGradingSettings
        grades.value = gradesResponse
      }
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceGradingPanelFetch' })
    }
    populateStudentGradingCommentState()

    isAwaitingGradingPanelApiResponse.value = false
  }

  function showSurfaceGradingPanel(): void {
    trackEvent('Surface Grading Panel', 'Open Surface Grading Panel', surfaceStore.wallId, {
      wall_id: surfaceStore.wallId,
    })
    xSurfaceGradingPanel.value = true
    setSearchParam('grading_panel_open', 'true')
  }

  function hideSurfaceGradingPanel(): void {
    xSurfaceGradingPanel.value = false
    clearSearchParam('grading_panel_open')
  }

  async function updateMaxScore(updatedMaxScore: number): Promise<void> {
    const response = await PadletApi.WallGradingSettings.update(surfaceStore.wallId, {
      maxScore: updatedMaxScore,
    })
    gradingSettings.value = response as WallGradingSettings
  }

  async function toggleLmsPassbackEnabled(): Promise<void> {
    trackEvent('Surface Grading Panel', 'Enable Lms Passback', surfaceStore.wallId, {
      wall_id: surfaceStore.wallId,
    })
    const response = await PadletApi.WallGradingSettings.update(surfaceStore.wallId, {
      lmsPassbackEnabled: !lmsPassbackEnabled.value,
    })
    gradingSettings.value = {
      ...{ showLmsPassback: showLmsPassbackToggle.value },
      ...(response as WallGradingSettings),
    }
  }

  async function updateGradeCalculationMethod(
    updatedGradeCalculationMethod: WallGradeCalculationMethods,
  ): Promise<void> {
    const response = await PadletApi.WallGradingSettings.update(surfaceStore.wallId, {
      gradeCalculationMethod: updatedGradeCalculationMethod,
    })
    gradingSettings.value = response as WallGradingSettings
  }

  function selectAndUploadFile(e: Event): void {
    isUploadingContextFile.value = true
    const file = (e.target as HTMLInputElement).files?.[0]
    if (file == null) {
      isUploadingContextFile.value = false
      return
    }

    uploadJob.value = new UploadJob(file, { maxFileSize: MAX_FILE_UPLOAD_SIZE })

    uploadJob.value.on('done', (response) => {
      uploadJob.value = null
      void addContextFile({ name: file.name, url: response.url })
      return response.url
    })

    uploadJob.value.on('error', (response) => {
      if (response.error === FILE_TOO_LARGE_ERROR) {
        globalSnackbarStore.setSnackbar({
          message: __('File is too large. Please upload a smaller file that is less than %{numberInHumanSize}.', {
            numberInHumanSize: numberToHumanSize(MAX_FILE_UPLOAD_SIZE),
          }),
          notificationType: SnackbarNotificationType.error,
          timeout: 8000,
        })
      } else {
        globalSnackbarStore.setSnackbar({
          message: __('Sorry, we encountered an error. Please try again.'),
          notificationType: SnackbarNotificationType.error,
          timeout: 8000,
        })
      }
      uploadJob.value = null
      isUploadingContextFile.value = false
    })

    uploadJob.value.perform()
  }

  async function addContextFile(file: WallGradingContextFile): Promise<void> {
    try {
      gradingSettings.value = { ...gradingSettings.value, contextFiles: [...contextFiles.value, file] }

      const response = await PadletApi.WallGradingSettings.update(surfaceStore.wallId, {
        contextFiles: gradingSettings.value.contextFiles,
      })
      gradingSettings.value = response as WallGradingSettings
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'Unable to add context files' })
    }
  }

  async function removeContextFile(fileUrl: string): Promise<void> {
    try {
      if (gradingSettings.value?.contextFiles == null) {
        return
      }
      gradingSettings.value.contextFiles = gradingSettings.value?.contextFiles?.filter((file) => file.url !== fileUrl)
      const response = await PadletApi.WallGradingSettings.update(surfaceStore.wallId, {
        contextFiles: gradingSettings.value.contextFiles,
      })
      gradingSettings.value = response as WallGradingSettings
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'Unable to remove context file' })
    }
  }

  async function deleteAllContextFiles(): Promise<void> {
    try {
      if (contextFiles.value.length === 0) {
        return
      }
      await PadletApi.WallGradingSettings.deleteContextFiles(surfaceStore.wallId)
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'Unable to delete all context files' })
    }
  }

  async function updateAiCustomization(updatedAiCustomization: string): Promise<void> {
    try {
      if (gradingSettings.value != null) {
        gradingSettings.value.aiCustomization = updatedAiCustomization
      } else {
        gradingSettings.value = { aiCustomization: updatedAiCustomization }
      }

      const response = await PadletApi.WallGradingSettings.update(surfaceStore.wallId, {
        aiCustomization: updatedAiCustomization,
      })
      gradingSettings.value = response as WallGradingSettings
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'Unable to update aiCustomization' })
    }
  }

  async function syncGradeWithLms(): Promise<void> {
    try {
      lmsPassbackErrorMessage.value = null
      gradingSettings.value = { ...gradingSettings.value, lmsSyncState: LmsSyncState.Loading }
      syncStateToUserId.value = null

      const response = await PadletApi.LmsPassback.syncWithLms(surfaceStore.wallId)
      const data = (response.data as JsonApiData<LmsPassbackResponse>)?.attributes

      gradingSettings.value = { ...gradingSettings.value, ...data }
      if (data.lmsSyncState === LmsSyncState.Failed) {
        lmsPassbackErrorMessage.value = data.lmsSyncErrorMessage ?? null
      }
      syncStateToUserId.value = data.userSyncState
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'SurfaceGradingPanelFetch' })
      lmsPassbackErrorMessage.value = __('Failed to sync with LMS')
      gradingSettings.value = { ...gradingSettings.value, lmsSyncState: LmsSyncState.Failed }
    }
  }

  return {
    // State
    xSurfaceGradingPanel,
    isAwaitingGradingPanelApiResponse,
    grades,
    calculatedWallGrades,
    UserSyncState,
    lmsPassbackErrorMessage,
    isUploadingContextFile,
    gradingCommentsByUserIdMap,
    isSuggestedCommentLoadingByUserIdMap,

    // Getters
    maxScore,
    wallGradeCalculationMethod,
    lmsPassbackEnabled,
    aiCustomization,
    contextFiles,
    showLmsPassbackToggle,
    syncMessage,
    lmsSyncState,
    userSyncStateMap,

    // Actions
    initialize,
    addContextFile,
    removeContextFile,
    deleteAllContextFiles,
    updateMaxScore,
    toggleLmsPassbackEnabled,
    updateGradeCalculationMethod,
    updateAiCustomization,
    getPostGrade,
    getWallGrade,
    getCalculatedWallGrade,
    createPostGrade,
    createWallGrade,
    createSuggestedComment,
    createSuggestedAiCustomization,
    updateGrade,
    deleteGrade,
    updateGradingComment,
    updateIsSuggestedCommentLoading,
    showSurfaceGradingPanel,
    hideSurfaceGradingPanel,
    syncGradeWithLms,
    selectAndUploadFile,
  }
})
