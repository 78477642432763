// @file Builds a post with all necessary attributes
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { Post, PostAttributes, PostPositionMap } from '@@/types'
import { addCidIfAbsent } from '@@/vuexstore/helpers/post'
import { storeToRefs } from 'pinia'

const DEFAULT_WIDTH = 256

const getDistanceToTopOfWishList = (el: HTMLElement): number => {
  let element: HTMLElement | null = el
  let distance = 0
  while (element != null && element.id !== 'wish-list') {
    distance += element.offsetTop
    element = element.offsetParent as HTMLElement
  }
  return distance
}

export const usePostBuilder = (): {
  buildPost: typeof buildPost
} => {
  const { wallId, viz, wallHashid } = storeToRefs(useSurfaceStore())
  const { allPosts } = storeToRefs(useSurfacePostsStore())
  const { canIModerate, requireApprovalForPosts } = storeToRefs(useSurfacePermissionsStore())
  const { currentUser } = storeToRefs(useSurfaceCurrentUserStore())

  const getAutoPositionForCanvasPost = (): Required<PostPositionMap> => {
    // First new post is 16px below surface header.
    if (allPosts.value.length === 0) {
      return { left: 16, top: 16 }
    }

    const postsBottoms = allPosts.value.map((post) => {
      const postEl =
        post.cid != null
          ? document.querySelector(`[data-post-cid="${post.cid}"]`)
          : document.getElementById(`wish-${post.id ?? 'new'}`)
      const postBottom =
        postEl != null ? getDistanceToTopOfWishList(postEl as HTMLElement) + postEl.getBoundingClientRect().height : 0
      return postBottom
    })

    // Each new post is 8px below the previous one.
    const newPostBottom = Math.max(Math.max(...postsBottoms) + 8, 16)
    return { left: 16, top: newPostBottom }
  }

  const buildPost = (attr: PostAttributes = {}): Post => {
    if (currentUser.value == null) throw new Error('[buildPost] currentUser is null')

    const timeNow = new Date().toISOString()

    if (viz.value === 'free' && (attr.top == null || attr.left == null)) {
      attr = { ...attr, ...getAutoPositionForCanvasPost() }
    }
    return {
      cid: addCidIfAbsent(attr).cid,
      author_id: currentUser.value.id,
      author_hashid: currentUser.value.hashid,
      wall_id: wallId.value,
      wall_hashid: wallHashid.value,
      wall_section_id: attr.wall_section_id ?? attr.wallSectionId,
      wall_section_hashid: attr.wall_section_hashid,
      color: attr.color,
      subject: attr.subject ?? '',
      body: attr.body ?? '',
      attachment: attr.attachment ?? '',
      attachment_caption: attr.attachment_caption ?? null,
      location_point: attr.location_point ?? attr.locationPoint,
      location_name: attr.location_name ?? attr.locationName,
      wish_content: attr.wish_content,
      custom_properties: attr.custom_properties,
      width: DEFAULT_WIDTH,
      top: attr.top,
      left: attr.left,
      col_id: attr.col_id,
      row_id: attr.row_id,
      sort_index: attr.sort_index ?? attr.sortIndex,
      published: canIModerate.value || !requireApprovalForPosts.value,
      scheduled_at: attr.scheduled_at,
      created_at: timeNow,
      updated_at: timeNow,
    }
  }

  return { buildPost }
}
