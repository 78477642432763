// @file Composable that provides methods to manage the state of post composer modals.
import { __ } from '@@/bits/intl'
import { FILE_TOO_LARGE_ERROR, MAX_FILE_UPLOAD_BYTES_SIZE, UploadJob } from '@@/bits/uploader'
import { unboundedWatch } from '@@/bits/vue'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { useSurfaceStore } from '@@/pinia/surface'
import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, readonly, ref, shallowRef, watch } from 'vue'

// `import type DockableModalList ...` doesn't work in TypeScript files so we just
// declare the types of these `DockableModalList` exposed values here.
interface DockableModalListInstance {
  getElementRectInsideModal: (element: Element, id: string) => DOMRect | null
  minimizeModal: (id: string) => void
  minimizeAllModals: () => void
  dockModal: (id: string) => void
  expandModal: (id: string) => void
  restoreModalState: (id: string) => void
  closeModal: (id: string) => void
  hasDockedModal: boolean
  hasExpandedModal: boolean
}

export const usePostComposerModalStore = defineStore('postComposerModalStore', () => {
  const dockableModalList = shallowRef<DockableModalListInstance>()
  const isDockableModalListRegistered = computed(() => dockableModalList.value != null)
  const surfaceStore = useSurfaceStore()
  const globalAlertDialogStore = useGlobalAlertDialogStore()

  const registerDockableModalListInstance = (instance: Ref<DockableModalListInstance | undefined>): void => {
    const stop = watch(instance, (inst) => {
      if (inst === undefined) return
      dockableModalList.value = inst
      stop()
    })
  }

  const throwErrorIfNotExposed = (): void => {
    if (dockableModalList.value === undefined) {
      throw new Error(
        'No instance of `DockableModalList` found. Ensure `DockableModalList` is mounted and `registerDockableModalListInstance` has been called.',
      )
    }
  }

  // ********************************
  // ** Get element rect inside modal
  // ********************************

  const getElementRectInsideModal = (element: Element, id: string): DOMRect | null => {
    return dockableModalList.value?.getElementRectInsideModal(element, id) ?? null
  }

  // ****************************
  // ** Post composer modal state
  // ****************************

  const hasDockedComposerModal = computed<boolean>(() => {
    return dockableModalList.value?.hasDockedModal ?? false
  })

  const hasExpandedComposerModal = computed<boolean>(() => {
    return dockableModalList.value?.hasExpandedModal ?? false
  })

  const isSubmissionRequestComposerModalExpandedRef = ref(true)

  const isSubmissionRequestComposerModalExpanded = computed(() => {
    return isSubmissionRequestComposerModalExpandedRef.value
  })

  // ************************************
  // ** Post composer modal state methods
  // ************************************

  const minimizeComposerModal = (id: string): void => {
    throwErrorIfNotExposed()
    dockableModalList.value?.minimizeModal(id)
  }

  const minimizeAllComposerModals = (): void => {
    throwErrorIfNotExposed()
    dockableModalList.value?.minimizeAllModals()
  }

  const dockComposerModal = (id: string): void => {
    throwErrorIfNotExposed()
    dockableModalList.value?.dockModal(id)
  }

  const expandComposerModal = (id: string): void => {
    throwErrorIfNotExposed()
    dockableModalList.value?.expandModal(id)
  }

  const restoreComposerModalState = (id: string): void => {
    throwErrorIfNotExposed()
    dockableModalList.value?.restoreModalState(id)
  }

  const closeComposerModal = (id: string): void => {
    throwErrorIfNotExposed()
    dockableModalList.value?.closeModal(id)
  }

  const expandSubmissionRequestComposerModal = (): void => {
    isSubmissionRequestComposerModalExpandedRef.value = true
  }

  const collapseSubmissionRequestComposerModal = (): void => {
    isSubmissionRequestComposerModalExpandedRef.value = false
  }

  // ********************************
  // ** Is showing attachment preview
  // ********************************

  const isShowingAttachmentPreview = ref(false)

  const syncIsShowingAttachmentPreview = (isShowing: Ref<boolean>): void => {
    void unboundedWatch(
      isShowing,
      (value) => {
        isShowingAttachmentPreview.value = value
      },
      { immediate: true },
    )
  }

  // ******************
  // ** Scroll position
  // ******************

  const scrolledToTop = ref(false)
  const scrolledToBottom = ref(false)

  const updateScrollPosition = ({
    scrolledToTop: newScrolledToTop,
    scrolledToBottom: newScrolledToBottom,
  }: {
    scrolledToTop: boolean
    scrolledToBottom: boolean
  }): void => {
    scrolledToTop.value = newScrolledToTop
    scrolledToBottom.value = newScrolledToBottom
  }

  // ******************
  // ** Approval banner
  // ******************

  const xRequiresApprovalBanner = ref(false)

  const showRequiresApprovalBanner = (): void => {
    xRequiresApprovalBanner.value = true
  }

  const hideRequiresApprovalBanner = (): void => {
    xRequiresApprovalBanner.value = false
  }

  // ********************************
  // ** Edit thumbnail
  // ********************************

  const xEditThumbnailModal = ref(false)
  const isCustomThumbnailUploading = ref(false)
  const selectedCustomThumbnailUrl = ref<string | null>(null)
  const handleCustomThumbnailFileUpload = ({ files }: { files: File[] | FileList | undefined }): void => {
    if (files == null || files.length === 0) return

    if (files.length > 1) {
      globalAlertDialogStore.openAlertDialog({
        title: __('Multiple images added'),
        body: __('Can only update thumbnail with one image'),
      })
      return
    }

    const fileToUpload = files[0]
    const isImage = fileToUpload?.type?.startsWith('image/')
    if (!isImage) {
      globalAlertDialogStore.openAlertDialog({
        title: __('Invalid file added'),
        body: __('Can only update thumbnail with images'),
      })
      return
    }

    if (fileToUpload != null) {
      const uploadJob = new UploadJob(fileToUpload, { maxFileSize: MAX_FILE_UPLOAD_BYTES_SIZE })
      isCustomThumbnailUploading.value = true
      uploadJob.perform()
      uploadJob.on('done', (response) => {
        const newThumbnailUrl: string = response.url
        selectedCustomThumbnailUrl.value = newThumbnailUrl
        isCustomThumbnailUploading.value = false
      })
      uploadJob.on('error', (err) => {
        isCustomThumbnailUploading.value = false
        if (err.error === FILE_TOO_LARGE_ERROR && fileToUpload != null) {
          surfaceStore.showOverFileSizeModal({ file: fileToUpload })
        } else {
          globalAlertDialogStore.openAlertDialog({
            title: __('There was an error loading the file'),
            body: __('Sorry, there was an error uploading your file.'),
          })
        }
      })
      uploadJob.on('cancel', () => {
        isCustomThumbnailUploading.value = false
      })
    }
  }

  return {
    registerDockableModalListInstance,
    isDockableModalListRegistered,

    getElementRectInsideModal,

    // Surface Composer Modals
    hasDockedComposerModal,
    hasExpandedComposerModal,
    minimizeComposerModal,
    minimizeAllComposerModals,
    dockComposerModal,
    expandComposerModal,
    restoreComposerModalState,
    closeComposerModal,

    // Submission Request Composer Modal
    isSubmissionRequestComposerModalExpanded,
    expandSubmissionRequestComposerModal,
    collapseSubmissionRequestComposerModal,

    // Is showing attachment preview
    isShowingAttachmentPreview: readonly(isShowingAttachmentPreview),
    syncIsShowingAttachmentPreview,

    // Scroll position
    scrolledToTop: readonly(scrolledToTop),
    scrolledToBottom: readonly(scrolledToBottom),
    updateScrollPosition,

    // Approval banner
    xRequiresApprovalBanner: readonly(xRequiresApprovalBanner),
    showRequiresApprovalBanner,
    hideRequiresApprovalBanner,

    // Edit thumbnail
    xEditThumbnailModal,
    isCustomThumbnailUploading,
    selectedCustomThumbnailUrl,
    handleCustomThumbnailFileUpload,
  }
})
