// @file Content Picker store
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type { ContentPickerSource } from '@@/bits/content_picker'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getVuexStore } from '@@/bits/pinia'
import { safeLocalStorage } from '@@/bits/safe_storage'
import { vDel, vSet } from '@@/bits/vue'
import type {
  AiImageSearchResult,
  AudioSearchResult,
  ImageSearchResult,
  SearchResult,
  SearchResultDataCollectionMap,
  VideoSearchResult,
  WebSearchResult,
} from '@@/bits/web_search'
import { aiImageSearch, search as webSearch, searchByUrl, SearchFilters } from '@@/bits/web_search'
import type { DashboardSettingsState } from '@@/vuexstore/dashboard_settings'
import type { RootState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'
import { computed, reactive, ref, toRefs } from 'vue'

export interface ContentPickerSearchMode {
  key: SearchFilters
  text: string
  visible: boolean
}

interface ContentPickerState {
  xContentSourceMenu: boolean
  isHiddenContentSourceMenu: boolean
  isFileBrowserTriggered: boolean
  // context is used to determine the source of the content picker, e.g. if there are 2 content pickers in the same page, we can use this to differentiate them
  contentPickerContext: string
  activeContentSource: ContentPickerSource | null
  isOpenedFromSearch: boolean
  searchQueriesInProgress: string[]
  searchMoreQueriesInProgress: string[]
  error: any
  searchResults: Record<string, SearchResultDataCollectionMap>
  searchMoreUrls: Record<string, Record<SearchFilters, string>>
  searchFilter: SearchFilters | null
  defaultSearchMode: SearchFilters
  xSearchScreen: boolean
  xAiImagePreview: boolean
  searchQuery: string
  submittedSearchQuery: string
  searchSuggestions: string[]
  isInformingLastSearchNoResults: boolean
  // for link input, we still show the content picker but with a different placeholder text in the search bar and save the link as post attachment instead of searching
  isLinkInputMode: boolean
  xAiImageResults: boolean
  searchBarPlaceholder: string
}

const blankState: ContentPickerState = {
  xContentSourceMenu: false,
  isHiddenContentSourceMenu: false,
  isFileBrowserTriggered: false,
  contentPickerContext: '',
  activeContentSource: null,
  isOpenedFromSearch: false,
  searchQueriesInProgress: [],
  searchMoreQueriesInProgress: [],
  error: null,
  searchResults: {},
  searchMoreUrls: {},
  searchFilter: null,
  defaultSearchMode: SearchFilters.Web,
  xSearchScreen: false,
  xAiImagePreview: false,
  searchQuery: '',
  submittedSearchQuery: '',
  searchSuggestions: [],
  isInformingLastSearchNoResults: false,
  /* for link input, we still show the content picker but with a different placeholder text in the search bar and save the link as post attachment instead of searching */
  isLinkInputMode: false,
  xAiImageResults: false,
  searchBarPlaceholder: '',
}

const SAFE_STORAGE_KEY = 'contentPickerSearchSuggestions'

export const useContentPickerStore = defineStore('contentPicker', () => {
  const vuexStore = getVuexStore<RootState | DashboardSettingsState>()

  const state = reactive({
    ...blankState,
  })

  /** Getters */
  const activeContentPickerPanel = computed<ContentPickerSource | null>(() => state.activeContentSource)
  const xContentPickerPanel = computed<boolean>(() => !!state.activeContentSource)
  const xContentPicker = computed<boolean>(
    () => xContentPickerPanel.value || state.xContentSourceMenu || !!state.xSearchScreen,
  )
  const currentSearchResultsMap = computed<SearchResultDataCollectionMap>(() => state.searchResults[state.searchQuery])
  const isSearchQueryInProgress = computed(() => state.searchQueriesInProgress.length > 0)
  const isSearchMoreQueryInProgress = computed(() => state.searchMoreQueriesInProgress.length > 0)
  const isError = computed<boolean>(() => !!state.error)
  const searchQueryShowingResult = computed(() => {
    if (state.searchFilter == null) return ''
    const resultForSearchQuery: any[] = state.searchResults[state.searchQuery]?.[state.searchFilter] ?? []
    if (state.searchQuery === state.submittedSearchQuery) {
      return state.searchQuery
    }
    if (resultForSearchQuery.length > 0) {
      return state.searchQuery
    }
    return state.submittedSearchQuery
  })
  const searchSuggestions = computed(() => [...new Set(state.searchSuggestions.reverse())])
  const currentSearchResultsForFilter = computed<
    Array<WebSearchResult | AudioSearchResult | VideoSearchResult | ImageSearchResult | AiImageSearchResult>
  >(() => {
    if (state.searchFilter == null) return []
    const allResults = state.searchResults[searchQueryShowingResult.value]
    return allResults != null ? allResults[state.searchFilter] ?? [] : []
  })
  const currentSearchMoreUrlForFilter = computed<string>(() => {
    if (state.searchFilter == null) return ''
    return state.searchMoreUrls[searchQueryShowingResult.value]?.[state.searchFilter]
  })
  const isAiImageSearchMode = computed<boolean>(() => state.xAiImageResults || state.xAiImagePreview)
  const xSearchSuggestions = computed<boolean>(() => {
    /**
     * Cases where we show search suggestions:
     * - when user first open the screen and hasn't start a search.
     *    - currentSearchResultsForFilter is empty
     * - when user searched but deleted all the text/press clear button.
     *    - currentSearchResultsForFilter will reset when query becomes empty
     *    - @see setSearchQuery
     * - when user searched a query with no results and changed it immediately
     *    - we utilize isInformingLastSearchNoResults
     ***/
    if (state.searchSuggestions.length === 0) return false
    if (isSearchQueryInProgress.value) return false
    if (currentSearchResultsForFilter.value.length !== 0) return false
    if (state.isInformingLastSearchNoResults) return false
    return true
  })

  const isImageSearchMode = computed(() => {
    return (
      state.searchFilter === SearchFilters.Images ||
      state.searchFilter === SearchFilters.Gif ||
      state.searchFilter === SearchFilters.UnsplashImages ||
      state.searchFilter === SearchFilters.Stock ||
      isAiImageSearchMode.value
    )
  })

  /** Actions */

  function pickContentSource({ source }: { source: ContentPickerSource }): void {
    endLinkInputMode()
    state.activeContentSource = source
    state.xContentSourceMenu = false
  }

  function showContentSourceMenu({
    search,
    searchFilter: updatedSearchFilter,
    isHiddenMenu,
    contentPickerContext,
  }: {
    search?: boolean
    searchFilter?: SearchFilters
    isHiddenMenu?: boolean
    contentPickerContext?: string
  } = {}): void {
    state.xContentSourceMenu = true
    if (search) {
      state.isOpenedFromSearch = true
      state.xSearchScreen = true
      state.searchFilter = updatedSearchFilter ?? state.defaultSearchMode
    }
    if (isHiddenMenu) {
      state.isHiddenContentSourceMenu = true
    } else {
      state.isHiddenContentSourceMenu = false
    }
    if (contentPickerContext) {
      state.contentPickerContext = contentPickerContext
    }
  }

  function startLinkInputMode(): void {
    state.isLinkInputMode = true
  }

  function openAiImagePreview(): void {
    state.xAiImagePreview = true
  }

  function resetSearch(): void {
    let searchFilter = state.searchFilter
    if (searchFilter === SearchFilters.AiImages) {
      searchFilter = SearchFilters.Images
    }

    const newState = reactive({ ...blankState, searchFilter })

    Object.assign(state, newState)
  }

  function clearSearchFilter(): void {
    state.searchFilter = null
  }

  function setSearchBarPlaceholder(placeholder: string): void {
    state.searchBarPlaceholder = placeholder
  }

  function setSearchQuery({ query }: { query: string }): void {
    state.searchQuery = query
    state.isInformingLastSearchNoResults = false
    if (query.trim() === '') {
      state.submittedSearchQuery = ''
    }
  }

  const appendSearchSuggestions = ({ query }: { query: string }): void => {
    const stringValue = safeLocalStorage.getItem(SAFE_STORAGE_KEY)
    const currentSuggestions: string[] = stringValue ? JSON.parse(stringValue) : []
    let newSuggestions = currentSuggestions.filter((q) => q !== query)
    newSuggestions.push(query)
    newSuggestions = newSuggestions.slice(-10)
    safeLocalStorage.setItem(SAFE_STORAGE_KEY, asciiSafeStringify(newSuggestions))
    state.searchSuggestions = newSuggestions
  }

  const startSearch = ({ query, more }: { query: string; more?: boolean }): void => {
    state.submittedSearchQuery = state.searchQuery

    const shouldSearchMore = more ?? false
    if (shouldSearchMore) {
      state.searchMoreQueriesInProgress = [...state.searchMoreQueriesInProgress, state.searchQuery]
    } else {
      state.searchQueriesInProgress = [...state.searchQueriesInProgress, query]
    }
  }

  const handleSearchResult = ({
    result,
    filter,
    query,
    mode = 'update',
  }: {
    result: SearchResult
    filter: SearchFilters
    query: string
    mode?: 'update' | 'append'
  }): void => {
    let searchHits: any[] = []
    switch (filter) {
      case SearchFilters.Web:
        searchHits = result.data.webpages as WebSearchResult[]
        break
      case SearchFilters.Audio:
        searchHits = result.data.audio as AudioSearchResult[]
        break
      case SearchFilters.Videos:
        searchHits = result.data.videos as VideoSearchResult[]
        break
      case SearchFilters.UnsplashImages:
      case SearchFilters.Images:
      case SearchFilters.Gif:
      case SearchFilters.Stock:
        searchHits = result.data.images as ImageSearchResult[]
        break
      case SearchFilters.Stock:
        // not used anywhere
        break
      case SearchFilters.AiImages:
        searchHits = result.data.images as AiImageSearchResult[]
    }
    if (searchHits.length === 0) {
      state.isInformingLastSearchNoResults = true
    }

    if (mode === 'update') {
      vSet(state.searchResults, query, {
        ...state.searchResults[query],
        [filter]: searchHits,
      })
    } else {
      vSet(state.searchResults, query, {
        ...state.searchResults[query],
        [filter]: [...state.searchResults[query][filter], ...searchHits],
      })
    }
    if (result.links?.next && searchHits.length > 0) {
      vSet(state.searchMoreUrls, query, { ...state.searchMoreUrls[query], [filter]: result.links.next })
    } else {
      vSet(state.searchMoreUrls, query, { ...state.searchMoreUrls[query], [filter]: undefined })
    }
  }

  const handleSearchError = ({ filter, error }: { filter?: SearchFilters; error: any }): void => {
    if (filter === state.searchFilter) {
      state.error = error
    } else {
      // do nothing, error belongs to the different tab
    }
  }

  const startAiImageSearch = (): void => {
    state.xAiImagePreview = false
    state.xAiImageResults = true
  }

  const finishSearch = ({ query }: { query: string }): void => {
    if (state.searchQueriesInProgress.includes(query)) {
      vDel(state.searchQueriesInProgress, state.searchQueriesInProgress.indexOf(query))
    }
    if (state.searchMoreQueriesInProgress.includes(query)) {
      vDel(state.searchMoreQueriesInProgress, state.searchMoreQueriesInProgress.indexOf(query))
    }
  }

  function pickSearchFilter({ filter }: { filter: SearchFilters }): void {
    if (filter !== SearchFilters.AiImages) {
      endLinkInputMode()
    }

    if (filter && !state.xAiImageResults) {
      state.xSearchScreen = true
    }

    state.submittedSearchQuery = state.searchQuery
    state.searchFilter = filter
    state.error = null
  }

  async function search(options?: { filter?: SearchFilters; query?: string }): Promise<void> {
    let searchResult: SearchResult | null = null
    if (state.searchFilter == null) {
      pickSearchFilter({ filter: SearchFilters.Web })
    }
    const filter: SearchFilters = options?.filter ?? state.searchFilter ?? state.defaultSearchMode
    const query: string = options?.query ?? state.searchQuery

    appendSearchSuggestions({ query })
    try {
      startSearch({ query })
      if (filter === SearchFilters.AiImages) {
        startAiImageSearch()

        // TODO: [to-be-migrated][user]
        searchResult = await aiImageSearch(query, vuexStore?.state.user?.id)
        if (searchResult.error) {
          throw new Error(searchResult.error)
        }
      } else {
        searchResult = await webSearch(query, vuexStore?.state.user?.id, filter)
      }

      if (searchResult !== null) {
        handleSearchResult({ result: searchResult, query, filter, mode: 'update' })
      }
    } catch (error) {
      handleSearchError({ filter, error })
    } finally {
      finishSearch({ query })
    }
  }

  function hideContentPickerPanel(): void {
    state.activeContentSource = null
    resetSearch()
  }

  function hideContentSourceMenu(): void {
    state.xContentSourceMenu = false
    resetSearch()
  }

  function openSearch(): void {
    state.xSearchScreen = true
  }

  async function searchMore(options?: { query: string }): Promise<void> {
    let searchResult: SearchResult | null = null
    const filter: SearchFilters = state.searchFilter ?? state.defaultSearchMode
    const query: string = options?.query ?? state.searchQuery
    try {
      startSearch({ query, more: true })
      const currentFilter: SearchFilters = filter
      const url = state.searchMoreUrls[query][currentFilter]
      if (url) searchResult = await searchByUrl(url) // For searching using next url
      if (searchResult != null) {
        handleSearchResult({ result: searchResult, filter, query, mode: 'append' })
      }
    } catch (error) {
      handleSearchError({ error })
    } finally {
      finishSearch({ query })
    }
  }

  function clearSearchError(): void {
    state.error = null
  }

  function syncSearchSuggestionsWithStorage(): void {
    const stringValue = safeLocalStorage.getItem(SAFE_STORAGE_KEY)
    const suggestions: string[] = stringValue ? JSON.parse(stringValue) : []
    state.searchSuggestions = suggestions
  }

  function closeSearch(): void {
    state.xSearchScreen = false
  }

  function endLinkInputMode(): void {
    state.isLinkInputMode = false
  }

  function closeAiImageSearchMode(): void {
    state.xAiImagePreview = false
    state.xAiImageResults = false
  }

  function reopenAiImagePreview(): void {
    state.xAiImageResults = false
    state.xAiImagePreview = true
  }

  function setDefaultSearchMode(mode: SearchFilters): void {
    state.defaultSearchMode = mode
  }

  const { searchSuggestions: _unreversedSuggestions, ...stateToBeExported } = toRefs(state)

  /** Manual file browser trigger */
  function triggerFileBrowser(): void {
    state.isFileBrowserTriggered = true
  }

  // z-index override
  const zIndexOverride = ref<number | null>(null)
  const setZIndexOverride = (value: number | null): void => {
    zIndexOverride.value = value
  }

  return {
    // state
    ...stateToBeExported,
    // getters
    activeContentPickerPanel,
    xContentPicker,
    xContentPickerPanel,
    currentSearchResultsMap,
    isSearchQueryInProgress,
    isSearchMoreQueryInProgress,
    isError,
    currentSearchResultsForFilter,
    currentSearchMoreUrlForFilter,
    isAiImageSearchMode,
    xSearchSuggestions,
    searchSuggestions,
    isImageSearchMode,

    // actions
    pickContentSource,
    showContentSourceMenu,
    startLinkInputMode,
    openAiImagePreview,
    clearSearchFilter,
    setSearchBarPlaceholder,
    setSearchQuery,
    search,
    hideContentPickerPanel,
    hideContentSourceMenu,
    pickSearchFilter,
    openSearch,
    searchMore,
    clearSearchError,
    syncSearchSuggestionsWithStorage,
    closeSearch,
    endLinkInputMode,
    closeAiImageSearchMode,
    reopenAiImagePreview,
    setDefaultSearchMode,
    triggerFileBrowser,

    // z-index override
    zIndexOverride,
    setZIndexOverride,
  }
})
