/** @file
 * The store tracks the history of screen reader messages and provides a method to set a new screen reader message
 * based on changes to the Padlet wall settings.
 */

import { __ } from '@@/bits/intl'
import { useSurfaceStore } from '@@/pinia/surface'
import type { Wall } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const useScreenReaderNotificationsStore = defineStore('screenReaderNotifications', () => {
  const surfaceStore = useSurfaceStore()

  const screenReaderMessageHistory = ref<string[]>([])
  const latestScreenReaderMessage = computed(() =>
    screenReaderMessageHistory.value.length > 0
      ? screenReaderMessageHistory.value[screenReaderMessageHistory.value.length - 1]
      : '',
  )

  const addScreenReaderMessage = (message: string): void => {
    screenReaderMessageHistory.value.push(message)
  }

  function setScreenReaderMessageBasedOnWallUpdate(latestUpdate: Partial<Wall>): void {
    const prevTitle = surfaceStore.wallAttributes.title
    const prevDescription = surfaceStore.wallAttributes.description
    if (prevTitle !== latestUpdate.title) {
      addScreenReaderMessage(
        __(__('Padlet title changed from %{prevTitle} to %{newTitle}', { prevTitle, newTitle: latestUpdate.title })),
      )
    } else if (prevDescription !== latestUpdate.description) {
      addScreenReaderMessage(
        __(
          __('Padlet description changed from %{prevDescription} to %{newDescription}', {
            prevDescription,
            newDescription: latestUpdate.description,
          }),
        ),
      )
    }
  }

  return {
    screenReaderMessageHistory,
    latestScreenReaderMessage,
    setScreenReaderMessageBasedOnWallUpdate,
    addScreenReaderMessage,
  }
})

export { useScreenReaderNotificationsStore }
