/*
 * @file Helper functions for reconciling paginated API results with what the frontend already has
 */

import { JsonAPIResource } from '@padlet/arvo'

// During auto refresh, we will fetch the same page again
// some data may have been deleted from the page but they are not synced by realtime and still exist in the page
// we need to identify and delete them
function idsToDeleteInPage(
  isFirstPage: boolean,
  isLastPage: boolean,
  newData: JsonAPIResource<any>[],
  existingEntitiesById: Record<string, any>,
): (string | number)[] {
  // the array returned from the back end is guaranteed to be ordered by id (DESC)
  const newIdList = newData.map(datum => parseInt(datum.attributes.id))

  // last page should cover the smallest post id
  const lookupRangeStart = isLastPage ? 0 : newIdList[newData.length - 1]

  // first page should cover the biggest post id
  const lookupRangeEnd = isFirstPage ? Infinity : newIdList[0]

  return Object.keys(existingEntitiesById).filter((idKey: string): boolean => {
    const id: number = parseInt(existingEntitiesById[idKey].id)
    // Keep everything not inside the page's range
    if (id > lookupRangeEnd || id < lookupRangeStart) {
      return false
    }

    // Anything that's inside the page's range but not in newIdList
    // means they are deleted in the backend but the frontend does not know yet
    return !newIdList.includes(id)
  })
}

export { idsToDeleteInPage }
