// @file Store to control the media player on the surface.
import type { Cid, CommentId } from '@@/types'
import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'

type PlayingMediaEntry =
  | {
      type: 'post'
      id: Cid
    }
  | {
      type: 'comment'
      id: CommentId
    }

export const useSurfaceMediaPlayerStore = defineStore('surfaceMediaPlayer', () => {
  /**
   * Either a post CID or a comment ID since both can have media attachments.
   * Prefixed with `post:` if it's a post CID.
   * Prefixed with `comment:` if it's a comment ID.
   */
  const currentlyPlayingMedia = ref<PlayingMediaEntry | null>(null)

  const currentlyPlayingPostCid = computed<Cid | null>(() => {
    return currentlyPlayingMedia.value?.type === 'post' ? currentlyPlayingMedia.value.id : null
  })

  const currentlyPlayingCommentId = computed<CommentId | null>(() => {
    return currentlyPlayingMedia.value?.type === 'comment' ? currentlyPlayingMedia.value.id : null
  })

  const isPostPlayingMedia = (cid: Cid): boolean => {
    return currentlyPlayingMedia.value?.type === 'post' && currentlyPlayingMedia.value.id === cid
  }

  const isCommentPlayingMedia = (id: CommentId | undefined): boolean => {
    return currentlyPlayingMedia.value?.type === 'comment' && currentlyPlayingMedia.value.id === id
  }

  const setCurrentlyPlayingMedia = (entry: PlayingMediaEntry): void => {
    currentlyPlayingMedia.value = entry
  }

  const unsetCurrentlyPlayingMedia = (): void => {
    currentlyPlayingMedia.value = null
  }

  return {
    currentlyPlayingMedia: readonly(currentlyPlayingMedia),
    currentlyPlayingPostCid,
    currentlyPlayingCommentId,
    isPostPlayingMedia,
    isCommentPlayingMedia,
    setCurrentlyPlayingMedia,
    unsetCurrentlyPlayingMedia,
  }
})
