// @file Surface post filter store
import { POST_COLOR_STRINGS } from '@@/bits/post_color'
import { isPostPublished, isPostScheduled, isPostSubmitted } from '@@/bits/post_state'
import type { OrderedFilterState } from '@@/bits/surface_filter_state_manager'
import { constructFiltersFromQueryParams, getOrderedFilterState } from '@@/bits/surface_filter_state_manager'
import { isRegistered } from '@@/bits/user_model'
import { unboundedWatch, unboundedWatchEffect } from '@@/bits/vue'
import { postFilterResults, postFilterState } from '@@/native_bridge/actions'
import postMessage from '@@/native_bridge/post_message'
import { useSurfaceStore } from '@@/pinia/surface'
import { useCommentsStore } from '@@/pinia/surface_comments'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { SEARCH_QUERY_PARAM_KEY, useSurfacePostSearchStore } from '@@/pinia/surface_post_search'
import { useSurfaceUserContributorsStore } from '@@/pinia/surface_user_contributors'
import type { Post, SingleSelectOption, User } from '@@/types'
import type {
  FilterGroup,
  FilterKeyForGroup,
  NonNullPostColor,
  PartialPostFilters,
  PostAuthorId,
  PostFilters,
  PostStatus,
} from '@@/types/surface'
import { cloneDeep, debounce, intersectionBy, isEqual } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

function areSomeFiltersSelected(filters: PostFilters): boolean {
  return Object.values(filters).some((filterGroup) => Object.values(filterGroup).some((isSelected) => isSelected))
}

const isCustomPropFilterKey = (group: string, key: string): boolean => {
  // for custom prop key should be in the format data_type:custom_prop_id:option_id
  // and group should be in the format data_type:custom_prop_id
  return key.startsWith(group)
}

/**
 * Returns a predicate function for the given filter key in the given group.
 * The predicate function is used to tell if a post matches the filter.
 */
function getFilterPredicateForGroup<G extends FilterGroup>(
  group: G,
  filterKey: FilterKeyForGroup<G>,
): (p: Post) => boolean {
  switch (group) {
    case 'post_status':
      return (p: Post) => {
        if (filterKey === 'published') {
          return isPostPublished(p)
        } else if (filterKey === 'submitted') {
          return isPostSubmitted(p)
        } else if (filterKey === 'scheduled') {
          return isPostScheduled(p)
        }
        throw new Error('unknown post status ')
      }
    case 'post_color':
      return (p: Post) => filterKey === p.color || (filterKey === 'default' && p.color == null)
    case 'post_author':
      // User ID is a number but since we store `filterKey` as object key, it's a string.
      // That's why we convert `author.id` to string here.
      return (p: Post) => p.author_id?.toString() === filterKey
    default:
      // As of now, a post custom property can only have a single value.
      // If we support filtering posts by a custom property type, we just need to
      // check if the post has the given value in its `custom_properties` object.
      return (p: Post) => isPostCustomPropertyEqualFilterKey(group, filterKey, p)
  }
}

function isPostCustomPropertyEqualFilterKey(group: string, filterKey: string, p: Post): boolean {
  const dataType = group.split(':')[0]
  const customPropId = group.split(':')[1]
  switch (dataType) {
    case 'single_select':
    default:
      return p.custom_properties?.[customPropId] === filterKey
  }
}

/**
 * Returns posts that match any of the filters in the given group (OR logic).
 */
function filterPostsByGroup<G extends FilterGroup>(posts: Post[], filters: PostFilters, group: G): Post[] {
  const filterGroup = filters[group]
  const allFilters = Object.keys(filterGroup)
  const selectedFilters = allFilters.filter((key) => filterGroup[key])
  if (selectedFilters.length === 0) return posts
  const results: Post[] = []
  selectedFilters.forEach((key) => {
    results.push(
      ...posts.filter((p) => {
        return getFilterPredicateForGroup(group, key as FilterKeyForGroup<typeof group>)(p)
      }),
    )
  })
  return results
}

function getCustomPropKeys(filters: PostFilters): string[] {
  const nonCustomPropGroupKeys = ['post_status', 'post_color', 'post_author']
  return Object.keys(filters).filter((key) => !nonCustomPropGroupKeys.includes(key))
}

/**
 * Returns posts that match all set of filters by groups (AND logic).
 */
export function filterPosts(posts: Post[], filters: PostFilters): Post[] {
  if (!areSomeFiltersSelected(filters)) return posts
  const customPropKeys = getCustomPropKeys(filters)

  const filteredPostsByCustomPropMap =
    customPropKeys.length > 0
      ? customPropKeys.reduce((acc: Record<string, Post[]>, propId: string): Record<string, Post[]> => {
          const filteredPosts = filterPostsByGroup(posts, filters, propId)
          return {
            ...acc,
            [propId]: filteredPosts,
          }
        }, {})
      : {}

  return intersectionBy(
    filterPostsByGroup(posts, filters, 'post_status'),
    filterPostsByGroup(posts, filters, 'post_color'),
    filterPostsByGroup(posts, filters, 'post_author'),
    ...Object.values(filteredPostsByCustomPropMap),
    // We use `cid` as the unique identifier for posts.
    'cid',
  )
}

function createFilterState({
  currentFilters,
  xAuthor,
  postAuthors,
  singleSelectProps,
  isScreenshotMode,
}: {
  currentFilters: PartialPostFilters
  xAuthor: boolean
  postAuthors: User[]
  singleSelectProps: Record<string, { data_type: string; selection_options: SingleSelectOption[] }>
  isScreenshotMode: boolean
}): PostFilters {
  const postColorOptions = POST_COLOR_STRINGS.reduce((acc, color) => {
    acc[color] = currentFilters.post_color?.[color] ?? false
    return acc
  }, {}) as Record<FilterKeyForGroup<'post_color'>, boolean>

  const postAuthorOptions: Record<FilterKeyForGroup<'post_author'>, boolean> = {}
  // We need to enable the post author filter in screenshot mode for this condition:
  // User displays author name from settings panel -> filter posts by author -> export image and turn off `Show author name` button when exporting walls.
  if (xAuthor || isScreenshotMode) {
    const registeredPostAuthors = postAuthors.filter((user) => isRegistered(user))
    registeredPostAuthors.forEach((user) => {
      postAuthorOptions[user.id] = currentFilters.post_author?.[user.id] ?? false
    })
  }

  const singleSelectPropOptions: Record<string, Record<string, boolean>> = {}
  Object.entries(singleSelectProps).forEach(([customPropertyId, customProperty]) => {
    if (customProperty.data_type === 'single_select') {
      const options = customProperty.selection_options
      const dataType = customProperty.data_type
      const filterGroupKey = `${dataType}:${customPropertyId}`
      singleSelectPropOptions[filterGroupKey] = {}
      options.forEach((option) => {
        if (option?.id == null) return
        // key is data_type:custom_prop_id to prevent collisions
        // and to make it easier to filter by custom property data
        singleSelectPropOptions[filterGroupKey][option.id] = currentFilters?.[filterGroupKey]?.[option.id] ?? false
      })
    }
  })

  return {
    post_status: {
      published: currentFilters.post_status?.published ?? false,
      submitted: currentFilters.post_status?.submitted ?? false,
      scheduled: currentFilters.post_status?.scheduled ?? false,
    },
    post_color: postColorOptions,
    post_author: postAuthorOptions,
    ...singleSelectPropOptions,
  }
}

export const useSurfaceFilterStore = defineStore('surfaceFilter', () => {
  // Values from other stores
  const surfaceStore = useSurfaceStore()
  const surfaceCurrentUserStore = useSurfaceCurrentUserStore()
  const surfaceUserContributorsStore = useSurfaceUserContributorsStore()
  const surfacePostProperties = useSurfacePostPropertiesStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfacePostSearchStore = useSurfacePostSearchStore()

  // State
  const xFilterModal = ref(false)
  const filters = ref<PostFilters>(
    createFilterState({
      currentFilters: {},
      xAuthor: surfaceStore.xAuthor,
      postAuthors: surfaceUserContributorsStore.postAuthors,
      singleSelectProps: surfacePostProperties.wallSingleSelectPropsByCustomPropertyId,
      isScreenshotMode: surfaceStore.isScreenshotMode,
    }),
  )

  // Getters
  const hasSelectedFilters = computed(() => areSomeFiltersSelected(filters.value))
  const orderedFilterState = computed(() => {
    return getOrderedFilterState({
      filters: filters.value,
      isDarkMode: surfaceStore.colorScheme === 'dark',
      postAuthorsById: surfaceUserContributorsStore.postAuthorsById,
      currentUserId: surfaceCurrentUserStore.currentUser?.id,
      customPropsById: surfacePostProperties.wallSingleSelectPropsByCustomPropertyId,
    })
  })

  // Actions
  function togglePostFilter(group: FilterGroup, key: PostStatus | NonNullPostColor | PostAuthorId | string): void {
    const filterGroup = filters.value[group]
    const filterKey = isCustomPropFilterKey(group, key)
      ? key.split(':')[2] // key is data_type:custom_prop_id:option_id if isCustomPropFilterKey is true
      : key
    const filterOption = filterGroup[filterKey] as boolean | undefined
    if (filterOption != null) {
      filterGroup[filterKey] = !filterOption
    }
  }

  function updatePostFilter(
    group: FilterGroup,
    key: PostStatus | NonNullPostColor | PostAuthorId,
    value: boolean,
  ): void {
    const filterGroup = filters.value[group]
    const filterKey = isCustomPropFilterKey(group, key)
      ? key.split(':')[2] // key is data_type:custom_prop_id:option_id if isCustomPropFilterKey is true
      : key
    const filterOption = filterGroup[filterKey] as boolean | undefined
    if (filterOption != null) {
      filterGroup[filterKey] = value
    }
  }

  function resetPostFilters(group?: FilterGroup): void {
    const groupsToReset: FilterGroup[] = group == null ? Object.keys(filters.value) : [group]
    const newFilters = cloneDeep(filters.value)
    groupsToReset.forEach((group) => {
      const filterGroup = newFilters[group]
      for (const key in filterGroup) {
        const filterOption = filterGroup[key] as boolean | undefined
        if (filterOption != null) {
          filterGroup[key] = false
        }
      }
    })
    filters.value = newFilters
  }

  function setPostFiltersFromQueryParams(params: URLSearchParams): void {
    const newFilters = constructFiltersFromQueryParams(params)
    filters.value = createFilterState({
      currentFilters: newFilters,
      xAuthor: surfaceStore.xAuthor,
      postAuthors: surfaceUserContributorsStore.postAuthors,
      singleSelectProps: surfacePostProperties.wallSingleSelectPropsByCustomPropertyId,
      isScreenshotMode: surfaceStore.isScreenshotMode,
    })
  }

  // Watchers
  void unboundedWatchEffect(() => {
    if (surfaceStore.isScreenshotMode) return

    filters.value = createFilterState({
      currentFilters: filters.value,
      xAuthor: surfaceStore.xAuthor,
      postAuthors: surfaceUserContributorsStore.postAuthors,
      singleSelectProps: surfacePostProperties.wallSingleSelectPropsByCustomPropertyId,
      isScreenshotMode: surfaceStore.isScreenshotMode,
    })
  })

  void unboundedWatch([() => surfacePostsStore.allPosts, () => surfaceUserContributorsStore.postAuthors], () => {
    if (!surfaceStore.isScreenshotMode) return

    const params = new URLSearchParams(window.location.search)

    // Filters
    useSurfaceFilterStore().setPostFiltersFromQueryParams(params)

    // Search
    if (params.get(SEARCH_QUERY_PARAM_KEY) == null) return
    // Fetch all comments from the filtered posts.
    // We need to fetch all comments once because comments are also part of search results.
    if (!useCommentsStore().commentIds.length) {
      const filteredPosts = filterPosts(surfacePostsStore.allPosts, filters.value)
      filteredPosts.forEach((post) => {
        useCommentsStore().fetchComments({ wishId: post.id as number, wishHashid: post.hashid as string })
      })
    }
    useSurfacePostSearchStore().setSearchTermFromQueryParam(params)
  })

  function publishPostFilterStateUpdate(newState: OrderedFilterState): void {
    postMessage(postFilterState(newState))
  }

  const debouncedPublishPostFilterStateUpdate = debounce(publishPostFilterStateUpdate, 100)

  void unboundedWatch(orderedFilterState, (newState, oldState) => {
    if (isEqual(newState, oldState)) return
    debouncedPublishPostFilterStateUpdate(newState)
  })

  const dedupSendPostFilterResults = (() => {
    let lastNumPosts: number | null = null
    return (numPosts: number): void => {
      if (lastNumPosts === numPosts) return
      lastNumPosts = numPosts
      postMessage(postFilterResults(numPosts))
    }
  })()

  void unboundedWatchEffect(() => {
    const filteredPosts = filterPosts(surfacePostsStore.allPosts, filters.value)

    void surfacePostSearchStore
      .filterPostsBySearchTerm(filteredPosts, surfacePostSearchStore.searchTerm)
      .then((results) => {
        surfacePostsStore.updateCurrentPosts(results)
        dedupSendPostFilterResults(results.length)
      })
  })

  return {
    // State
    xFilterModal,
    filters,

    // Getters
    hasSelectedFilters,
    orderedFilterState,

    // Actions
    togglePostFilter,
    updatePostFilter,
    resetPostFilters,
    setPostFiltersFromQueryParams,
  }
})
