// @file utilities dealing with randomness
/**
 * Non-secure seeded shuffle: https://stackoverflow.com/a/53758827
 * This shuffles in-place!
 * @param array
 * @param seed seed used for the shuffle. Shuffle should be stable if given the same seed.
 * @returns the shuffled array.
 */
export function shuffle<T>(array: T[], seed: number = Math.random()): T[] {
  let currentIndex = array.length
  let temporaryValue: T
  let randomIndex: number
  function random(): number {
    const x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
  }
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}

export const generateRandomAlphaNumericString = (length: number): string => {
  let randomString = ''
  for (; randomString.length < length; randomString += Math.random().toString(36).substring(2));
  return randomString.substring(0, length)
}
