// @file Helper functions to transform filter state to various formats
import { __ } from '@@/bits/intl'
import { clearSearchParam, setSearchParam } from '@@/bits/location'
import { postColorText, POST_COLOR_STRINGS } from '@@/bits/post_color'
import { ANONYMOUS_AVATAR } from '@@/bits/user_model'
import type { Id, SingleSelectOption, User, WallCustomPostSingleSelectProperty } from '@@/types'
import type { FilterGroup, NonNullPostColor, PartialPostFilters, PostFilters, PostStatus } from '@@/types/surface'

interface PostStatusFilterGroup {
  group: 'post_status'
  text: string
  data: Array<{
    key: PostStatus
    text: string
    icon: string
    isSelected: boolean
  }>
}

interface PostColorFilterGroup {
  group: 'post_color'
  text: string
  data: Array<{
    key: NonNullPostColor
    text: string
    isSelected: boolean
  }>
}

interface PostAuthorFilterGroup {
  group: 'post_author'
  text: string
  data: Array<{
    key: string
    text: string
    avatar: string
    isCurrentUser: boolean
    isSelected: boolean
  }>
}

interface SingleSelectFilterData {
  key: string
  text: string
  isSelected: boolean
}
interface SingleSelectFilterGroup {
  group: string
  text: string
  data: SingleSelectFilterData[]
}

type OrderedFilterState = Array<
  PostStatusFilterGroup | PostColorFilterGroup | PostAuthorFilterGroup | SingleSelectFilterGroup
>

const POST_STATUS_TEXT: Record<PostStatus, string> = {
  published: __('Published'),
  submitted: __('Submitted'),
  scheduled: __('Scheduled'),
}

const POST_STATUS_ICON: Record<PostStatus, string> = {
  published: 'send_outline',
  submitted: 'inbox_outline',
  scheduled: 'recent_outline',
}

function getOrderedFilterState({
  filters,
  isDarkMode,
  postAuthorsById,
  currentUserId,
  customPropsById,
}: {
  filters: PostFilters
  isDarkMode: boolean
  postAuthorsById: Record<Id, User>
  currentUserId: Id | undefined
  customPropsById: Record<Id, WallCustomPostSingleSelectProperty>
}): OrderedFilterState {
  type PostStatusFilterDataEntry = PostStatusFilterGroup['data'][number]
  type PostColorFilterDataEntry = PostColorFilterGroup['data'][number]
  type PostAuthorFilterDataEntry = PostAuthorFilterGroup['data'][number]

  const orderedPostStatuses: readonly PostStatus[] = ['published', 'submitted', 'scheduled']
  const postStatusOptions = orderedPostStatuses.map<PostStatusFilterDataEntry>((key) => ({
    key,
    text: POST_STATUS_TEXT[key],
    icon: POST_STATUS_ICON[key],
    isSelected: filters.post_status[key],
  }))

  const postColorOptions = POST_COLOR_STRINGS.map<PostColorFilterDataEntry>((color) => {
    return {
      key: color,
      text: postColorText({ postColor: color, isLightColorScheme: !isDarkMode }),
      isSelected: filters.post_color[color],
    }
  })

  const postAuthorOptions = Object.entries(filters.post_author)
    .map<PostAuthorFilterDataEntry>(([key, isSelected]) => {
      const userId = key
      const user = postAuthorsById[userId]
      return {
        key: userId,
        // Disable these rules to also check for empty string
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing
        text: user.display_name || user.name || user.username || __('Anonymous'),
        avatar: user.avatar ?? ANONYMOUS_AVATAR,
        isCurrentUser: user.id === currentUserId,
        isSelected,
      }
    })
    .sort((a, b) => {
      // Always put current user first
      if (a.isCurrentUser) return -1
      if (b.isCurrentUser) return 1
      return a.text.localeCompare(b.text)
    })

  const singleSelectGroups = Object.entries(customPropsById).map<SingleSelectFilterGroup>(
    ([customPropId, customProp]) => {
      const options = customProp.selection_options
      const filterGroup = `${customProp.data_type}:${customPropId}`
      const singleSelectOptions = options
        .map((option: SingleSelectOption) => {
          if (option?.id == null) return null
          return {
            key: `${customProp.data_type}:${customPropId}:${option.id}`,
            text: option.name,
            isSelected: filters[filterGroup]?.[option.id],
          }
        })
        .filter((option): option is SingleSelectFilterData => {
          return option != null
        })
      return {
        group: filterGroup,
        text: customProp.name,
        data: singleSelectOptions,
      }
    },
  )

  return [
    {
      group: 'post_status',
      text: __('Post status'),
      data: postStatusOptions,
    },
    {
      group: 'post_color',
      text: __('Color'),
      data: postColorOptions,
    },
    {
      group: 'post_author',
      text: __('Author'),
      data: postAuthorOptions,
    },
    ...singleSelectGroups,
  ]
}

function serializeFiltersToQueryParams(filters: PostFilters): void {
  const groups: FilterGroup[] = ['post_status', 'post_color', 'post_author']
  for (const group of groups) {
    const filterGroup = filters[group]
    if (filterGroup == null) {
      clearSearchParam(group, true)
      continue
    }
    const selectedFilters = Object.keys(filterGroup).filter((key) => filterGroup[key])
    if (selectedFilters.length === 0) {
      clearSearchParam(group, true)
    } else {
      setSearchParam(group, selectedFilters.join(','), true)
    }
  }
}

function constructFiltersFromQueryParams(params: URLSearchParams): PartialPostFilters {
  const selectedFilters = new Map<FilterGroup, Set<string>>()
  for (const [key, value] of params.entries()) {
    const group = key
    if (!selectedFilters.has(group)) {
      selectedFilters.set(group, new Set<string>())
    }
    value
      .split(',')
      .filter((v) => v !== '')
      .forEach((v) => selectedFilters.get(group)?.add(v))
  }
  const filters = {}
  for (const [group, filterKeys] of selectedFilters.entries()) {
    const filterGroup = {}
    for (const key of filterKeys) {
      filterGroup[key] = true
    }
    filters[group] = filterGroup
  }
  return filters
}

export { constructFiltersFromQueryParams, getOrderedFilterState, serializeFiltersToQueryParams }
export type {
  OrderedFilterState,
  PostAuthorFilterGroup,
  PostColorFilterGroup,
  PostStatusFilterGroup,
  SingleSelectFilterGroup,
}
